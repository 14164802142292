import { Box } from '@mui/material'
import { FC } from 'react'
import { AdoptionAttemptDto, AnimalInsightsDtoType } from '../../../interactors/gen/backendClient'
import { InfoBox } from '../../common/InfoBox'

interface Props {
  attempt: AdoptionAttemptDto
}

export const AllAdoptionInsightsInfoBox: FC<Props> = ({ attempt }) => {
  const order: Array<AnimalInsightsDtoType> = ['info', 'error', 'warning']

  return (
    <Box sx={{ marginBottom: 1, marginTop: 1.5 }}>
      {attempt.insights
        .sort((a, b) => {
          const typeA = a.type
          const typeB = b.type

          // Get the index of each type in the order array
          const indexA = order.indexOf(typeA)
          const indexB = order.indexOf(typeB)

          // Compare the indexes to determine the sorting order
          return indexA - indexB
        })
        .map((insight) => {
          // eslint-disable-next-line react/jsx-key
          return <InfoBox messageType={insight.type} content={insight.message} sx={{ marginBottom: 1 }} />
        })}
    </Box>
  )
}
