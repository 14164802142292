import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { backendClient } from '../../interactors/clients/client'
import {
  CustomerPdfTemplateDtoType,
  CustomerPdfTemplateVariableZoneDto,
  CustomerPdfTemplateZoneDto,
  UploadCustomerPdfTemplateDto,
} from '../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { getCustomerTemplatesSrc } from '../../utils/S3-links'
import PdfZoneSelector, { SelectionZone } from './PdfSelectorZone'
import { ZoneList } from './ZoneList'
import { VariableZoneList } from './VariableZoneList'
import { templateVariablesList } from '../../domain/Customer/TemplateVariables'
import { metricalpEvent } from '@metricalp/react'
import { useAccountsStore } from '../../store/AccountsStore'

type TabKey = 'zones' | 'variables'

export const SelectZonesScreen: React.FC = () => {
  const globalSnackbarStore = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const location = useLocation()
  const template = location.state?.template as {
    id?: string
    name: string
    type: CustomerPdfTemplateDtoType
    documentKey?: string
    zones?: string // JSON stringified array of CustomerPdfTemplateZoneDto
    variables: string // JSON stringified array of CustomerPdfTemplateVariableZoneDto
    file?: File
  }

  const [selectedZoneType, setSelectedZoneType] = useState<CustomerPdfTemplateZoneDto['type']>()
  const [selectedVariable, setSelectedVariable] = useState<string>()
  const [zones, setZones] = useState<CustomerPdfTemplateZoneDto[]>(
    template.zones && template.zones.length > 0 ? JSON.parse(template.zones) : []
  )
  const [variableZones, setVariableZones] = useState<CustomerPdfTemplateVariableZoneDto[]>(
    template.variables && template.variables.length > 0 ? JSON.parse(template.variables) : []
  )
  const [selectedZoneIndex, setSelectedZoneIndex] = useState<number | null>(null)
  const [selectedVariableZoneIndex, setSelectedVariableZoneIndex] = useState<number | null>(null)
  const [currentPage, setCurrentPage] = useState(1)

  const translateLabel = (label: string): string | undefined => {
    switch (label) {
      case 'fullname':
        return 'Nom complet'
      case 'location':
        return 'Lieu de signature'
      case 'tofill':
        return 'Mention à remplir'
      case 'idNumber':
        return 'N° CNI ou Passeport'
      case 'natality':
        return 'Nationalité'
      case 'paymentMethod':
        return 'Mode de Paiement'

      default:
        return undefined
    }
  }

  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('zones')

  const handleChange = (_: React.SyntheticEvent | null, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
  }

  const handleZoneSelect = (zone: SelectionZone | null, pageDimensions: { width: number; height: number } | null) => {
    if (zone && selectedZoneType && currentTabIndex === 'zones') {
      let name
      let type = selectedZoneType
      if (type.includes('-')) {
        const [realType, label] = type.split('-')
        type = realType as CustomerPdfTemplateZoneDto['type']
        name = label ? translateLabel(label) : realType === 'date' ? 'Date de signature' : undefined
      }
      const docusealCoords = {
        page: zone.pageNumber,
        x: zone.x / zone.pageWidth,
        y: zone.y / zone.pageHeight,
        width: zone.width / zone.pageWidth,
        height: zone.height / zone.pageHeight,
      }

      const newZone: CustomerPdfTemplateZoneDto = { type: type, name, ...docusealCoords }
      setZones([...zones, newZone])
      console.log('Added zone:', newZone)
    } else if (zone && selectedVariable && currentTabIndex === 'variables' && pageDimensions) {
      const pdfLibCoords = {
        page: zone.pageNumber,
        x: zone.x / zone.pageWidth,
        y: zone.y / zone.pageHeight,
        width: zone.width / zone.pageWidth,
        height: zone.height / zone.pageHeight,
      }

      const newVariableZone: CustomerPdfTemplateVariableZoneDto = {
        variable: selectedVariable,
        ...pdfLibCoords,
      }

      setVariableZones([...variableZones, newVariableZone])
      console.log('Added variable zone:', newVariableZone)
    }
  }

  const handleDeleteZone = (index: number) => {
    setZones((prevZones) => prevZones.filter((_, i) => i !== index))
    if (selectedZoneIndex === index) setSelectedZoneIndex(null)
  }

  const handleZoneClick = (index: number, fromMenu?: boolean) => {
    setSelectedZoneIndex(index)
    if (index !== -1) {
      handleChange(null, 'zones')
    }
    if (fromMenu) {
      setCurrentPage(zones[index].page)
    }
  }

  const handleDeleteVariableZone = (index: number) => {
    setVariableZones((prevVariableZones) => prevVariableZones.filter((_, i) => i !== index))
    if (selectedVariableZoneIndex === index) setSelectedVariableZoneIndex(null)
  }

  const handleVariableZoneClick = (index: number, fromMenu?: boolean) => {
    setSelectedVariableZoneIndex(index)
    if (index !== -1) {
      handleChange(null, 'variables')
    }
    if (fromMenu) {
      setCurrentPage(zones[index].page)
    }
  }

  const addMutation = useMutation(
    async ({ data, file }: { data: UploadCustomerPdfTemplateDto; file: File }) => {
      const formData = new FormData()
      formData.append('file', file)
      if (data.type !== undefined) {
        formData.append('type', data.type)
      }

      if (data.name !== undefined) {
        formData.append('name', data.name)
      }

      if (data.zones !== undefined) {
        formData.append('zones', JSON.stringify(data.zones))
      }

      if (data.variables !== undefined) {
        formData.append('variables', JSON.stringify(data.variables))
      }
      return await backendClient.post(`customers/pdf-templates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    {
      onSuccess: () => {
        globalSnackbarStore.triggerSuccessMessage(`Votre modèle PDF a bien été ajouté !`)
        navigate('/association?tab=templates')
      },
    }
  )

  const editMutation = useMutation(
    async ({ data }: { data: UploadCustomerPdfTemplateDto }) => {
      return await backendClient.put(`customers/pdf-templates/${template.id}`, data)
    },
    {
      onSuccess: () => {
        globalSnackbarStore.triggerSuccessMessage(`Votre modèle PDF a bien été modifié !`)
        navigate('/association?tab=templates')
      },
    }
  )

  const handleSubmit = async () => {
    if (template.file && zones.length > 0) {
      addMutation.mutate({ data: { ...template, zones, variables: variableZones }, file: template.file })
    } else if (template.documentKey && template.id && zones.length > 0) {
      editMutation.mutate({ data: { name: template.name, type: template.type, zones, variables: variableZones } })
    }
  }

  return (
    <Container maxWidth="lg" sx={{ mb: 10, display: 'flex', justifyContent: 'center', gap: 4 }}>
      {/* Sidebar for Zone Selection */}
      <Box
        sx={{
          position: 'sticky',
          top: '20px',
          width: '100%',
          maxWidth: 250,
          height: 'fit-content',
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          sx={{
            marginBottom: '1rem',
            borderBottom: '1px solid lightgray',
            minHeight: '49px',
          }}
        >
          <Tab
            label={`Champs (${zones.length})`}
            value="zones"
            sx={{
              textTransform: 'none',
              borderRadius: '5px 5px 0 0',
              border: '1px solid lightgray',
              borderBottom: 'none',
              marginRight: '5px',
              marginBottom: '-1px',
              '&.Mui-selected': {
                backgroundColor: 'white',
                color: 'black',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          />
          <Tab
            label={`Variables (${variableZones.length})`}
            value="variables"
            sx={{
              textTransform: 'none',
              borderRadius: '5px 5px 0 0',
              border: '1px solid lightgray',
              borderBottom: 'none',
              marginBottom: '-1px',
              '&.Mui-selected': {
                backgroundColor: 'white',
                color: 'black',
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          />
        </Tabs>
        <TabPanel value={currentTabIndex} index={'zones' as TabKey}>
          <Typography variant="h6" sx={{ mb: '10px' }}>
            Ajouter un champ
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type de champ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedZoneType}
              onChange={(e) => setSelectedZoneType(e.target.value as CustomerPdfTemplateZoneDto['type'])}
              label="Type de champ"
            >
              <MenuItem value="signature">Signature</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="text-fullname">Nom complet</MenuItem>
              <MenuItem value="text-location">Lieu de signature</MenuItem>
              <MenuItem value="text-tofill">Mention à remplir</MenuItem>
              <MenuItem value="text-idNumber">N° CNI ou Passeport</MenuItem>
              <MenuItem value="text-natality">Nationalité</MenuItem>
              <MenuItem value="text-paymentMethod">Mode de Paiement</MenuItem>
            </Select>
          </FormControl>

          {/* List of Added Zones */}
          <ZoneList
            zones={zones}
            selectedZoneIndex={selectedZoneIndex}
            handleZoneClick={handleZoneClick}
            handleDeleteZone={handleDeleteZone}
            setZones={setZones}
          />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'variables' as TabKey}>
          <Typography variant="h6" sx={{ mb: '10px' }}>
            Ajouter une variable
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Variable</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedVariable}
              onChange={(e) => setSelectedVariable(e.target.value)}
              label="Variable"
            >
              {/* List of available variables */}
              {templateVariablesList.map((variable) => (
                <MenuItem key={variable.value} value={variable.value}>
                  {variable.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* List of Added Variables */}
          <VariableZoneList
            variableZones={variableZones}
            selectedVariableZoneIndex={selectedVariableZoneIndex}
            handleVariableZoneClick={handleVariableZoneClick}
            handleDeleteVariableZone={handleDeleteVariableZone}
            setVariableZones={setVariableZones}
          />
        </TabPanel>

        {/* Submit Button */}
        <LoadingButton
          loading={addMutation.isLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 4 }}
        >
          Enregister et quitter
        </LoadingButton>

        {/* Legend Section */}
        <Box
          sx={{
            marginTop: '20px',
            padding: '16px',
            backgroundColor: 'rgba(240, 240, 240, 0.9)',
            borderRadius: '8px',
            border: '1px solid lightgray',
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: '8px' }}>
            Légende
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            {/* Zone Legend */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box
                sx={{
                  minWidth: '24px',
                  height: '24px',
                  backgroundColor: 'rgba(0, 0, 128, 0.1)',
                  border: '2px solid navy',
                  borderRadius: '4px',
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: 13 }}
              >{`Champ à remplir par l'adoptant (ex: signature, lieu, etc.)`}</Typography>
            </Box>

            {/* Variable Legend */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Box
                sx={{
                  minWidth: '24px',
                  height: '24px',
                  backgroundColor: 'rgba(34, 139, 34, 0.2)',
                  border: '2px dashed forestgreen',
                  borderRadius: '4px',
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: 13 }}
              >{`Variable automatiquement remplie (ex: nom de l'animal, date du jour, etc.)`}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2, // Add some spacing between the legend and the tutorial
            p: 2,
            backgroundColor: 'rgba(240, 248, 255, 0.8)', // Light background for emphasis
            borderRadius: '8px',
            border: '1px solid lightgray',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            Besoin d&apos;aide ?
          </Typography>
          <Typography variant="body2" sx={{ textAlign: 'center', maxWidth: 300, color: 'text.secondary' }}>
            Consultez notre tutoriel vidéo pour découvrir comment utiliser cet éditeur et ajouter des champs ou
            variables personnalisés.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://www.loom.com/share/4530c6ac16ea4b989fbbb98b6bf86c27?sid=9a0ddab4-05cb-4f29-b956-841223e7b3a4"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              mt: 1,
              textTransform: 'none',
              fontSize: '16px',
              padding: '8px 16px',
            }}
            onClick={() => {
              metricalpEvent({
                type: 'tutorial_pdf_template',
                customerId: accountsStore.connectedAccount?.customerId,
                accountId: accountsStore.connectedAccount?.id,
                accountName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
              })
            }}
          >
            Regarder le tutoriel
          </Button>
        </Box>
      </Box>

      {/* PDF Selector */}
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4, width: 'fit-content' }}>
        <PdfZoneSelector
          pdfFileUrl={template.documentKey ? getCustomerTemplatesSrc(template.documentKey) : undefined}
          file={template.file}
          onZoneSelect={handleZoneSelect}
          disableZoneSelection={
            (selectedZoneType == null && currentTabIndex === 'zones') ||
            (selectedVariable == null && currentTabIndex === 'variables')
          }
          allZones={zones}
          onDeleteZone={handleDeleteZone}
          selectedZoneIndex={selectedZoneIndex}
          handleZoneClick={handleZoneClick}
          variableZones={variableZones}
          selectedVariableZoneIndex={selectedVariableZoneIndex}
          handleVariableZoneClick={handleVariableZoneClick}
          onDeleteVariableZone={handleDeleteVariableZone}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Paper>
    </Container>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default SelectZonesScreen
