import { Search as SearchIcon } from '@mui/icons-material'
import { Card, CardHeader, CardContent, Box, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import { colors, spacingItem } from '../../../theme'
import { DocumentItem } from './DocumentItem'
import { AddDocumentModal } from './AddDocumentModal'
import { AnimalDocumentDto, AnimalDto } from '../../../../interactors/gen/backendClient'
import { getReadableAnimalDocumentType } from '../../../../domain/Animal/AnimalDisplay'
import { useFetchOrRefreshMembersOnMount } from '../../../../store/useFetchOrRefreshMembersOnMount'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { useFetchOrRefreshAdoptersOnMount } from '../../../../store/useFetchOrRefreshAdoptersOnMount'
import { useAdoptersStore } from '../../../../store/AdoptersStore'
import { AdoptionDocumentItem } from './AdoptionDocumentItem'
import { fullName } from '../../../../domain/Adopters/AccountDisplay'

interface DocumentsListProps {
  documents: AnimalDocumentDto[]
  animal: AnimalDto
}

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents, animal }) => {
  useFetchOrRefreshMembersOnMount()
  useFetchOrRefreshAdoptersOnMount()

  const adoptersStore = useAdoptersStore()
  const adopter = adoptersStore.adopters.find((a) => a.id === animal.adoption.adopterId)
  const adoptionAttempt =
    adopter && adopter.adoptionAttempts ? adopter.adoptionAttempts.find((a) => a.animalId === animal.id) : undefined

  const canEditAnimal = useCanEditAnimal()

  const [searchQuery, setSearchQuery] = useState('')
  const [documentsList, setDocumentsList] = useState<AnimalDocumentDto[]>(documents)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const filteredDocuments = documentsList
    ? documentsList.filter(
        (document) =>
          searchQuery === '' ||
          document.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          document.reference?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          getReadableAnimalDocumentType(document.type)?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

  return (
    <>
      <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ ...spacingItem, backgroundColor: colors.white }}>
        <TextField
          id="name-input"
          placeholder="Rechercher un document"
          type="text"
          fullWidth
          value={searchQuery}
          onChange={handleSearch}
          sx={{
            borderRadius: 0,
            [`& fieldset`]: {
              borderRadius: 0,
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Card>
        <CardHeader title="Documents" />
        <CardContent>
          {filteredDocuments.length > 0 &&
            filteredDocuments.map((document) => (
              <DocumentItem
                key={document.id}
                document={document}
                animalId={animal.id}
                setDocumentsList={setDocumentsList}
              />
            ))}
          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <AddDocumentModal animalId={animal.id} disabled={!canEditAnimal} />
          </Box>
        </CardContent>
        <CardHeader title="Adoption" />
        <CardContent>
          {adoptionAttempt &&
            adoptionAttempt.certificate &&
            adoptionAttempt.certificate.signed &&
            adoptionAttempt.certificate.signedDate && (
              <AdoptionDocumentItem
                type="certificate"
                documentKey={adoptionAttempt.certificate.signed}
                uploadedAt={adoptionAttempt.certificate.signedDate}
                fullName={adopter ? fullName(adopter) : ''}
              />
            )}
          {adoptionAttempt &&
            adoptionAttempt.contract &&
            adoptionAttempt.contract.signed &&
            adoptionAttempt.contract.signedDate && (
              <AdoptionDocumentItem
                type="contract"
                documentKey={adoptionAttempt.contract.signed}
                uploadedAt={adoptionAttempt.contract.signedDate}
                fullName={adopter ? fullName(adopter) : ''}
              />
            )}
          {adoptionAttempt &&
            adoptionAttempt.sterilization &&
            adoptionAttempt.sterilization.file &&
            adoptionAttempt.sterilization.documentSentDate && (
              <AdoptionDocumentItem
                type="sterilization"
                documentKey={adoptionAttempt.sterilization.file}
                uploadedAt={adoptionAttempt.sterilization.documentSentDate}
                fullName={adopter ? fullName(adopter) : ''}
              />
            )}
          {adoptionAttempt &&
            adoptionAttempt.documents &&
            adoptionAttempt.documents.length > 0 &&
            adoptionAttempt.documents.map((document) => (
              <AdoptionDocumentItem
                key={document.id}
                type={document.type}
                documentKey={document.documentKey}
                uploadedAt={document.uploadedAt}
                fullName={adopter ? fullName(adopter) : ''}
              />
            ))}
        </CardContent>
      </Card>
    </>
  )
}
