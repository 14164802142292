import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import {
  AdopterDto,
  AdoptionAttemptDto,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
} from '../../interactors/gen/backendClient'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
import { blue } from '../theme'
import { AddDocumentsStep } from './FastLaunchProcedureSteps/AddDocumentsStep'
import { MatchAnimalStep } from './FastLaunchProcedureSteps/MatchAnimalStep'
import { SummaryStep } from './FastLaunchProcedureSteps/SummaryStep'
import { useAnimalStore } from '../../store/AnimalStore'

interface Props {
  adopter: AdopterDto | null
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  attemptId?: string
  providedAnimal?: AnimalDto
  noAdopterDelete?: boolean
  needNavigate?: boolean
}

export interface ProcedureStepsFormParams {
  hasAnimal: boolean
  gavedCertificate: boolean
  gavedContract: boolean
  animalId: string
}

export const FastLaunchProcedureDialog: React.FC<Props> = ({
  open,
  setOpen,
  adopter,
  attemptId,
  providedAnimal,
  noAdopterDelete = false,
  needNavigate = false,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const [currentStep, setCurrentStep] = React.useState(providedAnimal ? 2 : 1)
  const [animal, setAnimal] = React.useState<AnimalDto | undefined>(providedAnimal)
  const [adoptionAttempt, setAdoptionAttempt] = React.useState<AdoptionAttemptDto | undefined>(undefined)

  useFetchOrRefreshAdoptersOnMount()
  const navigate = useNavigate()
  const animalStore = useAnimalStore()

  const closeDialog = () => {
    if (currentStep === 3) {
      setOpen(false)
      setCurrentStep(providedAnimal ? 2 : 1)
      setAnimal(providedAnimal)
      setAdoptionAttempt(undefined)
      setIsLoading(false)
      setIsClosing(false)
      navigate(`/suivi-adoptant/${adopter?.id}`)
      return
    }
    setIsClosing(true)
  }

  const deleteAdopterAndCloseDialog = async () => {
    if (animal) {
      animal.adoption.status = AnimalAdoptionDtoStatus.Adoptable
      animal.adoption.adopterId = ''
      animal.adoption.step = undefined
      animal.adoption.adoptionDate = ''
      animal.adoption.exitDate = ''
      animal.adoption.vpaDate = ''
      await AnimalsClient.editAnimal(animal)
      animalStore.changeSelectedAnimal(animal.id)
    }
    if (adopter && !noAdopterDelete) {
      AdoptersClient.deleteAdopter(adopter.id).then(() => {
        setOpen(false)
      })
    }
    if (noAdopterDelete && adopter && adopter.adoptionAttempts) {
      adopter.adoptionAttempts = adopter.adoptionAttempts.filter(
        (attempt) => attempt.id !== ((adoptionAttempt?.id ?? attemptId) as string)
      )
      await AdoptersClient.editAccount(adopter)
    }
    setOpen(false)

    setCurrentStep(providedAnimal ? 2 : 1)
    setAnimal(providedAnimal)
    setAdoptionAttempt(undefined)
    setIsLoading(false)
    setIsClosing(false)
    if (needNavigate) {
      return navigate(`/suivi-adoptant/${adopter?.id}`)
    }
  }

  const uploadAdoptionDocumentMutation = useMutation(
    async ({
      file,
      adopterId,
      adoptionAttemptId,
      type,
      deliveredByHand,
      isLocked,
      deliverLater,
      pdfTemplateKey,
    }: {
      file: File
      adopterId: string
      adoptionAttemptId: string
      type: 'certificate' | 'contract'
      deliveredByHand?: boolean
      isLocked?: boolean
      deliverLater?: boolean
      pdfTemplateKey?: string
    }) => {
      const formData = new FormData()
      formData.append('file', file)

      if (deliveredByHand) {
        formData.append('deliveredByHand', 'true')
      }

      if (deliverLater) {
        formData.append('deliverLater', 'true')
      }

      if (isLocked) {
        formData.append('isLocked', 'true')
      }

      if (pdfTemplateKey) {
        formData.append('pdfTemplateKey', pdfTemplateKey)
        formData.append('deliveredByHand', 'false')
      }

      return await AdoptersClient.addOriginalDocumentToAdoptionAttempt(adopterId, adoptionAttemptId, formData, type)
    },
    {
      onSuccess: (data) => {
        setAdoptionAttempt(data)
      },
    }
  )

  const submitStep = async (data: {
    animalId?: string
    files?: File[]
    deliverLater?: boolean
    isLocked?: boolean
    certificateTemplateKey?: string
    contractTemplateKey?: string
  }) => {
    const { animalId, files, deliverLater, isLocked, certificateTemplateKey, contractTemplateKey } = data
    if (!adopter) return
    setIsLoading(true)
    if (currentStep === 1) {
      if (animalId) {
        const animal = await AnimalsClient.getAnimal(animalId)
        setAnimal(animal)
        adopter.profileHasBeenAccepted = 'accepted'
        await AdoptersClient.editAccount(adopter)
        animal.adoption.adopterId = adopter.id
        animal.adoption.status = AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable
        animal.adoption.step = AnimalAdoptionDtoStep.AdoptionToBeValidated
        animal.adoption.adoptionDate = undefined
        animal.adoption.exitDate = undefined
        animal.adoption.vpaDate = undefined
        await AnimalsClient.editAnimal(animal)
        await AdoptersClient.newAdoptionAttempt(adopter.id, animalId).then(async (attempt) => {
          setAdoptionAttempt(attempt)
          adopter.adoptionAttempts = [...(adopter.adoptionAttempts ?? []), attempt]
        })
      } else {
        setCurrentStep(3)
        return setIsLoading(false)
      }
    }
    if (currentStep === 2) {
      if (files && (adoptionAttempt || attemptId)) {
        await uploadAdoptionDocumentMutation.mutateAsync({
          file: files[0],
          adopterId: adopter.id,
          adoptionAttemptId: (adoptionAttempt?.id ?? attemptId) as string,
          type: 'certificate',
          deliveredByHand: !files[0],
          pdfTemplateKey: certificateTemplateKey,
        })
        await uploadAdoptionDocumentMutation.mutateAsync({
          file: files[1],
          adopterId: adopter.id,
          adoptionAttemptId: (adoptionAttempt?.id ?? attemptId) as string,
          type: 'contract',
          deliveredByHand: !files[1],
          deliverLater,
          isLocked,
          pdfTemplateKey: contractTemplateKey,
        })
      }
    }
    if (currentStep === 3) {
      if (adoptionAttempt || attemptId) {
        navigate(
          `/suivi-adoptant/${adopter.id}?tab=ongoing-adoption&attemptId=${(adoptionAttempt?.id ?? attemptId) as string}`
        )
      } else {
        navigate(`/suivi-adoptant/${adopter.id}`)
      }
      setOpen(false)
      setCurrentStep(providedAnimal ? 2 : 1)
      setAnimal(providedAnimal)
      setAdoptionAttempt(undefined)
      setIsClosing(false)
      return
    }
    setCurrentStep((prev) => prev + 1)
    setIsLoading(false)
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <MatchAnimalStep submitStep={submitStep} close={closeDialog} adopterName={adopter?.firstName || ''} />
      case 2:
        return (
          <AddDocumentsStep
            submitStep={submitStep}
            adopter={adopter}
            animal={animal}
            setAnimal={setAnimal}
            close={closeDialog}
          />
        )
      case 3:
        return <SummaryStep submitStep={submitStep} hasAnimal={animal != null} close={closeDialog} />
      default:
        return null
    }
  }

  return (
    <Dialog onClose={() => console.log('trying to close')} open={open} maxWidth="lg">
      {isLoading ? (
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={3}
            sx={{ width: '100%', height: '100%' }}
          >
            <Typography variant="h4" component="h2" sx={{ fontSize: '20px', color: blue }}>
              Chargement en cours...
            </Typography>
            <CircularProgress sx={{ marginTop: 2 }} />
          </Box>
        </DialogContent>
      ) : isClosing ? (
        /* Alert user that adopter will be deleted and animal if applicable will be unassigned */
        <>
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              padding={3}
              sx={{ width: '100%', height: '100%' }}
            >
              <Typography variant="h4" component="h2" sx={{ fontSize: '20px', color: blue }}>
                Êtes-vous sûr de vouloir quitter ?
              </Typography>
              <Typography>{`Si vous continuez, ${
                noAdopterDelete ? '' : `l'adoptant sera supprimé et `
              } l'animal restera adoptable`}</Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" onClick={() => setIsClosing(false)}>
              Annuler
            </Button>
            <Button variant="contained" onClick={() => deleteAdopterAndCloseDialog()}>
              Confirmer
            </Button>
          </DialogActions>
        </>
      ) : (
        renderStep()
      )}
    </Dialog>
  )
}
