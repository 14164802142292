import { Box, Card, CardContent, CardHeader, SxProps, Theme } from '@mui/material'
import dayjs from 'dayjs'
import { isEqual } from 'lodash'
import { nanoid } from 'nanoid'
import { FC, useEffect, useRef, useState } from 'react'
import {
  AnimalDto,
  AnimalHealthDtoPathologies,
  AnimalHealthDtoSterilizedOrNeutered,
  AnimalHealthOperationDto,
  AnimalHealthOperationDtoType,
  AnimalHealthTestResultDtoResult,
  AnimalHealthTestResultDtoType,
} from '../../../../interactors/gen/backendClient'
import { useCanEditAnimal } from '../../../../store/useCanEditAnimal'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useFetchOrRefreshMembersOnMount } from '../../../../store/useFetchOrRefreshMembersOnMount'
import { isInEnum } from '../../../../utils/isInEnum'
import { omitEmptyString } from '../../../../utils/omitEmptyString'
import { spacingItem } from '../../../theme'
import { AnimalHealthNewOperationModal, AnimalHealthOperations } from './AnimalHealthOperations'
import { AnimalHealthVaccines } from './AnimalHealthVaccines'

export const getMostRecentVaccinationOperation = (
  operations: AnimalHealthOperationDto[]
): AnimalHealthOperationDto | null => {
  const vaccinationTypes: AnimalHealthOperationDtoType[] = [
    AnimalHealthOperationDtoType.FirstInjection,
    AnimalHealthOperationDtoType.SecondInjection,
    AnimalHealthOperationDtoType.ThirdInjection,
    AnimalHealthOperationDtoType.AnnualReminder,
  ]

  return operations.reduce((mostRecent: AnimalHealthOperationDto | null, operation: AnimalHealthOperationDto) => {
    const isVaccinationType = operation.type.some((type) => vaccinationTypes.includes(type))

    if (!isVaccinationType) {
      return mostRecent
    }

    const operationDate = dayjs(operation.date, 'DD/MM/YYYY')
    const mostRecentDate = mostRecent ? dayjs(mostRecent.date, 'DD/MM/YYYY') : dayjs(0)

    return operationDate.isAfter(mostRecentDate) ? operation : mostRecent
  }, null)
}

export const getSecondMostRecentVaccinationOperation = (
  operations: AnimalHealthOperationDto[]
): AnimalHealthOperationDto | null => {
  const vaccinationTypes: AnimalHealthOperationDtoType[] = [
    AnimalHealthOperationDtoType.FirstInjection,
    AnimalHealthOperationDtoType.SecondInjection,
    AnimalHealthOperationDtoType.ThirdInjection,
    AnimalHealthOperationDtoType.AnnualReminder,
  ]

  let mostRecent: AnimalHealthOperationDto | null = null
  let secondMostRecent: AnimalHealthOperationDto | null = null

  operations.forEach((operation) => {
    const isVaccinationType = operation.type.some((type) => vaccinationTypes.includes(type))

    if (!isVaccinationType) {
      return
    }

    const operationDate = dayjs(operation.date, 'DD/MM/YYYY')

    if (!mostRecent || operationDate.isAfter(dayjs(mostRecent.date, 'DD/MM/YYYY'))) {
      secondMostRecent = mostRecent
      mostRecent = operation
    } else if (
      (!secondMostRecent || operationDate.isAfter(dayjs(secondMostRecent.date, 'DD/MM/YYYY'))) &&
      operationDate.isBefore(dayjs(mostRecent.date, 'DD/MM/YYYY'))
    ) {
      secondMostRecent = operation
    }
  })

  return secondMostRecent
}

export const getMostRecentDewormingOperation = (
  operations: AnimalHealthOperationDto[]
): AnimalHealthOperationDto | null => {
  const dewormingType: AnimalHealthOperationDtoType = AnimalHealthOperationDtoType.Deworming

  return operations.reduce((mostRecent: AnimalHealthOperationDto | null, operation: AnimalHealthOperationDto) => {
    const isDewormingType = operation.type.some((type) => type === dewormingType)

    if (!isDewormingType) {
      return mostRecent
    }

    const operationDate = dayjs(operation.date, 'DD/MM/YYYY')
    const mostRecentDate = mostRecent ? dayjs(mostRecent.date, 'DD/MM/YYYY') : dayjs(0)

    return operationDate.isAfter(mostRecentDate) ? operation : mostRecent
  }, null)
}

export const getSecondMostRecentDewormingOperation = (
  operations: AnimalHealthOperationDto[]
): AnimalHealthOperationDto | null => {
  const dewormingType = 'deworming'

  let mostRecent: AnimalHealthOperationDto | null = null
  let secondMostRecent: AnimalHealthOperationDto | null = null

  operations.forEach((operation) => {
    const isDewormingType = operation.type.some((type) => type === dewormingType)

    if (!isDewormingType) {
      return
    }

    const operationDate = dayjs(operation.date, 'DD/MM/YYYY')

    if (!mostRecent || operationDate.isAfter(dayjs(mostRecent.date, 'DD/MM/YYYY'))) {
      secondMostRecent = mostRecent
      mostRecent = operation
    } else if (
      (!secondMostRecent || operationDate.isAfter(dayjs(secondMostRecent.date, 'DD/MM/YYYY'))) &&
      operationDate.isBefore(dayjs(mostRecent.date, 'DD/MM/YYYY'))
    ) {
      secondMostRecent = operation
    }
  })

  return secondMostRecent
}

export const getMostRecentFleaControlOperation = (
  operations: AnimalHealthOperationDto[]
): AnimalHealthOperationDto | null => {
  const fleaControlType: AnimalHealthOperationDtoType = AnimalHealthOperationDtoType.FleaControl

  return operations.reduce((mostRecent: AnimalHealthOperationDto | null, operation: AnimalHealthOperationDto) => {
    const isFleaControlType = operation.type.some((type) => type === fleaControlType)

    if (!isFleaControlType) {
      return mostRecent
    }

    const operationDate = dayjs(operation.date, 'DD/MM/YYYY')
    const mostRecentDate = mostRecent ? dayjs(mostRecent.date, 'DD/MM/YYYY') : dayjs(0)

    return operationDate.isAfter(mostRecentDate) ? operation : mostRecent
  }, null)
}

export const getSecondMostRecentFleaControlOperation = (
  operations: AnimalHealthOperationDto[]
): AnimalHealthOperationDto | null => {
  const fleaControlType = 'flea-control'

  let mostRecent: AnimalHealthOperationDto | null = null
  let secondMostRecent: AnimalHealthOperationDto | null = null

  operations.forEach((operation) => {
    const isFleaControlType = operation.type.some((type) => type === fleaControlType)

    if (!isFleaControlType) {
      return
    }

    const operationDate = dayjs(operation.date, 'DD/MM/YYYY')

    if (!mostRecent || operationDate.isAfter(dayjs(mostRecent.date, 'DD/MM/YYYY'))) {
      secondMostRecent = mostRecent
      mostRecent = operation
    } else if (
      (!secondMostRecent || operationDate.isAfter(dayjs(secondMostRecent.date, 'DD/MM/YYYY'))) &&
      operationDate.isBefore(dayjs(mostRecent.date, 'DD/MM/YYYY'))
    ) {
      secondMostRecent = operation
    }
  })

  return secondMostRecent
}

interface Props {
  animal: AnimalDto
  sx?: SxProps<Theme>
}

export const AnimalHealthOperationsCard: FC<Props> = ({ animal, sx }) => {
  useFetchOrRefreshMembersOnMount()
  const [operations, setOperations] = useState<AnimalHealthOperationDto[]>(animal.health.operations)

  const initialOperationsRef = useRef(animal.health.operations)

  const editAnimalMutation = useEditAnimalMutation({})

  const canEditAnimal = useCanEditAnimal()

  useEffect(() => {
    if (isEqual(operations, animal.health.operations)) return
    setOperations(animal.health.operations)
    console.log('rerender')
  }, [animal.health.operations])

  useEffect(() => {
    const saveOperations = async () => {
      if (JSON.stringify(operations) !== JSON.stringify(initialOperationsRef.current)) {
        interface ResultsType {
          [type: string]: AnimalHealthOperationDto
        }

        interface TestResult {
          date: string
          result: AnimalHealthTestResultDtoResult
        }

        interface MostRecentPositiveTestResults {
          [key: string]: TestResult
        }

        const mostRecentPositiveTestResults: MostRecentPositiveTestResults = {}

        operations.forEach((operation) => {
          const currentDate = dayjs(operation.date, 'DD/MM/YYYY')
          operation.testResults?.forEach((testResult) => {
            const { type, result } = testResult
            // Check if the result is positive and either the type hasn't been added or the current date is more recent
            if (
              result === 'positive' &&
              (!mostRecentPositiveTestResults[type] ||
                currentDate.isAfter(dayjs(mostRecentPositiveTestResults[type].date, 'DD/MM/YYYY')))
            ) {
              mostRecentPositiveTestResults[type] = {
                date: operation.date,
                result: result,
              }
            }
          })
        })

        // Update the pathologies based on the most recent positive test results
        const updatedPathologiesWithTest: AnimalHealthTestResultDtoType[] = Object.keys(
          mostRecentPositiveTestResults
        ).reduce((acc: AnimalHealthTestResultDtoType[], type) => {
          acc.push(type as AnimalHealthTestResultDtoType) // Cast type to your specific enum/type if necessary
          return acc
        }, [])

        const oldPathologiesWithoutTest = animal.health.pathologies?.filter(
          (pathology) => !isInEnum(AnimalHealthTestResultDtoType, pathology)
        )

        const updatedPathologies = [...(oldPathologiesWithoutTest ?? []), ...updatedPathologiesWithTest]

        const resultsByType = operations.reduce((results: ResultsType, operation) => {
          const currentDate = dayjs(operation.date, 'DD/MM/YYYY')
          operation.type.forEach((type) => {
            if (
              (operation.identificationNumber || operation.tattooNumber) &&
              (type === AnimalHealthOperationDtoType.Tatoo || type === AnimalHealthOperationDtoType.IdentificationChip)
            ) {
              if (!results[type] || currentDate.isAfter(dayjs(results[type].date, 'DD/MM/YYYY'))) {
                results[type] = operation
              }
            }
          })

          if (operation.type.includes(AnimalHealthOperationDtoType.SterilizedOrNeutered)) {
            results.sterilizedOrNeutered = operation
          }

          return results
        }, {} as ResultsType)

        const mostRecentVaccinationOperation = getMostRecentVaccinationOperation(operations)
        const secondMostRecentVaccinationOperation = getSecondMostRecentVaccinationOperation(operations)
        if (
          animal.vaccination.vaccinationStatus === 'completed' &&
          mostRecentVaccinationOperation &&
          animal.vaccination.annualReminderFrequencyInYear !== -1
        ) {
          if (dayjs(mostRecentVaccinationOperation.date, 'DD/MM/YYYY').isBefore(dayjs(), 'days')) {
            if (!mostRecentVaccinationOperation.needsValidation || mostRecentVaccinationOperation.validated) {
              const formattedDate = dayjs(mostRecentVaccinationOperation.date, 'DD/MM/YYYY')
                .add(animal.vaccination.annualReminderFrequencyInYear || 1, 'year')
                .format('DD/MM/YYYY')
              operations.push({
                id: nanoid(),
                date: formattedDate,
                type: [AnimalHealthOperationDtoType.AnnualReminder],
                needsValidation: true,
                additionalInfo:
                  'Rendez-vous créé automatiquement à la prochaine date de rappel annuel conseillée. Vous pouvez modifier cette date si besoin.',
              })
            }
          } else if (
            secondMostRecentVaccinationOperation &&
            dayjs(mostRecentVaccinationOperation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'days') &&
            mostRecentVaccinationOperation.type.includes(AnimalHealthOperationDtoType.AnnualReminder)
          ) {
            operations.find((operation) => operation.id === mostRecentVaccinationOperation.id)!.date = dayjs(
              secondMostRecentVaccinationOperation.date,
              'DD/MM/YYYY'
            )
              .add(animal.vaccination.annualReminderFrequencyInYear || 1, 'year')
              .format('DD/MM/YYYY')
          }
        }

        const mostRecentDewormingOperation = getMostRecentDewormingOperation(operations)
        const secondMostRecentDewormingOperation = getSecondMostRecentDewormingOperation(operations)
        if (mostRecentDewormingOperation && animal.health.dewormingFrequencyInMonths !== -1) {
          const delayToAdd =
            animal.health.dewormingFrequencyInMonths === 0.5 ? 15 : animal.health.dewormingFrequencyInMonths || 1
          const unityToAdd = animal.health.dewormingFrequencyInMonths === 0.5 ? 'days' : 'months'
          if (dayjs(mostRecentDewormingOperation.date, 'DD/MM/YYYY').isBefore(dayjs(), 'days')) {
            if (!mostRecentDewormingOperation.needsValidation || mostRecentDewormingOperation.validated) {
              const formattedDate = dayjs(mostRecentDewormingOperation.date, 'DD/MM/YYYY')
                .add(delayToAdd, unityToAdd)
                .format('DD/MM/YYYY')
              operations.push({
                id: nanoid(),
                date: formattedDate,
                type: [AnimalHealthOperationDtoType.Deworming],
                needsValidation: true,
                additionalInfo:
                  'Rendez-vous créé automatiquement à la prochaine date de rappel de vermifuge conseillée. Vous pouvez modifier cette date si besoin.',
              })
            }
          } else if (
            secondMostRecentDewormingOperation &&
            dayjs(mostRecentDewormingOperation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'days')
          ) {
            operations.find((operation) => operation.id === mostRecentDewormingOperation.id)!.date = dayjs(
              secondMostRecentDewormingOperation.date,
              'DD/MM/YYYY'
            )
              .add(delayToAdd, unityToAdd)
              .format('DD/MM/YYYY')
          }
        }

        const mostRecentFleaControlOperation = getMostRecentFleaControlOperation(operations)
        const secondMostRecentFleaControlOperation = getSecondMostRecentFleaControlOperation(operations)
        if (mostRecentFleaControlOperation && animal.health.fleaControlFrequencyInMonths !== -1) {
          const delayToAdd =
            animal.health.fleaControlFrequencyInMonths === 0.5 ? 15 : animal.health.fleaControlFrequencyInMonths || 1
          const unityToAdd = animal.health.fleaControlFrequencyInMonths === 0.5 ? 'days' : 'months'
          if (dayjs(mostRecentFleaControlOperation.date, 'DD/MM/YYYY').isBefore(dayjs(), 'days')) {
            if (!mostRecentFleaControlOperation.needsValidation || mostRecentFleaControlOperation.validated) {
              const formattedDate = dayjs(mostRecentFleaControlOperation.date, 'DD/MM/YYYY')
                .add(delayToAdd, unityToAdd)
                .format('DD/MM/YYYY')
              operations.push({
                id: nanoid(),
                date: formattedDate,
                type: [AnimalHealthOperationDtoType.FleaControl],
                needsValidation: true,
                additionalInfo:
                  "Rendez-vous créé automatiquement à la prochaine date de rappel d'antipuce conseillée. Vous pouvez modifier cette date si besoin.",
              })
            }
          } else if (
            secondMostRecentFleaControlOperation &&
            dayjs(mostRecentFleaControlOperation.date, 'DD/MM/YYYY').isAfter(dayjs(), 'days')
          ) {
            operations.find((operation) => operation.id === mostRecentFleaControlOperation.id)!.date = dayjs(
              secondMostRecentFleaControlOperation.date,
              'DD/MM/YYYY'
            )
              .add(delayToAdd, unityToAdd)
              .format('DD/MM/YYYY')
          }
        }
        const { documents, ...animalWithoutDocuments } = animal
        const update = omitEmptyString({
          ...animalWithoutDocuments,
          tattoo: {
            ...animal.tattoo,
            number: resultsByType[AnimalHealthOperationDtoType.Tatoo]
              ? resultsByType[AnimalHealthOperationDtoType.Tatoo]?.tattooNumber
              : animal.tattoo?.number,
          },
          identificationNumber: resultsByType[AnimalHealthOperationDtoType.IdentificationChip]
            ? (resultsByType[AnimalHealthOperationDtoType.IdentificationChip]?.identificationNumber as string)
            : animal.identificationNumber,
          health: {
            ...animal.health,
            sterilizedOrNeutered: resultsByType.sterilizedOrNeutered
              ? ('true' as AnimalHealthDtoSterilizedOrNeutered)
              : undefined,
            operations: operations,
            pathologies: updatedPathologies as AnimalHealthDtoPathologies[],
          },
        })

        setOperations(operations)
        await editAnimalMutation.mutateAsync(update)
      }
    }

    saveOperations()
  }, [operations])

  return (
    <>
      <Card sx={sx}>
        <CardHeader title="💉 Historique et rendez-vous de santé:" />
        <CardContent>
          <Box sx={{ width: '100%', marginBottom: 3 }}>
            <AnimalHealthVaccines animal={animal} canEditAnimal={canEditAnimal} />
          </Box>
          <AnimalHealthOperations operations={operations} setOperations={setOperations} animal={animal} />
          <Box display="flex" justifyContent="flex-end" sx={spacingItem}>
            <AnimalHealthNewOperationModal
              animal={animal}
              operations={operations}
              setOperations={setOperations}
              disabled={!useCanEditAnimal()}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
