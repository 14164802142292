import { UploadFile } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { blue } from '@mui/material/colors'
import { AxiosRequestConfig } from 'axios'
import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { translateVariablePlaceholder } from '../../../domain/Customer/TemplateVariables'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { backendClient } from '../../../interactors/clients/client'
import {
  AdopterDto,
  AdoptionAttemptDto,
  AnimalAdoptionDtoOfferType,
  AnimalDto,
  AnimalDtoSex,
  AnimalDtoSpecies,
  CreateAnimalBodyDtoVaccinationStatus,
  CustomerDto,
} from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { blobToFile } from '../../../utils/blobToFile'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { setOpacity } from '../../../utils/setOpacity'
import { ControlledAutoCompleteWithCategories } from '../../common/ControlledAutocompleteWithCategories'
import { InfoBox } from '../../common/InfoBox'
import { colors, spacingItem, theme } from '../../theme'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { ControlledDateField } from '../../common/ControlledDateField'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import {
  getColorForAnimalVaccineStatus,
  getReadableAnimalAdoptionOfferType,
  getReadableAnimalVaccineStatus,
  getReadableSpecies,
} from '../../../domain/Animal/AnimalDisplay'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { catBreeds, dogBreeds } from '../../../domain/Animal/Breeds'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { Link } from 'react-router-dom'
import { useAccountsStore } from '../../../store/AccountsStore'

interface Props {
  setAdoptionAttempt: React.Dispatch<React.SetStateAction<AdoptionAttemptDto | undefined>>
  type: 'certificate' | 'contract'
  adopter: AdopterDto
  animal: AnimalDto
  adoptionAttemptId: string
  association?: CustomerDto | null
  specie?: AnimalDtoSpecies
  isLocked?: boolean
  disabled?: boolean
}

export interface VariableFormType {
  '{{adopter_address}}': string | undefined
  '{{adopter_city}}': string | undefined
  '{{adopter_email}}': string | undefined
  '{{adopter_first_name}}': string | undefined
  '{{adopter_last_name}}': string | undefined
  '{{adopter_phone}}': string | undefined
  '{{adopter_postal_code}}': string | undefined
  '{{adoption_date}}': string | undefined
  '{{adoption_fee}}': string | undefined
  '{{adoption_type}}': string | undefined
  '{{animal_birth_date}}': string | undefined
  '{{animal_breed}}': string | undefined
  '{{animal_gender}}': string | undefined
  '{{animal_identification_number}}': string | undefined
  '{{animal_name}}': string | undefined
  '{{animal_new_name}}': string | undefined
  '{{current_date}}': string | undefined
  '{{vaccine_recall_date}}': string | undefined
  '{{vaccine_status}}': string | undefined
  '{{sterilization_date}}': string | undefined
  '{{deworming_recall_date}}': string | undefined
  '{{flea_control_recall_date}}': string | undefined
  '{{identifying_marks}}': string | undefined
  '{{animal_species}}': string | undefined
  '{{asso_name}}': string | undefined
  '{{asso_address}}': string | undefined
  '{{asso_postal_code}}': string | undefined
  '{{asso_city}}': string | undefined
  '{{asso_siret_number}}': string | undefined
  '{{president_last_name}}': string | undefined
  '{{president_first_name}}': string | undefined
  '{{asso_logo}}': string | undefined
  '{{asso_signature}}': string | undefined
}

export const AddAdoptionDocumentModal: React.FC<Props> = ({
  setAdoptionAttempt,
  type,
  adopter,
  adoptionAttemptId,
  animal,
  association,
  specie,
  disabled = false,
  isLocked = false,
}) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const globalSnackbar = useGlobalSnackbarStore()
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, inputRef } = useDropzone()
  const [step, setStep] = React.useState(0)
  const [loading, setLoading] = useState(false)
  const [choosedPetsoDocument, setChoosedPetsoDocument] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string>('')
  const [missingData, setMissingData] = useState<string[]>([])
  const [allVariables, setAllVariables] = useState<VariableFormType | null>()

  const adopterId = adopter.id
  const animalId = animal.id

  /*   const previewCertificateURLs = {
    cat: 'https://petso-customers-documents.s3.amazonaws.com/Certificat-Petso-Chat.pdf',
    dog: 'https://petso-customers-documents.s3.amazonaws.com/Certificat-Petso-Chien.pdf',
    ferret: 'https://petso-customers-documents.s3.amazonaws.com/Certificat-Petso-Furet.pdf',
    rabbit: 'https://petso-customers-documents.s3.amazonaws.com/Certificat-Petso-Lapin.pdf',
  } */

  const sxField = { ...spacingItem }

  const accountsStore = useAccountsStore()

  const resetAndCloseDialog = () => {
    setStep(0)
    setFileUrl('')
    setChoosedPetsoDocument(false)
    setMissingData([])
    setAllVariables(null)
    acceptedFiles.length = 0
    setLoading(false)
    closeDialog()
  }

  const specieThatHasCertificateGeneration =
    specie && ['cat', 'dog', 'ferret', 'rabbit'].includes(specie)
      ? (specie as 'cat' | 'dog' | 'ferret' | 'rabbit')
      : null

  const mutation = useMutation(
    async ({ file, pdfTemplateKey }: { file?: File; pdfTemplateKey?: string }) => {
      const formData = new FormData()

      if (file) {
        formData.append('file', file)
      }

      if (pdfTemplateKey) {
        formData.append('pdfTemplateKey', pdfTemplateKey)
        formData.append('deliveredByHand', 'false')
      }

      return await AdoptersClient.addOriginalDocumentToAdoptionAttempt(adopterId, adoptionAttemptId, formData, type)
    },
    {
      onSuccess: (data) => {
        setAdoptionAttempt(data)
        adopter.adoptionAttempts = adopter.adoptionAttempts?.map((a) => (a.id === adoptionAttemptId ? data : a))
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        if (inputRef.current?.value) {
          inputRef.current.value = ''
        }
        closeDialog()
        globalSnackbar.triggerSuccessMessage(`Votre document a bien été ajouté !`)
      },
    }
  )

  const generateCertificateMutation = useMutation(
    async () => {
      return await AdoptersClient.generateCertificate(animalId)
    },
    {
      onSuccess: (data) => {
        acceptedFiles[0] = blobToFile(data, 'Certificat by Petso.pdf')
        setFileUrl(URL.createObjectURL(data))
        setChoosedPetsoDocument(true)
        setStep(1)
        globalSnackbar.triggerSuccessMessage(`Votre certificat a bien été généré !`)
      },
    }
  )

  const convertHtmlToPdf = async (templateKey: string) => {
    setLoading(true)
    try {
      const config: AxiosRequestConfig = {
        method: 'GET',
        url: `/adopters/generate/template/${animalId}?adopterId=${adopterId}&templateKey=${templateKey}`,
        responseType: 'json', // Expect JSON response
      }

      const response = await backendClient(config)

      // Access the JSON response directly
      const htmlContent = response.data.htmlContent
      const missingData = response.data.missingData
      const allVariables = response.data.allVariables
      setAllVariables(allVariables)
      setMissingData(missingData)

      const pdf = new jsPDF('p', 'pt', 'a4') // Use points (pt) for dimensions

      // Create a container element to inject the HTML content
      const container = document.createElement('div')
      container.style.width = '565px' // Increase width to fill more of the page
      container.style.margin = '0 auto' // Center the content
      container.innerHTML = htmlContent

      // Improve HTML/CSS for PDF rendering
      container.style.fontSize = '10pt' // Slightly larger font size for better readability
      container.style.lineHeight = '1.3' // Adjust line height to prevent overlap
      container.style.overflow = 'hidden' // Prevent overflow
      container.querySelectorAll('ul').forEach((ul) => {
        ul.style.paddingInlineStart = '20px' // Adjust list indentation
      })
      container.querySelectorAll('li').forEach((li) => {
        li.style.paddingLeft = '10px' // Adjust list item indentation
      })

      // Add the HTML content to the PDF
      await pdf.html(container, {
        callback: (doc) => {
          // Get the PDF as a Blob
          const pdfBlob = doc.output('blob')
          acceptedFiles[0] = blobToFile(pdfBlob, 'Certificat by Petso.pdf')

          setFileUrl(URL.createObjectURL(pdfBlob))
          setChoosedPetsoDocument(true)
          setLoading(false)
        },
        margin: [40, 40, 40, 40], // Set margins in points (20pt ~ 7mm)
        autoPaging: 'text', // Adjust paging based on text content
        html2canvas: {
          proxy: 'https://petso-backend.herokuapp.com/v1/proxy/html2canvas', // Use a proxy to avoid CORS issues
          allowTaint: false,
          useCORS: false,
          letterRendering: true,
          logging: false,
          scale: 0.9, // Return to default scale for better content fit
        },
        width: 565, // Adjusted content width
      })
    } catch (error) {
      console.error('Error converting HTML to PDF:', error)
      setLoading(false)
    }
  }

  const fillPdfTemplate = async (templateId: string) => {
    setLoading(true)
    try {
      const config: AxiosRequestConfig = {
        method: 'GET',
        url: `/adopters/generate/pdf-template/${animalId}?adopterId=${adopterId}&templateId=${templateId}`,
        responseType: 'arraybuffer', // Expect binary PDF file
      }

      const response = await backendClient(config)

      // Extract metadata from headers
      const metadataHeader = response.headers['x-metadata'] // Adjust for the exact header name
      console.log('Headers', response.headers)
      let metadata: { missingData: string[]; allVariables: VariableFormType } | undefined
      if (metadataHeader) {
        metadata = JSON.parse(metadataHeader)
        console.log('Metadata:', metadata)
      }

      if (!metadata) return

      // Extract metadata details
      const { missingData, allVariables } = metadata

      // Update state
      setAllVariables(allVariables)
      setMissingData(missingData)

      // Convert binary PDF to Blob for rendering or download
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      console.log('Generated PDF URL:', pdfUrl)
      acceptedFiles[0] = blobToFile(blob, 'Certificat by Petso.pdf')
      setFileUrl(pdfUrl)
      setChoosedPetsoDocument(true)
      setLoading(false)

      // Use `pdfUrl` for rendering or downloading
      console.log('Generated PDF URL:', pdfUrl)
    } catch (error) {
      console.error('Error converting HTML to PDF:', error)
      setLoading(false)
    }
  }

  const onSubmit = async (data?: formType) => {
    if (data && data.templateKey === 'Pas encore de modèles dans votre asso') return
    if (step === 0) {
      setLoading(true)
      if (data && data.templateKey === 'Importer un document PDF') {
        return setStep(1)
      }
      setChoosedPetsoDocument(true)
      if (data && data.templateKey === 'Certificat généré par Petso' && type === 'certificate')
        await generateCertificateMutation.mutateAsync()
      if (data && data.templateKey === 'Contrat généré par Petso' && type === 'contract')
        await convertHtmlToPdf('C-1002-H2lmUwJMPpx3otc6zYjlX.html')
      if (data && data.templateKey) {
        const documentKey = association?.templates?.find((template) => template.name === data.templateKey)?.documentKey
        if (documentKey) await convertHtmlToPdf(documentKey)
      }
      if (data && data.templateKey) {
        const pdfTemplate = association?.pdfTemplates?.find((template) => template.name === data.templateKey)
        if (pdfTemplate) {
          if (pdfTemplate.variables && pdfTemplate.variables.length > 0) {
            await fillPdfTemplate(pdfTemplate.id)
          } else {
            const documentKey = pdfTemplate.documentKey
            if (documentKey) await mutation.mutateAsync({ pdfTemplateKey: documentKey })
          }
        }
      }
      setStep(1)
      return setLoading(false)
    }

    if (acceptedFiles[0]) {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl)
      }
      let templateKey
      if (watchTemplateKey) {
        const pdfTemplate = association?.pdfTemplates?.find((template) => template.name === watchTemplateKey)
        if (pdfTemplate) {
          templateKey = pdfTemplate.documentKey
        } else {
          templateKey = undefined
        }
      }
      mutation.mutate({ file: acceptedFiles[0], pdfTemplateKey: templateKey })
    } else {
      globalSnackbar.triggerErrorMessage(`Vous devez ajouter un fichier !`)
    }
  }

  const onSubmitVariableForm = async (data: VariableFormType) => {
    setLoading(true)

    const newAdopter: AdopterDto = {
      ...adopter,
      firstName: data['{{adopter_first_name}}'] || adopter.firstName,
      lastName: data['{{adopter_last_name}}'] || adopter.lastName,
      address: data['{{adopter_address}}'] || '',
      postalCode: data['{{adopter_postal_code}}'] || '',
      city: data['{{adopter_city}}'] || adopter.city,
      email: data['{{adopter_email}}'] || adopter.email,
      phoneNumber: data['{{adopter_phone}}'] || adopter.phoneNumber,
    }

    const newAnimal: AnimalDto = {
      ...animal,
      name: data['{{animal_name}}'] || '',
      sex: data['{{animal_gender}}'] as AnimalDtoSex,
      birthday: { date: data['{{animal_birth_date}}'] || animal.birthday?.date || '' },
      identificationNumber: data['{{animal_identification_number}}'] || animal.identificationNumber,
      species: (data['{{animal_species}}'] || animal.species) as AnimalDtoSpecies,
      breedData: {
        ...animal.breedData,
        breedTitle: animal.breedData?.breedTitle || 'breed-type',
        breed: data['{{animal_breed}}'] || animal.breedData?.breed || '',
      },
      adoption: {
        ...animal.adoption,
        futureAdoptionDate: data['{{adoption_date}}'] || '',
        priceInEuros: Number(data['{{adoption_fee}}']?.trim().replace(',', '.').replace('€', '')) || 0,
        offerType: data['{{adoption_type}}'] as AnimalAdoptionDtoOfferType,
        newName: data['{{animal_new_name}}'] || '',
      },
      vaccination: {
        ...animal.vaccination,
        vaccinationStatus: data['{{vaccine_status}}'] as CreateAnimalBodyDtoVaccinationStatus,
      },
      identifyingMarks: data['{{identifying_marks}}']?.split(',').map((mark) => mark.trim()) || [],
    }

    setMissingData([])
    setAllVariables(null)
    resetVariableForm({
      '{{adopter_address}}': adopter.address || undefined,
      '{{adopter_city}}': adopter.city || undefined,
      '{{adopter_email}}': adopter.email || undefined,
      '{{adopter_first_name}}': adopter.firstName || undefined,
      '{{adopter_last_name}}': adopter.lastName || undefined,
      '{{adopter_phone}}': adopter.phoneNumber || undefined,
      '{{adopter_postal_code}}': adopter.postalCode || undefined,
      '{{adoption_date}}': animal?.adoption?.futureAdoptionDate || undefined,
      '{{adoption_fee}}': animal?.adoption?.priceInEuros?.toString() || undefined,
      '{{adoption_type}}': animal?.adoption?.offerType || undefined,
      '{{animal_birth_date}}': animal?.birthday?.date || undefined,
      '{{animal_breed}}': animal?.breedData?.breed || undefined,
      '{{animal_gender}}': animal.sex || undefined,
      '{{animal_identification_number}}': animal?.identificationNumber || undefined,
      '{{animal_name}}': animal.name || undefined,
      '{{animal_new_name}}': animal?.adoption?.newName || undefined,
      '{{current_date}}': undefined,
      '{{vaccine_recall_date}}': undefined,
      '{{vaccine_status}}': animal?.vaccination?.vaccinationStatus || undefined,
      '{{sterilization_date}}': undefined,
      '{{deworming_recall_date}}': undefined,
      '{{flea_control_recall_date}}': undefined,
      '{{identifying_marks}}': animal?.identifyingMarks?.join(', ') || undefined,
      '{{animal_species}}': animal?.species || undefined,
      '{{asso_name}}': undefined,
      '{{asso_address}}': undefined,
      '{{asso_postal_code}}': undefined,
      '{{asso_city}}': undefined,
      '{{asso_siret_number}}': undefined,
      '{{president_last_name}}': undefined,
      '{{president_first_name}}': undefined,
      '{{asso_logo}}': undefined,
      '{{asso_signature}}': undefined,
    })
    setFileUrl('')

    acceptedFiles.length = 0

    await AdoptersClient.editAccount(newAdopter)
    await AnimalsClient.editAnimal(newAnimal)

    const pdfTemplate = association?.pdfTemplates?.find((template) => template.name === getValues('templateKey'))
    if (pdfTemplate) {
      if (pdfTemplate.variables && pdfTemplate.variables.length > 0) {
        await fillPdfTemplate(pdfTemplate.id)
      }
    }

    const documentKey =
      association?.templates?.find((template) => template.name === getValues('templateKey'))?.documentKey ||
      'C-1002-H2lmUwJMPpx3otc6zYjlX.html'
    if (documentKey) await convertHtmlToPdf(documentKey)
    setLoading(false)
  }

  const {
    control: variableControl,
    handleSubmit: handleVariableSubmit,
    reset: resetVariableForm,
    watch: watchVariableForm,
    register: registerVariableForm,
    formState: { isDirty },
  } = useForm<VariableFormType>({
    defaultValues: {
      '{{adopter_address}}': adopter.address || undefined,
      '{{adopter_city}}': adopter.city || undefined,
      '{{adopter_email}}': adopter.email || undefined,
      '{{adopter_first_name}}': adopter.firstName || undefined,
      '{{adopter_last_name}}': adopter.lastName || undefined,
      '{{adopter_phone}}': adopter.phoneNumber || undefined,
      '{{adopter_postal_code}}': adopter.postalCode || undefined,
      '{{adoption_date}}': animal?.adoption?.futureAdoptionDate || undefined,
      '{{adoption_fee}}': animal?.adoption?.priceInEuros?.toString() || undefined,
      '{{adoption_type}}': animal?.adoption?.offerType || undefined,
      '{{animal_birth_date}}': animal?.birthday?.date || undefined,
      '{{animal_breed}}': animal?.breedData?.breed || undefined,
      '{{animal_gender}}': animal.sex || undefined,
      '{{animal_identification_number}}': animal?.identificationNumber || undefined,
      '{{animal_name}}': animal.name || undefined,
      '{{animal_new_name}}': animal?.adoption?.newName || undefined,
      '{{current_date}}': undefined,
      '{{vaccine_recall_date}}': undefined,
      '{{vaccine_status}}': animal?.vaccination?.vaccinationStatus || undefined,
      '{{sterilization_date}}': undefined,
      '{{deworming_recall_date}}': undefined,
      '{{flea_control_recall_date}}': undefined,
      '{{identifying_marks}}': animal?.identifyingMarks?.join(', ') || undefined,
      '{{animal_species}}': animal?.species || undefined,
      '{{asso_name}}': undefined,
      '{{asso_address}}': undefined,
      '{{asso_postal_code}}': undefined,
      '{{asso_city}}': undefined,
      '{{asso_siret_number}}': undefined,
      '{{president_last_name}}': undefined,
      '{{president_first_name}}': undefined,
      '{{asso_logo}}': undefined,
      '{{asso_signature}}': undefined,
    },
  })

  const species = accountsStore.connectedCustomer?.preferences.species

  const adoptionFee = watchVariableForm('{{adoption_fee}}')

  useEffect(() => {
    if (allVariables && Object.keys(allVariables).length > 0) {
      if (!Object.keys(allVariables).includes('{{adoption_fee}}')) {
        return resetVariableForm({
          ...allVariables,
          '{{adoption_fee}}': adoptionFee,
        })
      } else {
        return resetVariableForm({
          ...allVariables,
          '{{adoption_fee}}': allVariables['{{adoption_fee}}']
            ? allVariables['{{adoption_fee}}'].trim().replace(',', '.').replace('€', '')
            : '0',
        })
      }
    }
  }, [allVariables])

  interface formType {
    templateKey: string
  }

  const { control, handleSubmit, getValues, watch } = useForm<formType>()

  const templateType = type === 'certificate' ? 'knowledge-certificate-model' : 'adoption-contract-model'

  const options = [
    {
      title: "Modèles PDF de l'asso",
      options: association?.pdfTemplates
        ? association?.pdfTemplates
            ?.filter((template) => template.type === templateType)
            .map((template) => template.name)
        : ['Pas encore de modèle PDF dans votre asso'],
    },
    {
      title: "Modèles de l'asso",
      options: association?.templates
        ? association?.templates?.filter((template) => template.type === templateType).map((template) => template.name)
        : ['Pas encore de modèles dans votre asso'],
    },
    {
      title: 'Modèle Petso',
      options: specieThatHasCertificateGeneration
        ? ['Certificat généré par Petso']
        : type === 'contract'
        ? ['Contrat généré par Petso']
        : [],
    },
    {
      title: 'Import ponctuel (pdf)',
      options: ['Importer un document PDF'],
    },
  ]

  const breedOptions =
    animal.species === 'cat'
      ? catBreeds.map((breed) => ({ label: breed, value: breed }))
      : dogBreeds.map((breed) => ({ label: breed, value: breed }))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const watchTemplateKey = watch('templateKey')

  return (
    <>
      <Tooltip
        title={
          type === 'contract' && disabled === true
            ? `Vous devez valider les informations de paiement avant de pouvoir envoyer le contrat`
            : ''
        }
      >
        <span style={{ width: '100%' }}>
          <Button
            variant="contained"
            fullWidth={isSmallScreen ? false : true}
            sx={{
              textTransform: 'none',
              fontSize: { xs: '14px', sm: '16px' },
              padding: { xs: 0.5, sm: 'auto' },
              width: '100%',
            }}
            onClick={openDialog}
            disabled={type === 'contract' && disabled === true}
          >
            {isLocked
              ? `Préparer le ${type === 'certificate' ? 'certificat' : 'contrat'}`
              : `Envoyer le ${type === 'certificate' ? 'certificat' : 'contrat'}`}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        open={isDialogOpen}
        onClose={() => resetAndCloseDialog()}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
        sx={{ width: { xs: 'auto', sm: '500px', md: 700 }, mx: 'auto' }}
      >
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          {loading && watchTemplateKey !== 'Importer un document PDF' && (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={3}>
              <Typography variant="h4" component="h2" sx={{ fontSize: '20px', color: blue }}>
                Génération du document...
              </Typography>
              <CircularProgress sx={{ marginTop: 2 }} />
            </Box>
          )}
          {step === 0 ? (
            <Box>
              {!loading && (
                <Typography
                  variant="h4"
                  component="h2"
                  marginBottom={4}
                  sx={{ fontSize: { xs: '18px', sm: '20px' }, color: blue }}
                >
                  {`Emission du ${type === 'certificate' ? 'certificat de connaissance' : "contrat d'adoption"}`}
                </Typography>
              )}

              <Typography variant="h4" component="h2" marginBottom={4} sx={{ fontSize: '18px', color: 'black' }}>
                Pour envoyer en un clique vos propre certificats et contrats,{' '}
                <Link to="/association/template">créez et enregistrez vos modèles</Link>
              </Typography>
              <form id="templateForm" onSubmit={handleSubmit(onSubmit)}>
                <ControlledAutoCompleteWithCategories
                  control={control}
                  error={
                    watchTemplateKey === 'Pas encore de modèles dans votre asso'
                      ? { type: 'value', message: 'Champ requis' }
                      : undefined
                  }
                  label="Choisir un modèle"
                  fieldName="templateKey"
                  options={options.filter((option) => option.options.length > 0)}
                  disabled={loading}
                  sx={{ marginBottom: 2 }}
                  categoryColor={theme.palette.secondary.main}
                />
              </form>
            </Box>
          ) : (
            // STEP 1: UPLOAD CERTIFICATE / CONTRACT
            <Box>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontSize: { xs: '18px', sm: '20px' },
                  color: blue,
                  fontWeight: { xs: 700, sm: 'normal' },
                  textAlign: { xs: 'center', sm: 'auto' },
                  mb: { xs: 3, sm: 4 },
                }}
              >
                {!loading &&
                  (choosedPetsoDocument
                    ? `Votre ${
                        type === 'certificate' ? 'certificat de connaissance' : "contrat d'adoption"
                      } est prêt pour envoi`
                    : `Importer votre propre modèle de ${
                        type === 'certificate' ? 'certificat de connaissance' : "contrat d'adoption"
                      } pour signature`)}
              </Typography>
              {missingData.length > 0 && (
                <Box marginBottom={2}>
                  <InfoBox
                    content="Un ou plusieurs champs utilisés dans le modèle sont manquants !"
                    messageType="error"
                  />
                </Box>
              )}
              {choosedPetsoDocument ? (
                <Box sx={{ mb: 2 }}>
                  <a
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                      color: colors.blue, // Style attribute for color
                      fontSize: isSmallScreen ? '16px' : '18px',
                    }}
                    href={fileUrl ?? '#'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {acceptedFiles[0]?.name ? 'Aperçu du document' : 'Aucun fichier sélectionné'}
                  </a>
                </Box>
              ) : isSmallScreen ? (
                <Box {...getRootProps()} width="100%">
                  <input {...getInputProps()} />
                  <Box>
                    <Typography fontSize="16px" textAlign="center">
                      {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                    </Typography>
                  </Box>
                  <Button variant="contained" sx={{ width: '100%', mt: 2, textTransform: 'none' }}>
                    Parcourir
                  </Button>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  padding={3}
                  {...getRootProps({
                    sx: {
                      ...spacingItem,
                      border: 3,
                      borderRadius: 5,
                      borderStyle: 'dashed',
                      borderColor: isDragActive ? 'green' : blue,
                      backgroundColor: isDragActive
                        ? 'lightgreen'
                        : acceptedFiles[0]
                        ? setOpacity(colors.geyser, 0.4)
                        : 'transparent',
                      transition: 'all 0.3s',
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
                  <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                    Glissez-déposer ou
                    <br />
                    Insérer un document
                  </Typography>
                  <Box
                    marginTop={2}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 1,
                      maxWidth: '100%',
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="p"
                      fontSize={18}
                      textAlign="center"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Votre document:
                    </Typography>
                    <Typography
                      variant="h4"
                      component="p"
                      fontSize={18}
                      textAlign="center"
                      color={colors.black}
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                      }}
                    >
                      {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                    </Typography>
                  </Box>
                  <Button variant="contained" sx={{ textTransform: 'none', fontSize: '16px', width: '40%', mt: 2 }}>
                    Parcourir
                  </Button>
                </Box>
              )}
              {missingData.length > 0 && (
                <Box marginBottom={2}>
                  <InfoBox
                    content="Un ou plusieurs champs utilisés dans le modèle sont manquants !"
                    messageType="error"
                  />
                </Box>
              )}
              {!adoptionFee && (
                <Box marginBottom={2}>
                  <InfoBox
                    content="Le montant de l'adoption n'a pas été renseigné, veuillez le renseigner pour continuer."
                    messageType="error"
                  />
                </Box>
              )}
              {((allVariables && Object.keys(allVariables).length > 0) || !animal?.adoption?.priceInEuros) && (
                <form id="variableForm" onSubmit={handleVariableSubmit(onSubmitVariableForm)}>
                  <Box marginBottom={2}>
                    <Typography variant="h4" component="h2" sx={{ fontSize: '18px', color: 'black' }}>
                      {`Variables utilisées dans le modèle:`}
                    </Typography>
                    <TextField
                      id="price-input"
                      label="Montant de l'adoption"
                      inputProps={{
                        step: '0.01',
                        inputMode: 'decimal',
                        pattern: '[0-9]*',
                      }}
                      fullWidth
                      {...registerVariableForm('{{adoption_fee}}', { required: true })}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      }}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      sx={sxField}
                    />
                    {allVariables &&
                      Object.keys(allVariables).length > 0 &&
                      Object.keys(allVariables).map((key) => {
                        if (key.includes('fee') || key.includes('logo') || key.includes('signature')) {
                          return null
                        }
                        if (key.includes('species')) {
                          return (
                            <ControlledSelectField
                              key={key}
                              sx={spacingItem}
                              control={variableControl}
                              error={undefined}
                              fieldName="{{animal_species}}"
                              label="Espèce"
                              options={
                                species
                                  ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                                      label: getReadableSpecies(status, { withEmoji: true }),
                                      value: status,
                                    }))
                                  : []
                              }
                              requiredRule="L'espèce est requise."
                              disabled={species?.length === 1}
                            />
                          )
                        }
                        if (key.includes('date')) {
                          return (
                            <ControlledDateField
                              key={key}
                              control={variableControl}
                              validate={(value) => isValidPastDateCoherence(value) || value === null}
                              error={undefined}
                              fieldName={key as keyof VariableFormType}
                              label={translateVariablePlaceholder(key)}
                              requiredRule={undefined}
                              sx={{
                                ...sxField,
                                backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                              }}
                              disabled={key.includes('recall') || key.includes('sterilization')}
                              helperText={
                                key.includes('recall') || key.includes('sterilization')
                                  ? `Champ modifiable uniquement via la fiche de l'animal`
                                  : ''
                              }
                              size="small"
                            />
                          )
                        }
                        if (key.includes('phone')) {
                          return (
                            <FormControl
                              fullWidth
                              key={key}
                              variant="outlined"
                              sx={{
                                ...sxField,
                              }}
                            >
                              <Controller
                                name={key as keyof VariableFormType}
                                control={variableControl}
                                rules={{
                                  validate: (value: string | undefined) => {
                                    return !value ||
                                      value.trim() === '' ||
                                      matchIsValidTel(value) ||
                                      value.trim() === '+33'
                                      ? true
                                      : 'Tel is invalid'
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <MuiTelInput
                                    {...field}
                                    label="Numero de Téléphone"
                                    defaultCountry="FR"
                                    helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                                    error={!!fieldState.error}
                                    sx={{
                                      backgroundColor: missingData.includes(key)
                                        ? setOpacity(colors.red, 0.5)
                                        : 'inherit',
                                    }}
                                    size="small"
                                  />
                                )}
                              />
                            </FormControl>
                          )
                        }
                        if (
                          animal &&
                          key.includes('breed') &&
                          (animal?.species === 'dog' || animal?.species === 'cat')
                        ) {
                          return (
                            <ControlledAutoCompleteWithCustomValue
                              key={key}
                              control={variableControl}
                              fieldName={key as keyof VariableFormType}
                              error={undefined}
                              aria-label="Race"
                              label="Race"
                              requiredRule="La race est requise"
                              options={breedOptions}
                              defaultValue=""
                              size="small"
                              sx={{
                                ...sxField,
                                backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                              }}
                            />
                          )
                        }
                        if (key.includes('adoption_type')) {
                          return (
                            <ControlledSelectField
                              key={key}
                              control={variableControl}
                              error={undefined}
                              fieldName={key as keyof VariableFormType}
                              label={translateVariablePlaceholder(key)}
                              options={Object.values(AnimalAdoptionDtoOfferType).map(
                                (field: AnimalAdoptionDtoOfferType) => ({
                                  label: getReadableAnimalAdoptionOfferType(field),
                                  value: field,
                                })
                              )}
                              requiredRule=""
                              size="small"
                              sx={{
                                ...sxField,
                                backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                              }}
                            />
                          )
                        }
                        if (key.includes('gender')) {
                          return (
                            <ControlledSelectField
                              key={key}
                              sx={{
                                ...sxField,
                                backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                              }}
                              control={variableControl}
                              error={undefined}
                              fieldName={key as keyof VariableFormType}
                              label={translateVariablePlaceholder(key)}
                              options={[
                                { label: 'Mâle', value: 'male' },
                                { label: 'Femelle', value: 'female' },
                              ]}
                              requiredRule="Le sexe est requis"
                              size="small"
                            />
                          )
                        }
                        if (key.includes('vaccine_status')) {
                          return (
                            <ColoredChipSelectField
                              key={key}
                              control={variableControl}
                              error={undefined}
                              fieldName={key as keyof VariableFormType}
                              size="small"
                              label="Statut primovaccination"
                              options={Object.values(CreateAnimalBodyDtoVaccinationStatus).map(
                                (status: CreateAnimalBodyDtoVaccinationStatus) => ({
                                  label: getReadableAnimalVaccineStatus(status),
                                  value: status,
                                  color: getColorForAnimalVaccineStatus(status),
                                })
                              )}
                              sx={{
                                ...sxField,
                                backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                              }}
                              requiredRule="Le statut primovaccination est requis"
                            />
                          )
                        }
                        return (
                          <Controller
                            key={key}
                            name={key as keyof VariableFormType}
                            control={variableControl}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label={translateVariablePlaceholder(key)}
                                fullWidth
                                sx={{
                                  ...sxField,
                                  backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                                }}
                                size="small"
                                disabled={key.includes('asso') || key.includes('president')}
                                helperText={
                                  key.includes('asso') || key.includes('president')
                                    ? `Champ modifiable uniquement via la fiche de l'asso`
                                    : ''
                                }
                              />
                            )}
                          />
                        )
                      })}
                  </Box>
                </form>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: { xs: 0, sm: 'auto' }, pt: { xs: 1, sm: 2 } }}>
          {step === 0 ? (
            <>
              <LoadingButton
                variant="contained"
                type="submit"
                form="templateForm"
                loading={loading}
                sx={{
                  textTransform: 'none',
                  fontSize: { xs: '14px', sm: '16px' },
                  width: { xs: '100%', sm: 'auto' },
                }}
              >
                {`Suivant`}
              </LoadingButton>
            </>
          ) : (
            <>
              <Button
                data-testid="cancel"
                onClick={() => resetAndCloseDialog()}
                sx={{
                  textTransform: 'none',
                  fontSize: { xs: '14px', sm: '16px' },
                  width: { xs: '50%', sm: 'auto' },
                  px: { xs: 'auto', sm: '16px' },
                }}
              >
                Annuler
              </Button>
              {isDirty ? (
                <LoadingButton
                  data-testid="apply-changes"
                  variant="contained"
                  form="variableForm"
                  type="submit"
                  loading={loading}
                  sx={{
                    textTransform: 'none',
                    fontSize: { xs: '14px', sm: '16px' },
                    width: { xs: '50%', sm: 'auto' },
                    px: { xs: 'auto', sm: '16px' },
                  }}
                >
                  Enregistrer les modifications
                </LoadingButton>
              ) : (
                <LoadingButton
                  data-testid="apply-changes"
                  variant="contained"
                  type="submit"
                  loading={mutation.isLoading}
                  onClick={() => onSubmit()}
                  sx={{
                    textTransform: 'none',
                    fontSize: { xs: '14px', sm: '16px' },
                    width: { xs: '50%', sm: 'auto' },
                    px: { xs: 'auto', sm: '16px' },
                  }}
                >
                  {missingData.length > 0 ? 'Valider quand même' : 'Valider'}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
