import React from 'react'

import { Stack, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, useMediaQuery } from '@mui/material'

import { AnimalSearchItemDto } from '../../interactors/gen/backendClient'
import { getReadableAnimalFieldLabel } from '../../domain/Animal/AnimalDisplay'
import dayjs from 'dayjs'
import { AnimalsTableItem } from './AnimalsTableItem'
import { sortAlphabetically, sortByAdoptionStatus } from '../../utils/sort'
import { fullName } from '../../domain/Account/AccountDisplay'
import AnimalsListCard from './AnimalsListCard'

type SearchResultsProps = {
  animals: Array<AnimalSearchItemDto>
  setSortDirection: React.Dispatch<React.SetStateAction<SortDirection>>
  setSortColumn: React.Dispatch<React.SetStateAction<SortColumn>>
  sortColumn: SortColumn
  sortDirection: SortDirection
  myAnimalsSection?: boolean
  disableFrontSorting?: boolean
  assignAnimalId?: React.Dispatch<React.SetStateAction<string | null>>
  selectedAnimalId?: string
}

export type SortDirection = 'asc' | 'desc'
export type SortColumn =
  | 'name'
  | 'takeOverAt'
  | 'species'
  | 'memberInChargeAccount'
  | 'hostFamilyInChargeAccount'
  | 'adoptionStatus'
  | 'identificationNumber'
  | 'insights'
  | 'birthdate'

export const AnimalsTable: React.FC<SearchResultsProps> = ({
  animals,
  setSortColumn,
  setSortDirection,
  sortColumn,
  sortDirection,
  myAnimalsSection = false,
  disableFrontSorting = false,
  assignAnimalId,
  selectedAnimalId,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const [expandedCardIndex, setExpandedCardIndex] = React.useState<number | null>(null)
  // Mobile version: when a tooltip is open, click on Card closes the tooltip (and does not navigate to animal's page)
  const [openedTooltip, setOpenedTooltip] = React.useState(false)

  const handleSort = (column: SortColumn, columnDefaultDirection: SortDirection) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortDirection(columnDefaultDirection)
    }

    setSortColumn(column)
  }

  const searchResult = disableFrontSorting
    ? animals
    : animals.sort((a, b) => {
        switch (sortColumn) {
          case 'takeOverAt':
            if (dayjs(a.takeOverAt).isAfter(dayjs(b.takeOverAt))) {
              return sortDirection === 'asc' ? -1 : 1
            }

            return sortDirection === 'asc' ? 1 : -1
          case 'name':
            return sortAlphabetically(a, b, sortDirection, 'name')
          case 'species':
            return sortAlphabetically(a, b, sortDirection, 'species', 'sex', 'name')
          case 'memberInChargeAccount':
            return sortAlphabetically(
              {
                memberInChargeAccount:
                  a.responsibilities?.memberInChargeAccount && fullName(a.responsibilities.memberInChargeAccount),
                name: a.name,
              },
              {
                memberInChargeAccount:
                  b.responsibilities?.memberInChargeAccount && fullName(b.responsibilities.memberInChargeAccount),
                name: b.name,
              },
              sortDirection,
              'memberInChargeAccount',
              'name'
            )
          case 'hostFamilyInChargeAccount':
            return sortAlphabetically(
              {
                hostFamilyInChargeAccount:
                  a.responsibilities?.hostFamilyInChargeAccount &&
                  fullName(a.responsibilities.hostFamilyInChargeAccount),
                name: a.name,
              },
              {
                hostFamilyInChargeAccount:
                  b.responsibilities?.hostFamilyInChargeAccount &&
                  fullName(b.responsibilities.hostFamilyInChargeAccount),
                name: b.name,
              },
              sortDirection,
              'hostFamilyInChargeAccount',
              'name'
            )
          case 'adoptionStatus':
            return sortByAdoptionStatus(a, b, sortDirection)
          case 'identificationNumber':
            return sortAlphabetically(a, b, sortDirection, 'identificationNumber', 'name')
          case 'insights':
            if (a.insightsCounters.error > b.insightsCounters.error) return sortDirection === 'asc' ? -1 : 1
            if (a.insightsCounters.error < b.insightsCounters.error) return sortDirection === 'asc' ? 1 : -1
            if (a.insightsCounters.info > b.insightsCounters.info) return sortDirection === 'asc' ? -1 : 1
            if (a.insightsCounters.info < b.insightsCounters.info) return sortDirection === 'asc' ? 1 : -1
            if (a.insightsCounters.warning > b.insightsCounters.warning) return sortDirection === 'asc' ? -1 : 1
            if (a.insightsCounters.warning < b.insightsCounters.warning) return sortDirection === 'asc' ? 1 : -1
            return sortAlphabetically(a, b, 'asc', 'name')
          default:
            throw new Error(`sortColumn ${sortColumn} not supported`)
        }
      })

  return !isMobile ? (
    <Table stickyHeader={true} aria-label="simple table" sx={{ minWidth: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'name'}
              direction={sortDirection}
              onClick={() => {
                handleSort('name', 'asc')
              }}
            >
              {getReadableAnimalFieldLabel('name')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'takeOverAt'}
              direction={sortDirection}
              onClick={() => {
                handleSort('takeOverAt', 'desc')
              }}
            >
              Date d&apos;entrée
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'species'}
              direction={sortDirection}
              onClick={() => {
                handleSort('species', 'asc')
              }}
            >
              {getReadableAnimalFieldLabel('species')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'insights'}
              direction={sortDirection}
              onClick={() => {
                handleSort('insights', 'desc')
              }}
            >
              Rappel à venir
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'memberInChargeAccount'}
              direction={sortDirection}
              onClick={() => {
                handleSort('memberInChargeAccount', 'asc')
              }}
            >
              Référent
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'hostFamilyInChargeAccount'}
              direction={sortDirection}
              onClick={() => {
                handleSort('hostFamilyInChargeAccount', 'asc')
              }}
            >
              Lieu de résidence
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'adoptionStatus'}
              direction={sortDirection}
              onClick={() => {
                handleSort('adoptionStatus', 'asc')
              }}
            >
              Statut
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'birthdate'}
              direction={sortDirection}
              disabled
              onClick={() => {
                handleSort('birthdate', 'desc')
              }}
            >
              Date de naissance
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ paddingRight: 0 }}>
            <TableSortLabel
              active={sortColumn === 'identificationNumber'}
              direction={sortDirection}
              onClick={() => {
                handleSort('identificationNumber', 'asc')
              }}
            >
              Identification
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResult.map((animal) => (
          <AnimalsTableItem
            key={animal.id}
            animal={animal}
            myAnimalsSection={myAnimalsSection}
            assignAnimalId={assignAnimalId}
            selectedAnimalId={selectedAnimalId}
          />
        ))}
      </TableBody>
    </Table>
  ) : (
    // Mobile version
    <Stack spacing={1.5} marginTop="10px" marginBottom="120px" marginX="15px">
      {searchResult.map((animal, index) => {
        return (
          <AnimalsListCard
            key={animal.id}
            animal={animal}
            myAnimalsSection={myAnimalsSection}
            assignAnimalId={assignAnimalId}
            selectedAnimalId={selectedAnimalId}
            index={index}
            expandedCardIndex={expandedCardIndex}
            setExpandedCardIndex={setExpandedCardIndex}
            openedTooltip={openedTooltip}
            setOpenedTooltip={setOpenedTooltip}
          />
        )
      })}
    </Stack>
  )
}
