import React, { useEffect } from 'react'
import {
  AccountDtoPermission,
  AdopterDto,
  AdopterDtoProfileHasBeenAccepted,
} from '../../../interactors/gen/backendClient'
import { useDialogOpened } from '../../../utils/hooks/useDialogOpened'
import { fullName } from '../../../domain/Adopters/AccountDisplay'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material'
import { Edit } from '@mui/icons-material'
import { Controller } from 'react-hook-form'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../PATHS'
import { useAccountsStore } from '../../../store/AccountsStore'

interface FormParams {
  hasDesiredAnimal?: boolean
}

interface Props {
  adopter: AdopterDto
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
}

export const DesiredAnimalPopUp: React.FC<Props> = ({ adopter, onSubmit }) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()

  const getDefaultValues = (adopter: AdopterDto): FormParams => ({
    hasDesiredAnimal: adopter.desiredAnimal?.hasDesiredAnimal || undefined,
  })

  const { control, handleSubmit, watch, setValue } = useFormExtended(adopter, getDefaultValues)

  const processSubmit = (data: FormParams) => {
    if (data.hasDesiredAnimal === undefined) {
      return
    }
    if (data.hasDesiredAnimal === true) {
      return navigate(`${PATHS.assignerAnimal.absolute}?adopterId=${adopter.id}`)
    }
    const update: Partial<AdopterDto> = {
      desiredAnimal: {
        hasDesiredAnimal: data.hasDesiredAnimal,
      },
    }
    onSubmit(update)
    closeDialog()
  }

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  useEffect(() => {
    if (
      queryParams.get('assign') === 'success' ||
      adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Refused ||
      adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.FormRefused ||
      (adopter.adoptionAttempts && adopter.adoptionAttempts?.length > 0)
    ) {
      return
    }
    if (
      adopter.desiredAnimal?.hasDesiredAnimal === undefined &&
      adopter.profileHasBeenAccepted !== 'accepted' &&
      !isReader
    ) {
      openDialog()
    }
  }, [])

  const watchDesiredAnimal = watch('hasDesiredAnimal')

  return (
    <>
      <Button color="primary" variant="contained" onClick={openDialog} disabled={isReader}>
        <Edit sx={{ mr: 1 }} />
        Modifier l&#39;animal souhaité
      </Button>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: { borderRadius: 15, padding: 15 },
        }}
      >
        <form onSubmit={handleSubmit(processSubmit)}>
          <DialogContent>
            <Box>
              <Typography variant="h4" component="h2" marginBottom={4} fontSize={20}>
                Savez-vous quel animal {adopter.firstName ? fullName(adopter) : '..'} souhaite adopter ?
              </Typography>

              <Controller
                name="hasDesiredAnimal"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox checked={field.value === true} />}
                      label="Oui"
                      onClick={() => {
                        setValue('hasDesiredAnimal', true)
                        field.onChange(true)
                      }}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={field.value === false} />}
                      label="Non"
                      onClick={() => {
                        setValue('hasDesiredAnimal', false)
                        field.onChange(false)
                      }}
                    />
                  </>
                )}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <LoadingButton data-testid="apply-changes" variant="contained" type="submit">
              {watchDesiredAnimal !== true ? 'Valider' : "Sélectionner l'animal"}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
