import React, { useState } from 'react'
import { Box, Button, Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { metricalpEvent } from '@metricalp/react'
import { useAccountsStore } from '../../store/AccountsStore'

export const AcacedReferralBanner: React.FC = () => {
  const [open, setOpen] = useState(true)
  const accountsStore = useAccountsStore()

  if (!open) return null

  return (
    <Box
      sx={{
        position: 'relative',
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: 1,
        padding: 2,
        width: '100%',
        maxWidth: 600, // Adjust as needed
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {/* Close Button */}
      <IconButton
        aria-label="close"
        size="small"
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      {/* Banner Text */}
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Passez l&apos;ACACED avec 5% de réduction immédiate
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Grâce à notre partenariat avec Zoo Pro, profitez de cette offre exclusive et démarrez votre formation
        professionnelle dès maintenant.
      </Typography>

      {/* Referral Button in Bottom-Right */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
        <Button
          variant="outlined"
          color="primary"
          href="https://www.billetweb.fr/multi_event.php?multi=22551&discount=petsoxzoopro24"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            metricalpEvent({
              type: `click_on_acaced_referral_banner`,
              accountId: accountsStore.connectedAccount?.id,
              accountName: `${accountsStore.connectedAccount?.firstName} ${accountsStore.connectedAccount?.lastName}`,
            })
          }
          sx={{ textTransform: 'none' }}
        >
          Je profite de l&apos;offre
        </Button>
      </Box>
    </Box>
  )
}
