import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fullName } from '../../../../domain/Account/AccountDisplay'
import {
  getColorForAnimalAdoptionStatus,
  getColorForAnimalAdoptionStep,
  getColorForCannotBeAdoptedStatus,
  getReadableAnimalAdoptionOfferType,
  getReadableAnimalAdoptionStatus,
  getReadableAnimalAdoptionStep,
  getReadableCannotBeAdoptedStatus,
} from '../../../../domain/Animal/AnimalDisplay'
import {
  AdopterDto,
  AdoptionAttemptDtoStatus,
  AnimalAdoptionDtoCannotBeAdoptedStatus,
  AnimalAdoptionDtoOfferType,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
} from '../../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../../store/AccountsStore'
import { useAdoptersStore } from '../../../../store/AdoptersStore'
import { useEditAnimalMutation } from '../../../../store/useEditAnimalMutation'
import { useFetchOrRefreshAdoptersOnMount } from '../../../../store/useFetchOrRefreshAdoptersOnMount'
import { animalButtonShouldBeDisabled } from '../../../../utils/animalButtonShouldBeDisabled'
import { toDateOnly } from '../../../../utils/date/DateOnly'
import { isValidPastDateCoherence } from '../../../../utils/date/isValidPastDateCoherence'
import { useFormExtended } from '../../../../utils/hooks/useFormExtended'
import { PATHS } from '../../../PATHS'
import { ColoredChipSelectField } from '../../../common/ColoredChipSelectField'
import { ControlledAutoCompleteWithCustomValue } from '../../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../../../common/ControlledDateField'
import { ControlledSelectField } from '../../../common/ControlledSelectField'
import { ReadOnlyItem } from '../../../common/ReadOnlyItem'
import { colors, spacingItem } from '../../../theme'
import { AnimalCardSaveButton } from '../AnimalCardSaveButton'
import { MainHostedFamilyFollowUp } from '../HostedFammilyFollowUpsTab/HostedFamillyFollowUpsTab'
import { CreateAdoptionAttemptConfirmationModal } from './CreateAdoptionAttemptConfirmationModal'
import { AdoptersClient } from '../../../../interactors/clients/AdoptersClient'
import { getAdoptionAttemptStatus } from '../../../../utils/getAdoptionAttemptStatus'
import { ForceAdopterAcceptedModal } from './ForceAdopterAcceptedModal'
import { SuspendAttemptModal } from './SuspendAttemptModal'
import { Info } from '@mui/icons-material'
import { useGlobalSnackbarStore } from '../../../../store/GlobalSnackBarStore'
import { DisplayChipLikeTextField } from '../../../common/DisplayChipLikeTextField'
import { GetAnimalAdoptedModal } from './GetAnimalAdoptedModal'
import { nanoid } from 'nanoid'
import { VerdictAfterVPAModal } from './VerdictAfterVPAModal'
import { FastLaunchProcedureDialog } from '../../../Adopters/FastLaunchProcedureDialog'

interface Props {
  animal: AnimalDto
}

export interface AdoptionFormParams {
  adoptionStatus: AnimalAdoptionDtoStatus
  adopterId: string | undefined
  cannotBeAdoptedStatus: AnimalAdoptionDtoCannotBeAdoptedStatus | ''
  exitDate: string | ''
  adoptionDate: string | ''
  futureAdoptionDate: string | ''
  vpaDate: string | ''
  step: AnimalAdoptionDtoStep | ''
  newName: string | ''
  goodbyeDate: string | ''
  goodbyeDetails: string | ''
  priceInEuros: number | null
  offerType: AnimalAdoptionDtoOfferType | null
  gpsPoint?: string
}

export const AnimalAdoptionTab: React.FC<Props> = ({ animal }) => {
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const adoptersStore = useAdoptersStore()
  const accountsStore = useAccountsStore()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const [isOpen, setIsOpen] = React.useState(false)
  const [adoptedModalIsOpen, setAdoptedModalIsOpen] = React.useState(false)
  const [suspendModalIsOpen, setSuspendModalIsOpen] = React.useState(false)
  const [getAnimalAdoptedIsOpen, setGetAnimalAdoptedIsOpen] = React.useState(false)
  const [verdictVPAModalIsOpen, setVerdictVPAModalIsOpen] = React.useState(false)
  const [alreadyExistingAttempt, setAlreadyExistingAttempt] = React.useState(false)
  const [isSameAdopter, setIsSameAdopter] = React.useState(false)
  const [oldAdopterName, setOldAdopterName] = React.useState<string | undefined>(undefined)
  const [adoptionPopUpOpen, setAdoptionPopUpOpen] = React.useState(false)
  const [attemptId, setAttemptId] = React.useState<string | undefined>(undefined)

  const onClose = () => setIsOpen(false)
  const onCloseAdoptedModal = () => setAdoptedModalIsOpen(false)
  const onCloseSuspendModal = () => setSuspendModalIsOpen(false)
  const onCloseGetAnimalAdopted = () => setGetAnimalAdoptedIsOpen(false)
  const onCloseVerdictVPAModal = () => setVerdictVPAModalIsOpen(false)

  useEffect(() => {
    const openVPAModal = () => {
      if (
        animal.adoption?.vpaDate &&
        animal.adoption.status === AnimalAdoptionDtoStatus.HasBeenAdopted &&
        dayjs().isAfter(dayjs(animal.adoption.vpaDate)) &&
        animal.adoption.step !== AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit
      ) {
        setVerdictVPAModalIsOpen(true)
      }
    }
    openVPAModal()
  }, [animal.adoption?.vpaDate])

  useFetchOrRefreshAdoptersOnMount()

  const initialAdopter = adoptersStore.adopters.find((adopter) => adopter.id === animal.adoption?.adopterId)

  const options = adoptersStore.adopters.map((adopter) => {
    const label = adopter.isDeleted ? `${fullName(adopter)} (Supprimé)` : fullName(adopter)

    return { ...adopter, label, key: adopter.id }
  })

  const initialAdopterOption = options.find((adopter) => adopter.id === animal.adoption?.adopterId)

  const getDefaultValues = (animal: AnimalDto): AdoptionFormParams => {
    const initialAdopterOption = options.find((adopter) => adopter.id === animal.adoption?.adopterId)

    return {
      adoptionStatus: animal.adoption.status || '',
      adopterId: initialAdopterOption?.id || undefined,
      cannotBeAdoptedStatus: animal.adoption.cannotBeAdoptedStatus || '',
      exitDate: animal.adoption.exitDate || '',
      adoptionDate: animal.adoption.adoptionDate || '',
      futureAdoptionDate: animal.adoption.futureAdoptionDate || '',
      vpaDate: animal.adoption.vpaDate || '',
      step: animal.adoption.step || '',
      newName: animal.adoption.newName || '',
      goodbyeDate: animal.adoption.goodbyeDate || '',
      goodbyeDetails: animal.adoption.goodbyeDetails || '',
      priceInEuros: animal.adoption.priceInEuros || null,
      offerType: animal.adoption.offerType || null,
      gpsPoint: animal.gpsPoint,
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    register,
    setValue,
  } = useFormExtended(animal, getDefaultValues)

  useEffect(() => {
    reset(getDefaultValues(animal))
  }, [initialAdopterOption?.id])

  const isDonation = watch('offerType') === AnimalAdoptionDtoOfferType.Donation
  useEffect(() => {
    if (isDonation) {
      setValue('priceInEuros', 0, { shouldValidate: true })
    }
  }, [isDonation, setValue])

  const watchAdopter = watch('adopterId')
  const watchAdoptionStatus = watch('adoptionStatus')
  const watchCannotBeAdoptedStatus = watch('cannotBeAdoptedStatus')
  const cannotBeAdopted = watchAdoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted

  const hasExitDates =
    (
      [AnimalAdoptionDtoStatus.HasBeenAdopted, AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable] as Array<string>
    ).includes(watchAdoptionStatus) ||
    // Or FALD
    (watchAdoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
      watchCannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily)

  const hasGoodbyeDates =
    watchAdoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
    (
      [
        AnimalAdoptionDtoCannotBeAdoptedStatus.Dead,
        AnimalAdoptionDtoCannotBeAdoptedStatus.Lost,
        AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation,
        AnimalAdoptionDtoCannotBeAdoptedStatus.Released,
      ] as Array<string>
    ).includes(watchCannotBeAdoptedStatus)

  const newAdoptionStep = (data: AdoptionFormParams) => {
    if (data.adoptionStatus === AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable) {
      return AnimalAdoptionDtoStep.AdoptionToBeValidated
    }
    if (data.step === AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit) {
      return AnimalAdoptionDtoStep.AdoptionValidatedAfterPostVisit
    }
    if (data.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted) {
      return AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit
    }

    return undefined
  }

  const editAnimalMutation = useEditAnimalMutation({})

  const onSubmit = async (data: AdoptionFormParams, confirmation?: boolean) => {
    if (isSameAdopter) {
      setIsSameAdopter(false)
    }
    if (alreadyExistingAttempt) {
      setAlreadyExistingAttempt(false)
    }

    if (isOpen) {
      onClose()
    }
    if (adoptedModalIsOpen) {
      onCloseAdoptedModal()
    }
    if (suspendModalIsOpen) {
      onCloseSuspendModal()
    }
    if (getAnimalAdoptedIsOpen) {
      onCloseGetAnimalAdopted()
    }

    if (data.adopterId) {
      const adopter = adoptersStore.adopters.find((adopter) => adopter.id === data.adopterId)
      if (adopter && adopter.isDeleted) {
        return globalSnackBarStore.triggerErrorMessage('Vous ne pouvez pas assigner un adoptant supprimé')
      }
    }
    if (data.adoptionDate && !animal.adoption.adoptionDate) {
      data.adoptionStatus = AnimalAdoptionDtoStatus.HasBeenAdopted
      if (!data.adopterId && !confirmation) {
        return setGetAnimalAdoptedIsOpen(true)
      }
    }

    let adopterThatAlreadyHasAttempt: AdopterDto | null = null
    if (animal.adoption.adopterId != null) {
      const adopter = adoptersStore.adopters.find((adopter) => adopter.id === animal.adoption.adopterId)
      if (adopter) {
        adopterThatAlreadyHasAttempt = adopter
        if (adopterThatAlreadyHasAttempt.phoneNumber?.startsWith('0')) {
          adopterThatAlreadyHasAttempt.phoneNumber = `+33${adopterThatAlreadyHasAttempt.phoneNumber.slice(1)}`
        }
        setAlreadyExistingAttempt(true)
        setOldAdopterName(fullName(adopter))
        setIsSameAdopter(adopter.id === data.adopterId)
      }
    }

    let newFollowUp: MainHostedFamilyFollowUp | undefined = undefined
    if (
      animalButtonShouldBeDisabled({
        ...animal,
        adoption: {
          ...animal.adoption,
          status: data.adoptionStatus as AnimalAdoptionDtoStatus,
          adopterId: data.adoptionStatus === AnimalAdoptionDtoStatus.Adoptable ? undefined : data.adopterId,
          exitDate: data.exitDate || undefined,
          adoptionDate: data.adoptionDate ? toDateOnly(data.adoptionDate) : undefined,
          futureAdoptionDate: data.futureAdoptionDate ?? undefined,
          cannotBeAdoptedStatus: (data.cannotBeAdoptedStatus as AnimalAdoptionDtoCannotBeAdoptedStatus) || undefined,
          step: (data.step as AnimalAdoptionDtoStep) || undefined,
          goodbyeDate: hasGoodbyeDates && data.goodbyeDate ? data.goodbyeDate : undefined,
          goodbyeDetails: hasGoodbyeDates && data.goodbyeDetails ? toDateOnly(data.goodbyeDetails) : undefined,
          offerType: data.offerType ? data.offerType : undefined,
          priceInEuros: data.priceInEuros ? data.priceInEuros : undefined,
        },
      })
    ) {
      if (data.adoptionStatus === 'cannot-be-adopted' && data.goodbyeDate) {
        newFollowUp = {
          type: 'exit',
          date: toDateOnly(dayjs(data.goodbyeDate)),
          destination: `${data.cannotBeAdoptedStatus}`,
          memberInChargeAccountId:
            accountsStore.connectedAccount?.id || animal.responsibilities?.memberInChargeAccountId || '',
          created: {
            by: accountsStore.connectedAccount?.id || '',
            at: dayjs().toISOString(),
          },
        }
      }
      if (data.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted && data.exitDate && data.adopterId) {
        newFollowUp = {
          type: 'exit',
          date: toDateOnly(dayjs(data.exitDate)),
          destination: `${data.adoptionStatus}`,
          memberInChargeAccountId:
            accountsStore.connectedAccount?.id || animal.responsibilities?.memberInChargeAccountId || '',
          created: {
            by: accountsStore.connectedAccount?.id || '',
            at: dayjs().toISOString(),
          },
        }
      }
    }

    if (
      data.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted &&
      data.adopterId &&
      (animal.adoption.status !== AnimalAdoptionDtoStatus.HasBeenAdopted ||
        animal.adoption.adopterId !== data.adopterId)
    ) {
      const adopter = adoptersStore.adopters.find((adopter) => adopter.id === data.adopterId)
      if (confirmation || (!alreadyExistingAttempt && adopter?.profileHasBeenAccepted === 'accepted')) {
        if (adopterThatAlreadyHasAttempt) {
          const index = adopterThatAlreadyHasAttempt.adoptionAttempts?.findIndex(
            (attempt) => attempt.animalId === animal.id
          )
          if (index !== -1 && index != null && adopterThatAlreadyHasAttempt.adoptionAttempts) {
            if (adopter && adopter.id === adopterThatAlreadyHasAttempt.id) {
              adopterThatAlreadyHasAttempt.adoptionAttempts[index].status = AdoptionAttemptDtoStatus.Done
              adopterThatAlreadyHasAttempt.adoptionAttempts[index].step = 6
              adopterThatAlreadyHasAttempt.adoptionAttempts[index].done = true
            } else {
              adopterThatAlreadyHasAttempt.adoptionAttempts[index].status = AdoptionAttemptDtoStatus.SuspendedByCustomer
            }
            adopterThatAlreadyHasAttempt.phoneNumber = ['+33', ''].includes(
              adopterThatAlreadyHasAttempt.phoneNumber?.trim() || ''
            )
              ? undefined
              : adopterThatAlreadyHasAttempt.phoneNumber
            await AdoptersClient.editAccount(adopterThatAlreadyHasAttempt)
          }
        }
        if (
          adopter &&
          adopter.adoptionAttempts?.some((adoptionAttempt) => adoptionAttempt.animalId === animal.id) &&
          adopter.id !== adopterThatAlreadyHasAttempt?.id
        ) {
          const index = adopter.adoptionAttempts?.findIndex((attempt) => attempt.animalId === animal.id)
          if (index !== -1 && index != null && adopter.adoptionAttempts) {
            adopter.adoptionAttempts[index].status = AdoptionAttemptDtoStatus.Done
            adopter.adoptionAttempts[index].step = 6
            adopter.adoptionAttempts[index].done = true
            adopter.phoneNumber = ['+33', ''].includes(adopter.phoneNumber?.trim() || '')
              ? undefined
              : adopter.phoneNumber
            await AdoptersClient.editAccount(adopter)
          }
        }
        if (adopter && adopter.profileHasBeenAccepted !== 'accepted') {
          adopter.profileHasBeenAccepted = 'accepted'
          if (adopter.phoneNumber?.startsWith('0')) {
            adopter.phoneNumber = `+33${adopter.phoneNumber.slice(1)}`
          }
          adopter.phoneNumber = ['+33', ''].includes(adopter.phoneNumber?.trim() || '')
            ? undefined
            : adopter.phoneNumber
          await AdoptersClient.editAccount(adopter)
        }
      } else {
        return setAdoptedModalIsOpen(true)
      }

      if (
        adopter &&
        !alreadyExistingAttempt &&
        !adopter.adoptionAttempts?.some((adoptionAttempt) => adoptionAttempt.animalId === animal.id)
      ) {
        adopter.adoptionAttempts = adopter.adoptionAttempts || []
        adopter.adoptionAttempts.push({
          id: nanoid(),
          animalId: animal.id,
          status: AdoptionAttemptDtoStatus.Done,
          step: 6,
          done: true,
          paymentDone: false,
          verifiedPaymentInfo: false,
          verifiedPriceInfo: false,
          customerName: accountsStore.connectedCustomer?.name || '',
          events: [],
          insights: [],
        })
        await AdoptersClient.editAccount(adopter)
      }
    }

    if (
      data.adoptionStatus === AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable &&
      data.adopterId &&
      (animal.adoption.status !== AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable ||
        animal.adoption.adopterId !== data.adopterId)
    ) {
      if (confirmation) {
        if (adopterThatAlreadyHasAttempt) {
          console.log('adopterThatAlreadyHasAttempt', adopterThatAlreadyHasAttempt)
          const index = adopterThatAlreadyHasAttempt.adoptionAttempts?.findIndex(
            (attempt) => attempt.animalId === animal.id
          )
          if (index !== -1 && index != null && adopterThatAlreadyHasAttempt.adoptionAttempts) {
            adopterThatAlreadyHasAttempt.adoptionAttempts[index].status = AdoptionAttemptDtoStatus.SuspendedByCustomer
            adopterThatAlreadyHasAttempt.phoneNumber = ['+33', ''].includes(
              adopterThatAlreadyHasAttempt.phoneNumber?.trim() || ''
            )
              ? undefined
              : adopterThatAlreadyHasAttempt.phoneNumber
            await AdoptersClient.editAccount(adopterThatAlreadyHasAttempt)
          }
        }

        const adopter = adoptersStore.adopters.find((adopter) => adopter.id === data.adopterId)
        if (!adopter?.adoptionAttempts?.some((adoptionAttempt) => adoptionAttempt.animalId === animal.id)) {
          const attempt = await AdoptersClient.newAdoptionAttempt(data.adopterId, animal.id)
          setAttemptId(attempt.id)
          setAdoptionPopUpOpen(true)
          /* if (adopter && adopter?.profileHasBeenAccepted !== 'accepted') {
            adopter.profileHasBeenAccepted = 'accepted'
            if (adopter.phoneNumber?.startsWith('0')) {
              adopter.phoneNumber = `+33${adopter.phoneNumber.slice(1)}`
            }
            adopter.phoneNumber = ['+33', ''].includes(adopter.phoneNumber?.trim() || '')
              ? undefined
              : adopter.phoneNumber
            await AdoptersClient.editAccount(adopter)
          } */
        }
      } else {
        return setIsOpen(true)
      }
    }

    if (
      adopterThatAlreadyHasAttempt != null &&
      (data.adoptionStatus === AnimalAdoptionDtoStatus.Adoptable ||
        data.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted)
    ) {
      if (confirmation) {
        if (adopterThatAlreadyHasAttempt) {
          const index = adopterThatAlreadyHasAttempt.adoptionAttempts?.findIndex(
            (attempt) => attempt.animalId === animal.id
          )
          if (index !== -1 && index != null && adopterThatAlreadyHasAttempt.adoptionAttempts) {
            adopterThatAlreadyHasAttempt.adoptionAttempts[index].status = AdoptionAttemptDtoStatus.SuspendedByCustomer
            adopterThatAlreadyHasAttempt.phoneNumber = ['+33', ''].includes(
              adopterThatAlreadyHasAttempt.phoneNumber?.trim() || ''
            )
              ? undefined
              : adopterThatAlreadyHasAttempt.phoneNumber
            await AdoptersClient.editAccount(adopterThatAlreadyHasAttempt)
          }
        }
      } else {
        return setSuspendModalIsOpen(true)
      }
    }

    const canHaveNewName =
      (watchAdoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted &&
        watchCannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily) ||
      watchAdoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted

    await editAnimalMutation.mutateAsync({
      ...animal,
      hostedFamilyFollowUps: {
        ...animal.hostedFamilyFollowUps,
        main: [...(animal.hostedFamilyFollowUps.main || []), newFollowUp].filter(
          (followUp) => followUp
        ) as MainHostedFamilyFollowUp[],
      },
      gpsPoint: data.gpsPoint,
      adoption: {
        ...animal.adoption,
        status: data.adoptionStatus as AnimalAdoptionDtoStatus,
        adopterId:
          data.adoptionStatus !== AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable &&
          data.adoptionStatus !== AnimalAdoptionDtoStatus.HasBeenAdopted
            ? undefined
            : data.adopterId,
        exitDate:
          data.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted ||
          data.adoptionStatus === AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable
            ? data.exitDate
            : undefined,
        adoptionDate:
          (data.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted ||
            data.adoptionStatus === AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable) &&
          data.adoptionDate
            ? toDateOnly(data.adoptionDate)
            : undefined,
        futureAdoptionDate:
          data.adoptionStatus === AnimalAdoptionDtoStatus.HasBeenAdopted ||
          data.adoptionStatus === AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable
            ? data.futureAdoptionDate
            : undefined,
        vpaDate: data.adoptionStatus !== AnimalAdoptionDtoStatus.HasBeenAdopted ? undefined : data.vpaDate,
        cannotBeAdoptedStatus: (data.cannotBeAdoptedStatus as AnimalAdoptionDtoCannotBeAdoptedStatus) || undefined,
        step: newAdoptionStep(data) ?? ((data.step as AnimalAdoptionDtoStep) || undefined),
        newName: data.newName && canHaveNewName ? data.newName : undefined,
        goodbyeDate:
          hasGoodbyeDates && data.goodbyeDate && data.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted
            ? data.goodbyeDate
            : undefined,
        goodbyeDetails:
          hasGoodbyeDates && data.adoptionStatus === AnimalAdoptionDtoStatus.CannotBeAdopted && data.goodbyeDetails
            ? toDateOnly(data.goodbyeDetails)
            : undefined,
        offerType: data.offerType ? data.offerType : undefined,
        priceInEuros:
          data.offerType === AnimalAdoptionDtoOfferType.Donation
            ? 0
            : data.priceInEuros
            ? data.priceInEuros
            : undefined,
      },
    })
    adoptersStore.fetchOrRefreshAdopters()
  }

  useEffect(() => {
    if (queryParams.get('tab') === 'adoption' && queryParams.get('card') === 'animalAdoptionCard') {
      const newAdopter = queryParams.get('adoptant')
      const status = queryParams.get('status')

      if (newAdopter !== undefined && newAdopter !== null && status !== undefined && status !== null) {
        setValue('adopterId', newAdopter || '')
        setValue('adoptionStatus', status as AnimalAdoptionDtoStatus)
        setTimeout(
          handleSubmit((data) => onSubmit(data)),
          1000
        )
      }
    }
  }, [])

  useEffect(() => {
    if (watchAdopter === 'addAccount') {
      reset({ adopterId: undefined })
      navigate(
        `${PATHS.ajouterAdoptant.absolute}?from=animaux/${animal.id}?tab=adoption&card=animalAdoptionCard&status=${watchAdoptionStatus}`
      )
    }
  }, [watchAdopter])

  const adoptionAttempt = adoptersStore.adopters
    .find((adopter) => adopter.id === watchAdopter)
    ?.adoptionAttempts?.find((adoptionAttempt) => adoptionAttempt.animalId === animal.id)

  const adoptionDate = watch('adoptionDate')
  const exitDatesDisabled = adoptionDate === '' || adoptionDate == null

  const selectedAdopter = adoptersStore.adopters.find((adopter) => adopter.id === watchAdopter) || null

  return (
    <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ ...spacingItem, backgroundColor: colors.white }}>
      <FastLaunchProcedureDialog
        open={adoptionPopUpOpen}
        setOpen={setAdoptionPopUpOpen}
        adopter={selectedAdopter}
        providedAnimal={animal}
        attemptId={attemptId}
        noAdopterDelete
      />
      <Card sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <CardHeader title="🐶 Adoption" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>
                <ColoredChipSelectField
                  label="Statut d'adoption"
                  control={control}
                  error={errors.adoptionStatus}
                  fieldName="adoptionStatus"
                  options={Object.values(AnimalAdoptionDtoStatus)
                    .filter((status) => status !== AnimalAdoptionDtoStatus['ToBeFilled'])
                    .map((status: AnimalAdoptionDtoStatus) => ({
                      label: getReadableAnimalAdoptionStatus(status),
                      value: status,
                      color: getColorForAnimalAdoptionStatus(status),
                    }))}
                  requiredRule="Ce champs est requis"
                  size="small"
                />
              </Grid>

              {watchAdoptionStatus !== AnimalAdoptionDtoStatus.CannotBeAdopted &&
                (
                  [
                    AnimalAdoptionDtoStatus.HasBeenAdopted,
                    AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable,
                    AnimalAdoptionDtoStatus.Adoptable,
                  ] as AnimalAdoptionDtoStatus[]
                ).includes(watchAdoptionStatus) && (
                  <>
                    <Grid item xs={12} sm={3}>
                      <ControlledSelectField
                        control={control}
                        error={errors.offerType}
                        fieldName="offerType"
                        label="Nature de l'offre"
                        options={Object.values(AnimalAdoptionDtoOfferType).map((field: AnimalAdoptionDtoOfferType) => ({
                          label: getReadableAnimalAdoptionOfferType(field),
                          value: field,
                        }))}
                        requiredRule=""
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id="price-input"
                        label="Montant"
                        type="number"
                        inputProps={{
                          step: '0.01',
                          inputMode: 'decimal',
                          pattern: '[0-9]*',
                        }}
                        disabled={isDonation}
                        fullWidth
                        {...register('priceInEuros')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      />
                    </Grid>
                  </>
                )}

              {watchAdoptionStatus !== AnimalAdoptionDtoStatus.CannotBeAdopted &&
                (
                  [
                    AnimalAdoptionDtoStatus.HasBeenAdopted,
                    AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable,
                  ] as AnimalAdoptionDtoStatus[]
                ).includes(watchAdoptionStatus) && (
                  <Grid item xs={12} sm={3} sx={{ mt: '-1px' }}>
                    {animal.adoption.step && (
                      <DisplayChipLikeTextField
                        label="État d'avancement"
                        value={getReadableAnimalAdoptionStep(animal.adoption.step)}
                        color={getColorForAnimalAdoptionStep(animal.adoption.step)}
                        documentPage
                      />
                    )}
                  </Grid>
                )}

              {watchAdoptionStatus === AnimalAdoptionDtoStatus.Adoptable && <Grid item xs={0} sm={3} />}

              <Grid item xs={12} sm={3}>
                {watchAdoptionStatus !== AnimalAdoptionDtoStatus.Adoptable ? (
                  !cannotBeAdopted ? (
                    <ControlledAutoCompleteWithCustomValue
                      control={control}
                      fieldName={'adopterId'}
                      defaultValue=""
                      size="small"
                      options={[
                        { value: 'addAccount', label: 'Ajouter un adoptant' },
                        ...options
                          .sort((a, b) => a.label.localeCompare(b.label))
                          .map((option) => ({
                            label: option.label,
                            value: option.id,
                          })),
                      ]}
                      label="Adoptant"
                      error={undefined}
                      requiredRule={undefined}
                      highlight
                    />
                  ) : (
                    <>
                      <ColoredChipSelectField
                        control={control}
                        error={errors.cannotBeAdoptedStatus}
                        fieldName="cannotBeAdoptedStatus"
                        label="Motif non adoptable:"
                        options={Object.values(AnimalAdoptionDtoCannotBeAdoptedStatus).map(
                          (status: AnimalAdoptionDtoCannotBeAdoptedStatus) => ({
                            label: getReadableCannotBeAdoptedStatus(status),
                            value: status,
                            color: getColorForCannotBeAdoptedStatus(status),
                          })
                        )}
                        requiredRule={undefined}
                        size="small"
                      />
                    </>
                  )
                ) : null}
              </Grid>
              {!cannotBeAdopted && watchAdoptionStatus !== AnimalAdoptionDtoStatus.Adoptable && (
                <>
                  <Grid item xs={12} sm={6}>
                    {watchAdopter && watchAdopter === animal.adoption.adopterId ? (
                      adoptionAttempt ? (
                        <Box
                          sx={{
                            borderLeft: '2px grey solid',
                            borderLeftColor: 'grey.200',
                            paddingLeft: 2,
                            boxSizing: 'border-box',
                            display: 'flex',
                          }}
                        >
                          <Link
                            to={`${PATHS.suiviAdoptant.absolute}/${watchAdopter}/?tab=${
                              adoptionAttempt.status === 'done'
                                ? 'past-adoption'
                                : adoptionAttempt.status === 'suspended-by-customer' ||
                                  adoptionAttempt.status === 'suspended-by-user'
                                ? 'suspended-adoption'
                                : 'ongoing-adoption'
                            }&attemptId=${adoptionAttempt.id}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <ReadOnlyItem
                              label="Statut de la demande"
                              value={getAdoptionAttemptStatus(adoptionAttempt)}
                              link
                            />
                          </Link>
                          {(adoptionAttempt.status === 'suspended-by-customer' ||
                            adoptionAttempt.status === 'suspended-by-user') && (
                            <Tooltip title="Une procédure existante avait été suspendu pour la relancer, cliquer sur le lien">
                              <Info sx={{ color: colors.blueSky }} />
                            </Tooltip>
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            borderLeft: '2px grey solid',
                            borderLeftColor: 'grey.200',
                            paddingLeft: 2,
                            boxSizing: 'border-box',
                          }}
                        >
                          <Link
                            to={`${PATHS.suiviAdoptant.absolute}/${watchAdopter}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <ReadOnlyItem label="Profil de l'adoptant" value="Voir le profil" link />
                          </Link>
                        </Box>
                      )
                    ) : null}
                  </Grid>

                  {hasExitDates && (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                      <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
                    </Box>
                  )}

                  {/* Hidden to redirect users to new adoption system */}
                  {/* {(
                    [
                      AnimalAdoptionDtoStatus.HasBeenAdopted,
                      AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable,
                    ] as Array<string>
                  ).includes(watchAdoptionStatus) && <AdoptionAdministrationFollowUpList animal={animal} />} */}
                </>
              )}

              {watchCannotBeAdoptedStatus === AnimalAdoptionDtoCannotBeAdoptedStatus.LongTermWithHostedFamily && (
                <Box sx={{ display: 'flex', justifyContent: 'left', marginLeft: 3 }}>
                  <Typography variant="h6" fontWeight={400}>
                    FALD: les coordonnées retenues sont celles de la famille en charge actuellement
                  </Typography>
                </Box>
              )}

              {hasExitDates && (
                <Grid item xs={12} sm={12}>
                  <Typography variant="h4" sx={{ fontSize: 20, fontWeight: 700 }}>
                    Infos de sortie
                  </Typography>
                  <Box
                    sx={{
                      marginLeft: 2,
                      borderLeft: '2px grey solid',
                      borderLeftColor: 'grey.200',
                      marginTop: 1,
                      paddingLeft: 2,
                      boxSizing: 'border-box',
                      paddingRight: 3,
                    }}
                  >
                    <Typography variant="h6">Dates de sorties:</Typography>

                    <Grid container spacing={1} sx={{ marginTop: 1 }}>
                      <Grid item xs={12} sm={3}>
                        <ControlledDateField
                          control={control}
                          validate={(value) => isValidPastDateCoherence(value) || value === null}
                          error={undefined}
                          fieldName={'futureAdoptionDate'}
                          label="Date d'adoption prévue"
                          requiredRule={undefined}
                          sx={{ marginTop: 0 }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <ControlledDateField
                          control={control}
                          validate={(value) => isValidPastDateCoherence(value) || value === null}
                          // error={errors.adoptionDate}
                          error={undefined}
                          fieldName={'adoptionDate'}
                          label="Adoption (physique)"
                          requiredRule={undefined}
                          sx={{ marginTop: 0 }}
                          size="small"
                          maxDate={dayjs()}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Tooltip title={exitDatesDisabled ? "Veuillez d'abord remplir la date d'adoption" : ''}>
                          <span>
                            <ControlledDateField
                              control={control}
                              validate={(value) => isValidPastDateCoherence(value) || value === null}
                              // error={errors.adoptionDate}
                              error={undefined}
                              fieldName={'vpaDate'}
                              label="Visite post-adoption"
                              requiredRule={undefined}
                              sx={{ marginTop: 0 }}
                              size="small"
                              minDate={dayjs(adoptionDate, 'DD/MM/YYYY')}
                              disabled={exitDatesDisabled}
                            />
                          </span>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <Tooltip title={exitDatesDisabled ? "Veuillez d'abord remplir la date d'adoption" : ''}>
                          <span>
                            <ControlledDateField
                              control={control}
                              validate={(value) => isValidPastDateCoherence(value) || value === null}
                              error={errors.exitDate}
                              fieldName={'exitDate'}
                              label="Date de sortie (Cession I-CAD)"
                              requiredRule={undefined}
                              sx={{ marginTop: 0 }}
                              size="small"
                              minDate={dayjs(adoptionDate, 'DD/MM/YYYY')}
                              disabled={exitDatesDisabled}
                            />
                          </span>
                        </Tooltip>
                      </Grid>

                      {hasExitDates && (
                        <Grid item xs={12} sm={3}>
                          <TextField
                            id="new-name-input"
                            label="Nouveau nom"
                            type="text"
                            fullWidth
                            {...register('newName')}
                            size="small"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              )}

              {/* GOODBYE */}
              {hasGoodbyeDates && (
                <Grid item xs={12} sm={12} sx={spacingItem}>
                  <Box
                    sx={{
                      marginLeft: 2,
                      borderLeft: '2px grey solid',
                      borderLeftColor: 'grey.200',
                      marginTop: 1,
                      paddingLeft: 2,
                      boxSizing: 'border-box',
                      paddingRight: 3,
                    }}
                  >
                    <Typography variant="h6">
                      {map[watchCannotBeAdoptedStatus as GoodbyeCannotBeAdoptedStatus].title}
                    </Typography>

                    <Grid container spacing={1} sx={{ marginTop: 1 }}>
                      <Grid item xs={12} sm={animal.adoption.cannotBeAdoptedStatus === 'released' ? 3 : 6}>
                        <ControlledDateField
                          control={control}
                          validate={(value) => isValidPastDateCoherence(value) || value === null}
                          error={errors.goodbyeDate}
                          fieldName={'goodbyeDate'}
                          label={map[watchCannotBeAdoptedStatus as GoodbyeCannotBeAdoptedStatus].date}
                          requiredRule={undefined}
                          size="small"
                          sx={spacingItem}
                        />
                      </Grid>

                      <Grid item xs={12} sm={animal.adoption.cannotBeAdoptedStatus === 'released' ? 3 : 6}>
                        <TextField
                          label={map[watchCannotBeAdoptedStatus as GoodbyeCannotBeAdoptedStatus].details}
                          type="text"
                          fullWidth
                          sx={{ marginTop: 2 }}
                          {...register('goodbyeDetails')}
                          multiline
                          size="small"
                        />
                      </Grid>

                      {animal.adoption.status === 'cannot-be-adopted' &&
                        animal.adoption.cannotBeAdoptedStatus === 'released' && (
                          <Grid item xs={12} sm={3}>
                            <TextField
                              label="Coordonnées GPS"
                              type="text"
                              fullWidth
                              {...register('gpsPoint')}
                              error={!!errors.gpsPoint}
                              helperText={`Vous pouvez copier-coller les coordonnées depuis Google maps`}
                              sx={spacingItem}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }}>
            <AnimalCardSaveButton isLoading={editAnimalMutation.isLoading} disabled={!isDirty} />
          </CardActions>
        </form>
      </Card>
      <CreateAdoptionAttemptConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        alreadyExistingAttempt={alreadyExistingAttempt}
        oldAdopterName={oldAdopterName}
      />
      <ForceAdopterAcceptedModal
        isOpen={adoptedModalIsOpen}
        onClose={onCloseAdoptedModal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        alreadyExistingAttempt={alreadyExistingAttempt}
        isSameAdopter={isSameAdopter}
        oldAdopterName={oldAdopterName}
      />
      <SuspendAttemptModal
        isOpen={suspendModalIsOpen}
        onClose={onCloseSuspendModal}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        oldAdopterName={oldAdopterName}
      />
      <GetAnimalAdoptedModal
        isOpen={getAnimalAdoptedIsOpen}
        onClose={onCloseGetAnimalAdopted}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        animalName={animal.name}
      />
      {initialAdopter && (
        <VerdictAfterVPAModal
          isOpen={verdictVPAModalIsOpen}
          onClose={onCloseVerdictVPAModal}
          animal={animal}
          adopter={initialAdopter}
        />
      )}
    </Box>
  )
}

type GoodbyeCannotBeAdoptedStatus =
  | typeof AnimalAdoptionDtoCannotBeAdoptedStatus.Dead
  | typeof AnimalAdoptionDtoCannotBeAdoptedStatus.Lost
  | typeof AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation
  | typeof AnimalAdoptionDtoCannotBeAdoptedStatus.Released

const map = {
  [AnimalAdoptionDtoCannotBeAdoptedStatus.Dead]: {
    title: 'Décès',
    date: 'Date du décès',
    details: 'Cause du décès',
  },
  [AnimalAdoptionDtoCannotBeAdoptedStatus.Lost]: {
    title: 'Perte',
    date: 'Date de perte',
    details: 'Détails',
  },
  [AnimalAdoptionDtoCannotBeAdoptedStatus.TransferToAnotherAssociation]: {
    title: 'Transfert',
    date: 'Date du transfert',
    details: "Coordonnées de l'association",
  },
  [AnimalAdoptionDtoCannotBeAdoptedStatus.Released]: {
    title: 'Sortie',
    date: 'Date de sortie',
    details: 'Lieu de stationnement',
  },
}
