import { DescriptionOutlined, Edit, MoreVert } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fullName } from '../../../domain/Account/AccountDisplay'
import { getReadableCustomerTemplateType } from '../../../domain/Customer/CustomerDisplay'
import { backendClient } from '../../../interactors/clients/client'
import { CustomerPdfTemplateDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { blueSky } from '../../theme'

interface TemplateItemProps {
  template: CustomerPdfTemplateDto
  setTemplatesList: React.Dispatch<React.SetStateAction<CustomerPdfTemplateDto[]>>
}

export const PdfTemplateItem: React.FC<TemplateItemProps> = ({ template, setTemplatesList }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [newName, setNewName] = useState(template.name)

  const globalSnackbar = useGlobalSnackbarStore()
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const members = accountsStore.members
  const member = members.find((m) => m.id === template.uploadedBy)

  const handleDelete = async () => {
    await backendClient.delete(`/customers/pdf-templates/${template.id}`)
    setTemplatesList((prev) => prev.filter((t) => t.id !== template.id))
    setDeleteModalOpen(false)
    globalSnackbar.triggerSuccessMessage('Modèle PDF supprimé avec succès')
  }

  const handleRename = async () => {
    // Implement rename logic here, e.g., updating the template's name in the backend.
    const updatedTemplate = { ...template, name: newName }
    setTemplatesList((prev) => prev.map((t) => (t.id === template.id ? updatedTemplate : t)))
    await backendClient.put(`customers/pdf-templates/${template.id}`, updatedTemplate)
    setRenameModalOpen(false)
    setAnchorEl(null)
    globalSnackbar.triggerSuccessMessage('Modèle PDF renommé avec succès')
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Card
        sx={{
          maxWidth: 300,
          width: '100%',
          borderRadius: 2,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.2s',

          position: 'relative',
          overflow: 'visible',
        }}
      >
        <CardActionArea
          onClick={() =>
            navigate('/association/zone', {
              state: {
                template: {
                  ...template,
                  zones: JSON.stringify(template.zones),
                  variables: JSON.stringify(template.variables),
                },
              },
            })
          }
          sx={{
            px: 2,
            pb: 0,
            position: 'relative',
            '&:hover .edit-icon': {
              opacity: 0.5,
            },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ height: 150 }}>
            <DescriptionOutlined sx={{ fontSize: 50, color: blueSky }} />
          </Box>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" component="div" noWrap>
              {template.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {template.type ? getReadableCustomerTemplateType(template.type) : ''}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Mis en ligne le {new Date(template.uploadedAt).toLocaleDateString()}
            </Typography>
            {member && (
              <Typography variant="caption" color="text.secondary">
                Par {fullName(member)}
              </Typography>
            )}
          </CardContent>
          <Edit
            className="edit-icon"
            sx={{
              position: 'absolute',
              fontSize: 72,
              color: 'grey',
              opacity: 0,
              transition: 'opacity 0.2s',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </CardActionArea>
        <CardActions
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <IconButton onClick={handleMenuOpen}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem onClick={() => setRenameModalOpen(true)}>Renommer</MenuItem>
            <MenuItem onClick={() => setDeleteModalOpen(true)}>Supprimer</MenuItem>
          </Menu>
        </CardActions>
      </Card>

      {/* Rename Modal */}
      <Modal
        open={renameModalOpen}
        onClose={() => setRenameModalOpen(false)}
        aria-labelledby="rename-modal-title"
        aria-describedby="rename-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="rename-modal-title" variant="h6" component="h2">
            Renommer le modèle
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            label="Nouveau nom"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button onClick={() => setRenameModalOpen(false)} variant="outlined" color="primary">
              Annuler
            </Button>
            <Button onClick={handleRename} variant="contained" color="primary">
              Renommer
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Delete Modal */}
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            Supprimer le modèle
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2 }}>
            Êtes-vous sûr de vouloir supprimer ce modèle ?
          </Typography>
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button onClick={() => setDeleteModalOpen(false)} variant="outlined" color="primary">
              Annuler
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Supprimer
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
