import { Paper, Typography, List, Box, IconButton, ListItem } from '@mui/material'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CustomerPdfTemplateZoneDto } from '../../interactors/gen/backendClient'
import { DragIndicator, Delete } from '@mui/icons-material'
import React, { useRef } from 'react'

interface Props {
  zones: CustomerPdfTemplateZoneDto[]
  selectedZoneIndex: number | null
  handleZoneClick: (index: number, fromMenu?: boolean) => void
  handleDeleteZone: (index: number) => void
  setZones: React.Dispatch<React.SetStateAction<CustomerPdfTemplateZoneDto[]>>
}

export const ZoneList: React.FC<Props> = ({
  zones,
  selectedZoneIndex,
  handleZoneClick,
  handleDeleteZone,
  setZones,
}) => {
  const moveZone = (fromIndex: number, toIndex: number) => {
    const updatedZones = [...zones]
    const [movedZone] = updatedZones.splice(fromIndex, 1)
    updatedZones.splice(toIndex, 0, movedZone)
    setZones(updatedZones)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Paper elevation={2} sx={{ p: 2, mt: 2, width: '250px', borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
          Zones du Document
        </Typography>
        <List dense sx={{ maxHeight: 400, overflowY: 'auto' }}>
          {zones.map((zone, index) => (
            <DraggableZone
              key={index}
              zone={zone}
              index={index}
              selectedZoneIndex={selectedZoneIndex}
              handleZoneClick={() => handleZoneClick(index, true)}
              handleDeleteZone={handleDeleteZone}
              moveZone={moveZone}
            />
          ))}
        </List>
      </Paper>
    </DndProvider>
  )
}

const ItemType = 'zone'

interface DragItem {
  index: number
  type: string
}

const DraggableZone: React.FC<{
  zone: CustomerPdfTemplateZoneDto
  index: number
  selectedZoneIndex: number | null
  handleZoneClick: (index: number) => void
  handleDeleteZone: (index: number) => void
  moveZone: (dragIndex: number, hoverIndex: number) => void
}> = ({ zone, index, selectedZoneIndex, handleZoneClick, handleDeleteZone, moveZone }) => {
  const ref = useRef<HTMLLIElement>(null)

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: DragItem) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex !== hoverIndex) {
        moveZone(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <ListItem
      ref={ref}
      onClick={() => handleZoneClick(index)}
      selected={selectedZoneIndex === index}
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: selectedZoneIndex === index ? 'rgba(255, 228, 181, 0.3)' : 'transparent',
        opacity: isDragging ? 0.5 : 1,
        transition: 'background-color 0.2s ease-in-out',
        cursor: 'pointer',
        pl: 0,
        mb: 1,
        pb: 1,
        borderBottom: '1px solid #ddd',
        '&:hover': {
          backgroundColor: 'rgba(255, 228, 181, 0.2)',
        },
      }}
    >
      {/* Drag Handle */}
      <DragIndicator fontSize="small" sx={{ color: 'grey.500', mr: 1, cursor: 'grab' }} />

      <Typography variant="body2" sx={{ color: 'text.primary', mr: 1 }}>
        {index + 1}
      </Typography>

      {/* Zone details */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pl: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
          {zone.name ?? zone.type.toUpperCase()}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Page {zone.page}
        </Typography>
      </Box>

      {/* Delete Button */}
      <IconButton
        edge="end"
        onClick={(e) => {
          e.stopPropagation()
          handleDeleteZone(index)
        }}
        size="small"
        color="error"
      >
        <Delete fontSize="small" />
      </IconButton>
    </ListItem>
  )
}
