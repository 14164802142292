import { metricalpEvent } from '@metricalp/react'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import { useMutation } from 'react-query'
import { AccountsClient } from '../../interactors/clients/AccountsClient'
import { AccountDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'

interface Props {
  isOpen: boolean
  close: () => void
}

export const SurveyPopUp: React.FC<Props> = ({ isOpen, close }) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const accountsStore = useAccountsStore()
  const [royalCanin, setRoyalCanin] = React.useState(true)
  const [proPlan, setProPlan] = React.useState(true)
  const [pedigree, setPedigree] = React.useState(true)
  const account = accountsStore.connectedAccount

  const editAccountMutation = useMutation(
    async (account: AccountDto) => {
      return await AccountsClient.editAccount(account)
    },
    {
      onSuccess: (account) => {
        accountsStore.changeConnectedAccount(account)
        globalSnackBarStore.triggerSuccessMessage('Merci pour votre réponse')
        close()
      },
    }
  )

  const sendSurvey = () => {
    if (!account) return
    metricalpEvent({
      type: `food_survey`,
      customerId: account.customerId,
      accountId: account.id,
      royal_canin: royalCanin ? 'oui' : 'non',
      pro_plan: proPlan ? 'oui' : 'non',
      pedigree: pedigree ? 'oui' : 'non',
    })
    editAccountMutation.mutate({
      ...account,
      hasParticipatedToSurvey: true,
    })
  }

  return (
    <Dialog open={isOpen} PaperProps={{ style: { width: 600, maxWidth: '90%', padding: '20px' } }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 0 }}>
        <Typography variant="h6" fontWeight="bold">
          Enquête Petso
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ maxHeight: '60vh', overflowY: 'auto', mt: 2, px: 2 }}>
        <Typography variant="body1" align="center" fontSize={20} sx={{ mb: 3 }}>
          <b>Dans le cadre du développement de Petso, quelle(s) marque(s) et produit(s) recommanderiez-vous ?</b>
        </Typography>

        {/* Survey Options */}
        {['Royal Canin', 'ProPlan', 'Pedigree'].map((brand, index) => (
          <Box key={brand} sx={{ mb: 3 }}>
            <Typography fontSize={16} fontWeight={700} gutterBottom>
              {brand}
            </Typography>
            <ToggleButtonGroup
              exclusive
              color="info"
              value={index === 0 ? royalCanin : index === 1 ? proPlan : pedigree}
              onChange={(_, newValue) => {
                if (newValue !== null) {
                  index === 0 ? setRoyalCanin(newValue) : index === 1 ? setProPlan(newValue) : setPedigree(newValue)
                }
              }}
              fullWidth
            >
              <ToggleButton
                value={true}
                sx={{
                  width: '50%',
                  fontSize: 16,
                  '&.Mui-selected': {
                    backgroundColor: '#FFECB3',
                    color: '#FF8F00',
                    fontWeight: 'bold',
                  },
                  '&:hover': { backgroundColor: '#FFE082' },
                }}
              >
                Oui
              </ToggleButton>
              <ToggleButton
                value={false}
                sx={{
                  width: '50%',
                  fontSize: 16,
                  '&.Mui-selected': {
                    backgroundColor: '#FFECB3',
                    color: '#FF8F00',
                    fontWeight: 'bold',
                  },
                  '&:hover': { backgroundColor: '#FFE082' },
                }}
              >
                Non
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
        <LoadingButton
          onClick={sendSurvey}
          loading={editAccountMutation.isLoading}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            maxWidth: 300,
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            py: 1,
          }}
        >
          Valider
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
