import { Cancel, Edit, UploadFile } from '@mui/icons-material'
import { Box, Button, Card, CardContent, IconButton, Typography, useMediaQuery } from '@mui/material'
import { blue } from '@mui/material/colors'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { setOpacity } from '../../../utils/setOpacity'
import { colors, spacingItem, theme } from '../../theme'
import { getAdoptionAttemptDocumentsSrc } from '../../../utils/S3-links'
import { useMutation } from 'react-query'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { LoadingButton } from '@mui/lab'
import { AdoptionAttemptDocumentDtoType } from '../../../interactors/gen/backendClient'

interface Props {
  title: string
  adopterId: string
  adoptionAttemptId: string
  type: AdoptionAttemptDocumentDtoType
  originalFileKey?: string
  disabled?: boolean
}

export const ExitDocumentCard: React.FC<Props> = ({
  title,
  adopterId,
  adoptionAttemptId,
  type,
  originalFileKey,
  disabled = false,
}) => {
  const [fileKey, setFileKey] = React.useState<string | undefined>(originalFileKey)
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    noClick: false,
    noKeyboard: false,
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0])
    },
  })

  const globalSnackbar = useGlobalSnackbarStore()

  const uploadFileMutation = useMutation(
    async ({ file }: { file: File }) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', type)

      return await AdoptersClient.addDocumentToAdoptionAttempt(adopterId, adoptionAttemptId, formData)
    },
    {
      onSuccess: (data) => {
        setFileKey(data)
        setIsEditing(false)
        setSelectedFile(null)
        globalSnackbar.triggerSuccessMessage(`Votre document a bien été ajouté !`)
      },
    }
  )

  const handleEditClick = () => {
    setIsEditing((prev) => !prev)
    setSelectedFile(null)
  }

  const handleFileUpload = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (selectedFile) {
      uploadFileMutation.mutate({ file: selectedFile })
    }
  }

  return (
    <Card
      sx={{
        border: { xs: 'none', sm: 'auto' }, // ???
        boxShadow: { xs: 'none', sm: 'auto' }, // ???
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 0, sm: 2 },
          height: { sm: '100%' },
          padding: { xs: 0, sm: '12px !important' },
          '&:last-child': { pb: '16px' },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Title */}
          <Typography variant="h5" component="p" sx={{ fontSize: { xs: 16, sm: 19 } }} fontWeight={700}>
            {title}
          </Typography>
          {/* Edit / cancel (desktop only) */}
          {!isMobile && isEditing && (
            <IconButton sx={{ p: 0 }} onClick={() => handleEditClick()}>
              {isEditing ? <Cancel /> : <Edit />}
            </IconButton>
          )}
        </Box>

        {/* Document not inserted: message */}
        {fileKey && !isEditing && fileKey === 'NOT_PROVIDED.pdf' && (
          <Box {...getRootProps()}>
            <Typography
              variant="h4"
              component="p"
              sx={{ fontSize: { xs: 14, sm: 16 }, height: { sm: 80 } }}
              textAlign="left"
              mb={2}
            >
              Ce document a été fourni en mains propres. Vous ne pouvez donc pas le consulter.
            </Typography>
          </Box>
        )}

        {/* Document confirmation message */}
        {fileKey && !isEditing && fileKey !== 'NOT_PROVIDED.pdf' && (
          <Box {...getRootProps()}>
            {type === 'knowledge-certificate' || type === 'adoption-contract' ? (
              <Typography
                variant="h4"
                component="p"
                sx={{ fontSize: { xs: 14, sm: 16 }, height: { sm: 80 } }}
                textAlign="left"
                mb={2}
              >
                Vous avez validé la signature du{' '}
                {type === 'knowledge-certificate'
                  ? "certificat d'engagement et de connaissance"
                  : type === 'adoption-contract' && 'contrat'}{' '}
                par l&#39;adoptant. Vous pouvez le consulter en cliquant sur le bouton ci-dessous.
              </Typography>
            ) : (
              <Typography
                variant="h4"
                component="p"
                sx={{ fontSize: { xs: 14, sm: 16 }, height: { sm: 80 } }}
                textAlign="left"
                mb={2}
              >
                Ce document a bien été partagé avec l&#39;adoptant. Vous pouvez le consulter en cliquant sur le bouton
                ci-dessous.
              </Typography>
            )}
          </Box>
        )}

        {isMobile && (!fileKey || isEditing) && (
          <Box {...getRootProps()}>
            <Typography fontSize={14} mb={1}>
              {selectedFile?.name || 'Aucun fichier sélectionné'}
            </Typography>
          </Box>
        )}

        {/* Buttons */}
        <Box display="flex" gap={1}>
          {/* Buttons: "Remplacer" (both screens) / "Annuler" (only for mobile) */}
          {(fileKey || isEditing) &&
            // Mobile
            (isMobile ? (
              <Button
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  width: fileKey === 'NOT_PROVIDED.pdf' ? '100%' : isEditing ? '100%' : '50%',
                }}
                onClick={() => handleEditClick()}
              >
                {isEditing ? 'Annuler' : fileKey === 'NOT_PROVIDED.pdf' ? 'Ajouter' : 'Remplacer'}
              </Button>
            ) : (
              // Desktop
              !isEditing && (
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'none', fontSize: { xs: 14, sm: 16 }, width: isEditing ? '100%' : '50%' }}
                  onClick={() => handleEditClick()}
                >
                  {fileKey === 'NOT_PROVIDED.pdf' ? 'Ajouter' : 'Remplacer'}
                </Button>
              )
            ))}

          {/* Buttons: "Parcourir" (only for mobile) / "Valider" (both screens) */}
          {(!fileKey || isEditing) &&
            // Mobile
            (isMobile ? (
              <Box
                {...getRootProps()}
                // width={
                //   (type === 'adoption-contract' || type === 'knowledge-certificate') && isEditing ? '100%' : '100%'
                // }
                width="100%"
              >
                <input {...getInputProps()} />
                {selectedFile ? (
                  <LoadingButton
                    variant="contained"
                    sx={{ textTransform: 'none', width: '100%', fontSize: { xs: 14, sm: 16 } }}
                    loading={uploadFileMutation.isLoading}
                    disabled={disabled}
                    onClick={(e) => handleFileUpload(e)}
                  >
                    Valider
                  </LoadingButton>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      width: '100%',
                      fontSize: { xs: 14, sm: 16 },
                    }}
                    disabled={disabled}
                  >
                    Parcourir
                  </Button>
                )}
              </Box>
            ) : (
              // Desktop
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                padding={3}
                {...getRootProps({
                  sx: {
                    ...spacingItem,
                    border: 3,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderColor: isDragActive ? 'green' : blue,
                    backgroundColor: isDragActive
                      ? 'lightgreen'
                      : acceptedFiles[0]
                      ? setOpacity(colors.geyser, 0.4)
                      : 'transparent',
                    transition: 'all 0.3s',
                  },
                })}
              >
                <input {...getInputProps()} />
                <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
                <Typography variant="h4" component="p" fontSize={18} textAlign="center">
                  Glissez-déposer ou
                  <br />
                  Insérer un document
                </Typography>
                <Box
                  marginTop={4}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    maxWidth: '100%',
                  }}
                >
                  <Typography variant="h4" component="p" fontSize={16} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
                    Votre document:
                  </Typography>
                  <Typography
                    variant="h4"
                    component="p"
                    fontSize={14}
                    textAlign="center"
                    color={colors.black}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '120%',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                    }}
                  >
                    {selectedFile?.name || 'Aucun fichier sélectionné'}
                  </Typography>
                </Box>
                {selectedFile ? (
                  <LoadingButton
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      fontSize: { xs: 14, sm: 16 },
                      width: '50%',
                      mt: 1,
                    }}
                    loading={uploadFileMutation.isLoading}
                    disabled={disabled}
                    onClick={(e) => handleFileUpload(e)}
                  >
                    Valider
                  </LoadingButton>
                ) : (
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      fontSize: { xs: 14, sm: 16 },
                      width: '50%',
                      mt: 1,
                    }}
                    disabled={disabled}
                  >
                    Parcourir
                  </Button>
                )}
              </Box>
            ))}

          {/* Button: "Consulter" */}
          {fileKey && !isEditing && fileKey !== 'NOT_PROVIDED.pdf' && (
            <Box width="50%">
              <a
                href={getAdoptionAttemptDocumentsSrc(fileKey)}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: 'none', fontSize: { xs: 14, sm: 16 }, width: '100%' }}
                  onClick={(e) => e.stopPropagation()}
                >
                  Consulter
                </Button>
              </a>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
