import { ArrowBack, ArrowForward, Check, Delete } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import * as pdfjs from 'pdfjs-dist'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CustomerPdfTemplateVariableZoneDto, CustomerPdfTemplateZoneDto } from '../../interactors/gen/backendClient'
import { translateVariablePlaceholder } from '../../domain/Customer/TemplateVariables'

// Configure pdfjs to use the local worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`,
  import.meta.url
).toString()

interface Props {
  pdfFileUrl?: string
  file?: File
  onZoneSelect: (pdfZone: SelectionZone | null, pageDimensions: { width: number; height: number } | null) => void
  disableZoneSelection?: boolean
  onDeleteZone: (index: number) => void
  allZones: CustomerPdfTemplateZoneDto[] // Add array for all zones
  selectedZoneIndex: number | null
  handleZoneClick: (index: number) => void
  onDeleteVariableZone: (index: number) => void
  variableZones: CustomerPdfTemplateVariableZoneDto[]
  selectedVariableZoneIndex: number | null
  handleVariableZoneClick: (index: number, fromMenu?: boolean) => void
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export interface SelectionZone {
  x: number
  y: number
  width: number
  height: number
  pageWidth: number
  pageHeight: number
  pageNumber: number
}

const PdfZoneSelector: React.FC<Props> = ({
  pdfFileUrl,
  file,
  onZoneSelect,
  onDeleteZone,
  disableZoneSelection,
  allZones,
  selectedZoneIndex,
  handleZoneClick,
  onDeleteVariableZone,
  variableZones,
  selectedVariableZoneIndex,
  handleVariableZoneClick,
  currentPage,
  setCurrentPage,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(null)
  const [selection, setSelection] = useState<Omit<SelectionZone, 'pageWidth' | 'pageHeight'> | null>(null)
  const [isSelecting, setIsSelecting] = useState(false)
  const [pageDimensions, setPageDimensions] = useState<{ width: number; height: number } | null>(null)
  const [pdf, setPdf] = useState<pdfjs.PDFDocumentProxy | null>(null)
  const [totalPages, setTotalPages] = useState(0)
  const animationFrameId = useRef<number | null>(null)

  useEffect(() => {
    const loadPdfFromWeb = async () => {
      if (!pdfFileUrl) return
      const loadingTask = pdfjs.getDocument(pdfFileUrl)
      const loadedPdf = await loadingTask.promise
      setPdf(loadedPdf)
      setTotalPages(loadedPdf.numPages)
      renderPage(loadedPdf, currentPage)
    }

    const loadPdf = async () => {
      if (!file) return
      const arrayBuffer = await file.arrayBuffer()
      const loadingTask = pdfjs.getDocument(arrayBuffer)
      const loadedPdf = await loadingTask.promise
      setPdf(loadedPdf)
      setTotalPages(loadedPdf.numPages)
      renderPage(loadedPdf, currentPage)
    }

    if (pdfFileUrl) {
      loadPdfFromWeb()
    } else if (file) {
      loadPdf()
    }
  }, [pdfFileUrl, file, currentPage])

  const renderPage = async (pdf: pdfjs.PDFDocumentProxy, pageNumber: number) => {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1.5 })
    setPageDimensions({ width: viewport.width, height: viewport.height })

    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        canvas.width = viewport.width
        canvas.height = viewport.height

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        }
        await page.render(renderContext).promise
      }
    }
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setStartPoint({ x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY })
    setIsSelecting(true)
    handleZoneClick(-1)
    handleVariableZoneClick(-1)
    setSelection({
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY,
      width: 0,
      height: 0,
      pageNumber: currentPage,
    })
  }

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      if (isSelecting && startPoint) {
        if (animationFrameId.current) cancelAnimationFrame(animationFrameId.current)

        animationFrameId.current = requestAnimationFrame(() => {
          const currentX = event.nativeEvent.offsetX
          const currentY = event.nativeEvent.offsetY
          const newSelection = {
            x: Math.min(startPoint.x, currentX),
            y: Math.min(startPoint.y, currentY),
            width: Math.abs(currentX - startPoint.x),
            height: Math.abs(currentY - startPoint.y),
            pageNumber: currentPage,
          }
          setSelection(newSelection)
        })
      }
    },
    [isSelecting, startPoint]
  )

  const handleMouseUp = useCallback(() => {
    setIsSelecting(false)
    if (animationFrameId.current) cancelAnimationFrame(animationFrameId.current)

    // Clear selection if no area is selected
    if (selection && (selection.width === 0 || selection.height === 0)) {
      setSelection(null)
    }
  }, [selection])

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseUp])

  const handleValidateSelection = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (selection && pageDimensions) {
      const pdfZone: SelectionZone = {
        x: selection.x,
        y: selection.y,
        width: selection.width,
        height: selection.height,
        pageWidth: pageDimensions.width,
        pageHeight: pageDimensions.height,
        pageNumber: selection.pageNumber,
      }
      onZoneSelect(pdfZone, pageDimensions)
    }
    setSelection(null)
  }

  const handleNextPage = () => {
    if (pdf && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1)
    }
  }

  const handlePrevPage = () => {
    if (pdf && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1)
    }
  }

  return (
    <Box sx={{ position: 'relative', border: '1px solid gray', width: 'fit-content' }}>
      <canvas
        ref={canvasRef}
        width={600}
        height={800}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        style={{ cursor: 'crosshair' }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 2 }}>
        <Button startIcon={<ArrowBack />} onClick={handlePrevPage} disabled={currentPage === 1}>
          Précédente
        </Button>
        <Typography sx={{ userSelect: 'none' }}>
          Page {currentPage} / {totalPages}
        </Typography>
        <Button endIcon={<ArrowForward />} onClick={handleNextPage} disabled={currentPage === totalPages}>
          Suivante
        </Button>
      </Box>

      {/* Render all zones */}
      {allZones.map((zone, index) => {
        if (zone.page === currentPage) {
          return (
            <Box
              key={index}
              sx={{
                position: 'absolute',
                border: selectedZoneIndex === index ? '2px solid orange' : '2px solid navy',
                left: zone.x * (pageDimensions?.width || 1),
                top: zone.y * (pageDimensions?.height || 1),
                width: zone.width * (pageDimensions?.width || 1),
                height: zone.height * (pageDimensions?.height || 1),
                backgroundColor: selectedZoneIndex === index ? 'rgba(255, 165, 0, 0.3)' : 'rgba(0, 0, 128, 0.1)',
                cursor: 'pointer',
                zIndex: 10,
              }}
              onClick={() => handleZoneClick(index)}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  maxHeight: zone.height * 800,
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  padding: '2px',
                  paddingTop: 0,
                  userSelect: 'none',
                  fontSize: zone.height * 600 < 18 ? zone.height * 600 : 17,
                }}
              >
                {zone.name ?? zone.type.toUpperCase()}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  top: -30,
                  right: -30,
                  display: 'flex',
                  gap: 1,
                  zIndex: 10,
                  pointerEvents: 'auto',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                  padding: 0.5,
                  visibility: selectedZoneIndex === index ? 'visible' : 'hidden',
                }}
              >
                <Tooltip title="Supprimer ce champ">
                  <IconButton onClick={() => onDeleteZone(index)} color="secondary" size="small">
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )
        }
      })}

      {/* Render all variables */}
      {variableZones.map((zone, index) => {
        if (zone.page === currentPage && pageDimensions) {
          return (
            <Box
              key={index}
              sx={{
                position: 'absolute',
                border: selectedVariableZoneIndex === index ? '2px dashed forestgreen' : '2px dashed darkgreen',
                left: zone.x * (pageDimensions?.width || 1),
                top: zone.y * (pageDimensions?.height || 1),
                width: zone.width * (pageDimensions?.width || 1),
                height: zone.height * (pageDimensions?.height || 1),
                backgroundColor:
                  selectedVariableZoneIndex === index ? 'rgba(34, 139, 34, 0.2)' : 'rgba(0, 100, 0, 0.1)',
                cursor: 'pointer',
                zIndex: 10,
              }}
              onClick={() => handleVariableZoneClick(index)}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  maxHeight: zone.height * 800,
                  textAlign: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  padding: '2px',
                  paddingTop: 0,
                  userSelect: 'none',
                  fontSize: zone.height * 600 < 18 ? zone.height * 600 : 17,
                }}
              >
                {translateVariablePlaceholder(zone.variable)}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  top: -30,
                  right: -30,
                  display: 'flex',
                  gap: 1,
                  zIndex: 10,
                  pointerEvents: 'auto',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                  padding: 0.5,
                  visibility: selectedVariableZoneIndex === index ? 'visible' : 'hidden',
                }}
              >
                <Tooltip title="Supprimer ce champ">
                  <IconButton onClick={() => onDeleteVariableZone(index)} color="secondary" size="small">
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )
        }
      })}

      {/* Display selection box */}
      {selection && (
        <Box
          sx={{
            position: 'absolute',
            border: '2px dashed blue',
            backgroundColor: 'rgba(0, 0, 255, 0.1)',
            left: selection.x,
            top: selection.y,
            width: selection.width,
            height: selection.height,
            zIndex: 10,
            pointerEvents: 'none',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -30,
              right: -30,
              display: 'flex',
              gap: 1,
              zIndex: 10,
              pointerEvents: 'auto',
              backgroundColor: 'white',
              borderRadius: '50%',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              padding: 0.5,
            }}
          >
            <Tooltip
              title={
                disableZoneSelection
                  ? 'Veuillez sélectionner un type de champ ou une variable dans le menu latéral'
                  : 'Valider cette sélection'
              }
              style={{ userSelect: 'none' }}
            >
              <span>
                <IconButton
                  onClick={handleValidateSelection}
                  color="primary"
                  size="small"
                  sx={{ color: 'green' }}
                  disabled={disableZoneSelection}
                >
                  <Check />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PdfZoneSelector
