/* tslint:disable */
/* eslint-disable */
/**
 * Petso Backend (987bf6f)
 * The Pesto Backend
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountCommentsDto
 */
export interface AccountCommentsDto {
    /**
     * 
     * @type {string}
     * @memberof AccountCommentsDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCommentsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCommentsDto
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCommentsDto
     */
    'createdBy': string;
}
/**
 * 
 * @export
 * @interface AccountDocumentDto
 */
export interface AccountDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'obtainedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'type': AccountDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'reference'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDocumentDto
     */
    'name'?: string;
}

export const AccountDocumentDtoType = {
    FosterFamilyContract: 'foster-family-contract',
    VolunteerContract: 'volunteer-contract',
    EquipmentLoanForm: 'equipment-loan-form',
    Acaced: 'acaced',
    Asv: 'asv',
    PresidentIdentityDocument: 'president-identity-document',
    Others: 'others'
} as const;

export type AccountDocumentDtoType = typeof AccountDocumentDtoType[keyof typeof AccountDocumentDtoType];

/**
 * 
 * @export
 * @interface AccountDto
 */
export interface AccountDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'memberNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'customerId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDto
     */
    'roles': Array<AccountDtoRoles>;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'permission': AccountDtoPermission;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'country'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {AccountImagesDto}
     * @memberof AccountDto
     */
    'images': AccountImagesDto;
    /**
     * 
     * @type {AccountDtoPreference}
     * @memberof AccountDto
     */
    'preferences': AccountDtoPreference;
    /**
     * 
     * @type {AccountDtoHome}
     * @memberof AccountDto
     */
    'home'?: AccountDtoHome;
    /**
     * 
     * @type {AccountVeterinaryDto}
     * @memberof AccountDto
     */
    'veterinary'?: AccountVeterinaryDto;
    /**
     * 
     * @type {string}
     * @memberof AccountDto
     */
    'status': AccountDtoStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDto
     */
    'titles': Array<string>;
    /**
     * 
     * @type {Array<AccountDtoAvailability>}
     * @memberof AccountDto
     */
    'availability'?: Array<AccountDtoAvailability>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'hasACACED'?: boolean;
    /**
     * 
     * @type {Array<AccountDocumentDto>}
     * @memberof AccountDto
     */
    'documents': Array<AccountDocumentDto>;
    /**
     * 
     * @type {Array<AccountCommentsDto>}
     * @memberof AccountDto
     */
    'comments'?: Array<AccountCommentsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'hasAcceptedTerms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'hasParticipatedToSurvey'?: boolean;
    /**
     * 
     * @type {AccountVisibilityDto}
     * @memberof AccountDto
     */
    'visibility'?: AccountVisibilityDto;
    /**
     * 
     * @type {CoordinatesDto}
     * @memberof AccountDto
     */
    'coordinates'?: CoordinatesDto;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'validated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDto
     */
    'restrainedData'?: boolean;
}

export const AccountDtoRoles = {
    Member: 'member',
    HostFamily: 'host-family',
    Veterinary: 'veterinary'
} as const;

export type AccountDtoRoles = typeof AccountDtoRoles[keyof typeof AccountDtoRoles];
export const AccountDtoPermission = {
    Administrator: 'administrator',
    Editor: 'editor',
    Read: 'read',
    Silent: 'silent'
} as const;

export type AccountDtoPermission = typeof AccountDtoPermission[keyof typeof AccountDtoPermission];
export const AccountDtoStatus = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type AccountDtoStatus = typeof AccountDtoStatus[keyof typeof AccountDtoStatus];

/**
 * 
 * @export
 * @interface AccountDtoAvailability
 */
export interface AccountDtoAvailability {
    /**
     * 
     * @type {string}
     * @memberof AccountDtoAvailability
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoAvailability
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoAvailability
     */
    'status': AccountDtoAvailabilityStatus;
}

export const AccountDtoAvailabilityStatus = {
    Active: 'active',
    Inactive: 'inactive',
    EmergencyOnly: 'emergency-only'
} as const;

export type AccountDtoAvailabilityStatus = typeof AccountDtoAvailabilityStatus[keyof typeof AccountDtoAvailabilityStatus];

/**
 * 
 * @export
 * @interface AccountDtoHome
 */
export interface AccountDtoHome {
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHome
     */
    'homeType'?: AccountDtoHomeHomeType;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHome
     */
    'balcony'?: AccountDtoHomeBalcony;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHome
     */
    'garden'?: AccountDtoHomeGarden;
    /**
     * 
     * @type {number}
     * @memberof AccountDtoHome
     */
    'quarantineRoomNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDtoHome
     */
    'adultNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDtoHome
     */
    'childrenNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountDtoHome
     */
    'maxCapacity'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHome
     */
    'hasCar'?: AccountDtoHomeHasCar;
    /**
     * 
     * @type {number}
     * @memberof AccountDtoHome
     */
    'homeSurface'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHome
     */
    'hasIsolationRoom'?: AccountDtoHomeHasIsolationRoom;
    /**
     * 
     * @type {Array<AccountDtoHomePet>}
     * @memberof AccountDtoHome
     */
    'pets'?: Array<AccountDtoHomePet>;
}

export const AccountDtoHomeHomeType = {
    House: 'house',
    Apartment: 'apartment'
} as const;

export type AccountDtoHomeHomeType = typeof AccountDtoHomeHomeType[keyof typeof AccountDtoHomeHomeType];
export const AccountDtoHomeBalcony = {
    No: 'no',
    YesSecured: 'yes-secured',
    YesNotSecured: 'yes-not-secured'
} as const;

export type AccountDtoHomeBalcony = typeof AccountDtoHomeBalcony[keyof typeof AccountDtoHomeBalcony];
export const AccountDtoHomeGarden = {
    No: 'no',
    YesSecured: 'yes-secured',
    YesNotSecured: 'yes-not-secured'
} as const;

export type AccountDtoHomeGarden = typeof AccountDtoHomeGarden[keyof typeof AccountDtoHomeGarden];
export const AccountDtoHomeHasCar = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AccountDtoHomeHasCar = typeof AccountDtoHomeHasCar[keyof typeof AccountDtoHomeHasCar];
export const AccountDtoHomeHasIsolationRoom = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AccountDtoHomeHasIsolationRoom = typeof AccountDtoHomeHasIsolationRoom[keyof typeof AccountDtoHomeHasIsolationRoom];

/**
 * 
 * @export
 * @interface AccountDtoHomePet
 */
export interface AccountDtoHomePet {
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHomePet
     */
    'specie': AccountDtoHomePetSpecie;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHomePet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHomePet
     */
    'sex': AccountDtoHomePetSex;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHomePet
     */
    'sterilizedOrNeutered': AccountDtoHomePetSterilizedOrNeutered;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoHomePet
     */
    'vaccinated': AccountDtoHomePetVaccinated;
}

export const AccountDtoHomePetSpecie = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AccountDtoHomePetSpecie = typeof AccountDtoHomePetSpecie[keyof typeof AccountDtoHomePetSpecie];
export const AccountDtoHomePetSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type AccountDtoHomePetSex = typeof AccountDtoHomePetSex[keyof typeof AccountDtoHomePetSex];
export const AccountDtoHomePetSterilizedOrNeutered = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AccountDtoHomePetSterilizedOrNeutered = typeof AccountDtoHomePetSterilizedOrNeutered[keyof typeof AccountDtoHomePetSterilizedOrNeutered];
export const AccountDtoHomePetVaccinated = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AccountDtoHomePetVaccinated = typeof AccountDtoHomePetVaccinated[keyof typeof AccountDtoHomePetVaccinated];

/**
 * 
 * @export
 * @interface AccountDtoPreference
 */
export interface AccountDtoPreference {
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDtoPreference
     */
    'species'?: Array<AccountDtoPreferenceSpecies>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDtoPreference
     */
    'seniority'?: Array<AccountDtoPreferenceSeniority>;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDtoPreference
     */
    'haveChildren'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountDtoPreference
     */
    'maxCapacity'?: number;
    /**
     * 
     * @type {AccountDtoPreferences}
     * @memberof AccountDtoPreference
     */
    'primaryPreferences'?: AccountDtoPreferences;
    /**
     * 
     * @type {Array<AccountDtoPreferences>}
     * @memberof AccountDtoPreference
     */
    'otherPreferences'?: Array<AccountDtoPreferences>;
}

export const AccountDtoPreferenceSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AccountDtoPreferenceSpecies = typeof AccountDtoPreferenceSpecies[keyof typeof AccountDtoPreferenceSpecies];
export const AccountDtoPreferenceSeniority = {
    Senior: 'senior',
    Junior: 'junior',
    Adult: 'adult'
} as const;

export type AccountDtoPreferenceSeniority = typeof AccountDtoPreferenceSeniority[keyof typeof AccountDtoPreferenceSeniority];

/**
 * 
 * @export
 * @interface AccountDtoPreferences
 */
export interface AccountDtoPreferences {
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'specie'?: AccountDtoPreferencesSpecie;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountDtoPreferences
     */
    'seniority'?: Array<AccountDtoPreferencesSeniority>;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'sex'?: AccountDtoPreferencesSex;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'sterilizedOrNeutered'?: AccountDtoPreferencesSterilizedOrNeutered;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'vaccinated'?: AccountDtoPreferencesVaccinated;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'withMedicalAntecedent'?: AccountDtoPreferencesWithMedicalAntecedent;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'willingToSocializeWildAnimals'?: AccountDtoPreferencesWillingToSocializeWildAnimals;
    /**
     * 
     * @type {string}
     * @memberof AccountDtoPreferences
     */
    'needsBottleFeeding'?: AccountDtoPreferencesNeedsBottleFeeding;
}

export const AccountDtoPreferencesSpecie = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AccountDtoPreferencesSpecie = typeof AccountDtoPreferencesSpecie[keyof typeof AccountDtoPreferencesSpecie];
export const AccountDtoPreferencesSeniority = {
    Senior: 'senior',
    Adult: 'adult',
    LessThanOneMonth: 'less-than-one-month',
    OneToSixMonths: 'one-to-six-months',
    MoreThanSixMonths: 'more-than-six-months',
    PregnantFemale: 'pregnant-female',
    MotherWithBabies: 'mother-with-babies',
    NoPreference: 'no-preference'
} as const;

export type AccountDtoPreferencesSeniority = typeof AccountDtoPreferencesSeniority[keyof typeof AccountDtoPreferencesSeniority];
export const AccountDtoPreferencesSex = {
    Male: 'male',
    Female: 'female',
    NoPreference: 'no-preference'
} as const;

export type AccountDtoPreferencesSex = typeof AccountDtoPreferencesSex[keyof typeof AccountDtoPreferencesSex];
export const AccountDtoPreferencesSterilizedOrNeutered = {
    True: 'true',
    False: 'false',
    NoPreference: 'no-preference'
} as const;

export type AccountDtoPreferencesSterilizedOrNeutered = typeof AccountDtoPreferencesSterilizedOrNeutered[keyof typeof AccountDtoPreferencesSterilizedOrNeutered];
export const AccountDtoPreferencesVaccinated = {
    True: 'true',
    False: 'false',
    NoPreference: 'no-preference'
} as const;

export type AccountDtoPreferencesVaccinated = typeof AccountDtoPreferencesVaccinated[keyof typeof AccountDtoPreferencesVaccinated];
export const AccountDtoPreferencesWithMedicalAntecedent = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AccountDtoPreferencesWithMedicalAntecedent = typeof AccountDtoPreferencesWithMedicalAntecedent[keyof typeof AccountDtoPreferencesWithMedicalAntecedent];
export const AccountDtoPreferencesWillingToSocializeWildAnimals = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AccountDtoPreferencesWillingToSocializeWildAnimals = typeof AccountDtoPreferencesWillingToSocializeWildAnimals[keyof typeof AccountDtoPreferencesWillingToSocializeWildAnimals];
export const AccountDtoPreferencesNeedsBottleFeeding = {
    True: 'true',
    False: 'false',
    NoPreference: 'no-preference'
} as const;

export type AccountDtoPreferencesNeedsBottleFeeding = typeof AccountDtoPreferencesNeedsBottleFeeding[keyof typeof AccountDtoPreferencesNeedsBottleFeeding];

/**
 * 
 * @export
 * @interface AccountImagesDto
 */
export interface AccountImagesDto {
    /**
     * 
     * @type {string}
     * @memberof AccountImagesDto
     */
    'profileImageKey'?: string;
}
/**
 * 
 * @export
 * @interface AccountVeterinaryDto
 */
export interface AccountVeterinaryDto {
    /**
     * 
     * @type {string}
     * @memberof AccountVeterinaryDto
     */
    'type': AccountVeterinaryDtoType;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountVeterinaryDto
     */
    'specialty'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AccountVeterinaryDto
     */
    'details'?: string;
}

export const AccountVeterinaryDtoType = {
    Clinic: 'clinic',
    Independent: 'independent'
} as const;

export type AccountVeterinaryDtoType = typeof AccountVeterinaryDtoType[keyof typeof AccountVeterinaryDtoType];

/**
 * 
 * @export
 * @interface AccountVisibilityDto
 */
export interface AccountVisibilityDto {
    /**
     * 
     * @type {boolean}
     * @memberof AccountVisibilityDto
     */
    'toAdmins': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountVisibilityDto
     */
    'toEditors': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountVisibilityDto
     */
    'toReaders': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountVisibilityDto
     */
    'onTheMap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountVisibilityDto
     */
    'pauseProfile'?: boolean;
}
/**
 * 
 * @export
 * @interface AdministrativeFollowUpDto
 */
export interface AdministrativeFollowUpDto {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeFollowUpDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeFollowUpDto
     */
    'status'?: AdministrativeFollowUpDtoStatus;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeFollowUpDto
     */
    'type': AdministrativeFollowUpDtoType;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeFollowUpDto
     */
    'memberInChargeAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeFollowUpDto
     */
    'description'?: string;
}

export const AdministrativeFollowUpDtoStatus = {
    Done: 'done',
    OnGoing: 'on-going',
    ToPlan: 'to-plan'
} as const;

export type AdministrativeFollowUpDtoStatus = typeof AdministrativeFollowUpDtoStatus[keyof typeof AdministrativeFollowUpDtoStatus];
export const AdministrativeFollowUpDtoType = {
    MakingContact: 'making-contact',
    HomeVisit: 'home-visit',
    AnimalMeeting: 'animal-meeting',
    IssueOfCertificate: 'issue-of-certificate',
    ReceiptOfSignedCertificate: 'receipt-of-signed-certificate',
    IssueOfAdoptionContract: 'issue-of-adoption-contract',
    ReceiptOfSignedContract: 'receipt-of-signed-contract'
} as const;

export type AdministrativeFollowUpDtoType = typeof AdministrativeFollowUpDtoType[keyof typeof AdministrativeFollowUpDtoType];

/**
 * 
 * @export
 * @interface AdopterDto
 */
export interface AdopterDto {
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'country'?: string;
    /**
     * 
     * @type {AnimalProfileHealthVeterinaryDto}
     * @memberof AdopterDto
     */
    'defaultVeterinary'?: AnimalProfileHealthVeterinaryDto;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'firstContact'?: AdopterDtoFirstContact;
    /**
     * 
     * @type {string}
     * @memberof AdopterDto
     */
    'profileHasBeenAccepted'?: AdopterDtoProfileHasBeenAccepted;
    /**
     * 
     * @type {AdopterDtoPreference}
     * @memberof AdopterDto
     */
    'preferences': AdopterDtoPreference;
    /**
     * 
     * @type {AdopterDtoAdoptionForm}
     * @memberof AdopterDto
     */
    'adoptionForm'?: AdopterDtoAdoptionForm;
    /**
     * 
     * @type {Array<AdopterFollowUpDto>}
     * @memberof AdopterDto
     */
    'followUp'?: Array<AdopterFollowUpDto>;
    /**
     * 
     * @type {DesiredAnimalDto}
     * @memberof AdopterDto
     */
    'desiredAnimal'?: DesiredAnimalDto;
    /**
     * 
     * @type {Array<AdoptionAttemptDto>}
     * @memberof AdopterDto
     */
    'adoptionAttempts'?: Array<AdoptionAttemptDto>;
    /**
     * 
     * @type {CoordinatesDto}
     * @memberof AdopterDto
     */
    'coordinates'?: CoordinatesDto;
    /**
     * 
     * @type {boolean}
     * @memberof AdopterDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdopterDto
     */
    'displayPublicly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdopterDto
     */
    'createdWithGoogle'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdopterDto
     */
    'tutorialStep'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdopterDto
     */
    'verifiedUserInformation'?: boolean;
}

export const AdopterDtoFirstContact = {
    WithPhone: 'with-phone',
    Physical: 'physical',
    ToDo: 'to-do'
} as const;

export type AdopterDtoFirstContact = typeof AdopterDtoFirstContact[keyof typeof AdopterDtoFirstContact];
export const AdopterDtoProfileHasBeenAccepted = {
    Accepted: 'accepted',
    Refused: 'refused',
    Waiting: 'waiting',
    FormToSent: 'form-to-sent',
    FormSent: 'form-sent',
    FormAccepted: 'form-accepted',
    FormRefused: 'form-refused',
    Blacklisted: 'blacklisted'
} as const;

export type AdopterDtoProfileHasBeenAccepted = typeof AdopterDtoProfileHasBeenAccepted[keyof typeof AdopterDtoProfileHasBeenAccepted];

/**
 * 
 * @export
 * @interface AdopterDtoAdoptionForm
 */
export interface AdopterDtoAdoptionForm {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdopterDtoAdoptionForm
     */
    'species'?: Array<AdopterDtoAdoptionFormSpecies>;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'sex'?: AdopterDtoAdoptionFormSex;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'sterilizedOrNeutered'?: AdopterDtoAdoptionFormSterilizedOrNeutered;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'hadAnimalBefore'?: AdopterDtoAdoptionFormHadAnimalBefore;
    /**
     * 
     * @type {AdopterDtoAlreadyHasAnimalOrChild}
     * @memberof AdopterDtoAdoptionForm
     */
    'alreadyHasAnimalOrChild'?: AdopterDtoAlreadyHasAnimalOrChild;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'ownAnimalUsedToAnimals'?: AdopterDtoAdoptionFormOwnAnimalUsedToAnimals;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'allHouseMembersAgree'?: AdopterDtoAdoptionFormAllHouseMembersAgree;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'allergiesInHousehold'?: AdopterDtoAdoptionFormAllergiesInHousehold;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'mainHomeType'?: AdopterDtoAdoptionFormMainHomeType;
    /**
     * 
     * @type {AdopterDtoHasExteriorAccess}
     * @memberof AdopterDtoAdoptionForm
     */
    'hasExteriorAccess'?: AdopterDtoHasExteriorAccess;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'workflow'?: AdopterDtoAdoptionFormWorkflow;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'consciousOfAnimalNeeds'?: AdopterDtoAdoptionFormConsciousOfAnimalNeeds;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'consciousOfAnimalCost'?: AdopterDtoAdoptionFormConsciousOfAnimalCost;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'consciousOfAnimalAdoption'?: AdopterDtoAdoptionFormConsciousOfAnimalAdoption;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'caretakerDuringAbsence'?: AdopterDtoAdoptionFormCaretakerDuringAbsence;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'planForPetDuringRelocation'?: AdopterDtoAdoptionFormPlanForPetDuringRelocation;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'petIntendedForSelfOrGift'?: AdopterDtoAdoptionFormPetIntendedForSelfOrGift;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'desiredPetCharacteristics'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdopterDtoAdoptionForm
     */
    'userConsentForInsuranceEstimate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAdoptionForm
     */
    'customerComments'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdopterDtoAdoptionForm
     */
    'finishedFillingForm'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdopterDtoAdoptionForm
     */
    'step'?: number;
}

export const AdopterDtoAdoptionFormSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AdopterDtoAdoptionFormSpecies = typeof AdopterDtoAdoptionFormSpecies[keyof typeof AdopterDtoAdoptionFormSpecies];
export const AdopterDtoAdoptionFormSex = {
    Male: 'male',
    Female: 'female',
    NoPreferences: 'no-preferences'
} as const;

export type AdopterDtoAdoptionFormSex = typeof AdopterDtoAdoptionFormSex[keyof typeof AdopterDtoAdoptionFormSex];
export const AdopterDtoAdoptionFormSterilizedOrNeutered = {
    SterilizedOrNeutered: 'sterilized-or-neutered',
    NotSterilizedOrNeutered: 'not-sterilized-or-neutered',
    NoPreferences: 'no-preferences'
} as const;

export type AdopterDtoAdoptionFormSterilizedOrNeutered = typeof AdopterDtoAdoptionFormSterilizedOrNeutered[keyof typeof AdopterDtoAdoptionFormSterilizedOrNeutered];
export const AdopterDtoAdoptionFormHadAnimalBefore = {
    SameAnimal: 'same-animal',
    OtherAnimal: 'other-animal',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormHadAnimalBefore = typeof AdopterDtoAdoptionFormHadAnimalBefore[keyof typeof AdopterDtoAdoptionFormHadAnimalBefore];
export const AdopterDtoAdoptionFormOwnAnimalUsedToAnimals = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormOwnAnimalUsedToAnimals = typeof AdopterDtoAdoptionFormOwnAnimalUsedToAnimals[keyof typeof AdopterDtoAdoptionFormOwnAnimalUsedToAnimals];
export const AdopterDtoAdoptionFormAllHouseMembersAgree = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormAllHouseMembersAgree = typeof AdopterDtoAdoptionFormAllHouseMembersAgree[keyof typeof AdopterDtoAdoptionFormAllHouseMembersAgree];
export const AdopterDtoAdoptionFormAllergiesInHousehold = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormAllergiesInHousehold = typeof AdopterDtoAdoptionFormAllergiesInHousehold[keyof typeof AdopterDtoAdoptionFormAllergiesInHousehold];
export const AdopterDtoAdoptionFormMainHomeType = {
    HouseWithGardenOrCourtyard: 'house-with-garden-or-courtyard',
    HouseWithoutGardenOrCourtyard: 'house-without-garden-or-courtyard',
    ApartmentWithBalcony: 'apartment-with-balcony',
    ApartmentWithoutBalcony: 'apartment-without-balcony'
} as const;

export type AdopterDtoAdoptionFormMainHomeType = typeof AdopterDtoAdoptionFormMainHomeType[keyof typeof AdopterDtoAdoptionFormMainHomeType];
export const AdopterDtoAdoptionFormWorkflow = {
    WorkFromHome: 'work-from-home',
    Teleworking: 'teleworking',
    WorkOutside: 'work-outside',
    CanGoToWorkWithAnimal: 'can-go-to-work-with-animal'
} as const;

export type AdopterDtoAdoptionFormWorkflow = typeof AdopterDtoAdoptionFormWorkflow[keyof typeof AdopterDtoAdoptionFormWorkflow];
export const AdopterDtoAdoptionFormConsciousOfAnimalNeeds = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormConsciousOfAnimalNeeds = typeof AdopterDtoAdoptionFormConsciousOfAnimalNeeds[keyof typeof AdopterDtoAdoptionFormConsciousOfAnimalNeeds];
export const AdopterDtoAdoptionFormConsciousOfAnimalCost = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormConsciousOfAnimalCost = typeof AdopterDtoAdoptionFormConsciousOfAnimalCost[keyof typeof AdopterDtoAdoptionFormConsciousOfAnimalCost];
export const AdopterDtoAdoptionFormConsciousOfAnimalAdoption = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormConsciousOfAnimalAdoption = typeof AdopterDtoAdoptionFormConsciousOfAnimalAdoption[keyof typeof AdopterDtoAdoptionFormConsciousOfAnimalAdoption];
export const AdopterDtoAdoptionFormCaretakerDuringAbsence = {
    Yes: 'yes',
    No: 'no'
} as const;

export type AdopterDtoAdoptionFormCaretakerDuringAbsence = typeof AdopterDtoAdoptionFormCaretakerDuringAbsence[keyof typeof AdopterDtoAdoptionFormCaretakerDuringAbsence];
export const AdopterDtoAdoptionFormPlanForPetDuringRelocation = {
    TakeAnimalWithMe: 'take-animal-with-me',
    LeaveAnimalWithSomeone: 'leave-animal-with-someone',
    LeaveAnimalInShelter: 'leave-animal-in-shelter',
    LeaveAnimalToWildState: 'leave-animal-to-wild-state'
} as const;

export type AdopterDtoAdoptionFormPlanForPetDuringRelocation = typeof AdopterDtoAdoptionFormPlanForPetDuringRelocation[keyof typeof AdopterDtoAdoptionFormPlanForPetDuringRelocation];
export const AdopterDtoAdoptionFormPetIntendedForSelfOrGift = {
    Self: 'self',
    Gift: 'gift'
} as const;

export type AdopterDtoAdoptionFormPetIntendedForSelfOrGift = typeof AdopterDtoAdoptionFormPetIntendedForSelfOrGift[keyof typeof AdopterDtoAdoptionFormPetIntendedForSelfOrGift];

/**
 * 
 * @export
 * @interface AdopterDtoAlreadyHasAnimalOrChild
 */
export interface AdopterDtoAlreadyHasAnimalOrChild {
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'baby'?: AdopterDtoAlreadyHasAnimalOrChildBaby;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'child'?: AdopterDtoAlreadyHasAnimalOrChildChild;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'teenager'?: AdopterDtoAlreadyHasAnimalOrChildTeenager;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'adult'?: AdopterDtoAlreadyHasAnimalOrChildAdult;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'dog'?: AdopterDtoAlreadyHasAnimalOrChildDog;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'cat'?: AdopterDtoAlreadyHasAnimalOrChildCat;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoAlreadyHasAnimalOrChild
     */
    'other'?: AdopterDtoAlreadyHasAnimalOrChildOther;
}

export const AdopterDtoAlreadyHasAnimalOrChildBaby = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildBaby = typeof AdopterDtoAlreadyHasAnimalOrChildBaby[keyof typeof AdopterDtoAlreadyHasAnimalOrChildBaby];
export const AdopterDtoAlreadyHasAnimalOrChildChild = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildChild = typeof AdopterDtoAlreadyHasAnimalOrChildChild[keyof typeof AdopterDtoAlreadyHasAnimalOrChildChild];
export const AdopterDtoAlreadyHasAnimalOrChildTeenager = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildTeenager = typeof AdopterDtoAlreadyHasAnimalOrChildTeenager[keyof typeof AdopterDtoAlreadyHasAnimalOrChildTeenager];
export const AdopterDtoAlreadyHasAnimalOrChildAdult = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildAdult = typeof AdopterDtoAlreadyHasAnimalOrChildAdult[keyof typeof AdopterDtoAlreadyHasAnimalOrChildAdult];
export const AdopterDtoAlreadyHasAnimalOrChildDog = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildDog = typeof AdopterDtoAlreadyHasAnimalOrChildDog[keyof typeof AdopterDtoAlreadyHasAnimalOrChildDog];
export const AdopterDtoAlreadyHasAnimalOrChildCat = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildCat = typeof AdopterDtoAlreadyHasAnimalOrChildCat[keyof typeof AdopterDtoAlreadyHasAnimalOrChildCat];
export const AdopterDtoAlreadyHasAnimalOrChildOther = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoAlreadyHasAnimalOrChildOther = typeof AdopterDtoAlreadyHasAnimalOrChildOther[keyof typeof AdopterDtoAlreadyHasAnimalOrChildOther];

/**
 * 
 * @export
 * @interface AdopterDtoHasExteriorAccess
 */
export interface AdopterDtoHasExteriorAccess {
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoHasExteriorAccess
     */
    'courtyard'?: AdopterDtoHasExteriorAccessCourtyard;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoHasExteriorAccess
     */
    'balcony'?: AdopterDtoHasExteriorAccessBalcony;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoHasExteriorAccess
     */
    'terrace'?: AdopterDtoHasExteriorAccessTerrace;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoHasExteriorAccess
     */
    'garden'?: AdopterDtoHasExteriorAccessGarden;
}

export const AdopterDtoHasExteriorAccessCourtyard = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoHasExteriorAccessCourtyard = typeof AdopterDtoHasExteriorAccessCourtyard[keyof typeof AdopterDtoHasExteriorAccessCourtyard];
export const AdopterDtoHasExteriorAccessBalcony = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoHasExteriorAccessBalcony = typeof AdopterDtoHasExteriorAccessBalcony[keyof typeof AdopterDtoHasExteriorAccessBalcony];
export const AdopterDtoHasExteriorAccessTerrace = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoHasExteriorAccessTerrace = typeof AdopterDtoHasExteriorAccessTerrace[keyof typeof AdopterDtoHasExteriorAccessTerrace];
export const AdopterDtoHasExteriorAccessGarden = {
    None: 'none',
    One: 'one',
    Two: 'two',
    Three: 'three',
    Four: 'four',
    Five: 'five',
    MoreThanFive: 'more-than-five'
} as const;

export type AdopterDtoHasExteriorAccessGarden = typeof AdopterDtoHasExteriorAccessGarden[keyof typeof AdopterDtoHasExteriorAccessGarden];

/**
 * 
 * @export
 * @interface AdopterDtoPreference
 */
export interface AdopterDtoPreference {
    /**
     * 
     * @type {Array<string>}
     * @memberof AdopterDtoPreference
     */
    'species'?: Array<AdopterDtoPreferenceSpecies>;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoPreference
     */
    'sex': AdopterDtoPreferenceSex;
    /**
     * 
     * @type {string}
     * @memberof AdopterDtoPreference
     */
    'sterilizedOrNeutered': AdopterDtoPreferenceSterilizedOrNeutered;
}

export const AdopterDtoPreferenceSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AdopterDtoPreferenceSpecies = typeof AdopterDtoPreferenceSpecies[keyof typeof AdopterDtoPreferenceSpecies];
export const AdopterDtoPreferenceSex = {
    Male: 'male',
    Female: 'female',
    NoPreferences: 'no-preferences'
} as const;

export type AdopterDtoPreferenceSex = typeof AdopterDtoPreferenceSex[keyof typeof AdopterDtoPreferenceSex];
export const AdopterDtoPreferenceSterilizedOrNeutered = {
    Yes: 'yes',
    No: 'no',
    NoPreferences: 'no-preferences'
} as const;

export type AdopterDtoPreferenceSterilizedOrNeutered = typeof AdopterDtoPreferenceSterilizedOrNeutered[keyof typeof AdopterDtoPreferenceSterilizedOrNeutered];

/**
 * 
 * @export
 * @interface AdopterFollowUpDto
 */
export interface AdopterFollowUpDto {
    /**
     * 
     * @type {string}
     * @memberof AdopterFollowUpDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterFollowUpDto
     */
    'interlocutorAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterFollowUpDto
     */
    'type': AdopterFollowUpDtoType;
    /**
     * 
     * @type {string}
     * @memberof AdopterFollowUpDto
     */
    'verdict': AdopterFollowUpDtoVerdict;
    /**
     * 
     * @type {string}
     * @memberof AdopterFollowUpDto
     */
    'description'?: string;
}

export const AdopterFollowUpDtoType = {
    Email: 'email',
    Phone: 'phone',
    Physical: 'physical',
    VisioConference: 'visio-conference',
    HomeTour: 'home-tour'
} as const;

export type AdopterFollowUpDtoType = typeof AdopterFollowUpDtoType[keyof typeof AdopterFollowUpDtoType];
export const AdopterFollowUpDtoVerdict = {
    Accepted: 'accepted',
    Refused: 'refused',
    Waiting: 'waiting'
} as const;

export type AdopterFollowUpDtoVerdict = typeof AdopterFollowUpDtoVerdict[keyof typeof AdopterFollowUpDtoVerdict];

/**
 * 
 * @export
 * @interface AdopterWithAnimalsReducedDto
 */
export interface AdopterWithAnimalsReducedDto {
    /**
     * 
     * @type {string}
     * @memberof AdopterWithAnimalsReducedDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterWithAnimalsReducedDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterWithAnimalsReducedDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof AdopterWithAnimalsReducedDto
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof AdopterWithAnimalsReducedDto
     */
    'distanceToUser': number;
    /**
     * 
     * @type {Array<AnimalReducedDto>}
     * @memberof AdopterWithAnimalsReducedDto
     */
    'animals': Array<AnimalReducedDto>;
}
/**
 * 
 * @export
 * @interface AdoptionAttemptDocumentDto
 */
export interface AdoptionAttemptDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDocumentDto
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDocumentDto
     */
    'type': AdoptionAttemptDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDocumentDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDocumentDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDocumentDto
     */
    'name'?: string;
}

export const AdoptionAttemptDocumentDtoType = {
    SellCertificate: 'sell-certificate',
    VeterinaryCertificate: 'veterinary-certificate',
    InformationDocument: 'information-document',
    HealthBook: 'health-book',
    KnowledgeCertificate: 'knowledge-certificate',
    AdoptionContract: 'adoption-contract',
    Others: 'others'
} as const;

export type AdoptionAttemptDocumentDtoType = typeof AdoptionAttemptDocumentDtoType[keyof typeof AdoptionAttemptDocumentDtoType];

/**
 * 
 * @export
 * @interface AdoptionAttemptDto
 */
export interface AdoptionAttemptDto {
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'animalId': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'status': AdoptionAttemptDtoStatus;
    /**
     * 
     * @type {number}
     * @memberof AdoptionAttemptDto
     */
    'step': number;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'customerName': string;
    /**
     * 
     * @type {AdoptionAttemptDtoCertificate}
     * @memberof AdoptionAttemptDto
     */
    'certificate'?: AdoptionAttemptDtoCertificate;
    /**
     * 
     * @type {AdoptionAttemptDtoCertificate}
     * @memberof AdoptionAttemptDto
     */
    'contract'?: AdoptionAttemptDtoCertificate;
    /**
     * 
     * @type {AdoptionAttemptDtoSterilization}
     * @memberof AdoptionAttemptDto
     */
    'sterilization'?: AdoptionAttemptDtoSterilization;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'paymentDone': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'paymentSentByUser'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'paymentMethod'?: AdoptionAttemptDtoPaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof AdoptionAttemptDto
     */
    'paymentInstallments'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'verifiedPaymentInfo': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'verifiedPriceInfo': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'lastUpdateCustomer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'lastUpdateUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'appointmentDate'?: string;
    /**
     * 
     * @type {Array<AdoptionAttemptEventDto>}
     * @memberof AdoptionAttemptDto
     */
    'events': Array<AdoptionAttemptEventDto>;
    /**
     * 
     * @type {Array<AdoptionAttemptDocumentDto>}
     * @memberof AdoptionAttemptDto
     */
    'documents'?: Array<AdoptionAttemptDocumentDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'done'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'archived'?: boolean;
    /**
     * 
     * @type {Array<AnimalInsightsDto>}
     * @memberof AdoptionAttemptDto
     */
    'insights': Array<AnimalInsightsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'petDeservesToBeSpoiled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'petHasNewName'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'newName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'petHasNewFamily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'petIsInsured'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDto
     */
    'petHasAppointment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDto
     */
    'hasGottenFoodSample'?: AdoptionAttemptDtoHasGottenFoodSample;
    /**
     * 
     * @type {number}
     * @memberof AdoptionAttemptDto
     */
    'preCertificateDialogStep'?: number;
}

export const AdoptionAttemptDtoStatus = {
    PendingCustomer: 'pending-customer',
    PendingUser: 'pending-user',
    AwaitingCertificate: 'awaiting-certificate',
    AwaitingSignedCertificate: 'awaiting-signed-certificate',
    AwaitingCertificateValidation: 'awaiting-certificate-validation',
    AwaitingContract: 'awaiting-contract',
    AwaitingSignedContract: 'awaiting-signed-contract',
    AwaitingContractValidation: 'awaiting-contract-validation',
    AwaitingPayment: 'awaiting-payment',
    AwaitingPaymentValidation: 'awaiting-payment-validation',
    Done: 'done',
    SuspendedByCustomer: 'suspended-by-customer',
    SuspendedByUser: 'suspended-by-user'
} as const;

export type AdoptionAttemptDtoStatus = typeof AdoptionAttemptDtoStatus[keyof typeof AdoptionAttemptDtoStatus];
export const AdoptionAttemptDtoPaymentMethod = {
    Cash: 'cash',
    Transfer: 'transfer',
    Check: 'check',
    UsingPetso: 'using-petso'
} as const;

export type AdoptionAttemptDtoPaymentMethod = typeof AdoptionAttemptDtoPaymentMethod[keyof typeof AdoptionAttemptDtoPaymentMethod];
export const AdoptionAttemptDtoHasGottenFoodSample = {
    Fish: 'fish',
    Chicken: 'chicken',
    Refused: 'refused'
} as const;

export type AdoptionAttemptDtoHasGottenFoodSample = typeof AdoptionAttemptDtoHasGottenFoodSample[keyof typeof AdoptionAttemptDtoHasGottenFoodSample];

/**
 * 
 * @export
 * @interface AdoptionAttemptDtoCertificate
 */
export interface AdoptionAttemptDtoCertificate {
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'original': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'originalDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'signed'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'signedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'refusedReason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'validated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'deliveredByHand'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'docuSealSlug'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'totalPages'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'isPetsoCertificate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoCertificate
     */
    'isLocked'?: boolean;
}
/**
 * 
 * @export
 * @interface AdoptionAttemptDtoSterilization
 */
export interface AdoptionAttemptDtoSterilization {
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'needed': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'file'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'documentSentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'refusedReason'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'validated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'depositPaid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'depositStatus'?: AdoptionAttemptDtoSterilizationDepositStatus;
    /**
     * 
     * @type {number}
     * @memberof AdoptionAttemptDtoSterilization
     */
    'depositAmount'?: number;
}

export const AdoptionAttemptDtoSterilizationDepositStatus = {
    Paid: 'paid',
    Refunded: 'refunded',
    Pending: 'pending'
} as const;

export type AdoptionAttemptDtoSterilizationDepositStatus = typeof AdoptionAttemptDtoSterilizationDepositStatus[keyof typeof AdoptionAttemptDtoSterilizationDepositStatus];

/**
 * 
 * @export
 * @interface AdoptionAttemptEventDto
 */
export interface AdoptionAttemptEventDto {
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptEventDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptEventDto
     */
    'type': AdoptionAttemptEventDtoType;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptEventDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptEventDto
     */
    'memberInChargeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptEventDto
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdoptionAttemptEventDto
     */
    'paymentMethod'?: string;
}

export const AdoptionAttemptEventDtoType = {
    HomeCheck: 'home-check',
    MakingContact: 'making-contact',
    MeetingWithAdopter: 'meeting-with-adopter',
    PaymentReception: 'payment-reception',
    PaymentCancelled: 'payment-cancelled',
    AdoptionDone: 'adoption-done',
    SterilizationDeposit: 'sterilization-deposit',
    SterilizationValidation: 'sterilization-validation'
} as const;

export type AdoptionAttemptEventDtoType = typeof AdoptionAttemptEventDtoType[keyof typeof AdoptionAttemptEventDtoType];

/**
 * 
 * @export
 * @interface AdoptionCountBySpeciesDto
 */
export interface AdoptionCountBySpeciesDto {
    /**
     * 
     * @type {string}
     * @memberof AdoptionCountBySpeciesDto
     */
    'speciesName': string;
    /**
     * 
     * @type {number}
     * @memberof AdoptionCountBySpeciesDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AdoptionStatusCountDto
 */
export interface AdoptionStatusCountDto {
    /**
     * 
     * @type {string}
     * @memberof AdoptionStatusCountDto
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof AdoptionStatusCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AdoptionStepCountDto
 */
export interface AdoptionStepCountDto {
    /**
     * 
     * @type {string}
     * @memberof AdoptionStepCountDto
     */
    'step': string;
    /**
     * 
     * @type {number}
     * @memberof AdoptionStepCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AnimalAdoptionDto
 */
export interface AnimalAdoptionDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'adopterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'adoptionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'futureAdoptionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'exitDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'vpaDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'certificateOfCommitmentSentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'adoptionContractSentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'newName'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnimalAdoptionDto
     */
    'priceInEuros'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'offerType'?: AnimalAdoptionDtoOfferType;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'goodbyeDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'status': AnimalAdoptionDtoStatus;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'step'?: AnimalAdoptionDtoStep;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'cannotBeAdoptedStatus'?: AnimalAdoptionDtoCannotBeAdoptedStatus;
    /**
     * 
     * @type {Array<AdministrativeFollowUpDto>}
     * @memberof AnimalAdoptionDto
     */
    'administrativeFollowUps'?: Array<AdministrativeFollowUpDto>;
    /**
     * 
     * @type {string}
     * @memberof AnimalAdoptionDto
     */
    'goodbyeDetails'?: string;
}

export const AnimalAdoptionDtoOfferType = {
    Donation: 'donation',
    SaleOffer: 'sale-offer',
    HealthCostCompensation: 'health-cost-compensation'
} as const;

export type AnimalAdoptionDtoOfferType = typeof AnimalAdoptionDtoOfferType[keyof typeof AnimalAdoptionDtoOfferType];
export const AnimalAdoptionDtoStatus = {
    Adoptable: 'adoptable',
    InTheProcessOfBeingAdoptable: 'in-the-process-of-being-adoptable',
    HasBeenAdopted: 'has-been-adopted',
    CannotBeAdopted: 'cannot-be-adopted',
    ToBeFilled: 'to-be-filled'
} as const;

export type AnimalAdoptionDtoStatus = typeof AnimalAdoptionDtoStatus[keyof typeof AnimalAdoptionDtoStatus];
export const AnimalAdoptionDtoStep = {
    AnimalReserved: 'animal-reserved',
    AdoptionToBeValidated: 'adoption-to-be-validated',
    AdoptionValidatedWithoutPostVisit: 'adoption-validated-without-post-visit',
    AdoptionValidatedAfterPostVisit: 'adoption-validated-after-post-visit',
    PostVisitNotValidated: 'post-visit-not-validated',
    AnimalReturned: 'animal-returned'
} as const;

export type AnimalAdoptionDtoStep = typeof AnimalAdoptionDtoStep[keyof typeof AnimalAdoptionDtoStep];
export const AnimalAdoptionDtoCannotBeAdoptedStatus = {
    Released: 'released',
    Dead: 'dead',
    LongTermWithHostedFamily: 'long-term-with-hosted-family',
    InCare: 'in-care',
    BeingSocialized: 'being-socialized',
    Lost: 'lost',
    TransferToAnotherAssociation: 'transfer-to-another-association',
    InGestation: 'in-gestation',
    InLactation: 'in-lactation'
} as const;

export type AnimalAdoptionDtoCannotBeAdoptedStatus = typeof AnimalAdoptionDtoCannotBeAdoptedStatus[keyof typeof AnimalAdoptionDtoCannotBeAdoptedStatus];

/**
 * 
 * @export
 * @interface AnimalBreedDto
 */
export interface AnimalBreedDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedDto
     */
    'breedTitle': AnimalBreedDtoBreedTitle;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedDto
     */
    'breed': string;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalBreedDto
     */
    'isPedigreeRegistered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedDto
     */
    'registrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedDto
     */
    'litterNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnimalBreedDto
     */
    'numberOfAnimalsInLitter'?: number;
    /**
     * 
     * @type {AnimalBreedFiliationDto}
     * @memberof AnimalBreedDto
     */
    'filiation'?: AnimalBreedFiliationDto;
}

export const AnimalBreedDtoBreedTitle = {
    Purebred: 'purebred',
    BreedType: 'breed-type',
    MixedBreed: 'mixed-breed'
} as const;

export type AnimalBreedDtoBreedTitle = typeof AnimalBreedDtoBreedTitle[keyof typeof AnimalBreedDtoBreedTitle];

/**
 * 
 * @export
 * @interface AnimalBreedFiliationDto
 */
export interface AnimalBreedFiliationDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'fatherName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'fatherBreed'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'fatherIdentification'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'fatherRegistrationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'motherName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'motherBreed'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'motherIdentification'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalBreedFiliationDto
     */
    'motherRegistrationNumber'?: string;
}
/**
 * 
 * @export
 * @interface AnimalCageDto
 */
export interface AnimalCageDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalCageDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalCageDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalCageDto
     */
    'species': AnimalCageDtoSpecies;
    /**
     * 
     * @type {string}
     * @memberof AnimalCageDto
     */
    'sex'?: AnimalCageDtoSex;
    /**
     * 
     * @type {string}
     * @memberof AnimalCageDto
     */
    'profileImageKey'?: string;
}

export const AnimalCageDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AnimalCageDtoSpecies = typeof AnimalCageDtoSpecies[keyof typeof AnimalCageDtoSpecies];
export const AnimalCageDtoSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type AnimalCageDtoSex = typeof AnimalCageDtoSex[keyof typeof AnimalCageDtoSex];

/**
 * 
 * @export
 * @interface AnimalDocumentDto
 */
export interface AnimalDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'type': AnimalDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalDocumentDto
     */
    'attributes': Array<AnimalDocumentDtoAttributes>;
}

export const AnimalDocumentDtoType = {
    IdentificationCertificate: 'identification-certificate',
    VeterinaryCertificate: 'veterinary-certificate',
    AdoptionContract: 'adoption-contract',
    CopyOfDeclarationOfBirth: 'copy-of-declaration-of-birth',
    TransferCertificate: 'transfer-certificate',
    TransferInvoice: 'transfer-invoice',
    HealthBooklet: 'health-booklet',
    CertificateOfGoodHealth: 'certificate-of-good-health',
    PrimovaccinationCertificate: 'primovaccination-certificate',
    TestResult: 'test-result',
    InformationDocument: 'information-document',
    VeterinaryOrderForm: 'veterinary-order-form',
    SignedAdoptionContract: 'signed-adoption-contract',
    SignedAdoptionCertificate: 'signed-adoption-certificate',
    NeuteringCertificate: 'neutering-certificate',
    SterilizationCertificate: 'sterilization-certificate',
    Others: 'others'
} as const;

export type AnimalDocumentDtoType = typeof AnimalDocumentDtoType[keyof typeof AnimalDocumentDtoType];
export const AnimalDocumentDtoAttributes = {
    Entry: 'required-for-the-register-entry',
    Exit: 'required-for-the-register-exit'
} as const;

export type AnimalDocumentDtoAttributes = typeof AnimalDocumentDtoAttributes[keyof typeof AnimalDocumentDtoAttributes];

/**
 * 
 * @export
 * @interface AnimalDto
 */
export interface AnimalDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'identificationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'lifeBeforeAssociation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'identificationNumberLocation'?: AnimalDtoIdentificationNumberLocation;
    /**
     * 
     * @type {TattooDto}
     * @memberof AnimalDto
     */
    'tattoo'?: TattooDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'sex'?: AnimalDtoSex;
    /**
     * 
     * @type {AnimalAdoptionDto}
     * @memberof AnimalDto
     */
    'adoption': AnimalAdoptionDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalDto
     */
    'characteristics'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalDto
     */
    'identifyingMarks'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'havePassport'?: AnimalDtoHavePassport;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'species': AnimalDtoSpecies;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'breed'?: string;
    /**
     * 
     * @type {AnimalBreedDto}
     * @memberof AnimalDto
     */
    'breedData'?: AnimalBreedDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'hair'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'hairLength'?: AnimalDtoHairLength;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'weightInKgs'?: string;
    /**
     * 
     * @type {BirthdayDto}
     * @memberof AnimalDto
     */
    'birthday'?: BirthdayDto;
    /**
     * 
     * @type {AnimalVaccinationDto}
     * @memberof AnimalDto
     */
    'vaccination': AnimalVaccinationDto;
    /**
     * 
     * @type {AnimalHealthDto}
     * @memberof AnimalDto
     */
    'health': AnimalHealthDto;
    /**
     * 
     * @type {AnimalResponsibilitiesDto}
     * @memberof AnimalDto
     */
    'responsibilities'?: AnimalResponsibilitiesDto;
    /**
     * 
     * @type {AnimalImagesDto}
     * @memberof AnimalDto
     */
    'images': AnimalImagesDto;
    /**
     * 
     * @type {HostedFamilyFollowUpsDto}
     * @memberof AnimalDto
     */
    'hostedFamilyFollowUps': HostedFamilyFollowUpsDto;
    /**
     * 
     * @type {AnimalTakeOverDto}
     * @memberof AnimalDto
     */
    'takeOver': AnimalTakeOverDto;
    /**
     * 
     * @type {AnimalFormAndActivityDto}
     * @memberof AnimalDto
     */
    'formAndActivity'?: AnimalFormAndActivityDto;
    /**
     * 
     * @type {Array<AnimalInsightsDto>}
     * @memberof AnimalDto
     */
    'insights': Array<AnimalInsightsDto>;
    /**
     * 
     * @type {Array<AnimalDocumentDto>}
     * @memberof AnimalDto
     */
    'documents': Array<AnimalDocumentDto>;
    /**
     * 
     * @type {AnnounceDto}
     * @memberof AnimalDto
     */
    'announce'?: AnnounceDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalDto
     */
    'gpsPoint'?: string;
}

export const AnimalDtoIdentificationNumberLocation = {
    LeftJugularGroove: 'left-jugular-groove',
    RightJugularGroove: 'right-jugular-groove'
} as const;

export type AnimalDtoIdentificationNumberLocation = typeof AnimalDtoIdentificationNumberLocation[keyof typeof AnimalDtoIdentificationNumberLocation];
export const AnimalDtoSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type AnimalDtoSex = typeof AnimalDtoSex[keyof typeof AnimalDtoSex];
export const AnimalDtoHavePassport = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalDtoHavePassport = typeof AnimalDtoHavePassport[keyof typeof AnimalDtoHavePassport];
export const AnimalDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AnimalDtoSpecies = typeof AnimalDtoSpecies[keyof typeof AnimalDtoSpecies];
export const AnimalDtoHairLength = {
    Short: 'short',
    Medium: 'medium',
    Long: 'long'
} as const;

export type AnimalDtoHairLength = typeof AnimalDtoHairLength[keyof typeof AnimalDtoHairLength];

/**
 * 
 * @export
 * @interface AnimalFormAndActivityDto
 */
export interface AnimalFormAndActivityDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalFormAndActivityDto
     */
    'size'?: AnimalFormAndActivityDtoSize;
    /**
     * 
     * @type {string}
     * @memberof AnimalFormAndActivityDto
     */
    'corpulence'?: AnimalFormAndActivityDtoCorpulence;
    /**
     * 
     * @type {string}
     * @memberof AnimalFormAndActivityDto
     */
    'activity'?: AnimalFormAndActivityDtoActivity;
    /**
     * 
     * @type {Array<AnimalWeightDto>}
     * @memberof AnimalFormAndActivityDto
     */
    'weight'?: Array<AnimalWeightDto>;
}

export const AnimalFormAndActivityDtoSize = {
    VerySmall: 'very-small',
    Small: 'small',
    Medium: 'medium',
    Large: 'large',
    VeryLarge: 'very-large'
} as const;

export type AnimalFormAndActivityDtoSize = typeof AnimalFormAndActivityDtoSize[keyof typeof AnimalFormAndActivityDtoSize];
export const AnimalFormAndActivityDtoCorpulence = {
    Underweight: 'underweight',
    ABitUnderweight: 'a-bit-underweight',
    Balanced: 'balanced',
    ABitOverweight: 'a-bit-overweight',
    Overweight: 'overweight'
} as const;

export type AnimalFormAndActivityDtoCorpulence = typeof AnimalFormAndActivityDtoCorpulence[keyof typeof AnimalFormAndActivityDtoCorpulence];
export const AnimalFormAndActivityDtoActivity = {
    VeryLow: 'very-low',
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;

export type AnimalFormAndActivityDtoActivity = typeof AnimalFormAndActivityDtoActivity[keyof typeof AnimalFormAndActivityDtoActivity];

/**
 * 
 * @export
 * @interface AnimalHealthDto
 */
export interface AnimalHealthDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'sterilizedOrNeutered'?: AnimalHealthDtoSterilizedOrNeutered;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'healthHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'hasEuropeanPassport'?: AnimalHealthDtoHasEuropeanPassport;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'alimentation'?: string;
    /**
     * Only for dog
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'dogCategory'?: AnimalHealthDtoDogCategory;
    /**
     * Only for cat
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'FeLV'?: AnimalHealthDtoFeLV;
    /**
     * Only for cat
     * @type {string}
     * @memberof AnimalHealthDto
     */
    'FIV'?: AnimalHealthDtoFIV;
    /**
     * 
     * @type {Array<AnimalHealthOperationDto>}
     * @memberof AnimalHealthDto
     */
    'operations': Array<AnimalHealthOperationDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalHealthDto
     */
    'pathologies'?: Array<AnimalHealthDtoPathologies>;
    /**
     * 
     * @type {number}
     * @memberof AnimalHealthDto
     */
    'dewormingFrequencyInMonths'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnimalHealthDto
     */
    'fleaControlFrequencyInMonths'?: number;
}

export const AnimalHealthDtoSterilizedOrNeutered = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalHealthDtoSterilizedOrNeutered = typeof AnimalHealthDtoSterilizedOrNeutered[keyof typeof AnimalHealthDtoSterilizedOrNeutered];
export const AnimalHealthDtoHasEuropeanPassport = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalHealthDtoHasEuropeanPassport = typeof AnimalHealthDtoHasEuropeanPassport[keyof typeof AnimalHealthDtoHasEuropeanPassport];
export const AnimalHealthDtoDogCategory = {
    One: 'one',
    Two: 'two',
    Three: 'three',
    Other: 'other'
} as const;

export type AnimalHealthDtoDogCategory = typeof AnimalHealthDtoDogCategory[keyof typeof AnimalHealthDtoDogCategory];
export const AnimalHealthDtoFeLV = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalHealthDtoFeLV = typeof AnimalHealthDtoFeLV[keyof typeof AnimalHealthDtoFeLV];
export const AnimalHealthDtoFIV = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalHealthDtoFIV = typeof AnimalHealthDtoFIV[keyof typeof AnimalHealthDtoFIV];
export const AnimalHealthDtoPathologies = {
    CanineDistemper: 'canine-distemper',
    RubarthHepatitis: 'rubarth-hepatitis',
    Leptospirosis: 'leptospirosis',
    Rabies: 'rabies',
    Parvovirus: 'parvovirus',
    KennelCough: 'kennel-cough',
    Herpesvirus: 'herpesvirus',
    Babesiosis: 'babesiosis',
    LymeDisease: 'lyme-disease',
    Diabetic: 'diabetic',
    VisuallyImpaired: 'visually-impaired',
    Deaf: 'deaf',
    Typhus: 'typhus',
    Chlamydiosis: 'chlamydiosis',
    Coryza: 'coryza',
    Fiv: 'FIV',
    FeLv: 'FeLV',
    Fip: 'fip',
    CatScratchDisease: 'cat-scratch-disease',
    Giardiasis: 'giardiasis',
    RenalFailure: 'renal-failure',
    Calicivirus: 'calicivirus',
    HeartInsufficiency: 'heart-insufficiency',
    HepaticInsufficiency: 'hepatic-insufficiency',
    Epilepsy: 'epilepsy',
    ChronicGingivitis: 'chronic-gingivitis',
    InjuryTrauma: 'injury-trauma',
    Cancer: 'cancer',
    Others: 'others'
} as const;

export type AnimalHealthDtoPathologies = typeof AnimalHealthDtoPathologies[keyof typeof AnimalHealthDtoPathologies];

/**
 * 
 * @export
 * @interface AnimalHealthOperationDto
 */
export interface AnimalHealthOperationDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'veterinary'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'memberInCharge'?: string;
    /**
     * Only for cat
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'felvResult'?: AnimalHealthOperationDtoFelvResult;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'fivResult'?: AnimalHealthOperationDtoFivResult;
    /**
     * Only for according types of operation
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'identificationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'tattooNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalHealthOperationDto
     */
    'type': Array<AnimalHealthOperationDtoType>;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthOperationDto
     */
    'price'?: string;
    /**
     * 
     * @type {Array<AnimalHealthTestResultDto>}
     * @memberof AnimalHealthOperationDto
     */
    'testResults'?: Array<AnimalHealthTestResultDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalHealthOperationDto
     */
    'orderGenerated'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalHealthOperationDto
     */
    'documentsIds'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalHealthOperationDto
     */
    'needsValidation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalHealthOperationDto
     */
    'validated'?: boolean;
}

export const AnimalHealthOperationDtoFelvResult = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalHealthOperationDtoFelvResult = typeof AnimalHealthOperationDtoFelvResult[keyof typeof AnimalHealthOperationDtoFelvResult];
export const AnimalHealthOperationDtoFivResult = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalHealthOperationDtoFivResult = typeof AnimalHealthOperationDtoFivResult[keyof typeof AnimalHealthOperationDtoFivResult];
export const AnimalHealthOperationDtoType = {
    SterilizedOrNeutered: 'sterilized-or-neutered',
    Identification: 'identification',
    Deworming: 'deworming',
    FleaControl: 'flea-control',
    Fiv: 'FIV',
    FeLv: 'FeLV',
    Tatoo: 'tatoo',
    Implant: 'implant',
    GoodHealthCertificate: 'good-health-certificate',
    EarMarking: 'ear-marking',
    FirstInjection: 'first-injection',
    SecondInjection: 'second-injection',
    ThirdInjection: 'third-injection',
    AnnualReminder: 'annual-reminder',
    Surgery: 'surgery',
    IdentificationChip: 'identification-chip',
    Others: 'others',
    CanineDistemper: 'canine-distemper',
    RubarthHepatitis: 'rubarth-hepatitis',
    Leptospirosis: 'leptospirosis',
    Rabies: 'rabies',
    Parvovirus: 'parvovirus',
    KennelCough: 'kennel-cough',
    Herpesvirus: 'herpesvirus',
    Babesiosis: 'babesiosis',
    LymeDisease: 'lyme-disease',
    Diabetic: 'diabetic',
    Typhus: 'typhus',
    Chlamydiosis: 'chlamydiosis',
    Coryza: 'coryza',
    Fip: 'fip',
    CatScratchDisease: 'cat-scratch-disease',
    BloodTest: 'blood-test',
    Hospitalization: 'hospitalization',
    Treatment: 'treatment',
    MedicalAppointment: 'medical-appointment',
    Giardiasis: 'giardiasis'
} as const;

export type AnimalHealthOperationDtoType = typeof AnimalHealthOperationDtoType[keyof typeof AnimalHealthOperationDtoType];

/**
 * 
 * @export
 * @interface AnimalHealthTestResultDto
 */
export interface AnimalHealthTestResultDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthTestResultDto
     */
    'type': AnimalHealthTestResultDtoType;
    /**
     * 
     * @type {string}
     * @memberof AnimalHealthTestResultDto
     */
    'result': AnimalHealthTestResultDtoResult;
}

export const AnimalHealthTestResultDtoType = {
    CanineDistemper: 'canine-distemper',
    RubarthHepatitis: 'rubarth-hepatitis',
    Leptospirosis: 'leptospirosis',
    Rabies: 'rabies',
    Parvovirus: 'parvovirus',
    KennelCough: 'kennel-cough',
    Herpesvirus: 'herpesvirus',
    Babesiosis: 'babesiosis',
    LymeDisease: 'lyme-disease',
    Diabetic: 'diabetic',
    Typhus: 'typhus',
    Chlamydiosis: 'chlamydiosis',
    Coryza: 'coryza',
    Fip: 'fip',
    CatScratchDisease: 'cat-scratch-disease',
    Fiv: 'FIV',
    FeLv: 'FeLV',
    Giardiasis: 'giardiasis'
} as const;

export type AnimalHealthTestResultDtoType = typeof AnimalHealthTestResultDtoType[keyof typeof AnimalHealthTestResultDtoType];
export const AnimalHealthTestResultDtoResult = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalHealthTestResultDtoResult = typeof AnimalHealthTestResultDtoResult[keyof typeof AnimalHealthTestResultDtoResult];

/**
 * 
 * @export
 * @interface AnimalImagesDto
 */
export interface AnimalImagesDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalImagesDto
     */
    'profileImageKey'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalImagesDto
     */
    'otherImagesKeys'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnimalInsightsDto
 */
export interface AnimalInsightsDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalInsightsDto
     */
    'type': AnimalInsightsDtoType;
    /**
     * 
     * @type {string}
     * @memberof AnimalInsightsDto
     */
    'message': string;
}

export const AnimalInsightsDtoType = {
    Info: 'info',
    Warning: 'warning',
    Error: 'error',
    Success: 'success'
} as const;

export type AnimalInsightsDtoType = typeof AnimalInsightsDtoType[keyof typeof AnimalInsightsDtoType];

/**
 * 
 * @export
 * @interface AnimalProfileDocumentDto
 */
export interface AnimalProfileDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDocumentDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDocumentDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDocumentDto
     */
    'uploadedAt': string;
}
/**
 * 
 * @export
 * @interface AnimalProfileDto
 */
export interface AnimalProfileDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'adopterId': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'species': AnimalProfileDtoSpecies;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'identificationNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalProfileDto
     */
    'characteristics'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalProfileDto
     */
    'identifyingMarks'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'havePassport'?: AnimalProfileDtoHavePassport;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'lifeBeforeAssociation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'description'?: string;
    /**
     * 
     * @type {FromCustomerAnimalDto}
     * @memberof AnimalProfileDto
     */
    'fromCustomerAnimal'?: FromCustomerAnimalDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'sex'?: AnimalProfileDtoSex;
    /**
     * 
     * @type {TattooDto}
     * @memberof AnimalProfileDto
     */
    'tattoo'?: TattooDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileDto
     */
    'hairLength'?: AnimalProfileDtoHairLength;
    /**
     * 
     * @type {AnimalProfileHealthDto}
     * @memberof AnimalProfileDto
     */
    'health': AnimalProfileHealthDto;
    /**
     * 
     * @type {AnimalFormAndActivityDto}
     * @memberof AnimalProfileDto
     */
    'formAndActivity'?: AnimalFormAndActivityDto;
    /**
     * 
     * @type {AnimalImagesDto}
     * @memberof AnimalProfileDto
     */
    'images': AnimalImagesDto;
    /**
     * 
     * @type {AnimalBreedDto}
     * @memberof AnimalProfileDto
     */
    'breed'?: AnimalBreedDto;
    /**
     * 
     * @type {Array<AnimalProfileDocumentDto>}
     * @memberof AnimalProfileDto
     */
    'documents': Array<AnimalProfileDocumentDto>;
    /**
     * 
     * @type {BirthdayDto}
     * @memberof AnimalProfileDto
     */
    'birthday'?: BirthdayDto;
}

export const AnimalProfileDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AnimalProfileDtoSpecies = typeof AnimalProfileDtoSpecies[keyof typeof AnimalProfileDtoSpecies];
export const AnimalProfileDtoHavePassport = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalProfileDtoHavePassport = typeof AnimalProfileDtoHavePassport[keyof typeof AnimalProfileDtoHavePassport];
export const AnimalProfileDtoSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type AnimalProfileDtoSex = typeof AnimalProfileDtoSex[keyof typeof AnimalProfileDtoSex];
export const AnimalProfileDtoHairLength = {
    Short: 'short',
    Medium: 'medium',
    Long: 'long'
} as const;

export type AnimalProfileDtoHairLength = typeof AnimalProfileDtoHairLength[keyof typeof AnimalProfileDtoHairLength];

/**
 * 
 * @export
 * @interface AnimalProfileHealthDto
 */
export interface AnimalProfileHealthDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthDto
     */
    'sterilizedOrNeutered'?: AnimalProfileHealthDtoSterilizedOrNeutered;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthDto
     */
    'healthHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthDto
     */
    'hasEuropeanPassport'?: AnimalProfileHealthDtoHasEuropeanPassport;
    /**
     * Only for dog
     * @type {string}
     * @memberof AnimalProfileHealthDto
     */
    'dogCategory'?: AnimalProfileHealthDtoDogCategory;
    /**
     * Only for cat
     * @type {string}
     * @memberof AnimalProfileHealthDto
     */
    'FeLV'?: AnimalProfileHealthDtoFeLV;
    /**
     * Only for cat
     * @type {string}
     * @memberof AnimalProfileHealthDto
     */
    'FIV'?: AnimalProfileHealthDtoFIV;
    /**
     * 
     * @type {Array<AnimalProfileHealthOperationDto>}
     * @memberof AnimalProfileHealthDto
     */
    'operations': Array<AnimalProfileHealthOperationDto>;
    /**
     * 
     * @type {AnimalProfileHealthScoreDto}
     * @memberof AnimalProfileHealthDto
     */
    'score'?: AnimalProfileHealthScoreDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalProfileHealthDto
     */
    'pathologies'?: Array<AnimalProfileHealthDtoPathologies>;
    /**
     * 
     * @type {Array<AnimalProfileHealthObservationsDto>}
     * @memberof AnimalProfileHealthDto
     */
    'observations'?: Array<AnimalProfileHealthObservationsDto>;
    /**
     * 
     * @type {AnimalProfileHealthPestControlDto}
     * @memberof AnimalProfileHealthDto
     */
    'deworming'?: AnimalProfileHealthPestControlDto;
    /**
     * 
     * @type {AnimalProfileHealthPestControlDto}
     * @memberof AnimalProfileHealthDto
     */
    'fleaControl'?: AnimalProfileHealthPestControlDto;
}

export const AnimalProfileHealthDtoSterilizedOrNeutered = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthDtoSterilizedOrNeutered = typeof AnimalProfileHealthDtoSterilizedOrNeutered[keyof typeof AnimalProfileHealthDtoSterilizedOrNeutered];
export const AnimalProfileHealthDtoHasEuropeanPassport = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthDtoHasEuropeanPassport = typeof AnimalProfileHealthDtoHasEuropeanPassport[keyof typeof AnimalProfileHealthDtoHasEuropeanPassport];
export const AnimalProfileHealthDtoDogCategory = {
    One: 'one',
    Two: 'two',
    Three: 'three',
    Other: 'other'
} as const;

export type AnimalProfileHealthDtoDogCategory = typeof AnimalProfileHealthDtoDogCategory[keyof typeof AnimalProfileHealthDtoDogCategory];
export const AnimalProfileHealthDtoFeLV = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthDtoFeLV = typeof AnimalProfileHealthDtoFeLV[keyof typeof AnimalProfileHealthDtoFeLV];
export const AnimalProfileHealthDtoFIV = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthDtoFIV = typeof AnimalProfileHealthDtoFIV[keyof typeof AnimalProfileHealthDtoFIV];
export const AnimalProfileHealthDtoPathologies = {
    CanineDistemper: 'canine-distemper',
    RubarthHepatitis: 'rubarth-hepatitis',
    Leptospirosis: 'leptospirosis',
    Rabies: 'rabies',
    Parvovirus: 'parvovirus',
    KennelCough: 'kennel-cough',
    Herpesvirus: 'herpesvirus',
    Babesiosis: 'babesiosis',
    LymeDisease: 'lyme-disease',
    Diabetic: 'diabetic',
    VisuallyImpaired: 'visually-impaired',
    Deaf: 'deaf',
    Typhus: 'typhus',
    Chlamydiosis: 'chlamydiosis',
    Coryza: 'coryza',
    Fiv: 'FIV',
    FeLv: 'FeLV',
    Fip: 'fip',
    CatScratchDisease: 'cat-scratch-disease',
    Giardiasis: 'giardiasis',
    RenalFailure: 'renal-failure',
    Calicivirus: 'calicivirus',
    HeartInsufficiency: 'heart-insufficiency',
    HepaticInsufficiency: 'hepatic-insufficiency',
    Epilepsy: 'epilepsy',
    ChronicGingivitis: 'chronic-gingivitis',
    InjuryTrauma: 'injury-trauma',
    Cancer: 'cancer',
    Others: 'others'
} as const;

export type AnimalProfileHealthDtoPathologies = typeof AnimalProfileHealthDtoPathologies[keyof typeof AnimalProfileHealthDtoPathologies];

/**
 * 
 * @export
 * @interface AnimalProfileHealthObservationsDto
 */
export interface AnimalProfileHealthObservationsDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'type': Array<AnimalProfileHealthObservationsDtoType>;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {AnimalProfileHealthVeterinaryDto}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'veterinary'?: AnimalProfileHealthVeterinaryDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'documentKey'?: string;
    /**
     * 
     * @type {Array<ObservationCommentDto>}
     * @memberof AnimalProfileHealthObservationsDto
     */
    'comments'?: Array<ObservationCommentDto>;
}

export const AnimalProfileHealthObservationsDtoType = {
    DiarrheaAndStoolIssues: 'diarrhea-and-stool-issues',
    Vomiting: 'vomiting',
    MeowingInLitter: 'meowing-in-litter',
    AppetiteChange: 'appetite-change',
    ExcessiveWeightGain: 'excessive-weight-gain',
    IncreasedWaterConsumption: 'increased-water-consumption',
    SkinCrusts: 'skin-crusts',
    RedAndSwollenGums: 'red-and-swollen-gums',
    Panting: 'panting',
    Sneezing: 'sneezing',
    NoseBleeding: 'nose-bleeding',
    ExcessiveEyeBlinking: 'excessive-eye-blinking',
    MobilityIssues: 'mobility-issues',
    InappropriateScratching: 'inappropriate-scratching',
    NocturnalMeowingOrAgitation: 'nocturnal-meowing-or-agitation',
    InappropriateElimination: 'inappropriate-elimination',
    FearfulBehavior: 'fearful-behavior',
    ExcessiveObjectChewing: 'excessive-object-chewing',
    ExcessiveScratching: 'excessive-scratching',
    UnusualFrequentUrination: 'unusual-frequent-urination',
    AppetiteLoss: 'appetite-loss',
    ExcessiveLicking: 'excessive-licking',
    UnexplainedAggressiveness: 'unexplained-aggressiveness',
    SuddenWeightChange: 'sudden-weight-change',
    VomitingDog: 'vomiting-dog',
    Diarrhea: 'diarrhea',
    EscapeAttempts: 'escape-attempts',
    SleepHabitsChange: 'sleep-habits-change',
    ConstantBarking: 'constant-barking',
    NoBarking: 'no-barking',
    ConstantRestlessness: 'constant-restlessness',
    Lethargy: 'lethargy',
    RefusalToEat: 'refusal-to-eat',
    HydrationRefusal: 'hydration-refusal',
    BadBreath: 'bad-breath',
    IncreasedTerritorialMarking: 'increased-territorial-marking',
    Itching: 'itching',
    SelfMutilation: 'self-mutilation',
    WalkingDifficulty: 'walking-difficulty',
    PawPadsIssues: 'paw-pads-issues',
    SkinProblems: 'skin-problems',
    RedEyes: 'red-eyes',
    Drooling: 'drooling',
    SkinIrritation: 'skin-irritation'
} as const;

export type AnimalProfileHealthObservationsDtoType = typeof AnimalProfileHealthObservationsDtoType[keyof typeof AnimalProfileHealthObservationsDtoType];

/**
 * 
 * @export
 * @interface AnimalProfileHealthOperationDto
 */
export interface AnimalProfileHealthOperationDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'memberInCharge'?: string;
    /**
     * Only for cat
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'felvResult'?: AnimalProfileHealthOperationDtoFelvResult;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'fivResult'?: AnimalProfileHealthOperationDtoFivResult;
    /**
     * Only for according types of operation
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'identificationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'tattooNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalProfileHealthOperationDto
     */
    'type': Array<AnimalProfileHealthOperationDtoType>;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthOperationDto
     */
    'price'?: string;
    /**
     * 
     * @type {AnimalProfileHealthVeterinaryDto}
     * @memberof AnimalProfileHealthOperationDto
     */
    'veterinary'?: AnimalProfileHealthVeterinaryDto;
    /**
     * 
     * @type {Array<AnimalProfileHealthTestResultDto>}
     * @memberof AnimalProfileHealthOperationDto
     */
    'testResults'?: Array<AnimalProfileHealthTestResultDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalProfileHealthOperationDto
     */
    'orderGenerated'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalProfileHealthOperationDto
     */
    'documentsIds'?: Array<string>;
    /**
     * 
     * @type {Array<ObservationCommentDto>}
     * @memberof AnimalProfileHealthOperationDto
     */
    'comments'?: Array<ObservationCommentDto>;
}

export const AnimalProfileHealthOperationDtoFelvResult = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthOperationDtoFelvResult = typeof AnimalProfileHealthOperationDtoFelvResult[keyof typeof AnimalProfileHealthOperationDtoFelvResult];
export const AnimalProfileHealthOperationDtoFivResult = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthOperationDtoFivResult = typeof AnimalProfileHealthOperationDtoFivResult[keyof typeof AnimalProfileHealthOperationDtoFivResult];
export const AnimalProfileHealthOperationDtoType = {
    SterilizedOrNeutered: 'sterilized-or-neutered',
    Identification: 'identification',
    Deworming: 'deworming',
    FleaControl: 'flea-control',
    Fiv: 'FIV',
    FeLv: 'FeLV',
    Tatoo: 'tatoo',
    Implant: 'implant',
    GoodHealthCertificate: 'good-health-certificate',
    EarMarking: 'ear-marking',
    FirstInjection: 'first-injection',
    SecondInjection: 'second-injection',
    ThirdInjection: 'third-injection',
    AnnualReminder: 'annual-reminder',
    Surgery: 'surgery',
    IdentificationChip: 'identification-chip',
    Others: 'others',
    CanineDistemper: 'canine-distemper',
    RubarthHepatitis: 'rubarth-hepatitis',
    Leptospirosis: 'leptospirosis',
    Rabies: 'rabies',
    Parvovirus: 'parvovirus',
    KennelCough: 'kennel-cough',
    Herpesvirus: 'herpesvirus',
    Babesiosis: 'babesiosis',
    LymeDisease: 'lyme-disease',
    Diabetic: 'diabetic',
    Typhus: 'typhus',
    Chlamydiosis: 'chlamydiosis',
    Coryza: 'coryza',
    Fip: 'fip',
    CatScratchDisease: 'cat-scratch-disease',
    BloodTest: 'blood-test',
    Hospitalization: 'hospitalization',
    Treatment: 'treatment',
    MedicalAppointment: 'medical-appointment',
    Giardiasis: 'giardiasis'
} as const;

export type AnimalProfileHealthOperationDtoType = typeof AnimalProfileHealthOperationDtoType[keyof typeof AnimalProfileHealthOperationDtoType];

/**
 * 
 * @export
 * @interface AnimalProfileHealthPestControlDto
 */
export interface AnimalProfileHealthPestControlDto {
    /**
     * Array of dates in string format
     * @type {Array<string>}
     * @memberof AnimalProfileHealthPestControlDto
     */
    'dates': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AnimalProfileHealthPestControlDto
     */
    'frequency': number;
}
/**
 * 
 * @export
 * @interface AnimalProfileHealthScoreDto
 */
export interface AnimalProfileHealthScoreDto {
    /**
     * 
     * @type {number}
     * @memberof AnimalProfileHealthScoreDto
     */
    'healthScore': number;
    /**
     * 
     * @type {Array<EnvironmentQuestionsDto>}
     * @memberof AnimalProfileHealthScoreDto
     */
    'environmentQuestions'?: Array<EnvironmentQuestionsDto>;
    /**
     * 
     * @type {Array<BehaviorQuestionsDto>}
     * @memberof AnimalProfileHealthScoreDto
     */
    'behaviorQuestions'?: Array<BehaviorQuestionsDto>;
    /**
     * 
     * @type {Array<FeedingQuestionsDto>}
     * @memberof AnimalProfileHealthScoreDto
     */
    'feedingQuestions'?: Array<FeedingQuestionsDto>;
}
/**
 * 
 * @export
 * @interface AnimalProfileHealthTestResultDto
 */
export interface AnimalProfileHealthTestResultDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthTestResultDto
     */
    'type': AnimalProfileHealthTestResultDtoType;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthTestResultDto
     */
    'result': AnimalProfileHealthTestResultDtoResult;
}

export const AnimalProfileHealthTestResultDtoType = {
    CanineDistemper: 'canine-distemper',
    RubarthHepatitis: 'rubarth-hepatitis',
    Leptospirosis: 'leptospirosis',
    Rabies: 'rabies',
    Parvovirus: 'parvovirus',
    KennelCough: 'kennel-cough',
    Herpesvirus: 'herpesvirus',
    Babesiosis: 'babesiosis',
    LymeDisease: 'lyme-disease',
    Diabetic: 'diabetic',
    Typhus: 'typhus',
    Chlamydiosis: 'chlamydiosis',
    Coryza: 'coryza',
    Fip: 'fip',
    CatScratchDisease: 'cat-scratch-disease',
    Fiv: 'FIV',
    FeLv: 'FeLV',
    Giardiasis: 'giardiasis'
} as const;

export type AnimalProfileHealthTestResultDtoType = typeof AnimalProfileHealthTestResultDtoType[keyof typeof AnimalProfileHealthTestResultDtoType];
export const AnimalProfileHealthTestResultDtoResult = {
    Positive: 'positive',
    Negative: 'negative',
    Unknown: 'unknown'
} as const;

export type AnimalProfileHealthTestResultDtoResult = typeof AnimalProfileHealthTestResultDtoResult[keyof typeof AnimalProfileHealthTestResultDtoResult];

/**
 * 
 * @export
 * @interface AnimalProfileHealthVeterinaryDto
 */
export interface AnimalProfileHealthVeterinaryDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthVeterinaryDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalProfileHealthVeterinaryDto
     */
    'locationId': string;
}
/**
 * 
 * @export
 * @interface AnimalReducedDto
 */
export interface AnimalReducedDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalReducedDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalReducedDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalReducedDto
     */
    'species': AnimalReducedDtoSpecies;
    /**
     * 
     * @type {AnimalBreedDto}
     * @memberof AnimalReducedDto
     */
    'breed': AnimalBreedDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalReducedDto
     */
    'sex': AnimalReducedDtoSex;
    /**
     * 
     * @type {BirthdayDto}
     * @memberof AnimalReducedDto
     */
    'birthday'?: BirthdayDto;
    /**
     * 
     * @type {AnimalImagesDto}
     * @memberof AnimalReducedDto
     */
    'images': AnimalImagesDto;
}

export const AnimalReducedDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AnimalReducedDtoSpecies = typeof AnimalReducedDtoSpecies[keyof typeof AnimalReducedDtoSpecies];
export const AnimalReducedDtoSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type AnimalReducedDtoSex = typeof AnimalReducedDtoSex[keyof typeof AnimalReducedDtoSex];

/**
 * 
 * @export
 * @interface AnimalResponsibilitiesDto
 */
export interface AnimalResponsibilitiesDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesDto
     */
    'hostFamilyInChargeAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesDto
     */
    'memberInChargeAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesDto
     */
    'veterinaryChargeAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesDto
     */
    'cageNumber'?: string;
}
/**
 * 
 * @export
 * @interface AnimalResponsibilitiesPopulatedDto
 */
export interface AnimalResponsibilitiesPopulatedDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesPopulatedDto
     */
    'hostFamilyInChargeAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesPopulatedDto
     */
    'memberInChargeAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalResponsibilitiesPopulatedDto
     */
    'cageNumber'?: string;
    /**
     * 
     * @type {AnimalSearchItemAccountDto}
     * @memberof AnimalResponsibilitiesPopulatedDto
     */
    'hostFamilyInChargeAccount'?: AnimalSearchItemAccountDto;
    /**
     * 
     * @type {AnimalSearchItemAccountDto}
     * @memberof AnimalResponsibilitiesPopulatedDto
     */
    'memberInChargeAccount'?: AnimalSearchItemAccountDto;
}
/**
 * 
 * @export
 * @interface AnimalSearchItemAccountDto
 */
export interface AnimalSearchItemAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemAccountDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemAccountDto
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface AnimalSearchItemDto
 */
export interface AnimalSearchItemDto {
    /**
     * 
     * @type {number}
     * @memberof AnimalSearchItemDto
     */
    'identificationNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'takeOverAt': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'birthdate': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'adoptionStatus': AnimalSearchItemDtoAdoptionStatus;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'cannotBeAdoptedStatus'?: AnimalSearchItemDtoCannotBeAdoptedStatus;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'adoptionStep'?: AnimalSearchItemDtoAdoptionStep;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'sterilizedOrNeutered': AnimalSearchItemDtoSterilizedOrNeutered;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'name': string;
    /**
     * 
     * @type {AnimalImagesDto}
     * @memberof AnimalSearchItemDto
     */
    'images': AnimalImagesDto;
    /**
     * 
     * @type {TattooDto}
     * @memberof AnimalSearchItemDto
     */
    'tattoo'?: TattooDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'sex'?: AnimalSearchItemDtoSex;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'newName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalSearchItemDto
     */
    'species': AnimalSearchItemDtoSpecies;
    /**
     * 
     * @type {AnimalResponsibilitiesPopulatedDto}
     * @memberof AnimalSearchItemDto
     */
    'responsibilities'?: AnimalResponsibilitiesPopulatedDto;
    /**
     * 
     * @type {InsightsCountersDto}
     * @memberof AnimalSearchItemDto
     */
    'insightsCounters': InsightsCountersDto;
    /**
     * 
     * @type {Array<AnimalInsightsDto>}
     * @memberof AnimalSearchItemDto
     */
    'insights': Array<AnimalInsightsDto>;
}

export const AnimalSearchItemDtoAdoptionStatus = {
    Adoptable: 'adoptable',
    InTheProcessOfBeingAdoptable: 'in-the-process-of-being-adoptable',
    HasBeenAdopted: 'has-been-adopted',
    CannotBeAdopted: 'cannot-be-adopted',
    ToBeFilled: 'to-be-filled'
} as const;

export type AnimalSearchItemDtoAdoptionStatus = typeof AnimalSearchItemDtoAdoptionStatus[keyof typeof AnimalSearchItemDtoAdoptionStatus];
export const AnimalSearchItemDtoCannotBeAdoptedStatus = {
    Released: 'released',
    Dead: 'dead',
    LongTermWithHostedFamily: 'long-term-with-hosted-family',
    InCare: 'in-care',
    BeingSocialized: 'being-socialized',
    Lost: 'lost',
    TransferToAnotherAssociation: 'transfer-to-another-association',
    InGestation: 'in-gestation',
    InLactation: 'in-lactation'
} as const;

export type AnimalSearchItemDtoCannotBeAdoptedStatus = typeof AnimalSearchItemDtoCannotBeAdoptedStatus[keyof typeof AnimalSearchItemDtoCannotBeAdoptedStatus];
export const AnimalSearchItemDtoAdoptionStep = {
    AnimalReserved: 'animal-reserved',
    AdoptionToBeValidated: 'adoption-to-be-validated',
    AdoptionValidatedWithoutPostVisit: 'adoption-validated-without-post-visit',
    AdoptionValidatedAfterPostVisit: 'adoption-validated-after-post-visit',
    PostVisitNotValidated: 'post-visit-not-validated',
    AnimalReturned: 'animal-returned'
} as const;

export type AnimalSearchItemDtoAdoptionStep = typeof AnimalSearchItemDtoAdoptionStep[keyof typeof AnimalSearchItemDtoAdoptionStep];
export const AnimalSearchItemDtoSterilizedOrNeutered = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type AnimalSearchItemDtoSterilizedOrNeutered = typeof AnimalSearchItemDtoSterilizedOrNeutered[keyof typeof AnimalSearchItemDtoSterilizedOrNeutered];
export const AnimalSearchItemDtoSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type AnimalSearchItemDtoSex = typeof AnimalSearchItemDtoSex[keyof typeof AnimalSearchItemDtoSex];
export const AnimalSearchItemDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type AnimalSearchItemDtoSpecies = typeof AnimalSearchItemDtoSpecies[keyof typeof AnimalSearchItemDtoSpecies];

/**
 * 
 * @export
 * @interface AnimalTakeOverContactDto
 */
export interface AnimalTakeOverContactDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverContactDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverContactDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverContactDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverContactDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverContactDto
     */
    'city'?: string;
}
/**
 * 
 * @export
 * @interface AnimalTakeOverDto
 */
export interface AnimalTakeOverDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverDto
     */
    'takeOverAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverDto
     */
    'reason'?: AnimalTakeOverDtoReason;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverDto
     */
    'origin'?: AnimalTakeOverDtoOrigin;
    /**
     * 
     * @type {AnimalTakeOverContactDto}
     * @memberof AnimalTakeOverDto
     */
    'takeOverBy': AnimalTakeOverContactDto;
    /**
     * 
     * @type {AnimalTakeOverContactDto}
     * @memberof AnimalTakeOverDto
     */
    'takeOverFrom'?: AnimalTakeOverContactDto;
    /**
     * 
     * @type {boolean}
     * @memberof AnimalTakeOverDto
     */
    'takeOverContactIsAlsoOwner': boolean;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverDto
     */
    'cityOfOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalTakeOverDto
     */
    'addressOfOrigin'?: string;
}

export const AnimalTakeOverDtoReason = {
    DeathOfTheOwner: 'death-of-the-owner',
    AbandonmentVeterinaryClinic: 'abandonment-veterinary-clinic',
    AbandonmentPound: 'abandonment-pound',
    AbandonmentTownHall: 'abandonment-town-hall',
    AbandonmentAssociation: 'abandonment-association',
    AbandonmentIndividual: 'abandonment-individual',
    AnimalSeizure: 'animal-seizure',
    Trapping: 'trapping',
    Others: 'others',
    Birth: 'birth'
} as const;

export type AnimalTakeOverDtoReason = typeof AnimalTakeOverDtoReason[keyof typeof AnimalTakeOverDtoReason];
export const AnimalTakeOverDtoOrigin = {
    BirthInCaptivity: 'birth-in-captivity',
    CaptureInTheWild: 'capture-in-the-wild',
    Unknown: 'unknown'
} as const;

export type AnimalTakeOverDtoOrigin = typeof AnimalTakeOverDtoOrigin[keyof typeof AnimalTakeOverDtoOrigin];

/**
 * 
 * @export
 * @interface AnimalVaccinationDto
 */
export interface AnimalVaccinationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AnimalVaccinationDto
     */
    'vaccines'?: Array<AnimalVaccinationDtoVaccines>;
    /**
     * 
     * @type {string}
     * @memberof AnimalVaccinationDto
     */
    'vaccinationStatus'?: AnimalVaccinationDtoVaccinationStatus;
    /**
     * 
     * @type {InjectionDto}
     * @memberof AnimalVaccinationDto
     */
    'firstInjection'?: InjectionDto;
    /**
     * 
     * @type {InjectionDto}
     * @memberof AnimalVaccinationDto
     */
    'secondInjection'?: InjectionDto;
    /**
     * 
     * @type {InjectionDto}
     * @memberof AnimalVaccinationDto
     */
    'thirdInjection'?: InjectionDto;
    /**
     * 
     * @type {string}
     * @memberof AnimalVaccinationDto
     */
    'vaccinationType'?: string;
    /**
     * 
     * @type {Array<VaccinationOperationDto>}
     * @memberof AnimalVaccinationDto
     */
    'vaccinations'?: Array<VaccinationOperationDto>;
    /**
     * 
     * @type {number}
     * @memberof AnimalVaccinationDto
     */
    'annualReminderFrequencyInYear'?: number;
}

export const AnimalVaccinationDtoVaccines = {
    Chppi: 'CHPPi',
    Leptospirose: 'leptospirose',
    Rage: 'rage',
    Tc: 'TC',
    Leucose: 'leucose',
    Clamoxydose: 'clamoxydose',
    Herpesvirose: 'herpesvirose',
    KennelCough: 'kennel-cough'
} as const;

export type AnimalVaccinationDtoVaccines = typeof AnimalVaccinationDtoVaccines[keyof typeof AnimalVaccinationDtoVaccines];
export const AnimalVaccinationDtoVaccinationStatus = {
    InProgress: 'in-progress',
    Unknown: 'unknown',
    Completed: 'completed',
    ToDo: 'to-do',
    NotDoing: 'not-doing'
} as const;

export type AnimalVaccinationDtoVaccinationStatus = typeof AnimalVaccinationDtoVaccinationStatus[keyof typeof AnimalVaccinationDtoVaccinationStatus];

/**
 * 
 * @export
 * @interface AnimalWeightDto
 */
export interface AnimalWeightDto {
    /**
     * 
     * @type {string}
     * @memberof AnimalWeightDto
     */
    'dateOfWeightIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnimalWeightDto
     */
    'weightInKgs'?: string;
}
/**
 * 
 * @export
 * @interface AnimalsEnteredByDateDto
 */
export interface AnimalsEnteredByDateDto {
    /**
     * Date when animals were registered, in DD/MM/YYYY format.
     * @type {string}
     * @memberof AnimalsEnteredByDateDto
     */
    'name': string;
    /**
     * Number of animals registered on this date.
     * @type {number}
     * @memberof AnimalsEnteredByDateDto
     */
    'animaux': number;
}
/**
 * 
 * @export
 * @interface AnnounceDto
 */
export interface AnnounceDto {
    /**
     * 
     * @type {string}
     * @memberof AnnounceDto
     */
    'autoGeneratedAnnounce': string;
    /**
     * 
     * @type {string}
     * @memberof AnnounceDto
     */
    'autoGeneratedAt': string;
}
/**
 * 
 * @export
 * @interface BeRealDto
 */
export interface BeRealDto {
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    'adopterId': string;
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    'animalProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    'imageKey': string;
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    'likedBy': string;
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BeRealDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof BeRealDto
     */
    'tags': Array<object>;
}
/**
 * 
 * @export
 * @interface BehaviorQuestionsDto
 */
export interface BehaviorQuestionsDto {
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'inappropriateElimination': BehaviorQuestionsDtoInappropriateElimination;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'nocturnalVocalization': BehaviorQuestionsDtoNocturnalVocalization;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'fearfulBehavior': BehaviorQuestionsDtoFearfulBehavior;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'humanInteraction': BehaviorQuestionsDtoHumanInteraction;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'catSocialization': BehaviorQuestionsDtoCatSocialization;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'otherAnimalsSocialization': BehaviorQuestionsDtoOtherAnimalsSocialization;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'playBehavior': BehaviorQuestionsDtoPlayBehavior;
    /**
     * 
     * @type {string}
     * @memberof BehaviorQuestionsDto
     */
    'inappropriateScratching': BehaviorQuestionsDtoInappropriateScratching;
}

export const BehaviorQuestionsDtoInappropriateElimination = {
    Always: 'always',
    Often: 'often',
    Sometimes: 'sometimes',
    Rarely: 'rarely',
    Never: 'never'
} as const;

export type BehaviorQuestionsDtoInappropriateElimination = typeof BehaviorQuestionsDtoInappropriateElimination[keyof typeof BehaviorQuestionsDtoInappropriateElimination];
export const BehaviorQuestionsDtoNocturnalVocalization = {
    Always: 'always',
    Often: 'often',
    Sometimes: 'sometimes',
    Rarely: 'rarely',
    Never: 'never'
} as const;

export type BehaviorQuestionsDtoNocturnalVocalization = typeof BehaviorQuestionsDtoNocturnalVocalization[keyof typeof BehaviorQuestionsDtoNocturnalVocalization];
export const BehaviorQuestionsDtoFearfulBehavior = {
    Always: 'always',
    Often: 'often',
    Sometimes: 'sometimes',
    Rarely: 'rarely',
    Never: 'never'
} as const;

export type BehaviorQuestionsDtoFearfulBehavior = typeof BehaviorQuestionsDtoFearfulBehavior[keyof typeof BehaviorQuestionsDtoFearfulBehavior];
export const BehaviorQuestionsDtoHumanInteraction = {
    Aggressive: 'aggressive',
    Curious: 'curious',
    Shy: 'shy',
    Emotional: 'emotional',
    Indifferent: 'indifferent'
} as const;

export type BehaviorQuestionsDtoHumanInteraction = typeof BehaviorQuestionsDtoHumanInteraction[keyof typeof BehaviorQuestionsDtoHumanInteraction];
export const BehaviorQuestionsDtoCatSocialization = {
    Aggressive: 'aggressive',
    Curious: 'curious',
    Shy: 'shy',
    Emotional: 'emotional',
    Indifferent: 'indifferent'
} as const;

export type BehaviorQuestionsDtoCatSocialization = typeof BehaviorQuestionsDtoCatSocialization[keyof typeof BehaviorQuestionsDtoCatSocialization];
export const BehaviorQuestionsDtoOtherAnimalsSocialization = {
    Aggressive: 'aggressive',
    Curious: 'curious',
    Shy: 'shy',
    Emotional: 'emotional',
    Indifferent: 'indifferent'
} as const;

export type BehaviorQuestionsDtoOtherAnimalsSocialization = typeof BehaviorQuestionsDtoOtherAnimalsSocialization[keyof typeof BehaviorQuestionsDtoOtherAnimalsSocialization];
export const BehaviorQuestionsDtoPlayBehavior = {
    Always: 'always',
    Often: 'often',
    Sometimes: 'sometimes',
    Rarely: 'rarely',
    Never: 'never'
} as const;

export type BehaviorQuestionsDtoPlayBehavior = typeof BehaviorQuestionsDtoPlayBehavior[keyof typeof BehaviorQuestionsDtoPlayBehavior];
export const BehaviorQuestionsDtoInappropriateScratching = {
    Always: 'always',
    Often: 'often',
    Sometimes: 'sometimes',
    Rarely: 'rarely',
    Never: 'never'
} as const;

export type BehaviorQuestionsDtoInappropriateScratching = typeof BehaviorQuestionsDtoInappropriateScratching[keyof typeof BehaviorQuestionsDtoInappropriateScratching];

/**
 * 
 * @export
 * @interface BirthdayDto
 */
export interface BirthdayDto {
    /**
     * 
     * @type {string}
     * @memberof BirthdayDto
     */
    'date': string;
    /**
     * 
     * @type {boolean}
     * @memberof BirthdayDto
     */
    'isApproximate'?: boolean;
}
/**
 * 
 * @export
 * @interface CannotBeAdoptedStatusCountDto
 */
export interface CannotBeAdoptedStatusCountDto {
    /**
     * 
     * @type {string}
     * @memberof CannotBeAdoptedStatusCountDto
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof CannotBeAdoptedStatusCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ChangeOfHostedFamilyHostedFamilyFollowUpDto
 */
export interface ChangeOfHostedFamilyHostedFamilyFollowUpDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'type': ChangeOfHostedFamilyHostedFamilyFollowUpDtoType;
    /**
     * 
     * @type {string}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'beforeAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'afterAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'description'?: string;
    /**
     * 
     * @type {MetaForFollowUpDto}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'created': MetaForFollowUpDto;
    /**
     * 
     * @type {MetaForFollowUpDto}
     * @memberof ChangeOfHostedFamilyHostedFamilyFollowUpDto
     */
    'modified'?: MetaForFollowUpDto;
}

export const ChangeOfHostedFamilyHostedFamilyFollowUpDtoType = {
    ChangeOfHostedFamily: 'change-of-hosted-family'
} as const;

export type ChangeOfHostedFamilyHostedFamilyFollowUpDtoType = typeof ChangeOfHostedFamilyHostedFamilyFollowUpDtoType[keyof typeof ChangeOfHostedFamilyHostedFamilyFollowUpDtoType];

/**
 * 
 * @export
 * @interface ChatMessageDto
 */
export interface ChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'senderId': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageDto
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChatMessageDto
     */
    'readBy': Array<string>;
}
/**
 * 
 * @export
 * @interface CityOfOriginCountDto
 */
export interface CityOfOriginCountDto {
    /**
     * 
     * @type {string}
     * @memberof CityOfOriginCountDto
     */
    'cityName': string;
    /**
     * 
     * @type {number}
     * @memberof CityOfOriginCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ContactHostedFamilyFollowUpDto
 */
export interface ContactHostedFamilyFollowUpDto {
    /**
     * 
     * @type {string}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'type': ContactHostedFamilyFollowUpDtoType;
    /**
     * 
     * @type {string}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'hostFamilyAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'interlocutorAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'description'?: string;
    /**
     * 
     * @type {MetaForFollowUpDto}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'created': MetaForFollowUpDto;
    /**
     * 
     * @type {MetaForFollowUpDto}
     * @memberof ContactHostedFamilyFollowUpDto
     */
    'modified'?: MetaForFollowUpDto;
}

export const ContactHostedFamilyFollowUpDtoType = {
    Physical: 'physical',
    Phone: 'phone'
} as const;

export type ContactHostedFamilyFollowUpDtoType = typeof ContactHostedFamilyFollowUpDtoType[keyof typeof ContactHostedFamilyFollowUpDtoType];

/**
 * 
 * @export
 * @interface ConversationDto
 */
export interface ConversationDto {
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    'type': ConversationDtoType;
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConversationDto
     */
    'participants': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConversationDto
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConversationDto
     */
    'participantNames'?: Array<string>;
    /**
     * 
     * @type {ChatMessageDto}
     * @memberof ConversationDto
     */
    'lastMessage'?: ChatMessageDto;
    /**
     * 
     * @type {number}
     * @memberof ConversationDto
     */
    'unreadCount'?: number;
}

export const ConversationDtoType = {
    OneOnOne: 'one-on-one',
    Group: 'group'
} as const;

export type ConversationDtoType = typeof ConversationDtoType[keyof typeof ConversationDtoType];

/**
 * 
 * @export
 * @interface CoordinatesDto
 */
export interface CoordinatesDto {
    /**
     * 
     * @type {string}
     * @memberof CoordinatesDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordinatesDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordinatesDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoordinatesDto
     */
    'country'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoordinatesDto
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof CoordinatesDto
     */
    'lng': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CoordinatesDto
     */
    'geoPoint': Array<number>;
}
/**
 * 
 * @export
 * @interface CreateAccountBodyDto
 */
export interface CreateAccountBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'password': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAccountBodyDto
     */
    'roles': Array<CreateAccountBodyDtoRoles>;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'permission': CreateAccountBodyDtoPermission;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountBodyDto
     */
    'country'?: string;
}

export const CreateAccountBodyDtoRoles = {
    Member: 'member',
    HostFamily: 'host-family',
    Veterinary: 'veterinary'
} as const;

export type CreateAccountBodyDtoRoles = typeof CreateAccountBodyDtoRoles[keyof typeof CreateAccountBodyDtoRoles];
export const CreateAccountBodyDtoPermission = {
    Administrator: 'administrator',
    Editor: 'editor',
    Read: 'read',
    Silent: 'silent'
} as const;

export type CreateAccountBodyDtoPermission = typeof CreateAccountBodyDtoPermission[keyof typeof CreateAccountBodyDtoPermission];

/**
 * 
 * @export
 * @interface CreateAdopterBodyDto
 */
export interface CreateAdopterBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdopterBodyDto
     */
    'sendForm': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdopterBodyDto
     */
    'country'?: string;
}
/**
 * 
 * @export
 * @interface CreateAnimalBodyDto
 */
export interface CreateAnimalBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'identificationNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'takingChargeByTheCustomerAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'lifeBeforeAssociation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'sex'?: CreateAnimalBodyDtoSex;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'adoptionStatus': CreateAnimalBodyDtoAdoptionStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'species': CreateAnimalBodyDtoSpecies;
    /**
     * 
     * @type {AnimalBreedDto}
     * @memberof CreateAnimalBodyDto
     */
    'breedData'?: AnimalBreedDto;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'hair'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'weightInKgs'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'cityOfOrigin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'vaccinationStatus': CreateAnimalBodyDtoVaccinationStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateAnimalBodyDto
     */
    'sterilizedOrNeutered'?: CreateAnimalBodyDtoSterilizedOrNeutered;
    /**
     * 
     * @type {BirthdayDto}
     * @memberof CreateAnimalBodyDto
     */
    'birthday'?: BirthdayDto;
}

export const CreateAnimalBodyDtoSex = {
    Male: 'male',
    Female: 'female'
} as const;

export type CreateAnimalBodyDtoSex = typeof CreateAnimalBodyDtoSex[keyof typeof CreateAnimalBodyDtoSex];
export const CreateAnimalBodyDtoAdoptionStatus = {
    Adoptable: 'adoptable',
    InTheProcessOfBeingAdoptable: 'in-the-process-of-being-adoptable',
    HasBeenAdopted: 'has-been-adopted',
    CannotBeAdopted: 'cannot-be-adopted',
    ToBeFilled: 'to-be-filled'
} as const;

export type CreateAnimalBodyDtoAdoptionStatus = typeof CreateAnimalBodyDtoAdoptionStatus[keyof typeof CreateAnimalBodyDtoAdoptionStatus];
export const CreateAnimalBodyDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type CreateAnimalBodyDtoSpecies = typeof CreateAnimalBodyDtoSpecies[keyof typeof CreateAnimalBodyDtoSpecies];
export const CreateAnimalBodyDtoVaccinationStatus = {
    InProgress: 'in-progress',
    Unknown: 'unknown',
    Completed: 'completed',
    ToDo: 'to-do',
    NotDoing: 'not-doing'
} as const;

export type CreateAnimalBodyDtoVaccinationStatus = typeof CreateAnimalBodyDtoVaccinationStatus[keyof typeof CreateAnimalBodyDtoVaccinationStatus];
export const CreateAnimalBodyDtoSterilizedOrNeutered = {
    True: 'true',
    False: 'false',
    Unknown: 'unknown'
} as const;

export type CreateAnimalBodyDtoSterilizedOrNeutered = typeof CreateAnimalBodyDtoSterilizedOrNeutered[keyof typeof CreateAnimalBodyDtoSterilizedOrNeutered];

/**
 * 
 * @export
 * @interface CreateBeRealDto
 */
export interface CreateBeRealDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBeRealDto
     */
    'adopterId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeRealDto
     */
    'animalProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeRealDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateChatMessageDto
 */
export interface CreateChatMessageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateChatMessageDto
     */
    'conversationId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateChatMessageDto
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateCustomerDto
 */
export interface CreateCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'SIRETNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerDto
     */
    'postalCode': string;
    /**
     * 
     * @type {CustomerPreferencesDto}
     * @memberof CreateCustomerDto
     */
    'preferences': CustomerPreferencesDto;
}
/**
 * 
 * @export
 * @interface CreateDonationTrackingBodyDto
 */
export interface CreateDonationTrackingBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDonationTrackingBodyDto
     */
    'donaterId': string;
    /**
     * 
     * @type {object}
     * @memberof CreateDonationTrackingBodyDto
     */
    'dateCollected': object;
    /**
     * 
     * @type {number}
     * @memberof CreateDonationTrackingBodyDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreateDonationTrackingBodyDto
     */
    'formOfDonation': CreateDonationTrackingBodyDtoFormOfDonation;
    /**
     * 
     * @type {string}
     * @memberof CreateDonationTrackingBodyDto
     */
    'typeOfDonation': CreateDonationTrackingBodyDtoTypeOfDonation;
    /**
     * 
     * @type {string}
     * @memberof CreateDonationTrackingBodyDto
     */
    'methodOfPayment': CreateDonationTrackingBodyDtoMethodOfPayment;
    /**
     * 
     * @type {string}
     * @memberof CreateDonationTrackingBodyDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDonationTrackingBodyDto
     */
    'cgi': CreateDonationTrackingBodyDtoCgi;
}

export const CreateDonationTrackingBodyDtoFormOfDonation = {
    Deed: 'deed',
    PrivateDeed: 'private-deed',
    DeclarationOfManualDonation: 'declaration-of-manual-donation',
    Other: 'other'
} as const;

export type CreateDonationTrackingBodyDtoFormOfDonation = typeof CreateDonationTrackingBodyDtoFormOfDonation[keyof typeof CreateDonationTrackingBodyDtoFormOfDonation];
export const CreateDonationTrackingBodyDtoTypeOfDonation = {
    Cash: 'cash',
    Food: 'food',
    Equipment: 'equipment',
    Other: 'other'
} as const;

export type CreateDonationTrackingBodyDtoTypeOfDonation = typeof CreateDonationTrackingBodyDtoTypeOfDonation[keyof typeof CreateDonationTrackingBodyDtoTypeOfDonation];
export const CreateDonationTrackingBodyDtoMethodOfPayment = {
    Cash: 'cash',
    Cheque: 'cheque',
    WireTransfer: 'wire-transfer',
    DirectDebit: 'direct-debit',
    BankCard: 'bank-card'
} as const;

export type CreateDonationTrackingBodyDtoMethodOfPayment = typeof CreateDonationTrackingBodyDtoMethodOfPayment[keyof typeof CreateDonationTrackingBodyDtoMethodOfPayment];
export const CreateDonationTrackingBodyDtoCgi = {
    _200: '200',
    _238: '238',
    _885: '885',
    Other: 'other'
} as const;

export type CreateDonationTrackingBodyDtoCgi = typeof CreateDonationTrackingBodyDtoCgi[keyof typeof CreateDonationTrackingBodyDtoCgi];

/**
 * 
 * @export
 * @interface CreateDonorBodyDto
 */
export interface CreateDonorBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDonorBodyDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDonorBodyDto
     */
    'type': CreateDonorBodyDtoType;
    /**
     * 
     * @type {Human}
     * @memberof CreateDonorBodyDto
     */
    'human'?: Human;
    /**
     * 
     * @type {Organization}
     * @memberof CreateDonorBodyDto
     */
    'organization'?: Organization;
}

export const CreateDonorBodyDtoType = {
    Individuals: 'individuals',
    Companies: 'companies',
    Associations: 'associations',
    PublicBodies: 'public-bodies'
} as const;

export type CreateDonorBodyDtoType = typeof CreateDonorBodyDtoType[keyof typeof CreateDonorBodyDtoType];

/**
 * 
 * @export
 * @interface CustomerBureauMemberDto
 */
export interface CustomerBureauMemberDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerBureauMemberDto
     */
    'accountId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerBureauMemberDto
     */
    'titles': Array<CustomerBureauMemberDtoTitles>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerBureauMemberDto
     */
    'isSignatory': boolean;
}

export const CustomerBureauMemberDtoTitles = {
    President: 'president',
    VicePresident: 'vice-president',
    SecretaryGeneral: 'secretary-general',
    Treasurer: 'treasurer'
} as const;

export type CustomerBureauMemberDtoTitles = typeof CustomerBureauMemberDtoTitles[keyof typeof CustomerBureauMemberDtoTitles];

/**
 * 
 * @export
 * @interface CustomerCageDto
 */
export interface CustomerCageDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerCageDto
     */
    'memberInChargeId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerCageDto
     */
    'suitableForSpecies': Array<CustomerCageDtoSuitableForSpecies>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerCageDto
     */
    'animals'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageDto
     */
    'cageNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCageDto
     */
    'capacity': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageDto
     */
    'postalCode': string;
}

export const CustomerCageDtoSuitableForSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type CustomerCageDtoSuitableForSpecies = typeof CustomerCageDtoSuitableForSpecies[keyof typeof CustomerCageDtoSuitableForSpecies];

/**
 * 
 * @export
 * @interface CustomerCageWithAnimalsDetailsDto
 */
export interface CustomerCageWithAnimalsDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'memberInChargeId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'suitableForSpecies': Array<CustomerCageWithAnimalsDetailsDtoSuitableForSpecies>;
    /**
     * 
     * @type {Array<AnimalCageDto>}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'animals'?: Array<AnimalCageDto>;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'cageNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'capacity': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCageWithAnimalsDetailsDto
     */
    'postalCode': string;
}

export const CustomerCageWithAnimalsDetailsDtoSuitableForSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type CustomerCageWithAnimalsDetailsDtoSuitableForSpecies = typeof CustomerCageWithAnimalsDetailsDtoSuitableForSpecies[keyof typeof CustomerCageWithAnimalsDetailsDtoSuitableForSpecies];

/**
 * 
 * @export
 * @interface CustomerDocumentDto
 */
export interface CustomerDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'type': CustomerDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'reference': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDocumentDto
     */
    'name'?: string;
}

export const CustomerDocumentDtoType = {
    KnowledgeCertificateModel: 'knowledge-certificate-model',
    AdoptionContractModel: 'adoption-contract-model',
    SanitaryRegulations: 'sanitary-regulations',
    HostFamilyCharter: 'host-family-charter',
    VolunteerCharter: 'volunteer-charter',
    LegalStatus: 'legal-status',
    SituationNotice: 'situation-notice',
    OfficialJournalPublication: 'official-journal-publication',
    BureauMembersDeclaration: 'bureau-members-declaration',
    ActivityDeclaration: 'activity-declaration',
    Others: 'others'
} as const;

export type CustomerDocumentDtoType = typeof CustomerDocumentDtoType[keyof typeof CustomerDocumentDtoType];

/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'SIRETNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'RNANumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'masterAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'sanitaryVeterinarianAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'adoptionDocumentsValidatorAccountId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerDto
     */
    'types'?: Array<CustomerDtoTypes>;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {CustomerSubscriptionDto}
     * @memberof CustomerDto
     */
    'subscription'?: CustomerSubscriptionDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'profileImageKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'streetNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'city'?: string;
    /**
     * 
     * @type {CustomerPaymentInfoDto}
     * @memberof CustomerDto
     */
    'paymentInfo'?: CustomerPaymentInfoDto;
    /**
     * 
     * @type {CustomerPreferencesDto}
     * @memberof CustomerDto
     */
    'preferences': CustomerPreferencesDto;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'howManyAnimalsPerYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'howManyAdoptionsPerYear'?: number;
    /**
     * 
     * @type {Array<CustomerBureauMemberDto>}
     * @memberof CustomerDto
     */
    'bureauMembers'?: Array<CustomerBureauMemberDto>;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'legalForm'?: string;
    /**
     * 
     * @type {OrganizationTypeDto}
     * @memberof CustomerDto
     */
    'organizationType'?: OrganizationTypeDto;
    /**
     * 
     * @type {Array<CustomerDocumentDto>}
     * @memberof CustomerDto
     */
    'documents': Array<CustomerDocumentDto>;
    /**
     * 
     * @type {Array<CustomerTemplateDto>}
     * @memberof CustomerDto
     */
    'templates'?: Array<CustomerTemplateDto>;
    /**
     * 
     * @type {Array<CustomerPdfTemplateDto>}
     * @memberof CustomerDto
     */
    'pdfTemplates'?: Array<CustomerPdfTemplateDto>;
    /**
     * 
     * @type {CustomerSignatureDto}
     * @memberof CustomerDto
     */
    'signature'?: CustomerSignatureDto;
    /**
     * 
     * @type {Array<CustomerCageDto>}
     * @memberof CustomerDto
     */
    'cages'?: Array<CustomerCageDto>;
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'veterinaryFormIndex'?: number;
}

export const CustomerDtoTypes = {
    Pound: 'pound',
    Shelter: 'shelter',
    Charity: 'charity'
} as const;

export type CustomerDtoTypes = typeof CustomerDtoTypes[keyof typeof CustomerDtoTypes];

/**
 * 
 * @export
 * @interface CustomerPaymentInfoDto
 */
export interface CustomerPaymentInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentInfoDto
     */
    'IBANNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentInfoDto
     */
    'IBANFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentInfoDto
     */
    'IBANLastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentInfoDto
     */
    'checkOrder'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPaymentInfoDto
     */
    'onlinePaymentURL'?: string;
}
/**
 * 
 * @export
 * @interface CustomerPdfTemplateDto
 */
export interface CustomerPdfTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'type': CustomerPdfTemplateDtoType;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<CustomerPdfTemplateZoneDto>}
     * @memberof CustomerPdfTemplateDto
     */
    'zones': Array<CustomerPdfTemplateZoneDto>;
    /**
     * 
     * @type {Array<CustomerPdfTemplateVariableZoneDto>}
     * @memberof CustomerPdfTemplateDto
     */
    'variables'?: Array<CustomerPdfTemplateVariableZoneDto>;
}

export const CustomerPdfTemplateDtoType = {
    KnowledgeCertificateModel: 'knowledge-certificate-model',
    AdoptionContractModel: 'adoption-contract-model'
} as const;

export type CustomerPdfTemplateDtoType = typeof CustomerPdfTemplateDtoType[keyof typeof CustomerPdfTemplateDtoType];

/**
 * 
 * @export
 * @interface CustomerPdfTemplateVariableZoneDto
 */
export interface CustomerPdfTemplateVariableZoneDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateVariableZoneDto
     */
    'variable': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateVariableZoneDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateVariableZoneDto
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateVariableZoneDto
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateVariableZoneDto
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateVariableZoneDto
     */
    'height': number;
}
/**
 * 
 * @export
 * @interface CustomerPdfTemplateZoneDto
 */
export interface CustomerPdfTemplateZoneDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'type': CustomerPdfTemplateZoneDtoType;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'y': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'width': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerPdfTemplateZoneDto
     */
    'height': number;
}

export const CustomerPdfTemplateZoneDtoType = {
    Text: 'text',
    Signature: 'signature',
    Initials: 'initials',
    Date: 'date',
    Image: 'image',
    File: 'file',
    Payment: 'payment',
    Stamp: 'stamp',
    Select: 'select',
    Checkbox: 'checkbox',
    Multiple: 'multiple',
    Radio: 'radio',
    Cells: 'cells',
    Phone: 'phone'
} as const;

export type CustomerPdfTemplateZoneDtoType = typeof CustomerPdfTemplateZoneDtoType[keyof typeof CustomerPdfTemplateZoneDtoType];

/**
 * 
 * @export
 * @interface CustomerPreferencesDto
 */
export interface CustomerPreferencesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerPreferencesDto
     */
    'species': Array<CustomerPreferencesDtoSpecies>;
}

export const CustomerPreferencesDtoSpecies = {
    Cat: 'cat',
    Dog: 'dog',
    Birds: 'birds',
    GuineaPig: 'guinea-pig',
    Hamster: 'hamster',
    Mouse: 'mouse',
    Rat: 'rat',
    Rabbit: 'rabbit',
    Ferret: 'ferret',
    Equidae: 'equidae',
    Pogonas: 'pogonas',
    Lizard: 'lizard',
    Snake: 'snake',
    Turtle: 'turtle',
    Pigs: 'pigs',
    Cattle: 'cattle',
    Sheep: 'sheep',
    Goats: 'goats',
    Poultry: 'poultry'
} as const;

export type CustomerPreferencesDtoSpecies = typeof CustomerPreferencesDtoSpecies[keyof typeof CustomerPreferencesDtoSpecies];

/**
 * 
 * @export
 * @interface CustomerSignatureDto
 */
export interface CustomerSignatureDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSignatureDto
     */
    'madeBy': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignatureDto
     */
    'signedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSignatureDto
     */
    'fileKey': string;
}
/**
 * 
 * @export
 * @interface CustomerSubscriptionDto
 */
export interface CustomerSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscriptionDto
     */
    'plan': CustomerSubscriptionDtoPlan;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscriptionDto
     */
    'status': CustomerSubscriptionDtoStatus;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscriptionDto
     */
    'expiresAt': string;
}

export const CustomerSubscriptionDtoPlan = {
    Essential: 'essential',
    Premium: 'premium',
    Free: 'free'
} as const;

export type CustomerSubscriptionDtoPlan = typeof CustomerSubscriptionDtoPlan[keyof typeof CustomerSubscriptionDtoPlan];
export const CustomerSubscriptionDtoStatus = {
    Active: 'active',
    Expired: 'expired',
    Canceled: 'canceled'
} as const;

export type CustomerSubscriptionDtoStatus = typeof CustomerSubscriptionDtoStatus[keyof typeof CustomerSubscriptionDtoStatus];

/**
 * 
 * @export
 * @interface CustomerTemplateDto
 */
export interface CustomerTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'uploadedBy': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'type': CustomerTemplateDtoType;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'documentKey': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'uploadedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTemplateDto
     */
    'updatedAt'?: string;
}

export const CustomerTemplateDtoType = {
    KnowledgeCertificateModel: 'knowledge-certificate-model',
    AdoptionContractModel: 'adoption-contract-model'
} as const;

export type CustomerTemplateDtoType = typeof CustomerTemplateDtoType[keyof typeof CustomerTemplateDtoType];

/**
 * 
 * @export
 * @interface DashboardDataDto
 */
export interface DashboardDataDto {
    /**
     * Count of animals by city of origin excluding undefined/null values.
     * @type {Array<CityOfOriginCountDto>}
     * @memberof DashboardDataDto
     */
    'citiesOfOriginCount': Array<CityOfOriginCountDto>;
    /**
     * Count of animals by their adoption statuses.
     * @type {Array<AdoptionStatusCountDto>}
     * @memberof DashboardDataDto
     */
    'adoptionStatusesCount': Array<AdoptionStatusCountDto>;
    /**
     * Count of adoptions by species.
     * @type {Array<AdoptionCountBySpeciesDto>}
     * @memberof DashboardDataDto
     */
    'adoptionCountBySpecies': Array<AdoptionCountBySpeciesDto>;
    /**
     * Count of animals by their adoption step.
     * @type {Array<AdoptionStepCountDto>}
     * @memberof DashboardDataDto
     */
    'adoptionStepCount': Array<AdoptionStepCountDto>;
    /**
     * Count of animals by their cannot be adopted status.
     * @type {Array<CannotBeAdoptedStatusCountDto>}
     * @memberof DashboardDataDto
     */
    'cannotBeAdoptedStatusCount': Array<CannotBeAdoptedStatusCountDto>;
    /**
     * Count of animals registered for each date.
     * @type {Array<AnimalsEnteredByDateDto>}
     * @memberof DashboardDataDto
     */
    'animalsEnteredByDateCount': Array<AnimalsEnteredByDateDto>;
    /**
     * Count of animals exited for each date.
     * @type {Array<AnimalsEnteredByDateDto>}
     * @memberof DashboardDataDto
     */
    'animalsExitedByDateCount': Array<AnimalsEnteredByDateDto>;
    /**
     * Count of animals by their takeover reason.
     * @type {Array<TakeOverReasonCountDto>}
     * @memberof DashboardDataDto
     */
    'takeOverReasonCount': Array<TakeOverReasonCountDto>;
    /**
     * Count of entries by species.
     * @type {Array<EntriesBySpeciesCountDto>}
     * @memberof DashboardDataDto
     */
    'entriesBySpeciesCount': Array<EntriesBySpeciesCountDto>;
    /**
     * Count of all sterilized or neutered animals.
     * @type {number}
     * @memberof DashboardDataDto
     */
    'sterilizedOrNeuteredCount': number;
    /**
     * Count of animals with vaccination status as completed.
     * @type {number}
     * @memberof DashboardDataDto
     */
    'completedVaccinationCount': number;
    /**
     * Count of all animals registered.
     * @type {number}
     * @memberof DashboardDataDto
     */
    'totalCount': number;
}
/**
 * 
 * @export
 * @interface DesiredAnimalDto
 */
export interface DesiredAnimalDto {
    /**
     * 
     * @type {boolean}
     * @memberof DesiredAnimalDto
     */
    'hasDesiredAnimal': boolean;
    /**
     * 
     * @type {string}
     * @memberof DesiredAnimalDto
     */
    'desiredAnimalId'?: string;
}
/**
 * 
 * @export
 * @interface DonationTrackingDto
 */
export interface DonationTrackingDto {
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'dateCollected': string;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'donaterId': string;
    /**
     * 
     * @type {number}
     * @memberof DonationTrackingDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'formOfDonation': DonationTrackingDtoFormOfDonation;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'typeOfDonation': DonationTrackingDtoTypeOfDonation;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'methodOfPayment': DonationTrackingDtoMethodOfPayment;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingDto
     */
    'cgi': DonationTrackingDtoCgi;
}

export const DonationTrackingDtoFormOfDonation = {
    Deed: 'deed',
    PrivateDeed: 'private-deed',
    DeclarationOfManualDonation: 'declaration-of-manual-donation',
    Other: 'other'
} as const;

export type DonationTrackingDtoFormOfDonation = typeof DonationTrackingDtoFormOfDonation[keyof typeof DonationTrackingDtoFormOfDonation];
export const DonationTrackingDtoTypeOfDonation = {
    Cash: 'cash',
    Food: 'food',
    Equipment: 'equipment',
    Other: 'other'
} as const;

export type DonationTrackingDtoTypeOfDonation = typeof DonationTrackingDtoTypeOfDonation[keyof typeof DonationTrackingDtoTypeOfDonation];
export const DonationTrackingDtoMethodOfPayment = {
    Cash: 'cash',
    Cheque: 'cheque',
    WireTransfer: 'wire-transfer',
    DirectDebit: 'direct-debit',
    BankCard: 'bank-card'
} as const;

export type DonationTrackingDtoMethodOfPayment = typeof DonationTrackingDtoMethodOfPayment[keyof typeof DonationTrackingDtoMethodOfPayment];
export const DonationTrackingDtoCgi = {
    _200: '200',
    _238: '238',
    _885: '885',
    Other: 'other'
} as const;

export type DonationTrackingDtoCgi = typeof DonationTrackingDtoCgi[keyof typeof DonationTrackingDtoCgi];

/**
 * 
 * @export
 * @interface Donor
 */
export interface Donor {
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof Donor
     */
    'type': DonorType;
    /**
     * 
     * @type {Human}
     * @memberof Donor
     */
    'human'?: Human;
    /**
     * 
     * @type {Organization}
     * @memberof Donor
     */
    'organization'?: Organization;
}

export const DonorType = {
    Individuals: 'individuals',
    Companies: 'companies',
    Associations: 'associations',
    PublicBodies: 'public-bodies'
} as const;

export type DonorType = typeof DonorType[keyof typeof DonorType];

/**
 * 
 * @export
 * @interface EatsGoodAmountDto
 */
export interface EatsGoodAmountDto {
    /**
     * 
     * @type {string}
     * @memberof EatsGoodAmountDto
     */
    'age': EatsGoodAmountDtoAge;
    /**
     * 
     * @type {string}
     * @memberof EatsGoodAmountDto
     */
    'lifestyle': EatsGoodAmountDtoLifestyle;
    /**
     * 
     * @type {string}
     * @memberof EatsGoodAmountDto
     */
    'activityLevel': EatsGoodAmountDtoActivityLevel;
    /**
     * 
     * @type {string}
     * @memberof EatsGoodAmountDto
     */
    'corpulence': EatsGoodAmountDtoCorpulence;
}

export const EatsGoodAmountDtoAge = {
    NewBorn: 'new-born',
    Puppy: 'puppy',
    Young: 'young',
    Adult: 'adult',
    Senior: 'senior'
} as const;

export type EatsGoodAmountDtoAge = typeof EatsGoodAmountDtoAge[keyof typeof EatsGoodAmountDtoAge];
export const EatsGoodAmountDtoLifestyle = {
    Inside: 'inside',
    InsideAndOutside: 'inside-and-outside',
    Outside: 'outside'
} as const;

export type EatsGoodAmountDtoLifestyle = typeof EatsGoodAmountDtoLifestyle[keyof typeof EatsGoodAmountDtoLifestyle];
export const EatsGoodAmountDtoActivityLevel = {
    VeryLow: 'very-low',
    Low: 'low',
    Medium: 'medium',
    High: 'high'
} as const;

export type EatsGoodAmountDtoActivityLevel = typeof EatsGoodAmountDtoActivityLevel[keyof typeof EatsGoodAmountDtoActivityLevel];
export const EatsGoodAmountDtoCorpulence = {
    Underweight: 'underweight',
    ABitUnderweight: 'a-bit-underweight',
    Balanced: 'balanced',
    ABitOverweight: 'a-bit-overweight',
    Overweight: 'overweight'
} as const;

export type EatsGoodAmountDtoCorpulence = typeof EatsGoodAmountDtoCorpulence[keyof typeof EatsGoodAmountDtoCorpulence];

/**
 * 
 * @export
 * @interface EntriesBySpeciesCountDto
 */
export interface EntriesBySpeciesCountDto {
    /**
     * Species of the animal.
     * @type {string}
     * @memberof EntriesBySpeciesCountDto
     */
    'speciesName': string;
    /**
     * Number of entries for this species.
     * @type {number}
     * @memberof EntriesBySpeciesCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface EnvironmentQuestionsDto
 */
export interface EnvironmentQuestionsDto {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'homeType': EnvironmentQuestionsDtoHomeType;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'hasAccessToSecuredWindows': EnvironmentQuestionsDtoHasAccessToSecuredWindows;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'hasAnAdaptedHidingPlace': EnvironmentQuestionsDtoHasAnAdaptedHidingPlace;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'hasPerches': EnvironmentQuestionsDtoHasPerches;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'hasToys': EnvironmentQuestionsDtoHasToys;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'hasScratchingPosts': EnvironmentQuestionsDtoHasScratchingPosts;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'hasOneMoreLitterBoxThanNumberOfCats'?: EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats;
    /**
     * 
     * @type {string}
     * @memberof EnvironmentQuestionsDto
     */
    'LitterIsFarFromFood': EnvironmentQuestionsDtoLitterIsFarFromFood;
}

export const EnvironmentQuestionsDtoHomeType = {
    Apartment: 'apartment',
    House: 'house'
} as const;

export type EnvironmentQuestionsDtoHomeType = typeof EnvironmentQuestionsDtoHomeType[keyof typeof EnvironmentQuestionsDtoHomeType];
export const EnvironmentQuestionsDtoHasAccessToSecuredWindows = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoHasAccessToSecuredWindows = typeof EnvironmentQuestionsDtoHasAccessToSecuredWindows[keyof typeof EnvironmentQuestionsDtoHasAccessToSecuredWindows];
export const EnvironmentQuestionsDtoHasAnAdaptedHidingPlace = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoHasAnAdaptedHidingPlace = typeof EnvironmentQuestionsDtoHasAnAdaptedHidingPlace[keyof typeof EnvironmentQuestionsDtoHasAnAdaptedHidingPlace];
export const EnvironmentQuestionsDtoHasPerches = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoHasPerches = typeof EnvironmentQuestionsDtoHasPerches[keyof typeof EnvironmentQuestionsDtoHasPerches];
export const EnvironmentQuestionsDtoHasToys = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoHasToys = typeof EnvironmentQuestionsDtoHasToys[keyof typeof EnvironmentQuestionsDtoHasToys];
export const EnvironmentQuestionsDtoHasScratchingPosts = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoHasScratchingPosts = typeof EnvironmentQuestionsDtoHasScratchingPosts[keyof typeof EnvironmentQuestionsDtoHasScratchingPosts];
export const EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats = typeof EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats[keyof typeof EnvironmentQuestionsDtoHasOneMoreLitterBoxThanNumberOfCats];
export const EnvironmentQuestionsDtoLitterIsFarFromFood = {
    Yes: 'yes',
    No: 'no'
} as const;

export type EnvironmentQuestionsDtoLitterIsFarFromFood = typeof EnvironmentQuestionsDtoLitterIsFarFromFood[keyof typeof EnvironmentQuestionsDtoLitterIsFarFromFood];

/**
 * 
 * @export
 * @interface FeedingQuestionsDto
 */
export interface FeedingQuestionsDto {
    /**
     * 
     * @type {string}
     * @memberof FeedingQuestionsDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof FeedingQuestionsDto
     */
    'catHydration': FeedingQuestionsDtoCatHydration;
    /**
     * 
     * @type {string}
     * @memberof FeedingQuestionsDto
     */
    'hasWaterFountain': FeedingQuestionsDtoHasWaterFountain;
    /**
     * 
     * @type {string}
     * @memberof FeedingQuestionsDto
     */
    'mealFrequency': FeedingQuestionsDtoMealFrequency;
    /**
     * 
     * @type {number}
     * @memberof FeedingQuestionsDto
     */
    'energeticalNeeds'?: number;
    /**
     * 
     * @type {EatsGoodAmountDto}
     * @memberof FeedingQuestionsDto
     */
    'eatsGoodAmount': EatsGoodAmountDto;
    /**
     * 
     * @type {NutritionalIntakeDto}
     * @memberof FeedingQuestionsDto
     */
    'nutritionalIntake': NutritionalIntakeDto;
}

export const FeedingQuestionsDtoCatHydration = {
    Yes: 'yes',
    No: 'no'
} as const;

export type FeedingQuestionsDtoCatHydration = typeof FeedingQuestionsDtoCatHydration[keyof typeof FeedingQuestionsDtoCatHydration];
export const FeedingQuestionsDtoHasWaterFountain = {
    Yes: 'yes',
    No: 'no'
} as const;

export type FeedingQuestionsDtoHasWaterFountain = typeof FeedingQuestionsDtoHasWaterFountain[keyof typeof FeedingQuestionsDtoHasWaterFountain];
export const FeedingQuestionsDtoMealFrequency = {
    Unlimited: 'unlimited',
    OnceADay: 'once-a-day',
    TwiceADay: 'twice-a-day',
    ThreeTimesADay: 'three-times-a-day',
    FourTimesADay: 'four-times-a-day'
} as const;

export type FeedingQuestionsDtoMealFrequency = typeof FeedingQuestionsDtoMealFrequency[keyof typeof FeedingQuestionsDtoMealFrequency];

/**
 * 
 * @export
 * @interface FromCustomerAnimalDto
 */
export interface FromCustomerAnimalDto {
    /**
     * 
     * @type {string}
     * @memberof FromCustomerAnimalDto
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof FromCustomerAnimalDto
     */
    'animalId': string;
}
/**
 * 
 * @export
 * @interface HostedFamilyFollowUpsDto
 */
export interface HostedFamilyFollowUpsDto {
    /**
     * 
     * @type {Array<ContactHostedFamilyFollowUpDto>}
     * @memberof HostedFamilyFollowUpsDto
     */
    'contact'?: Array<ContactHostedFamilyFollowUpDto>;
    /**
     * 
     * @type {Array<HostedFamilyFollowUpsDtoMainInner>}
     * @memberof HostedFamilyFollowUpsDto
     */
    'main'?: Array<HostedFamilyFollowUpsDtoMainInner>;
}
/**
 * @type HostedFamilyFollowUpsDtoMainInner
 * @export
 */
export type HostedFamilyFollowUpsDtoMainInner = ChangeOfHostedFamilyHostedFamilyFollowUpDto | ShiftHostedFamilyFollowUpDto;

/**
 * 
 * @export
 * @interface Human
 */
export interface Human {
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Human
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface InitiateCustomerDto
 */
export interface InitiateCustomerDto {
    /**
     * 
     * @type {CreateCustomerDto}
     * @memberof InitiateCustomerDto
     */
    'customer': CreateCustomerDto;
    /**
     * 
     * @type {Array<CreateAccountBodyDto>}
     * @memberof InitiateCustomerDto
     */
    'adminAccounts': Array<CreateAccountBodyDto>;
}
/**
 * 
 * @export
 * @interface InjectionDto
 */
export interface InjectionDto {
    /**
     * 
     * @type {string}
     * @memberof InjectionDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface InsightsCountersDto
 */
export interface InsightsCountersDto {
    /**
     * 
     * @type {number}
     * @memberof InsightsCountersDto
     */
    'info': number;
    /**
     * 
     * @type {number}
     * @memberof InsightsCountersDto
     */
    'warning': number;
    /**
     * 
     * @type {number}
     * @memberof InsightsCountersDto
     */
    'error': number;
    /**
     * 
     * @type {number}
     * @memberof InsightsCountersDto
     */
    'success': number;
}
/**
 * 
 * @export
 * @interface InsuranceEstimationBody
 */
export interface InsuranceEstimationBody {
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof InsuranceEstimationBody
     */
    'offerNumber': number;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalId': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalName': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalSpecie': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalBreed': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalBreedTitle': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalBirthdate': string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceEstimationBody
     */
    'animalSex': string;
}
/**
 * 
 * @export
 * @interface LoginBodyDto
 */
export interface LoginBodyDto {
    /**
     * 
     * @type {string}
     * @memberof LoginBodyDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginBodyDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface MetaForFollowUpDto
 */
export interface MetaForFollowUpDto {
    /**
     * 
     * @type {string}
     * @memberof MetaForFollowUpDto
     */
    'at': string;
    /**
     * 
     * @type {string}
     * @memberof MetaForFollowUpDto
     */
    'by': string;
}
/**
 * 
 * @export
 * @interface NutritionalIntakeDto
 */
export interface NutritionalIntakeDto {
    /**
     * 
     * @type {number}
     * @memberof NutritionalIntakeDto
     */
    'metabolicEnergy': number;
    /**
     * 
     * @type {number}
     * @memberof NutritionalIntakeDto
     */
    'dailyPortion': number;
}
/**
 * 
 * @export
 * @interface ObservationCommentDto
 */
export interface ObservationCommentDto {
    /**
     * 
     * @type {string}
     * @memberof ObservationCommentDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ObservationCommentDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ObservationCommentDto
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'SIRETNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'country': string;
}
/**
 * 
 * @export
 * @interface OrganizationTypeDto
 */
export interface OrganizationTypeDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeDto
     */
    'otherField'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationTypeDto
     */
    'type'?: OrganizationTypeDtoType;
}

export const OrganizationTypeDtoType = {
    GeneralInterestOrganization: 'general-interest-organization',
    CorporateFoundation: 'corporate-foundation',
    IntermediaryAssociations: 'intermediary-associations',
    UniversityOrPartnerFoundation: 'university-or-partner-foundation',
    NonProfitEducationalInstitution: 'non-profit-educational-institution',
    NonProfitResearchInstitution: 'non-profit-research-institution',
    InsertionOrTempWorkCompany: 'insertion-or-temp-work-company',
    InsertionWorkshopsAndSites: 'insertion-workshops-and-sites',
    AdaptedEnterprises: 'adapted-enterprises',
    OtherOrganizations: 'other-organizations'
} as const;

export type OrganizationTypeDtoType = typeof OrganizationTypeDtoType[keyof typeof OrganizationTypeDtoType];

/**
 * 
 * @export
 * @interface PromoCodeDto
 */
export interface PromoCodeDto {
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'discount': number;
    /**
     * 
     * @type {string}
     * @memberof PromoCodeDto
     */
    'discountType': PromoCodeDtoDiscountType;
    /**
     * 
     * @type {number}
     * @memberof PromoCodeDto
     */
    'minimalOrderAmount': number | null;
}

export const PromoCodeDtoDiscountType = {
    Percentage: 'percentage',
    Amount: 'amount'
} as const;

export type PromoCodeDtoDiscountType = typeof PromoCodeDtoDiscountType[keyof typeof PromoCodeDtoDiscountType];

/**
 * 
 * @export
 * @interface ServiceLocationDto
 */
export interface ServiceLocationDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'type': ServiceLocationDtoType;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceLocationDto
     */
    'serviceProviders': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'bookingLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'country': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ServiceLocationDto
     */
    'coordinates': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationDto
     */
    'schedules'?: string;
}

export const ServiceLocationDtoType = {
    Veterinary: 'veterinary',
    Behaviorist: 'behaviorist'
} as const;

export type ServiceLocationDtoType = typeof ServiceLocationDtoType[keyof typeof ServiceLocationDtoType];

/**
 * 
 * @export
 * @interface ServiceLocationWithProvidersNamesDto
 */
export interface ServiceLocationWithProvidersNamesDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'type': ServiceLocationWithProvidersNamesDtoType;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'serviceProviders': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'bookingLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'postalCode': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'country': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'coordinates': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'logo'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'schedules'?: string;
    /**
     * 
     * @type {Array<ServiceProvidersNames>}
     * @memberof ServiceLocationWithProvidersNamesDto
     */
    'serviceProvidersNames': Array<ServiceProvidersNames>;
}

export const ServiceLocationWithProvidersNamesDtoType = {
    Veterinary: 'veterinary',
    Behaviorist: 'behaviorist'
} as const;

export type ServiceLocationWithProvidersNamesDtoType = typeof ServiceLocationWithProvidersNamesDtoType[keyof typeof ServiceLocationWithProvidersNamesDtoType];

/**
 * 
 * @export
 * @interface ServiceProviderDto
 */
export interface ServiceProviderDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'presentation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderDto
     */
    'schedules'?: string;
    /**
     * 
     * @type {Array<ServiceProviderPricingDto>}
     * @memberof ServiceProviderDto
     */
    'pricing'?: Array<ServiceProviderPricingDto>;
}
/**
 * 
 * @export
 * @interface ServiceProviderPricingDto
 */
export interface ServiceProviderPricingDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderPricingDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProviderPricingDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ServiceProvidersNames
 */
export interface ServiceProvidersNames {
    /**
     * 
     * @type {string}
     * @memberof ServiceProvidersNames
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvidersNames
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceProvidersNames
     */
    'lastName': string;
}
/**
 * 
 * @export
 * @interface ShiftHostedFamilyFollowUpDto
 */
export interface ShiftHostedFamilyFollowUpDto {
    /**
     * 
     * @type {string}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'type': ShiftHostedFamilyFollowUpDtoType;
    /**
     * 
     * @type {string}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'destination': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'memberInChargeAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'description'?: string;
    /**
     * 
     * @type {MetaForFollowUpDto}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'created': MetaForFollowUpDto;
    /**
     * 
     * @type {MetaForFollowUpDto}
     * @memberof ShiftHostedFamilyFollowUpDto
     */
    'modified'?: MetaForFollowUpDto;
}

export const ShiftHostedFamilyFollowUpDtoType = {
    InitialPlacement: 'initial-placement',
    Shift: 'shift',
    Exit: 'exit'
} as const;

export type ShiftHostedFamilyFollowUpDtoType = typeof ShiftHostedFamilyFollowUpDtoType[keyof typeof ShiftHostedFamilyFollowUpDtoType];

/**
 * 
 * @export
 * @interface SimplifiedBeRealDto
 */
export interface SimplifiedBeRealDto {
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    'adopterId': string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    'animalProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    'imageKey': string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof SimplifiedBeRealDto
     */
    'likedByCurrentUser': boolean;
    /**
     * 
     * @type {number}
     * @memberof SimplifiedBeRealDto
     */
    'likesCount': number;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    'animalName': string;
    /**
     * 
     * @type {string}
     * @memberof SimplifiedBeRealDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof SimplifiedBeRealDto
     */
    'tags': Array<object>;
}
/**
 * 
 * @export
 * @interface TakeOverReasonCountDto
 */
export interface TakeOverReasonCountDto {
    /**
     * Reason for taking over the animal.
     * @type {string}
     * @memberof TakeOverReasonCountDto
     */
    'reason': string;
    /**
     * Number of animals taken over for this reason.
     * @type {number}
     * @memberof TakeOverReasonCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface TattooDto
 */
export interface TattooDto {
    /**
     * 
     * @type {string}
     * @memberof TattooDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof TattooDto
     */
    'location'?: TattooDtoLocation;
}

export const TattooDtoLocation = {
    LeftEar: 'left-ear',
    RightEar: 'right-ear',
    LeftGroin: 'left-groin',
    RightGroin: 'right-groin',
    Other: 'other'
} as const;

export type TattooDtoLocation = typeof TattooDtoLocation[keyof typeof TattooDtoLocation];

/**
 * 
 * @export
 * @interface UploadAccountDocumentDto
 */
export interface UploadAccountDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UploadAccountDocumentDto
     */
    'obtainedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadAccountDocumentDto
     */
    'type': UploadAccountDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadAccountDocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadAccountDocumentDto
     */
    'reference'?: string;
}

export const UploadAccountDocumentDtoType = {
    FosterFamilyContract: 'foster-family-contract',
    VolunteerContract: 'volunteer-contract',
    EquipmentLoanForm: 'equipment-loan-form',
    Acaced: 'acaced',
    Asv: 'asv',
    PresidentIdentityDocument: 'president-identity-document',
    Others: 'others'
} as const;

export type UploadAccountDocumentDtoType = typeof UploadAccountDocumentDtoType[keyof typeof UploadAccountDocumentDtoType];

/**
 * 
 * @export
 * @interface UploadAdoptionAttemptDocumentDto
 */
export interface UploadAdoptionAttemptDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UploadAdoptionAttemptDocumentDto
     */
    'type': UploadAdoptionAttemptDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadAdoptionAttemptDocumentDto
     */
    'name'?: string;
}

export const UploadAdoptionAttemptDocumentDtoType = {
    SellCertificate: 'sell-certificate',
    VeterinaryCertificate: 'veterinary-certificate',
    InformationDocument: 'information-document',
    HealthBook: 'health-book',
    KnowledgeCertificate: 'knowledge-certificate',
    AdoptionContract: 'adoption-contract',
    Others: 'others'
} as const;

export type UploadAdoptionAttemptDocumentDtoType = typeof UploadAdoptionAttemptDocumentDtoType[keyof typeof UploadAdoptionAttemptDocumentDtoType];

/**
 * 
 * @export
 * @interface UploadAnimalDocumentDto
 */
export interface UploadAnimalDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UploadAnimalDocumentDto
     */
    'type': UploadAnimalDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadAnimalDocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadAnimalDocumentDto
     */
    'reference': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadAnimalDocumentDto
     */
    'attributes': Array<UploadAnimalDocumentDtoAttributes>;
}

export const UploadAnimalDocumentDtoType = {
    IdentificationCertificate: 'identification-certificate',
    VeterinaryCertificate: 'veterinary-certificate',
    AdoptionContract: 'adoption-contract',
    CopyOfDeclarationOfBirth: 'copy-of-declaration-of-birth',
    TransferCertificate: 'transfer-certificate',
    TransferInvoice: 'transfer-invoice',
    HealthBooklet: 'health-booklet',
    CertificateOfGoodHealth: 'certificate-of-good-health',
    PrimovaccinationCertificate: 'primovaccination-certificate',
    TestResult: 'test-result',
    InformationDocument: 'information-document',
    VeterinaryOrderForm: 'veterinary-order-form',
    SignedAdoptionContract: 'signed-adoption-contract',
    SignedAdoptionCertificate: 'signed-adoption-certificate',
    NeuteringCertificate: 'neutering-certificate',
    SterilizationCertificate: 'sterilization-certificate',
    Others: 'others'
} as const;

export type UploadAnimalDocumentDtoType = typeof UploadAnimalDocumentDtoType[keyof typeof UploadAnimalDocumentDtoType];
export const UploadAnimalDocumentDtoAttributes = {
    Entry: 'required-for-the-register-entry',
    Exit: 'required-for-the-register-exit'
} as const;

export type UploadAnimalDocumentDtoAttributes = typeof UploadAnimalDocumentDtoAttributes[keyof typeof UploadAnimalDocumentDtoAttributes];

/**
 * 
 * @export
 * @interface UploadAnimalProfileDocumentDto
 */
export interface UploadAnimalProfileDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UploadAnimalProfileDocumentDto
     */
    'type': UploadAnimalProfileDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadAnimalProfileDocumentDto
     */
    'name'?: string;
}

export const UploadAnimalProfileDocumentDtoType = {
    IdentificationCertificate: 'identification-certificate',
    VeterinaryCertificate: 'veterinary-certificate',
    AdoptionContract: 'adoption-contract',
    CopyOfDeclarationOfBirth: 'copy-of-declaration-of-birth',
    TransferCertificate: 'transfer-certificate',
    TransferInvoice: 'transfer-invoice',
    HealthBooklet: 'health-booklet',
    CertificateOfGoodHealth: 'certificate-of-good-health',
    PrimovaccinationCertificate: 'primovaccination-certificate',
    TestResult: 'test-result',
    InformationDocument: 'information-document',
    VeterinaryOrderForm: 'veterinary-order-form',
    SignedAdoptionContract: 'signed-adoption-contract',
    SignedAdoptionCertificate: 'signed-adoption-certificate',
    NeuteringCertificate: 'neutering-certificate',
    SterilizationCertificate: 'sterilization-certificate',
    Others: 'others'
} as const;

export type UploadAnimalProfileDocumentDtoType = typeof UploadAnimalProfileDocumentDtoType[keyof typeof UploadAnimalProfileDocumentDtoType];

/**
 * 
 * @export
 * @interface UploadCustomerDocumentDto
 */
export interface UploadCustomerDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerDocumentDto
     */
    'type': UploadCustomerDocumentDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerDocumentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerDocumentDto
     */
    'reference': string;
}

export const UploadCustomerDocumentDtoType = {
    KnowledgeCertificateModel: 'knowledge-certificate-model',
    AdoptionContractModel: 'adoption-contract-model',
    SanitaryRegulations: 'sanitary-regulations',
    HostFamilyCharter: 'host-family-charter',
    VolunteerCharter: 'volunteer-charter',
    LegalStatus: 'legal-status',
    SituationNotice: 'situation-notice',
    OfficialJournalPublication: 'official-journal-publication',
    BureauMembersDeclaration: 'bureau-members-declaration',
    ActivityDeclaration: 'activity-declaration',
    Others: 'others'
} as const;

export type UploadCustomerDocumentDtoType = typeof UploadCustomerDocumentDtoType[keyof typeof UploadCustomerDocumentDtoType];

/**
 * 
 * @export
 * @interface UploadCustomerPdfTemplateDto
 */
export interface UploadCustomerPdfTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerPdfTemplateDto
     */
    'type': UploadCustomerPdfTemplateDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerPdfTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof UploadCustomerPdfTemplateDto
     */
    'zones': Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof UploadCustomerPdfTemplateDto
     */
    'variables'?: Array<object>;
}

export const UploadCustomerPdfTemplateDtoType = {
    KnowledgeCertificateModel: 'knowledge-certificate-model',
    AdoptionContractModel: 'adoption-contract-model'
} as const;

export type UploadCustomerPdfTemplateDtoType = typeof UploadCustomerPdfTemplateDtoType[keyof typeof UploadCustomerPdfTemplateDtoType];

/**
 * 
 * @export
 * @interface UploadCustomerTemplateDto
 */
export interface UploadCustomerTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerTemplateDto
     */
    'type': UploadCustomerTemplateDtoType;
    /**
     * 
     * @type {string}
     * @memberof UploadCustomerTemplateDto
     */
    'name': string;
}

export const UploadCustomerTemplateDtoType = {
    KnowledgeCertificateModel: 'knowledge-certificate-model',
    AdoptionContractModel: 'adoption-contract-model'
} as const;

export type UploadCustomerTemplateDtoType = typeof UploadCustomerTemplateDtoType[keyof typeof UploadCustomerTemplateDtoType];

/**
 * 
 * @export
 * @interface VaccinationOperationDto
 */
export interface VaccinationOperationDto {
    /**
     * 
     * @type {string}
     * @memberof VaccinationOperationDto
     */
    'type': VaccinationOperationDtoType;
    /**
     * 
     * @type {string}
     * @memberof VaccinationOperationDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationOperationDto
     */
    'veterinary'?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationOperationDto
     */
    'memberInCharge'?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationOperationDto
     */
    'additionalInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationOperationDto
     */
    'price'?: string;
}

export const VaccinationOperationDtoType = {
    FirstInjection: 'first-injection',
    SecondInjection: 'second-injection',
    ThirdInjection: 'third-injection',
    AnnualReminder: 'annual-reminder'
} as const;

export type VaccinationOperationDtoType = typeof VaccinationOperationDtoType[keyof typeof VaccinationOperationDtoType];


/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAddComment: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsControllerAddComment', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/comments`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create account.
         * @summary 
         * @param {CreateAccountBodyDto} createAccountBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateAccount: async (createAccountBodyDto: CreateAccountBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAccountBodyDto' is not null or undefined
            assertParamExists('accountsControllerCreateAccount', 'createAccountBodyDto', createAccountBodyDto)
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete account.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDeleteComment: async (commentId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('accountsControllerDeleteComment', 'commentId', commentId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsControllerDeleteComment', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDeleteDocument: async (documentId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('accountsControllerDeleteDocument', 'documentId', documentId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsControllerDeleteDocument', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit account.
         * @summary 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerEditAccount: async (accountDto: AccountDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountDto' is not null or undefined
            assertParamExists('accountsControllerEditAccount', 'accountDto', accountDto)
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerEditComment: async (commentId: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('accountsControllerEditComment', 'commentId', commentId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsControllerEditComment', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/comments/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerExportAnimalsAsXLSX: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/export/xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get account data for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetCurrentAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {UploadAccountDocumentDto} uploadAccountDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUploadDocument: async (accountId: string, uploadAccountDocumentDto: UploadAccountDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsControllerUploadDocument', 'accountId', accountId)
            // verify required parameter 'uploadAccountDocumentDto' is not null or undefined
            assertParamExists('accountsControllerUploadDocument', 'uploadAccountDocumentDto', uploadAccountDocumentDto)
            const localVarPath = `/accounts/{accountId}/documents`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAccountDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUploadProfilePhoto: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('accountsControllerUploadProfilePhoto', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/upload-profile-photos`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerAddComment(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCommentsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerAddComment(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create account.
         * @summary 
         * @param {CreateAccountBodyDto} createAccountBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerCreateAccount(createAccountBodyDto: CreateAccountBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerCreateAccount(createAccountBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete account.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerDeleteComment(commentId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCommentsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerDeleteComment(commentId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerDeleteDocument(documentId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerDeleteDocument(documentId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit account.
         * @summary 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerEditAccount(accountDto: AccountDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerEditAccount(accountDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerEditComment(commentId: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCommentsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerEditComment(commentId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerExportAnimalsAsXLSX(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerExportAnimalsAsXLSX(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get account data for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerGetCurrentAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerGetCurrentAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {UploadAccountDocumentDto} uploadAccountDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerUploadDocument(accountId: string, uploadAccountDocumentDto: UploadAccountDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDocumentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerUploadDocument(accountId, uploadAccountDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsControllerUploadProfilePhoto(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsControllerUploadProfilePhoto(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerAddComment(accountId: string, options?: any): AxiosPromise<Array<AccountCommentsDto>> {
            return localVarFp.accountsControllerAddComment(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create account.
         * @summary 
         * @param {CreateAccountBodyDto} createAccountBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerCreateAccount(createAccountBodyDto: CreateAccountBodyDto, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.accountsControllerCreateAccount(createAccountBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete account.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDelete(options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDeleteComment(commentId: string, accountId: string, options?: any): AxiosPromise<Array<AccountCommentsDto>> {
            return localVarFp.accountsControllerDeleteComment(commentId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerDeleteDocument(documentId: string, accountId: string, options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerDeleteDocument(documentId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit account.
         * @summary 
         * @param {AccountDto} accountDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerEditAccount(accountDto: AccountDto, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.accountsControllerEditAccount(accountDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commentId 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerEditComment(commentId: string, accountId: string, options?: any): AxiosPromise<Array<AccountCommentsDto>> {
            return localVarFp.accountsControllerEditComment(commentId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerExportAnimalsAsXLSX(options?: any): AxiosPromise<void> {
            return localVarFp.accountsControllerExportAnimalsAsXLSX(options).then((request) => request(axios, basePath));
        },
        /**
         * Get account data for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerGetCurrentAccount(options?: any): AxiosPromise<AccountDto> {
            return localVarFp.accountsControllerGetCurrentAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {UploadAccountDocumentDto} uploadAccountDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUploadDocument(accountId: string, uploadAccountDocumentDto: UploadAccountDocumentDto, options?: any): AxiosPromise<AccountDocumentDto> {
            return localVarFp.accountsControllerUploadDocument(accountId, uploadAccountDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsControllerUploadProfilePhoto(accountId: string, options?: any): AxiosPromise<string> {
            return localVarFp.accountsControllerUploadProfilePhoto(accountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountsControllerAddComment operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerAddCommentRequest
 */
export interface AccountsApiAccountsControllerAddCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerAddComment
     */
    readonly accountId: string
}

/**
 * Request parameters for accountsControllerCreateAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerCreateAccountRequest
 */
export interface AccountsApiAccountsControllerCreateAccountRequest {
    /**
     * 
     * @type {CreateAccountBodyDto}
     * @memberof AccountsApiAccountsControllerCreateAccount
     */
    readonly createAccountBodyDto: CreateAccountBodyDto
}

/**
 * Request parameters for accountsControllerDeleteComment operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerDeleteCommentRequest
 */
export interface AccountsApiAccountsControllerDeleteCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerDeleteComment
     */
    readonly commentId: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerDeleteComment
     */
    readonly accountId: string
}

/**
 * Request parameters for accountsControllerDeleteDocument operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerDeleteDocumentRequest
 */
export interface AccountsApiAccountsControllerDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerDeleteDocument
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerDeleteDocument
     */
    readonly accountId: string
}

/**
 * Request parameters for accountsControllerEditAccount operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerEditAccountRequest
 */
export interface AccountsApiAccountsControllerEditAccountRequest {
    /**
     * 
     * @type {AccountDto}
     * @memberof AccountsApiAccountsControllerEditAccount
     */
    readonly accountDto: AccountDto
}

/**
 * Request parameters for accountsControllerEditComment operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerEditCommentRequest
 */
export interface AccountsApiAccountsControllerEditCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerEditComment
     */
    readonly commentId: string

    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerEditComment
     */
    readonly accountId: string
}

/**
 * Request parameters for accountsControllerUploadDocument operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerUploadDocumentRequest
 */
export interface AccountsApiAccountsControllerUploadDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerUploadDocument
     */
    readonly accountId: string

    /**
     * 
     * @type {UploadAccountDocumentDto}
     * @memberof AccountsApiAccountsControllerUploadDocument
     */
    readonly uploadAccountDocumentDto: UploadAccountDocumentDto
}

/**
 * Request parameters for accountsControllerUploadProfilePhoto operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsControllerUploadProfilePhotoRequest
 */
export interface AccountsApiAccountsControllerUploadProfilePhotoRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsControllerUploadProfilePhoto
     */
    readonly accountId: string
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {AccountsApiAccountsControllerAddCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerAddComment(requestParameters: AccountsApiAccountsControllerAddCommentRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerAddComment(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create account.
     * @summary 
     * @param {AccountsApiAccountsControllerCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerCreateAccount(requestParameters: AccountsApiAccountsControllerCreateAccountRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerCreateAccount(requestParameters.createAccountBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete account.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerDelete(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsControllerDeleteCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerDeleteComment(requestParameters: AccountsApiAccountsControllerDeleteCommentRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerDeleteComment(requestParameters.commentId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsControllerDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerDeleteDocument(requestParameters: AccountsApiAccountsControllerDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerDeleteDocument(requestParameters.documentId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit account.
     * @summary 
     * @param {AccountsApiAccountsControllerEditAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerEditAccount(requestParameters: AccountsApiAccountsControllerEditAccountRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerEditAccount(requestParameters.accountDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsControllerEditCommentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerEditComment(requestParameters: AccountsApiAccountsControllerEditCommentRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerEditComment(requestParameters.commentId, requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerExportAnimalsAsXLSX(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerExportAnimalsAsXLSX(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get account data for the currently logged in user.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerGetCurrentAccount(options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerGetCurrentAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsControllerUploadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerUploadDocument(requestParameters: AccountsApiAccountsControllerUploadDocumentRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerUploadDocument(requestParameters.accountId, requestParameters.uploadAccountDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsControllerUploadProfilePhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsControllerUploadProfilePhoto(requestParameters: AccountsApiAccountsControllerUploadProfilePhotoRequest, options?: AxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsControllerUploadProfilePhoto(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdoptersApi - axios parameter creator
 * @export
 */
export const AdoptersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add original document to adoption attempt.
         * @summary 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerAddOriginalDocument: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adoptersControllerAddOriginalDocument', 'type', type)
            const localVarPath = `/adopters/{adopterId}/adoption-attempts/{adoptionAttemptId}/original-document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add signed certificate to adoption attempt.
         * @summary 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerAddSignedCertificate: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adoptersControllerAddSignedCertificate', 'type', type)
            const localVarPath = `/adopters/{adopterId}/adoption-attempts/{adoptionAttemptId}/signed-certificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create adopter.
         * @summary 
         * @param {CreateAdopterBodyDto} createAdopterBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerCreateAdopter: async (createAdopterBodyDto: CreateAdopterBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdopterBodyDto' is not null or undefined
            assertParamExists('adoptersControllerCreateAdopter', 'createAdopterBodyDto', createAdopterBodyDto)
            const localVarPath = `/adopters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdopterBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete adopter.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit adopter.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerEditAccount: async (adopterDto: AdopterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adopterDto' is not null or undefined
            assertParamExists('adoptersControllerEditAccount', 'adopterDto', adopterDto)
            const localVarPath = `/adopters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adopterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate adoption certificate
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionCertificate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters/generate/certificate/{animalId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate adoption certificate
         * @summary 
         * @param {string} adopterId 
         * @param {string} templateId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionDocFromPDFTemplate: async (adopterId: string, templateId: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adopterId' is not null or undefined
            assertParamExists('adoptersControllerGenerateAdoptionDocFromPDFTemplate', 'adopterId', adopterId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('adoptersControllerGenerateAdoptionDocFromPDFTemplate', 'templateId', templateId)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('adoptersControllerGenerateAdoptionDocFromPDFTemplate', 'animalId', animalId)
            const localVarPath = `/adopters/generate/template/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adopterId !== undefined) {
                localVarQueryParameter['adopterId'] = adopterId;
            }

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate adoption certificate with dummy infos
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters/generate/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate adoption certificate with dummy infos
         * @summary 
         * @param {string} templateKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey: async (templateKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateKey' is not null or undefined
            assertParamExists('adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey', 'templateKey', templateKey)
            const localVarPath = `/adopters/generate/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (templateKey !== undefined) {
                localVarQueryParameter['templateKey'] = templateKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer adopters.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetAllAdopters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create new adoption attempt
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerNewAdoptionAttempt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters/{adopterId}/adoption-attempts/{animalId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all adopters for retool dashboard
         * @summary 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerRetool: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('adoptersControllerRetool', 'key', key)
            const localVarPath = `/adopters/retool`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send form to adopter by email.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerSendForm: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters/{adopterId}/send-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Automatically go next step on adoption attempt.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerToNextStepAdoptionAttempt: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters/{adopterId}/adoption-attempts/{adoptionAttemptId}/next-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update adoption attempt
         * @summary 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttempt: async (adoptionAttemptDto: AdoptionAttemptDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adoptionAttemptDto' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttempt', 'adoptionAttemptDto', adoptionAttemptDto)
            const localVarPath = `/adopters/{adopterId}/adoption-attempts/{adoptionAttemptId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adoptionAttemptDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadAdoptionAttemptDocumentDto} uploadAdoptionAttemptDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUploadAdoptionAttemptDocument: async (uploadAdoptionAttemptDocumentDto: UploadAdoptionAttemptDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadAdoptionAttemptDocumentDto' is not null or undefined
            assertParamExists('adoptersControllerUploadAdoptionAttemptDocument', 'uploadAdoptionAttemptDocumentDto', uploadAdoptionAttemptDocumentDto)
            const localVarPath = `/adopters/{adopterId}/adoption-attempts/{adoptionAttemptId}/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAdoptionAttemptDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdoptersApi - functional programming interface
 * @export
 */
export const AdoptersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdoptersApiAxiosParamCreator(configuration)
    return {
        /**
         * Add original document to adoption attempt.
         * @summary 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerAddOriginalDocument(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerAddOriginalDocument(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add signed certificate to adoption attempt.
         * @summary 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerAddSignedCertificate(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerAddSignedCertificate(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create adopter.
         * @summary 
         * @param {CreateAdopterBodyDto} createAdopterBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerCreateAdopter(createAdopterBodyDto: CreateAdopterBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerCreateAdopter(createAdopterBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete adopter.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit adopter.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerEditAccount(adopterDto: AdopterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerEditAccount(adopterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate adoption certificate
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGenerateAdoptionCertificate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGenerateAdoptionCertificate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate adoption certificate
         * @summary 
         * @param {string} adopterId 
         * @param {string} templateId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGenerateAdoptionDocFromPDFTemplate(adopterId: string, templateId: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGenerateAdoptionDocFromPDFTemplate(adopterId, templateId, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate adoption certificate with dummy infos
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate adoption certificate with dummy infos
         * @summary 
         * @param {string} templateKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey(templateKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey(templateKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all customer adopters.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGetAllAdopters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGetAllAdopters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create new adoption attempt
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerNewAdoptionAttempt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerNewAdoptionAttempt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all adopters for retool dashboard
         * @summary 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerRetool(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerRetool(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send form to adopter by email.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerSendForm(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerSendForm(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Automatically go next step on adoption attempt.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerToNextStepAdoptionAttempt(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerToNextStepAdoptionAttempt(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update adoption attempt
         * @summary 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUpdateAdoptionAttempt(adoptionAttemptDto: AdoptionAttemptDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUpdateAdoptionAttempt(adoptionAttemptDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadAdoptionAttemptDocumentDto} uploadAdoptionAttemptDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUploadAdoptionAttemptDocument(uploadAdoptionAttemptDocumentDto: UploadAdoptionAttemptDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUploadAdoptionAttemptDocument(uploadAdoptionAttemptDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdoptersApi - factory interface
 * @export
 */
export const AdoptersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdoptersApiFp(configuration)
    return {
        /**
         * Add original document to adoption attempt.
         * @summary 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerAddOriginalDocument(type: string, options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerAddOriginalDocument(type, options).then((request) => request(axios, basePath));
        },
        /**
         * Add signed certificate to adoption attempt.
         * @summary 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerAddSignedCertificate(type: string, options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerAddSignedCertificate(type, options).then((request) => request(axios, basePath));
        },
        /**
         * Create adopter.
         * @summary 
         * @param {CreateAdopterBodyDto} createAdopterBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerCreateAdopter(createAdopterBodyDto: CreateAdopterBodyDto, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerCreateAdopter(createAdopterBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete adopter.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerDelete(options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Edit adopter.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerEditAccount(adopterDto: AdopterDto, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerEditAccount(adopterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate adoption certificate
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionCertificate(options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerGenerateAdoptionCertificate(options).then((request) => request(axios, basePath));
        },
        /**
         * Generate adoption certificate
         * @summary 
         * @param {string} adopterId 
         * @param {string} templateId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionDocFromPDFTemplate(adopterId: string, templateId: string, animalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerGenerateAdoptionDocFromPDFTemplate(adopterId, templateId, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate adoption certificate with dummy infos
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile(options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile(options).then((request) => request(axios, basePath));
        },
        /**
         * Generate adoption certificate with dummy infos
         * @summary 
         * @param {string} templateKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey(templateKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey(templateKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer adopters.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetAllAdopters(options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerGetAllAdopters(options).then((request) => request(axios, basePath));
        },
        /**
         * Create new adoption attempt
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerNewAdoptionAttempt(options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerNewAdoptionAttempt(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all adopters for retool dashboard
         * @summary 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerRetool(key: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.adoptersControllerRetool(key, options).then((request) => request(axios, basePath));
        },
        /**
         * Send form to adopter by email.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerSendForm(options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerSendForm(options).then((request) => request(axios, basePath));
        },
        /**
         * Automatically go next step on adoption attempt.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerToNextStepAdoptionAttempt(options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerToNextStepAdoptionAttempt(options).then((request) => request(axios, basePath));
        },
        /**
         * Update adoption attempt
         * @summary 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttempt(adoptionAttemptDto: AdoptionAttemptDto, options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerUpdateAdoptionAttempt(adoptionAttemptDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadAdoptionAttemptDocumentDto} uploadAdoptionAttemptDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUploadAdoptionAttemptDocument(uploadAdoptionAttemptDocumentDto: UploadAdoptionAttemptDocumentDto, options?: any): AxiosPromise<string> {
            return localVarFp.adoptersControllerUploadAdoptionAttemptDocument(uploadAdoptionAttemptDocumentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adoptersControllerAddOriginalDocument operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerAddOriginalDocumentRequest
 */
export interface AdoptersApiAdoptersControllerAddOriginalDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerAddOriginalDocument
     */
    readonly type: string
}

/**
 * Request parameters for adoptersControllerAddSignedCertificate operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerAddSignedCertificateRequest
 */
export interface AdoptersApiAdoptersControllerAddSignedCertificateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerAddSignedCertificate
     */
    readonly type: string
}

/**
 * Request parameters for adoptersControllerCreateAdopter operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerCreateAdopterRequest
 */
export interface AdoptersApiAdoptersControllerCreateAdopterRequest {
    /**
     * 
     * @type {CreateAdopterBodyDto}
     * @memberof AdoptersApiAdoptersControllerCreateAdopter
     */
    readonly createAdopterBodyDto: CreateAdopterBodyDto
}

/**
 * Request parameters for adoptersControllerEditAccount operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerEditAccountRequest
 */
export interface AdoptersApiAdoptersControllerEditAccountRequest {
    /**
     * 
     * @type {AdopterDto}
     * @memberof AdoptersApiAdoptersControllerEditAccount
     */
    readonly adopterDto: AdopterDto
}

/**
 * Request parameters for adoptersControllerGenerateAdoptionDocFromPDFTemplate operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplateRequest
 */
export interface AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplate
     */
    readonly adopterId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplate
     */
    readonly templateId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplate
     */
    readonly animalId: string
}

/**
 * Request parameters for adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKeyRequest
 */
export interface AdoptersApiAdoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey
     */
    readonly templateKey: string
}

/**
 * Request parameters for adoptersControllerRetool operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerRetoolRequest
 */
export interface AdoptersApiAdoptersControllerRetoolRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersApiAdoptersControllerRetool
     */
    readonly key: string
}

/**
 * Request parameters for adoptersControllerUpdateAdoptionAttempt operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerUpdateAdoptionAttemptRequest
 */
export interface AdoptersApiAdoptersControllerUpdateAdoptionAttemptRequest {
    /**
     * 
     * @type {AdoptionAttemptDto}
     * @memberof AdoptersApiAdoptersControllerUpdateAdoptionAttempt
     */
    readonly adoptionAttemptDto: AdoptionAttemptDto
}

/**
 * Request parameters for adoptersControllerUploadAdoptionAttemptDocument operation in AdoptersApi.
 * @export
 * @interface AdoptersApiAdoptersControllerUploadAdoptionAttemptDocumentRequest
 */
export interface AdoptersApiAdoptersControllerUploadAdoptionAttemptDocumentRequest {
    /**
     * 
     * @type {UploadAdoptionAttemptDocumentDto}
     * @memberof AdoptersApiAdoptersControllerUploadAdoptionAttemptDocument
     */
    readonly uploadAdoptionAttemptDocumentDto: UploadAdoptionAttemptDocumentDto
}

/**
 * AdoptersApi - object-oriented interface
 * @export
 * @class AdoptersApi
 * @extends {BaseAPI}
 */
export class AdoptersApi extends BaseAPI {
    /**
     * Add original document to adoption attempt.
     * @summary 
     * @param {AdoptersApiAdoptersControllerAddOriginalDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerAddOriginalDocument(requestParameters: AdoptersApiAdoptersControllerAddOriginalDocumentRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerAddOriginalDocument(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add signed certificate to adoption attempt.
     * @summary 
     * @param {AdoptersApiAdoptersControllerAddSignedCertificateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerAddSignedCertificate(requestParameters: AdoptersApiAdoptersControllerAddSignedCertificateRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerAddSignedCertificate(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create adopter.
     * @summary 
     * @param {AdoptersApiAdoptersControllerCreateAdopterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerCreateAdopter(requestParameters: AdoptersApiAdoptersControllerCreateAdopterRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerCreateAdopter(requestParameters.createAdopterBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete adopter.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerDelete(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit adopter.
     * @summary 
     * @param {AdoptersApiAdoptersControllerEditAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerEditAccount(requestParameters: AdoptersApiAdoptersControllerEditAccountRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerEditAccount(requestParameters.adopterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate adoption certificate
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerGenerateAdoptionCertificate(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerGenerateAdoptionCertificate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate adoption certificate
     * @summary 
     * @param {AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerGenerateAdoptionDocFromPDFTemplate(requestParameters: AdoptersApiAdoptersControllerGenerateAdoptionDocFromPDFTemplateRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerGenerateAdoptionDocFromPDFTemplate(requestParameters.adopterId, requestParameters.templateId, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate adoption certificate with dummy infos
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate adoption certificate with dummy infos
     * @summary 
     * @param {AdoptersApiAdoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey(requestParameters: AdoptersApiAdoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKeyRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerGenerateAdoptionDocFromTemplateWithDummyInfosFromKey(requestParameters.templateKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer adopters.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerGetAllAdopters(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerGetAllAdopters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create new adoption attempt
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerNewAdoptionAttempt(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerNewAdoptionAttempt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all adopters for retool dashboard
     * @summary 
     * @param {AdoptersApiAdoptersControllerRetoolRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerRetool(requestParameters: AdoptersApiAdoptersControllerRetoolRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerRetool(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send form to adopter by email.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerSendForm(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerSendForm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Automatically go next step on adoption attempt.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerToNextStepAdoptionAttempt(options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerToNextStepAdoptionAttempt(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update adoption attempt
     * @summary 
     * @param {AdoptersApiAdoptersControllerUpdateAdoptionAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerUpdateAdoptionAttempt(requestParameters: AdoptersApiAdoptersControllerUpdateAdoptionAttemptRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerUpdateAdoptionAttempt(requestParameters.adoptionAttemptDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersApiAdoptersControllerUploadAdoptionAttemptDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersApi
     */
    public adoptersControllerUploadAdoptionAttemptDocument(requestParameters: AdoptersApiAdoptersControllerUploadAdoptionAttemptDocumentRequest, options?: AxiosRequestConfig) {
        return AdoptersApiFp(this.configuration).adoptersControllerUploadAdoptionAttemptDocument(requestParameters.uploadAdoptionAttemptDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdoptersPlatformApi - axios parameter creator
 * @export
 */
export const AdoptersPlatformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} animalProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerCheckIfBeRealExistsToday: async (animalProfileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalProfileId' is not null or undefined
            assertParamExists('beRealsControllerCheckIfBeRealExistsToday', 'animalProfileId', animalProfileId)
            const localVarPath = `/adopters-platform/bereals/check/{animalProfileId}`
                .replace(`{${"animalProfileId"}}`, encodeURIComponent(String(animalProfileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerCheckUserHasPostedBeRealToday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/bereals/check-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBeRealDto} createBeRealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerCreateBeReal: async (createBeRealDto: CreateBeRealDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBeRealDto' is not null or undefined
            assertParamExists('beRealsControllerCreateBeReal', 'createBeRealDto', createBeRealDto)
            const localVarPath = `/adopters-platform/bereals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBeRealDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerFindAllForToday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/bereals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} beRealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerToggleLike: async (beRealId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'beRealId' is not null or undefined
            assertParamExists('beRealsControllerToggleLike', 'beRealId', beRealId)
            const localVarPath = `/adopters-platform/bereals/{beRealId}/like`
                .replace(`{${"beRealId"}}`, encodeURIComponent(String(beRealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerGetMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/messages/{conversationId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerMarkMessageAsRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/messages/read/{messageId}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateChatMessageDto} createChatMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerSendMessage: async (createChatMessageDto: CreateChatMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChatMessageDto' is not null or undefined
            assertParamExists('chatMessagesControllerSendMessage', 'createChatMessageDto', createChatMessageDto)
            const localVarPath = `/adopters-platform/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChatMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerTestNotification: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/messages/test-notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerCreateConversation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerFindAllByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/conversations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerFindAllUnreadByUserId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/unread-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} page 
         * @param {string} limit 
         * @param {string} coordinates 
         * @param {string} type 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceLocationsControllerGetAllServiceLocations: async (page: string, limit: string, coordinates: string, type: string, query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('serviceLocationsControllerGetAllServiceLocations', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('serviceLocationsControllerGetAllServiceLocations', 'limit', limit)
            // verify required parameter 'coordinates' is not null or undefined
            assertParamExists('serviceLocationsControllerGetAllServiceLocations', 'coordinates', coordinates)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('serviceLocationsControllerGetAllServiceLocations', 'type', type)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('serviceLocationsControllerGetAllServiceLocations', 'query', query)
            const localVarPath = `/adopters-platform/service-locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (coordinates !== undefined) {
                localVarQueryParameter['coordinates'] = coordinates;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceLocationsControllerGetOneServiceLocationsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceLocationsControllerGetOneServiceLocationsById', 'id', id)
            const localVarPath = `/adopters-platform/service-locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceProvidersControllerGetOneServiceProvidersById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceProvidersControllerGetOneServiceProvidersById', 'id', id)
            const localVarPath = `/adopters-platform/service-providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdoptersPlatformApi - functional programming interface
 * @export
 */
export const AdoptersPlatformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdoptersPlatformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} animalProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beRealsControllerCheckIfBeRealExistsToday(animalProfileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beRealsControllerCheckIfBeRealExistsToday(animalProfileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beRealsControllerCheckUserHasPostedBeRealToday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beRealsControllerCheckUserHasPostedBeRealToday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBeRealDto} createBeRealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beRealsControllerCreateBeReal(createBeRealDto: CreateBeRealDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeRealDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beRealsControllerCreateBeReal(createBeRealDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beRealsControllerFindAllForToday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimplifiedBeRealDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beRealsControllerFindAllForToday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} beRealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beRealsControllerToggleLike(beRealId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beRealsControllerToggleLike(beRealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatMessagesControllerGetMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChatMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatMessagesControllerGetMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatMessagesControllerMarkMessageAsRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatMessagesControllerMarkMessageAsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateChatMessageDto} createChatMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatMessagesControllerSendMessage(createChatMessageDto: CreateChatMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatMessagesControllerSendMessage(createChatMessageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatMessagesControllerTestNotification(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatMessagesControllerTestNotification(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerCreateConversation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConversationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerCreateConversation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerFindAllByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerFindAllByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversationsControllerFindAllUnreadByUserId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConversationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversationsControllerFindAllUnreadByUserId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} page 
         * @param {string} limit 
         * @param {string} coordinates 
         * @param {string} type 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceLocationsControllerGetAllServiceLocations(page: string, limit: string, coordinates: string, type: string, query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceLocationsControllerGetAllServiceLocations(page, limit, coordinates, type, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceLocationsControllerGetOneServiceLocationsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceLocationsControllerGetOneServiceLocationsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceProvidersControllerGetOneServiceProvidersById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceProvidersControllerGetOneServiceProvidersById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdoptersPlatformApi - factory interface
 * @export
 */
export const AdoptersPlatformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdoptersPlatformApiFp(configuration)
    return {
        /**
         * 
         * @param {string} animalProfileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerCheckIfBeRealExistsToday(animalProfileId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.beRealsControllerCheckIfBeRealExistsToday(animalProfileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerCheckUserHasPostedBeRealToday(options?: any): AxiosPromise<boolean> {
            return localVarFp.beRealsControllerCheckUserHasPostedBeRealToday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBeRealDto} createBeRealDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerCreateBeReal(createBeRealDto: CreateBeRealDto, options?: any): AxiosPromise<BeRealDto> {
            return localVarFp.beRealsControllerCreateBeReal(createBeRealDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerFindAllForToday(options?: any): AxiosPromise<Array<SimplifiedBeRealDto>> {
            return localVarFp.beRealsControllerFindAllForToday(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} beRealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beRealsControllerToggleLike(beRealId: string, options?: any): AxiosPromise<void> {
            return localVarFp.beRealsControllerToggleLike(beRealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerGetMessages(options?: any): AxiosPromise<Array<ChatMessageDto>> {
            return localVarFp.chatMessagesControllerGetMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerMarkMessageAsRead(options?: any): AxiosPromise<void> {
            return localVarFp.chatMessagesControllerMarkMessageAsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateChatMessageDto} createChatMessageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerSendMessage(createChatMessageDto: CreateChatMessageDto, options?: any): AxiosPromise<ChatMessageDto> {
            return localVarFp.chatMessagesControllerSendMessage(createChatMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatMessagesControllerTestNotification(options?: any): AxiosPromise<void> {
            return localVarFp.chatMessagesControllerTestNotification(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerCreateConversation(options?: any): AxiosPromise<ConversationDto> {
            return localVarFp.conversationsControllerCreateConversation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerFindAllByUserId(options?: any): AxiosPromise<Array<ConversationDto>> {
            return localVarFp.conversationsControllerFindAllByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversationsControllerFindAllUnreadByUserId(options?: any): AxiosPromise<Array<ConversationDto>> {
            return localVarFp.conversationsControllerFindAllUnreadByUserId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} page 
         * @param {string} limit 
         * @param {string} coordinates 
         * @param {string} type 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceLocationsControllerGetAllServiceLocations(page: string, limit: string, coordinates: string, type: string, query: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceLocationsControllerGetAllServiceLocations(page, limit, coordinates, type, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceLocationsControllerGetOneServiceLocationsById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceLocationsControllerGetOneServiceLocationsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceProvidersControllerGetOneServiceProvidersById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceProvidersControllerGetOneServiceProvidersById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for beRealsControllerCheckIfBeRealExistsToday operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiBeRealsControllerCheckIfBeRealExistsTodayRequest
 */
export interface AdoptersPlatformApiBeRealsControllerCheckIfBeRealExistsTodayRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiBeRealsControllerCheckIfBeRealExistsToday
     */
    readonly animalProfileId: string
}

/**
 * Request parameters for beRealsControllerCreateBeReal operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiBeRealsControllerCreateBeRealRequest
 */
export interface AdoptersPlatformApiBeRealsControllerCreateBeRealRequest {
    /**
     * 
     * @type {CreateBeRealDto}
     * @memberof AdoptersPlatformApiBeRealsControllerCreateBeReal
     */
    readonly createBeRealDto: CreateBeRealDto
}

/**
 * Request parameters for beRealsControllerToggleLike operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiBeRealsControllerToggleLikeRequest
 */
export interface AdoptersPlatformApiBeRealsControllerToggleLikeRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiBeRealsControllerToggleLike
     */
    readonly beRealId: string
}

/**
 * Request parameters for chatMessagesControllerSendMessage operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiChatMessagesControllerSendMessageRequest
 */
export interface AdoptersPlatformApiChatMessagesControllerSendMessageRequest {
    /**
     * 
     * @type {CreateChatMessageDto}
     * @memberof AdoptersPlatformApiChatMessagesControllerSendMessage
     */
    readonly createChatMessageDto: CreateChatMessageDto
}

/**
 * Request parameters for serviceLocationsControllerGetAllServiceLocations operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocationsRequest
 */
export interface AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocationsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocations
     */
    readonly page: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocations
     */
    readonly limit: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocations
     */
    readonly coordinates: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocations
     */
    readonly type: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocations
     */
    readonly query: string
}

/**
 * Request parameters for serviceLocationsControllerGetOneServiceLocationsById operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiServiceLocationsControllerGetOneServiceLocationsByIdRequest
 */
export interface AdoptersPlatformApiServiceLocationsControllerGetOneServiceLocationsByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceLocationsControllerGetOneServiceLocationsById
     */
    readonly id: string
}

/**
 * Request parameters for serviceProvidersControllerGetOneServiceProvidersById operation in AdoptersPlatformApi.
 * @export
 * @interface AdoptersPlatformApiServiceProvidersControllerGetOneServiceProvidersByIdRequest
 */
export interface AdoptersPlatformApiServiceProvidersControllerGetOneServiceProvidersByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformApiServiceProvidersControllerGetOneServiceProvidersById
     */
    readonly id: string
}

/**
 * AdoptersPlatformApi - object-oriented interface
 * @export
 * @class AdoptersPlatformApi
 * @extends {BaseAPI}
 */
export class AdoptersPlatformApi extends BaseAPI {
    /**
     * 
     * @param {AdoptersPlatformApiBeRealsControllerCheckIfBeRealExistsTodayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public beRealsControllerCheckIfBeRealExistsToday(requestParameters: AdoptersPlatformApiBeRealsControllerCheckIfBeRealExistsTodayRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).beRealsControllerCheckIfBeRealExistsToday(requestParameters.animalProfileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public beRealsControllerCheckUserHasPostedBeRealToday(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).beRealsControllerCheckUserHasPostedBeRealToday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformApiBeRealsControllerCreateBeRealRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public beRealsControllerCreateBeReal(requestParameters: AdoptersPlatformApiBeRealsControllerCreateBeRealRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).beRealsControllerCreateBeReal(requestParameters.createBeRealDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public beRealsControllerFindAllForToday(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).beRealsControllerFindAllForToday(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformApiBeRealsControllerToggleLikeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public beRealsControllerToggleLike(requestParameters: AdoptersPlatformApiBeRealsControllerToggleLikeRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).beRealsControllerToggleLike(requestParameters.beRealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public chatMessagesControllerGetMessages(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).chatMessagesControllerGetMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public chatMessagesControllerMarkMessageAsRead(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).chatMessagesControllerMarkMessageAsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformApiChatMessagesControllerSendMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public chatMessagesControllerSendMessage(requestParameters: AdoptersPlatformApiChatMessagesControllerSendMessageRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).chatMessagesControllerSendMessage(requestParameters.createChatMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public chatMessagesControllerTestNotification(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).chatMessagesControllerTestNotification(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public conversationsControllerCreateConversation(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).conversationsControllerCreateConversation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public conversationsControllerFindAllByUserId(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).conversationsControllerFindAllByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public conversationsControllerFindAllUnreadByUserId(options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).conversationsControllerFindAllUnreadByUserId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public serviceLocationsControllerGetAllServiceLocations(requestParameters: AdoptersPlatformApiServiceLocationsControllerGetAllServiceLocationsRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).serviceLocationsControllerGetAllServiceLocations(requestParameters.page, requestParameters.limit, requestParameters.coordinates, requestParameters.type, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformApiServiceLocationsControllerGetOneServiceLocationsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public serviceLocationsControllerGetOneServiceLocationsById(requestParameters: AdoptersPlatformApiServiceLocationsControllerGetOneServiceLocationsByIdRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).serviceLocationsControllerGetOneServiceLocationsById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformApiServiceProvidersControllerGetOneServiceProvidersByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformApi
     */
    public serviceProvidersControllerGetOneServiceProvidersById(requestParameters: AdoptersPlatformApiServiceProvidersControllerGetOneServiceProvidersByIdRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformApiFp(this.configuration).serviceProvidersControllerGetOneServiceProvidersById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdoptersPlatformAccountsApi - axios parameter creator
 * @export
 */
export const AdoptersPlatformAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Edit adopter.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerEditAdopter: async (adopterDto: AdopterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adopterDto' is not null or undefined
            assertParamExists('adoptersControllerEditAdopter', 'adopterDto', adopterDto)
            const localVarPath = `/adopters-platform/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adopterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit adopter\'s adoption form.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerEditAdoptionForm: async (adopterDto: AdopterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adopterDto' is not null or undefined
            assertParamExists('adoptersControllerEditAdoptionForm', 'adopterDto', adopterDto)
            const localVarPath = `/adopters-platform/accounts/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adopterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all adopters with their animals
         * @summary 
         * @param {string} page 
         * @param {string} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetAllAdoptersWithAnimals: async (page: string, limit: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('adoptersControllerGetAllAdoptersWithAnimals', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('adoptersControllerGetAllAdoptersWithAnimals', 'limit', limit)
            const localVarPath = `/adopters-platform/accounts/with-animals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get account data for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetCurrentAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/accounts/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get DocuSeal slug for adoption document
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetDocuSealSlug: async (attemptId: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('adoptersControllerGetDocuSealSlug', 'attemptId', attemptId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adoptersControllerGetDocuSealSlug', 'type', type)
            const localVarPath = `/adopters-platform/accounts/adoption-attempts/docuseal/{attemptId}`
                .replace(`{${"attemptId"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an email to request food sample
         * @summary 
         * @param {string} attemptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerRequestFoodSample: async (attemptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('adoptersControllerRequestFoodSample', 'attemptId', attemptId)
            const localVarPath = `/adopters-platform/accounts/food-sample/{attemptId}`
                .replace(`{${"attemptId"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send an email to request insurance estimation
         * @summary 
         * @param {InsuranceEstimationBody} insuranceEstimationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerRequestInsuranceEstimation: async (insuranceEstimationBody: InsuranceEstimationBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuranceEstimationBody' is not null or undefined
            assertParamExists('adoptersControllerRequestInsuranceEstimation', 'insuranceEstimationBody', insuranceEstimationBody)
            const localVarPath = `/adopters-platform/accounts/insurance-estimation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insuranceEstimationBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get insurance estimate for specific animal
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerSendInsuranceEstimate: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('adoptersControllerSendInsuranceEstimate', 'animalId', animalId)
            const localVarPath = `/adopters-platform/accounts/insurance-estimate/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerSubscribe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/accounts/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tutorial step on an existing adopter
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdopterTutorialStep: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/accounts/tutorial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttempt: async (attemptId: string, type: string, adoptionAttemptDto: AdoptionAttemptDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttempt', 'attemptId', attemptId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttempt', 'type', type)
            // verify required parameter 'adoptionAttemptDto' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttempt', 'adoptionAttemptDto', adoptionAttemptDto)
            const localVarPath = `/adopters-platform/accounts/adoption-attempts/{attemptId}`
                .replace(`{${"attemptId"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adoptionAttemptDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update dummy infos on an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttemptDummyInfos: async (attemptId: string, adoptionAttemptDto: AdoptionAttemptDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttemptDummyInfos', 'attemptId', attemptId)
            // verify required parameter 'adoptionAttemptDto' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttemptDummyInfos', 'adoptionAttemptDto', adoptionAttemptDto)
            const localVarPath = `/adopters-platform/accounts/adoption-attempts/dummy/{attemptId}`
                .replace(`{${"attemptId"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adoptionAttemptDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update tutorial step on an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttemptTutorialStep: async (attemptId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('adoptersControllerUpdateAdoptionAttemptTutorialStep', 'attemptId', attemptId)
            const localVarPath = `/adopters-platform/accounts/adoption-attempts/tutorial/{attemptId}`
                .replace(`{${"attemptId"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update FCM Tokens for adopter.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateFcmTokens: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/accounts/fcm-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get DocuSeal slug for adoption document
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUploadSignedDocumentFromSubmission: async (attemptId: string, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attemptId' is not null or undefined
            assertParamExists('adoptersControllerUploadSignedDocumentFromSubmission', 'attemptId', attemptId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('adoptersControllerUploadSignedDocumentFromSubmission', 'type', type)
            const localVarPath = `/adopters-platform/accounts/adoption-attempts/docuseal/{attemptId}`
                .replace(`{${"attemptId"}}`, encodeURIComponent(String(attemptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdoptersPlatformAccountsApi - functional programming interface
 * @export
 */
export const AdoptersPlatformAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdoptersPlatformAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Edit adopter.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerEditAdopter(adopterDto: AdopterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerEditAdopter(adopterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit adopter\'s adoption form.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerEditAdoptionForm(adopterDto: AdopterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerEditAdoptionForm(adopterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all adopters with their animals
         * @summary 
         * @param {string} page 
         * @param {string} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGetAllAdoptersWithAnimals(page: string, limit: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGetAllAdoptersWithAnimals(page, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get account data for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGetCurrentAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGetCurrentAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get DocuSeal slug for adoption document
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerGetDocuSealSlug(attemptId: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerGetDocuSealSlug(attemptId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an email to request food sample
         * @summary 
         * @param {string} attemptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerRequestFoodSample(attemptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerRequestFoodSample(attemptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send an email to request insurance estimation
         * @summary 
         * @param {InsuranceEstimationBody} insuranceEstimationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerRequestInsuranceEstimation(insuranceEstimationBody: InsuranceEstimationBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerRequestInsuranceEstimation(insuranceEstimationBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get insurance estimate for specific animal
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerSendInsuranceEstimate(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerSendInsuranceEstimate(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerSubscribe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerSubscribe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update tutorial step on an existing adopter
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUpdateAdopterTutorialStep(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUpdateAdopterTutorialStep(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUpdateAdoptionAttempt(attemptId: string, type: string, adoptionAttemptDto: AdoptionAttemptDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUpdateAdoptionAttempt(attemptId, type, adoptionAttemptDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update dummy infos on an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUpdateAdoptionAttemptDummyInfos(attemptId: string, adoptionAttemptDto: AdoptionAttemptDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUpdateAdoptionAttemptDummyInfos(attemptId, adoptionAttemptDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update tutorial step on an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUpdateAdoptionAttemptTutorialStep(attemptId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdoptionAttemptDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUpdateAdoptionAttemptTutorialStep(attemptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update FCM Tokens for adopter.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUpdateFcmTokens(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUpdateFcmTokens(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get DocuSeal slug for adoption document
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adoptersControllerUploadSignedDocumentFromSubmission(attemptId: string, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adoptersControllerUploadSignedDocumentFromSubmission(attemptId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdoptersPlatformAccountsApi - factory interface
 * @export
 */
export const AdoptersPlatformAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdoptersPlatformAccountsApiFp(configuration)
    return {
        /**
         * Edit adopter.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerEditAdopter(adopterDto: AdopterDto, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerEditAdopter(adopterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit adopter\'s adoption form.
         * @summary 
         * @param {AdopterDto} adopterDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerEditAdoptionForm(adopterDto: AdopterDto, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerEditAdoptionForm(adopterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all adopters with their animals
         * @summary 
         * @param {string} page 
         * @param {string} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetAllAdoptersWithAnimals(page: string, limit: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerGetAllAdoptersWithAnimals(page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get account data for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetCurrentAccount(options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerGetCurrentAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * Get DocuSeal slug for adoption document
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerGetDocuSealSlug(attemptId: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerGetDocuSealSlug(attemptId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an email to request food sample
         * @summary 
         * @param {string} attemptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerRequestFoodSample(attemptId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerRequestFoodSample(attemptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Send an email to request insurance estimation
         * @summary 
         * @param {InsuranceEstimationBody} insuranceEstimationBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerRequestInsuranceEstimation(insuranceEstimationBody: InsuranceEstimationBody, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerRequestInsuranceEstimation(insuranceEstimationBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Get insurance estimate for specific animal
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerSendInsuranceEstimate(animalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerSendInsuranceEstimate(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerSubscribe(options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerSubscribe(options).then((request) => request(axios, basePath));
        },
        /**
         * Update tutorial step on an existing adopter
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdopterTutorialStep(options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.adoptersControllerUpdateAdopterTutorialStep(options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttempt(attemptId: string, type: string, adoptionAttemptDto: AdoptionAttemptDto, options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerUpdateAdoptionAttempt(attemptId, type, adoptionAttemptDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update dummy infos on an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {AdoptionAttemptDto} adoptionAttemptDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttemptDummyInfos(attemptId: string, adoptionAttemptDto: AdoptionAttemptDto, options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerUpdateAdoptionAttemptDummyInfos(attemptId, adoptionAttemptDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update tutorial step on an existing adoption attempt for the current user.
         * @summary 
         * @param {string} attemptId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateAdoptionAttemptTutorialStep(attemptId: string, options?: any): AxiosPromise<AdoptionAttemptDto> {
            return localVarFp.adoptersControllerUpdateAdoptionAttemptTutorialStep(attemptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update FCM Tokens for adopter.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUpdateFcmTokens(options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerUpdateFcmTokens(options).then((request) => request(axios, basePath));
        },
        /**
         * Get DocuSeal slug for adoption document
         * @summary 
         * @param {string} attemptId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adoptersControllerUploadSignedDocumentFromSubmission(attemptId: string, type: string, options?: any): AxiosPromise<void> {
            return localVarFp.adoptersControllerUploadSignedDocumentFromSubmission(attemptId, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adoptersControllerEditAdopter operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerEditAdopterRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerEditAdopterRequest {
    /**
     * 
     * @type {AdopterDto}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerEditAdopter
     */
    readonly adopterDto: AdopterDto
}

/**
 * Request parameters for adoptersControllerEditAdoptionForm operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerEditAdoptionFormRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerEditAdoptionFormRequest {
    /**
     * 
     * @type {AdopterDto}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerEditAdoptionForm
     */
    readonly adopterDto: AdopterDto
}

/**
 * Request parameters for adoptersControllerGetAllAdoptersWithAnimals operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerGetAllAdoptersWithAnimalsRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerGetAllAdoptersWithAnimalsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerGetAllAdoptersWithAnimals
     */
    readonly page: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerGetAllAdoptersWithAnimals
     */
    readonly limit: string
}

/**
 * Request parameters for adoptersControllerGetDocuSealSlug operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerGetDocuSealSlugRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerGetDocuSealSlugRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerGetDocuSealSlug
     */
    readonly attemptId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerGetDocuSealSlug
     */
    readonly type: string
}

/**
 * Request parameters for adoptersControllerRequestFoodSample operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerRequestFoodSampleRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerRequestFoodSampleRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerRequestFoodSample
     */
    readonly attemptId: string
}

/**
 * Request parameters for adoptersControllerRequestInsuranceEstimation operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerRequestInsuranceEstimationRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerRequestInsuranceEstimationRequest {
    /**
     * 
     * @type {InsuranceEstimationBody}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerRequestInsuranceEstimation
     */
    readonly insuranceEstimationBody: InsuranceEstimationBody
}

/**
 * Request parameters for adoptersControllerSendInsuranceEstimate operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerSendInsuranceEstimateRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerSendInsuranceEstimateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerSendInsuranceEstimate
     */
    readonly animalId: string
}

/**
 * Request parameters for adoptersControllerUpdateAdoptionAttempt operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttempt
     */
    readonly attemptId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttempt
     */
    readonly type: string

    /**
     * 
     * @type {AdoptionAttemptDto}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttempt
     */
    readonly adoptionAttemptDto: AdoptionAttemptDto
}

/**
 * Request parameters for adoptersControllerUpdateAdoptionAttemptDummyInfos operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptDummyInfosRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptDummyInfosRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptDummyInfos
     */
    readonly attemptId: string

    /**
     * 
     * @type {AdoptionAttemptDto}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptDummyInfos
     */
    readonly adoptionAttemptDto: AdoptionAttemptDto
}

/**
 * Request parameters for adoptersControllerUpdateAdoptionAttemptTutorialStep operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptTutorialStepRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptTutorialStepRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptTutorialStep
     */
    readonly attemptId: string
}

/**
 * Request parameters for adoptersControllerUploadSignedDocumentFromSubmission operation in AdoptersPlatformAccountsApi.
 * @export
 * @interface AdoptersPlatformAccountsApiAdoptersControllerUploadSignedDocumentFromSubmissionRequest
 */
export interface AdoptersPlatformAccountsApiAdoptersControllerUploadSignedDocumentFromSubmissionRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUploadSignedDocumentFromSubmission
     */
    readonly attemptId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAccountsApiAdoptersControllerUploadSignedDocumentFromSubmission
     */
    readonly type: string
}

/**
 * AdoptersPlatformAccountsApi - object-oriented interface
 * @export
 * @class AdoptersPlatformAccountsApi
 * @extends {BaseAPI}
 */
export class AdoptersPlatformAccountsApi extends BaseAPI {
    /**
     * Edit adopter.
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerEditAdopterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerEditAdopter(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerEditAdopterRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerEditAdopter(requestParameters.adopterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit adopter\'s adoption form.
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerEditAdoptionFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerEditAdoptionForm(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerEditAdoptionFormRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerEditAdoptionForm(requestParameters.adopterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all adopters with their animals
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerGetAllAdoptersWithAnimalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerGetAllAdoptersWithAnimals(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerGetAllAdoptersWithAnimalsRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerGetAllAdoptersWithAnimals(requestParameters.page, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get account data for the currently logged in user.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerGetCurrentAccount(options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerGetCurrentAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get DocuSeal slug for adoption document
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerGetDocuSealSlugRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerGetDocuSealSlug(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerGetDocuSealSlugRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerGetDocuSealSlug(requestParameters.attemptId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an email to request food sample
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerRequestFoodSampleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerRequestFoodSample(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerRequestFoodSampleRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerRequestFoodSample(requestParameters.attemptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send an email to request insurance estimation
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerRequestInsuranceEstimationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerRequestInsuranceEstimation(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerRequestInsuranceEstimationRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerRequestInsuranceEstimation(requestParameters.insuranceEstimationBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get insurance estimate for specific animal
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerSendInsuranceEstimateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerSendInsuranceEstimate(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerSendInsuranceEstimateRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerSendInsuranceEstimate(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerSubscribe(options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerSubscribe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update tutorial step on an existing adopter
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerUpdateAdopterTutorialStep(options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerUpdateAdopterTutorialStep(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing adoption attempt for the current user.
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerUpdateAdoptionAttempt(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerUpdateAdoptionAttempt(requestParameters.attemptId, requestParameters.type, requestParameters.adoptionAttemptDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update dummy infos on an existing adoption attempt for the current user.
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptDummyInfosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerUpdateAdoptionAttemptDummyInfos(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptDummyInfosRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerUpdateAdoptionAttemptDummyInfos(requestParameters.attemptId, requestParameters.adoptionAttemptDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update tutorial step on an existing adoption attempt for the current user.
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptTutorialStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerUpdateAdoptionAttemptTutorialStep(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerUpdateAdoptionAttemptTutorialStepRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerUpdateAdoptionAttemptTutorialStep(requestParameters.attemptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update FCM Tokens for adopter.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerUpdateFcmTokens(options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerUpdateFcmTokens(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get DocuSeal slug for adoption document
     * @summary 
     * @param {AdoptersPlatformAccountsApiAdoptersControllerUploadSignedDocumentFromSubmissionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAccountsApi
     */
    public adoptersControllerUploadSignedDocumentFromSubmission(requestParameters: AdoptersPlatformAccountsApiAdoptersControllerUploadSignedDocumentFromSubmissionRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAccountsApiFp(this.configuration).adoptersControllerUploadSignedDocumentFromSubmission(requestParameters.attemptId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdoptersPlatformAnimalprofilesApi - axios parameter creator
 * @export
 */
export const AdoptersPlatformAnimalprofilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} observationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerAddCommentToObservation: async (observationId: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'observationId' is not null or undefined
            assertParamExists('animalProfilesControllerAddCommentToObservation', 'observationId', observationId)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerAddCommentToObservation', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/observations/{observationId}/add-comment`
                .replace(`{${"observationId"}}`, encodeURIComponent(String(observationId)))
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} operationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerAddCommentToOperation: async (operationId: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'operationId' is not null or undefined
            assertParamExists('animalProfilesControllerAddCommentToOperation', 'operationId', operationId)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerAddCommentToOperation', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/operations/{operationId}/add-comment`
                .replace(`{${"operationId"}}`, encodeURIComponent(String(operationId)))
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} observationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerAddFileToObservation: async (observationId: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'observationId' is not null or undefined
            assertParamExists('animalProfilesControllerAddFileToObservation', 'observationId', observationId)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerAddFileToObservation', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/observations/{observationId}/add-file`
                .replace(`{${"observationId"}}`, encodeURIComponent(String(observationId)))
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create animalprofile.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerCreateAnimal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/animalprofiles/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerDeleteDocument: async (documentId: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('animalProfilesControllerDeleteDocument', 'documentId', documentId)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerDeleteDocument', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit animalprofile.
         * @summary 
         * @param {AnimalProfileDto} animalProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerEditAnimal: async (animalProfileDto: AnimalProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalProfileDto' is not null or undefined
            assertParamExists('animalProfilesControllerEditAnimal', 'animalProfileDto', animalProfileDto)
            const localVarPath = `/adopters-platform/animalprofiles/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(animalProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all animalprofiles.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAllAnimalProfiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/animalprofiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animalprofile.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAnimal: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerGetAnimal', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animalprofile.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAnimalByMongoId: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerGetAnimalByMongoId', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/mongo/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animalprofile share link.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAnimalShareLink: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerGetAnimalShareLink', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/share-link/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerNewObservation: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerNewObservation', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/observations`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Transfer an Animal to AnimalProfile
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerTransferAnimalToAnimalProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/animalprofiles/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {UploadAnimalProfileDocumentDto} uploadAnimalProfileDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerUploadDocument: async (animalId: string, uploadAnimalProfileDocumentDto: UploadAnimalProfileDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerUploadDocument', 'animalId', animalId)
            // verify required parameter 'uploadAnimalProfileDocumentDto' is not null or undefined
            assertParamExists('animalProfilesControllerUploadDocument', 'uploadAnimalProfileDocumentDto', uploadAnimalProfileDocumentDto)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/documents`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAnimalProfileDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerUploadProfilePhoto: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalProfilesControllerUploadProfilePhoto', 'animalId', animalId)
            const localVarPath = `/adopters-platform/animalprofiles/{animalId}/upload-profile-photos`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdoptersPlatformAnimalprofilesApi - functional programming interface
 * @export
 */
export const AdoptersPlatformAnimalprofilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdoptersPlatformAnimalprofilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} observationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerAddCommentToObservation(observationId: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerAddCommentToObservation(observationId, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} operationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerAddCommentToOperation(operationId: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerAddCommentToOperation(operationId, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} observationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerAddFileToObservation(observationId: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalProfileHealthObservationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerAddFileToObservation(observationId, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create animalprofile.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerCreateAnimal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerCreateAnimal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerDeleteDocument(documentId: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerDeleteDocument(documentId, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit animalprofile.
         * @summary 
         * @param {AnimalProfileDto} animalProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerEditAnimal(animalProfileDto: AnimalProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerEditAnimal(animalProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all animalprofiles.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerGetAllAnimalProfiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerGetAllAnimalProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animalprofile.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerGetAnimal(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerGetAnimal(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animalprofile.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerGetAnimalByMongoId(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerGetAnimalByMongoId(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animalprofile share link.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerGetAnimalShareLink(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdopterDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerGetAnimalShareLink(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerNewObservation(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalProfileHealthObservationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerNewObservation(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Transfer an Animal to AnimalProfile
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerTransferAnimalToAnimalProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerTransferAnimalToAnimalProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {UploadAnimalProfileDocumentDto} uploadAnimalProfileDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerUploadDocument(animalId: string, uploadAnimalProfileDocumentDto: UploadAnimalProfileDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerUploadDocument(animalId, uploadAnimalProfileDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalProfilesControllerUploadProfilePhoto(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalProfilesControllerUploadProfilePhoto(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdoptersPlatformAnimalprofilesApi - factory interface
 * @export
 */
export const AdoptersPlatformAnimalprofilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdoptersPlatformAnimalprofilesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} observationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerAddCommentToObservation(observationId: string, animalId: string, options?: any): AxiosPromise<AnimalProfileDto> {
            return localVarFp.animalProfilesControllerAddCommentToObservation(observationId, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} operationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerAddCommentToOperation(operationId: string, animalId: string, options?: any): AxiosPromise<AnimalProfileDto> {
            return localVarFp.animalProfilesControllerAddCommentToOperation(operationId, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} observationId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerAddFileToObservation(observationId: string, animalId: string, options?: any): AxiosPromise<AnimalProfileHealthObservationsDto> {
            return localVarFp.animalProfilesControllerAddFileToObservation(observationId, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create animalprofile.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerCreateAnimal(options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.animalProfilesControllerCreateAnimal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerDeleteDocument(documentId: string, animalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalProfilesControllerDeleteDocument(documentId, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit animalprofile.
         * @summary 
         * @param {AnimalProfileDto} animalProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerEditAnimal(animalProfileDto: AnimalProfileDto, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.animalProfilesControllerEditAnimal(animalProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all animalprofiles.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAllAnimalProfiles(options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.animalProfilesControllerGetAllAnimalProfiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Get animalprofile.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAnimal(animalId: string, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.animalProfilesControllerGetAnimal(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animalprofile.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAnimalByMongoId(animalId: string, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.animalProfilesControllerGetAnimalByMongoId(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animalprofile share link.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerGetAnimalShareLink(animalId: string, options?: any): AxiosPromise<AdopterDto> {
            return localVarFp.animalProfilesControllerGetAnimalShareLink(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerNewObservation(animalId: string, options?: any): AxiosPromise<AnimalProfileHealthObservationsDto> {
            return localVarFp.animalProfilesControllerNewObservation(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Transfer an Animal to AnimalProfile
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerTransferAnimalToAnimalProfile(options?: any): AxiosPromise<AnimalProfileDto> {
            return localVarFp.animalProfilesControllerTransferAnimalToAnimalProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {UploadAnimalProfileDocumentDto} uploadAnimalProfileDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerUploadDocument(animalId: string, uploadAnimalProfileDocumentDto: UploadAnimalProfileDocumentDto, options?: any): AxiosPromise<void> {
            return localVarFp.animalProfilesControllerUploadDocument(animalId, uploadAnimalProfileDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalProfilesControllerUploadProfilePhoto(animalId: string, options?: any): AxiosPromise<string> {
            return localVarFp.animalProfilesControllerUploadProfilePhoto(animalId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for animalProfilesControllerAddCommentToObservation operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToObservationRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToObservationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToObservation
     */
    readonly observationId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToObservation
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerAddCommentToOperation operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToOperationRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToOperationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToOperation
     */
    readonly operationId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToOperation
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerAddFileToObservation operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddFileToObservationRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddFileToObservationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddFileToObservation
     */
    readonly observationId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddFileToObservation
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerDeleteDocument operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerDeleteDocumentRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerDeleteDocument
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerDeleteDocument
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerEditAnimal operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerEditAnimalRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerEditAnimalRequest {
    /**
     * 
     * @type {AnimalProfileDto}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerEditAnimal
     */
    readonly animalProfileDto: AnimalProfileDto
}

/**
 * Request parameters for animalProfilesControllerGetAnimal operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimal
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerGetAnimalByMongoId operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalByMongoIdRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalByMongoIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalByMongoId
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerGetAnimalShareLink operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalShareLinkRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalShareLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalShareLink
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerNewObservation operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerNewObservationRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerNewObservationRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerNewObservation
     */
    readonly animalId: string
}

/**
 * Request parameters for animalProfilesControllerUploadDocument operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadDocumentRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadDocument
     */
    readonly animalId: string

    /**
     * 
     * @type {UploadAnimalProfileDocumentDto}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadDocument
     */
    readonly uploadAnimalProfileDocumentDto: UploadAnimalProfileDocumentDto
}

/**
 * Request parameters for animalProfilesControllerUploadProfilePhoto operation in AdoptersPlatformAnimalprofilesApi.
 * @export
 * @interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadProfilePhotoRequest
 */
export interface AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadProfilePhotoRequest {
    /**
     * 
     * @type {string}
     * @memberof AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadProfilePhoto
     */
    readonly animalId: string
}

/**
 * AdoptersPlatformAnimalprofilesApi - object-oriented interface
 * @export
 * @class AdoptersPlatformAnimalprofilesApi
 * @extends {BaseAPI}
 */
export class AdoptersPlatformAnimalprofilesApi extends BaseAPI {
    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToObservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerAddCommentToObservation(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToObservationRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerAddCommentToObservation(requestParameters.observationId, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToOperationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerAddCommentToOperation(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddCommentToOperationRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerAddCommentToOperation(requestParameters.operationId, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddFileToObservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerAddFileToObservation(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerAddFileToObservationRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerAddFileToObservation(requestParameters.observationId, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create animalprofile.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerCreateAnimal(options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerCreateAnimal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerDeleteDocument(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerDeleteDocument(requestParameters.documentId, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit animalprofile.
     * @summary 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerEditAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerEditAnimal(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerEditAnimalRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerEditAnimal(requestParameters.animalProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all animalprofiles.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerGetAllAnimalProfiles(options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerGetAllAnimalProfiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animalprofile.
     * @summary 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerGetAnimal(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerGetAnimal(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animalprofile.
     * @summary 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalByMongoIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerGetAnimalByMongoId(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalByMongoIdRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerGetAnimalByMongoId(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animalprofile share link.
     * @summary 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalShareLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerGetAnimalShareLink(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerGetAnimalShareLinkRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerGetAnimalShareLink(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerNewObservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerNewObservation(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerNewObservationRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerNewObservation(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Transfer an Animal to AnimalProfile
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerTransferAnimalToAnimalProfile(options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerTransferAnimalToAnimalProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerUploadDocument(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadDocumentRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerUploadDocument(requestParameters.animalId, requestParameters.uploadAnimalProfileDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadProfilePhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformAnimalprofilesApi
     */
    public animalProfilesControllerUploadProfilePhoto(requestParameters: AdoptersPlatformAnimalprofilesApiAnimalProfilesControllerUploadProfilePhotoRequest, options?: AxiosRequestConfig) {
        return AdoptersPlatformAnimalprofilesApiFp(this.configuration).animalProfilesControllerUploadProfilePhoto(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdoptersPlatformPromocodeApi - axios parameter creator
 * @export
 */
export const AdoptersPlatformPromocodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodeControllerCheckPromoCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/adopters-platform/promocode/check/{code}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication adoptersBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdoptersPlatformPromocodeApi - functional programming interface
 * @export
 */
export const AdoptersPlatformPromocodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdoptersPlatformPromocodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoCodeControllerCheckPromoCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoCodeControllerCheckPromoCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdoptersPlatformPromocodeApi - factory interface
 * @export
 */
export const AdoptersPlatformPromocodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdoptersPlatformPromocodeApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoCodeControllerCheckPromoCode(options?: any): AxiosPromise<PromoCodeDto> {
            return localVarFp.promoCodeControllerCheckPromoCode(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdoptersPlatformPromocodeApi - object-oriented interface
 * @export
 * @class AdoptersPlatformPromocodeApi
 * @extends {BaseAPI}
 */
export class AdoptersPlatformPromocodeApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdoptersPlatformPromocodeApi
     */
    public promoCodeControllerCheckPromoCode(options?: AxiosRequestConfig) {
        return AdoptersPlatformPromocodeApiFp(this.configuration).promoCodeControllerCheckPromoCode(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnimalsApi - axios parameter creator
 * @export
 */
export const AnimalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create animal.
         * @summary 
         * @param {CreateAnimalBodyDto} createAnimalBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerCreateAnimal: async (createAnimalBodyDto: CreateAnimalBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAnimalBodyDto' is not null or undefined
            assertParamExists('animalsControllerCreateAnimal', 'createAnimalBodyDto', createAnimalBodyDto)
            const localVarPath = `/animals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAnimalBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete animal.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/animals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerDeleteDocument: async (documentId: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('animalsControllerDeleteDocument', 'documentId', documentId)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerDeleteDocument', 'animalId', animalId)
            const localVarPath = `/animals/{animalId}/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} imageKey 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerDeleteOtherImage: async (imageKey: string, animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageKey' is not null or undefined
            assertParamExists('animalsControllerDeleteOtherImage', 'imageKey', imageKey)
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerDeleteOtherImage', 'animalId', animalId)
            const localVarPath = `/animals/{animalId}/images/other/{imageKey}`
                .replace(`{${"imageKey"}}`, encodeURIComponent(String(imageKey)))
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit animal.
         * @summary 
         * @param {AnimalDto} animalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerEditAnimal: async (animalDto: AnimalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalDto' is not null or undefined
            assertParamExists('animalsControllerEditAnimal', 'animalDto', animalDto)
            const localVarPath = `/animals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(animalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportAnimalsAsCSV: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/animals/export/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} dateToCheck 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportAnimalsAsPDF: async (accountId: string, dateToCheck: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('animalsControllerExportAnimalsAsPDF', 'accountId', accountId)
            // verify required parameter 'dateToCheck' is not null or undefined
            assertParamExists('animalsControllerExportAnimalsAsPDF', 'dateToCheck', dateToCheck)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('animalsControllerExportAnimalsAsPDF', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('animalsControllerExportAnimalsAsPDF', 'endDate', endDate)
            const localVarPath = `/animals/export/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (dateToCheck !== undefined) {
                localVarQueryParameter['dateToCheck'] = dateToCheck;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportAnimalsAsXLSX: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/animals/export/xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportICADAnimalsAsXLSX: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/animals/export/icad-xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGenerateAnnounce: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerGenerateAnnounce', 'animalId', animalId)
            const localVarPath = `/animals/{animalId}/generate-announce`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate veterinary order form
         * @summary 
         * @param {string} animalId 
         * @param {AnimalHealthOperationDto} animalHealthOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGenerateVeterinaryOrderForm: async (animalId: string, animalHealthOperationDto: AnimalHealthOperationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerGenerateVeterinaryOrderForm', 'animalId', animalId)
            // verify required parameter 'animalHealthOperationDto' is not null or undefined
            assertParamExists('animalsControllerGenerateVeterinaryOrderForm', 'animalHealthOperationDto', animalHealthOperationDto)
            const localVarPath = `/animals/{animalId}/veterinary-order-form/generate`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(animalHealthOperationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animal.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimal: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerGetAnimal', 'animalId', animalId)
            const localVarPath = `/animals/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animals for account id.
         * @summary 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimalsForAccountId: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('animalsControllerGetAnimalsForAccountId', 'accountId', accountId)
            const localVarPath = `/animals/accounts/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animals for adopter id.
         * @summary 
         * @param {string} adopterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimalsForAdopterId: async (adopterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adopterId' is not null or undefined
            assertParamExists('animalsControllerGetAnimalsForAdopterId', 'adopterId', adopterId)
            const localVarPath = `/animals/adopters/{adopterId}`
                .replace(`{${"adopterId"}}`, encodeURIComponent(String(adopterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animals for host family using account id.
         * @summary 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimalsForHostFamilyId: async (accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('animalsControllerGetAnimalsForHostFamilyId', 'accountId', accountId)
            const localVarPath = `/animals/host-family/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get count for all customer animals.
         * @summary 
         * @param {string} filters 
         * @param {string} searchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetCountAllCustomerAnimals: async (filters: string, searchQuery: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filters' is not null or undefined
            assertParamExists('animalsControllerGetCountAllCustomerAnimals', 'filters', filters)
            // verify required parameter 'searchQuery' is not null or undefined
            assertParamExists('animalsControllerGetCountAllCustomerAnimals', 'searchQuery', searchQuery)
            const localVarPath = `/animals/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get dashboard data for animals.
         * @summary 
         * @param {string} [startDate] Start date in the format full ISO-8601
         * @param {string} [endDate] End date in the format full ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetDashboardData: async (startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/animals/dashboard-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get paginated customer animals.
         * @summary 
         * @param {string} page 
         * @param {string} limit 
         * @param {string} filters 
         * @param {string} searchQuery 
         * @param {string} sortColumn 
         * @param {string} sortDirection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetPaginatedCustomerAnimals: async (page: string, limit: string, filters: string, searchQuery: string, sortColumn: string, sortDirection: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('animalsControllerGetPaginatedCustomerAnimals', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('animalsControllerGetPaginatedCustomerAnimals', 'limit', limit)
            // verify required parameter 'filters' is not null or undefined
            assertParamExists('animalsControllerGetPaginatedCustomerAnimals', 'filters', filters)
            // verify required parameter 'searchQuery' is not null or undefined
            assertParamExists('animalsControllerGetPaginatedCustomerAnimals', 'searchQuery', searchQuery)
            // verify required parameter 'sortColumn' is not null or undefined
            assertParamExists('animalsControllerGetPaginatedCustomerAnimals', 'sortColumn', sortColumn)
            // verify required parameter 'sortDirection' is not null or undefined
            assertParamExists('animalsControllerGetPaginatedCustomerAnimals', 'sortDirection', sortDirection)
            const localVarPath = `/animals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }

            if (sortColumn !== undefined) {
                localVarQueryParameter['sortColumn'] = sortColumn;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerImportDataFromXlsx: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/animals/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all animals for retool dashboard
         * @summary 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerRetool: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('animalsControllerRetool', 'key', key)
            const localVarPath = `/animals/retool`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {UploadAnimalDocumentDto} uploadAnimalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerUploadDocument: async (animalId: string, uploadAnimalDocumentDto: UploadAnimalDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerUploadDocument', 'animalId', animalId)
            // verify required parameter 'uploadAnimalDocumentDto' is not null or undefined
            assertParamExists('animalsControllerUploadDocument', 'uploadAnimalDocumentDto', uploadAnimalDocumentDto)
            const localVarPath = `/animals/{animalId}/documents`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadAnimalDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerUploadOtherImage: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerUploadOtherImage', 'animalId', animalId)
            const localVarPath = `/animals/{animalId}/images/other`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerUploadProfilePhoto: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('animalsControllerUploadProfilePhoto', 'animalId', animalId)
            const localVarPath = `/animals/{animalId}/upload-profile-photos`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer members.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membersControllerGetAllMembers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnimalsApi - functional programming interface
 * @export
 */
export const AnimalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnimalsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create animal.
         * @summary 
         * @param {CreateAnimalBodyDto} createAnimalBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerCreateAnimal(createAnimalBodyDto: CreateAnimalBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerCreateAnimal(createAnimalBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete animal.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerDeleteDocument(documentId: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerDeleteDocument(documentId, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} imageKey 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerDeleteOtherImage(imageKey: string, animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerDeleteOtherImage(imageKey, animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit animal.
         * @summary 
         * @param {AnimalDto} animalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerEditAnimal(animalDto: AnimalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerEditAnimal(animalDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerExportAnimalsAsCSV(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerExportAnimalsAsCSV(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} dateToCheck 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerExportAnimalsAsPDF(accountId: string, dateToCheck: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerExportAnimalsAsPDF(accountId, dateToCheck, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerExportAnimalsAsXLSX(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerExportAnimalsAsXLSX(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerExportICADAnimalsAsXLSX(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerExportICADAnimalsAsXLSX(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGenerateAnnounce(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGenerateAnnounce(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate veterinary order form
         * @summary 
         * @param {string} animalId 
         * @param {AnimalHealthOperationDto} animalHealthOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGenerateVeterinaryOrderForm(animalId: string, animalHealthOperationDto: AnimalHealthOperationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGenerateVeterinaryOrderForm(animalId, animalHealthOperationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animal.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetAnimal(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetAnimal(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animals for account id.
         * @summary 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetAnimalsForAccountId(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetAnimalsForAccountId(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animals for adopter id.
         * @summary 
         * @param {string} adopterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetAnimalsForAdopterId(adopterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnimalSearchItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetAnimalsForAdopterId(adopterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animals for host family using account id.
         * @summary 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetAnimalsForHostFamilyId(accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetAnimalsForHostFamilyId(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get count for all customer animals.
         * @summary 
         * @param {string} filters 
         * @param {string} searchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetCountAllCustomerAnimals(filters: string, searchQuery: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetCountAllCustomerAnimals(filters, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get dashboard data for animals.
         * @summary 
         * @param {string} [startDate] Start date in the format full ISO-8601
         * @param {string} [endDate] End date in the format full ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetDashboardData(startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetDashboardData(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get paginated customer animals.
         * @summary 
         * @param {string} page 
         * @param {string} limit 
         * @param {string} filters 
         * @param {string} searchQuery 
         * @param {string} sortColumn 
         * @param {string} sortDirection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerGetPaginatedCustomerAnimals(page: string, limit: string, filters: string, searchQuery: string, sortColumn: string, sortDirection: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerGetPaginatedCustomerAnimals(page, limit, filters, searchQuery, sortColumn, sortDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerImportDataFromXlsx(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerImportDataFromXlsx(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all animals for retool dashboard
         * @summary 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerRetool(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerRetool(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {UploadAnimalDocumentDto} uploadAnimalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerUploadDocument(animalId: string, uploadAnimalDocumentDto: UploadAnimalDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerUploadDocument(animalId, uploadAnimalDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerUploadOtherImage(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerUploadOtherImage(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async animalsControllerUploadProfilePhoto(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.animalsControllerUploadProfilePhoto(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all customer members.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async membersControllerGetAllMembers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.membersControllerGetAllMembers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnimalsApi - factory interface
 * @export
 */
export const AnimalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnimalsApiFp(configuration)
    return {
        /**
         * Create animal.
         * @summary 
         * @param {CreateAnimalBodyDto} createAnimalBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerCreateAnimal(createAnimalBodyDto: CreateAnimalBodyDto, options?: any): AxiosPromise<AnimalDto> {
            return localVarFp.animalsControllerCreateAnimal(createAnimalBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete animal.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerDelete(options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerDeleteDocument(documentId: string, animalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerDeleteDocument(documentId, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} imageKey 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerDeleteOtherImage(imageKey: string, animalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerDeleteOtherImage(imageKey, animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit animal.
         * @summary 
         * @param {AnimalDto} animalDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerEditAnimal(animalDto: AnimalDto, options?: any): AxiosPromise<AnimalDto> {
            return localVarFp.animalsControllerEditAnimal(animalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportAnimalsAsCSV(options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerExportAnimalsAsCSV(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accountId 
         * @param {string} dateToCheck 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportAnimalsAsPDF(accountId: string, dateToCheck: string, startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerExportAnimalsAsPDF(accountId, dateToCheck, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportAnimalsAsXLSX(options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerExportAnimalsAsXLSX(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerExportICADAnimalsAsXLSX(options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerExportICADAnimalsAsXLSX(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGenerateAnnounce(animalId: string, options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerGenerateAnnounce(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate veterinary order form
         * @summary 
         * @param {string} animalId 
         * @param {AnimalHealthOperationDto} animalHealthOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGenerateVeterinaryOrderForm(animalId: string, animalHealthOperationDto: AnimalHealthOperationDto, options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerGenerateVeterinaryOrderForm(animalId, animalHealthOperationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animal.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimal(animalId: string, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.animalsControllerGetAnimal(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animals for account id.
         * @summary 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimalsForAccountId(accountId: string, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.animalsControllerGetAnimalsForAccountId(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animals for adopter id.
         * @summary 
         * @param {string} adopterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimalsForAdopterId(adopterId: string, options?: any): AxiosPromise<Array<AnimalSearchItemDto>> {
            return localVarFp.animalsControllerGetAnimalsForAdopterId(adopterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animals for host family using account id.
         * @summary 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetAnimalsForHostFamilyId(accountId: string, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.animalsControllerGetAnimalsForHostFamilyId(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get count for all customer animals.
         * @summary 
         * @param {string} filters 
         * @param {string} searchQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetCountAllCustomerAnimals(filters: string, searchQuery: string, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.animalsControllerGetCountAllCustomerAnimals(filters, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Get dashboard data for animals.
         * @summary 
         * @param {string} [startDate] Start date in the format full ISO-8601
         * @param {string} [endDate] End date in the format full ISO-8601
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetDashboardData(startDate?: string, endDate?: string, options?: any): AxiosPromise<DashboardDataDto> {
            return localVarFp.animalsControllerGetDashboardData(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get paginated customer animals.
         * @summary 
         * @param {string} page 
         * @param {string} limit 
         * @param {string} filters 
         * @param {string} searchQuery 
         * @param {string} sortColumn 
         * @param {string} sortDirection 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerGetPaginatedCustomerAnimals(page: string, limit: string, filters: string, searchQuery: string, sortColumn: string, sortDirection: string, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.animalsControllerGetPaginatedCustomerAnimals(page, limit, filters, searchQuery, sortColumn, sortDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerImportDataFromXlsx(options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerImportDataFromXlsx(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all animals for retool dashboard
         * @summary 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerRetool(key: string, options?: any): AxiosPromise<Array<AnimalDto>> {
            return localVarFp.animalsControllerRetool(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {UploadAnimalDocumentDto} uploadAnimalDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerUploadDocument(animalId: string, uploadAnimalDocumentDto: UploadAnimalDocumentDto, options?: any): AxiosPromise<void> {
            return localVarFp.animalsControllerUploadDocument(animalId, uploadAnimalDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerUploadOtherImage(animalId: string, options?: any): AxiosPromise<string> {
            return localVarFp.animalsControllerUploadOtherImage(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        animalsControllerUploadProfilePhoto(animalId: string, options?: any): AxiosPromise<string> {
            return localVarFp.animalsControllerUploadProfilePhoto(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer members.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        membersControllerGetAllMembers(options?: any): AxiosPromise<AccountDto> {
            return localVarFp.membersControllerGetAllMembers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for animalsControllerCreateAnimal operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerCreateAnimalRequest
 */
export interface AnimalsApiAnimalsControllerCreateAnimalRequest {
    /**
     * 
     * @type {CreateAnimalBodyDto}
     * @memberof AnimalsApiAnimalsControllerCreateAnimal
     */
    readonly createAnimalBodyDto: CreateAnimalBodyDto
}

/**
 * Request parameters for animalsControllerDeleteDocument operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerDeleteDocumentRequest
 */
export interface AnimalsApiAnimalsControllerDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerDeleteDocument
     */
    readonly documentId: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerDeleteDocument
     */
    readonly animalId: string
}

/**
 * Request parameters for animalsControllerDeleteOtherImage operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerDeleteOtherImageRequest
 */
export interface AnimalsApiAnimalsControllerDeleteOtherImageRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerDeleteOtherImage
     */
    readonly imageKey: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerDeleteOtherImage
     */
    readonly animalId: string
}

/**
 * Request parameters for animalsControllerEditAnimal operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerEditAnimalRequest
 */
export interface AnimalsApiAnimalsControllerEditAnimalRequest {
    /**
     * 
     * @type {AnimalDto}
     * @memberof AnimalsApiAnimalsControllerEditAnimal
     */
    readonly animalDto: AnimalDto
}

/**
 * Request parameters for animalsControllerExportAnimalsAsPDF operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerExportAnimalsAsPDFRequest
 */
export interface AnimalsApiAnimalsControllerExportAnimalsAsPDFRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerExportAnimalsAsPDF
     */
    readonly accountId: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerExportAnimalsAsPDF
     */
    readonly dateToCheck: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerExportAnimalsAsPDF
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerExportAnimalsAsPDF
     */
    readonly endDate: string
}

/**
 * Request parameters for animalsControllerGenerateAnnounce operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGenerateAnnounceRequest
 */
export interface AnimalsApiAnimalsControllerGenerateAnnounceRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGenerateAnnounce
     */
    readonly animalId: string
}

/**
 * Request parameters for animalsControllerGenerateVeterinaryOrderForm operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGenerateVeterinaryOrderFormRequest
 */
export interface AnimalsApiAnimalsControllerGenerateVeterinaryOrderFormRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGenerateVeterinaryOrderForm
     */
    readonly animalId: string

    /**
     * 
     * @type {AnimalHealthOperationDto}
     * @memberof AnimalsApiAnimalsControllerGenerateVeterinaryOrderForm
     */
    readonly animalHealthOperationDto: AnimalHealthOperationDto
}

/**
 * Request parameters for animalsControllerGetAnimal operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetAnimalRequest
 */
export interface AnimalsApiAnimalsControllerGetAnimalRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetAnimal
     */
    readonly animalId: string
}

/**
 * Request parameters for animalsControllerGetAnimalsForAccountId operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetAnimalsForAccountIdRequest
 */
export interface AnimalsApiAnimalsControllerGetAnimalsForAccountIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetAnimalsForAccountId
     */
    readonly accountId: string
}

/**
 * Request parameters for animalsControllerGetAnimalsForAdopterId operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetAnimalsForAdopterIdRequest
 */
export interface AnimalsApiAnimalsControllerGetAnimalsForAdopterIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetAnimalsForAdopterId
     */
    readonly adopterId: string
}

/**
 * Request parameters for animalsControllerGetAnimalsForHostFamilyId operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetAnimalsForHostFamilyIdRequest
 */
export interface AnimalsApiAnimalsControllerGetAnimalsForHostFamilyIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetAnimalsForHostFamilyId
     */
    readonly accountId: string
}

/**
 * Request parameters for animalsControllerGetCountAllCustomerAnimals operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetCountAllCustomerAnimalsRequest
 */
export interface AnimalsApiAnimalsControllerGetCountAllCustomerAnimalsRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetCountAllCustomerAnimals
     */
    readonly filters: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetCountAllCustomerAnimals
     */
    readonly searchQuery: string
}

/**
 * Request parameters for animalsControllerGetDashboardData operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetDashboardDataRequest
 */
export interface AnimalsApiAnimalsControllerGetDashboardDataRequest {
    /**
     * Start date in the format full ISO-8601
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetDashboardData
     */
    readonly startDate?: string

    /**
     * End date in the format full ISO-8601
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetDashboardData
     */
    readonly endDate?: string
}

/**
 * Request parameters for animalsControllerGetPaginatedCustomerAnimals operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerGetPaginatedCustomerAnimalsRequest
 */
export interface AnimalsApiAnimalsControllerGetPaginatedCustomerAnimalsRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetPaginatedCustomerAnimals
     */
    readonly page: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetPaginatedCustomerAnimals
     */
    readonly limit: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetPaginatedCustomerAnimals
     */
    readonly filters: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetPaginatedCustomerAnimals
     */
    readonly searchQuery: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetPaginatedCustomerAnimals
     */
    readonly sortColumn: string

    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerGetPaginatedCustomerAnimals
     */
    readonly sortDirection: string
}

/**
 * Request parameters for animalsControllerRetool operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerRetoolRequest
 */
export interface AnimalsApiAnimalsControllerRetoolRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerRetool
     */
    readonly key: string
}

/**
 * Request parameters for animalsControllerUploadDocument operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerUploadDocumentRequest
 */
export interface AnimalsApiAnimalsControllerUploadDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerUploadDocument
     */
    readonly animalId: string

    /**
     * 
     * @type {UploadAnimalDocumentDto}
     * @memberof AnimalsApiAnimalsControllerUploadDocument
     */
    readonly uploadAnimalDocumentDto: UploadAnimalDocumentDto
}

/**
 * Request parameters for animalsControllerUploadOtherImage operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerUploadOtherImageRequest
 */
export interface AnimalsApiAnimalsControllerUploadOtherImageRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerUploadOtherImage
     */
    readonly animalId: string
}

/**
 * Request parameters for animalsControllerUploadProfilePhoto operation in AnimalsApi.
 * @export
 * @interface AnimalsApiAnimalsControllerUploadProfilePhotoRequest
 */
export interface AnimalsApiAnimalsControllerUploadProfilePhotoRequest {
    /**
     * 
     * @type {string}
     * @memberof AnimalsApiAnimalsControllerUploadProfilePhoto
     */
    readonly animalId: string
}

/**
 * AnimalsApi - object-oriented interface
 * @export
 * @class AnimalsApi
 * @extends {BaseAPI}
 */
export class AnimalsApi extends BaseAPI {
    /**
     * Create animal.
     * @summary 
     * @param {AnimalsApiAnimalsControllerCreateAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerCreateAnimal(requestParameters: AnimalsApiAnimalsControllerCreateAnimalRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerCreateAnimal(requestParameters.createAnimalBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete animal.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerDelete(options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerDeleteDocument(requestParameters: AnimalsApiAnimalsControllerDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerDeleteDocument(requestParameters.documentId, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerDeleteOtherImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerDeleteOtherImage(requestParameters: AnimalsApiAnimalsControllerDeleteOtherImageRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerDeleteOtherImage(requestParameters.imageKey, requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit animal.
     * @summary 
     * @param {AnimalsApiAnimalsControllerEditAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerEditAnimal(requestParameters: AnimalsApiAnimalsControllerEditAnimalRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerEditAnimal(requestParameters.animalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerExportAnimalsAsCSV(options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerExportAnimalsAsCSV(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerExportAnimalsAsPDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerExportAnimalsAsPDF(requestParameters: AnimalsApiAnimalsControllerExportAnimalsAsPDFRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerExportAnimalsAsPDF(requestParameters.accountId, requestParameters.dateToCheck, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerExportAnimalsAsXLSX(options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerExportAnimalsAsXLSX(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerExportICADAnimalsAsXLSX(options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerExportICADAnimalsAsXLSX(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerGenerateAnnounceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGenerateAnnounce(requestParameters: AnimalsApiAnimalsControllerGenerateAnnounceRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGenerateAnnounce(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate veterinary order form
     * @summary 
     * @param {AnimalsApiAnimalsControllerGenerateVeterinaryOrderFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGenerateVeterinaryOrderForm(requestParameters: AnimalsApiAnimalsControllerGenerateVeterinaryOrderFormRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGenerateVeterinaryOrderForm(requestParameters.animalId, requestParameters.animalHealthOperationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animal.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetAnimal(requestParameters: AnimalsApiAnimalsControllerGetAnimalRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetAnimal(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animals for account id.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetAnimalsForAccountIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetAnimalsForAccountId(requestParameters: AnimalsApiAnimalsControllerGetAnimalsForAccountIdRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetAnimalsForAccountId(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animals for adopter id.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetAnimalsForAdopterIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetAnimalsForAdopterId(requestParameters: AnimalsApiAnimalsControllerGetAnimalsForAdopterIdRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetAnimalsForAdopterId(requestParameters.adopterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animals for host family using account id.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetAnimalsForHostFamilyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetAnimalsForHostFamilyId(requestParameters: AnimalsApiAnimalsControllerGetAnimalsForHostFamilyIdRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetAnimalsForHostFamilyId(requestParameters.accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get count for all customer animals.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetCountAllCustomerAnimalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetCountAllCustomerAnimals(requestParameters: AnimalsApiAnimalsControllerGetCountAllCustomerAnimalsRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetCountAllCustomerAnimals(requestParameters.filters, requestParameters.searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get dashboard data for animals.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetDashboardDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetDashboardData(requestParameters: AnimalsApiAnimalsControllerGetDashboardDataRequest = {}, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetDashboardData(requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get paginated customer animals.
     * @summary 
     * @param {AnimalsApiAnimalsControllerGetPaginatedCustomerAnimalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerGetPaginatedCustomerAnimals(requestParameters: AnimalsApiAnimalsControllerGetPaginatedCustomerAnimalsRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerGetPaginatedCustomerAnimals(requestParameters.page, requestParameters.limit, requestParameters.filters, requestParameters.searchQuery, requestParameters.sortColumn, requestParameters.sortDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerImportDataFromXlsx(options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerImportDataFromXlsx(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all animals for retool dashboard
     * @summary 
     * @param {AnimalsApiAnimalsControllerRetoolRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerRetool(requestParameters: AnimalsApiAnimalsControllerRetoolRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerRetool(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerUploadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerUploadDocument(requestParameters: AnimalsApiAnimalsControllerUploadDocumentRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerUploadDocument(requestParameters.animalId, requestParameters.uploadAnimalDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerUploadOtherImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerUploadOtherImage(requestParameters: AnimalsApiAnimalsControllerUploadOtherImageRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerUploadOtherImage(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AnimalsApiAnimalsControllerUploadProfilePhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public animalsControllerUploadProfilePhoto(requestParameters: AnimalsApiAnimalsControllerUploadProfilePhotoRequest, options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).animalsControllerUploadProfilePhoto(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer members.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnimalsApi
     */
    public membersControllerGetAllMembers(options?: AxiosRequestConfig) {
        return AnimalsApiFp(this.configuration).membersControllerGetAllMembers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Request a JWT to authenticate for the other endpoints.
         * @summary 
         * @param {LoginBodyDto} loginBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginBodyDto: LoginBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginBodyDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginBodyDto', loginBodyDto)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestPasswordReset: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/password-reset/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdatePassword: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/update-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Request a JWT to authenticate for the other endpoints.
         * @summary 
         * @param {LoginBodyDto} loginBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginBodyDto: LoginBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRequestPasswordReset(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRequestPasswordReset(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPassword(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPassword(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUpdatePassword(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUpdatePassword(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Request a JWT to authenticate for the other endpoints.
         * @summary 
         * @param {LoginBodyDto} loginBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginBodyDto: LoginBodyDto, options?: any): AxiosPromise<LoginResponseDto> {
            return localVarFp.authControllerLogin(loginBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRequestPasswordReset(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerRequestPasswordReset(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerResetPassword(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdatePassword(options?: any): AxiosPromise<void> {
            return localVarFp.authControllerUpdatePassword(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authControllerLogin operation in AuthApi.
 * @export
 * @interface AuthApiAuthControllerLoginRequest
 */
export interface AuthApiAuthControllerLoginRequest {
    /**
     * 
     * @type {LoginBodyDto}
     * @memberof AuthApiAuthControllerLogin
     */
    readonly loginBodyDto: LoginBodyDto
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Request a JWT to authenticate for the other endpoints.
     * @summary 
     * @param {AuthApiAuthControllerLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(requestParameters: AuthApiAuthControllerLoginRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(requestParameters.loginBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRequestPasswordReset(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRequestPasswordReset(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPassword(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResetPassword(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUpdatePassword(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerUpdatePassword(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BubbleApi - axios parameter creator
 * @export
 */
export const BubbleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get animal.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bubbleControllerGetAnimal: async (animalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'animalId' is not null or undefined
            assertParamExists('bubbleControllerGetAnimal', 'animalId', animalId)
            const localVarPath = `/bubble/animals/{animalId}`
                .replace(`{${"animalId"}}`, encodeURIComponent(String(animalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get animals for adopter email.
         * @summary 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bubbleControllerGetAnimalsForAdopterId: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('bubbleControllerGetAnimalsForAdopterId', 'email', email)
            const localVarPath = `/bubble/animals/adopters/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BubbleApi - functional programming interface
 * @export
 */
export const BubbleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BubbleApiAxiosParamCreator(configuration)
    return {
        /**
         * Get animal.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bubbleControllerGetAnimal(animalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bubbleControllerGetAnimal(animalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get animals for adopter email.
         * @summary 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bubbleControllerGetAnimalsForAdopterId(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bubbleControllerGetAnimalsForAdopterId(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BubbleApi - factory interface
 * @export
 */
export const BubbleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BubbleApiFp(configuration)
    return {
        /**
         * Get animal.
         * @summary 
         * @param {string} animalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bubbleControllerGetAnimal(animalId: string, options?: any): AxiosPromise<AccountDto> {
            return localVarFp.bubbleControllerGetAnimal(animalId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get animals for adopter email.
         * @summary 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bubbleControllerGetAnimalsForAdopterId(email: string, options?: any): AxiosPromise<Array<AnimalDto>> {
            return localVarFp.bubbleControllerGetAnimalsForAdopterId(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bubbleControllerGetAnimal operation in BubbleApi.
 * @export
 * @interface BubbleApiBubbleControllerGetAnimalRequest
 */
export interface BubbleApiBubbleControllerGetAnimalRequest {
    /**
     * 
     * @type {string}
     * @memberof BubbleApiBubbleControllerGetAnimal
     */
    readonly animalId: string
}

/**
 * Request parameters for bubbleControllerGetAnimalsForAdopterId operation in BubbleApi.
 * @export
 * @interface BubbleApiBubbleControllerGetAnimalsForAdopterIdRequest
 */
export interface BubbleApiBubbleControllerGetAnimalsForAdopterIdRequest {
    /**
     * 
     * @type {string}
     * @memberof BubbleApiBubbleControllerGetAnimalsForAdopterId
     */
    readonly email: string
}

/**
 * BubbleApi - object-oriented interface
 * @export
 * @class BubbleApi
 * @extends {BaseAPI}
 */
export class BubbleApi extends BaseAPI {
    /**
     * Get animal.
     * @summary 
     * @param {BubbleApiBubbleControllerGetAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BubbleApi
     */
    public bubbleControllerGetAnimal(requestParameters: BubbleApiBubbleControllerGetAnimalRequest, options?: AxiosRequestConfig) {
        return BubbleApiFp(this.configuration).bubbleControllerGetAnimal(requestParameters.animalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get animals for adopter email.
     * @summary 
     * @param {BubbleApiBubbleControllerGetAnimalsForAdopterIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BubbleApi
     */
    public bubbleControllerGetAnimalsForAdopterId(requestParameters: BubbleApiBubbleControllerGetAnimalsForAdopterIdRequest, options?: AxiosRequestConfig) {
        return BubbleApiFp(this.configuration).bubbleControllerGetAnimalsForAdopterId(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new customer and the initial admin account (from direct API call only).
         * @summary 
         * @param {InitiateCustomerDto} initiateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreateCustomerSpace: async (initiateCustomerDto: InitiateCustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initiateCustomerDto' is not null or undefined
            assertParamExists('customersControllerCreateCustomerSpace', 'initiateCustomerDto', initiateCustomerDto)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateCustomerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteDocument: async (documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('customersControllerDeleteDocument', 'documentId', documentId)
            const localVarPath = `/customers/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeletePdfTemplate: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('customersControllerDeletePdfTemplate', 'templateId', templateId)
            const localVarPath = `/customers/pdf-templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteTemplate: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('customersControllerDeleteTemplate', 'templateId', templateId)
            const localVarPath = `/customers/templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit customer.
         * @summary 
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerEditAccount: async (customerDto: CustomerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerDto' is not null or undefined
            assertParamExists('customersControllerEditAccount', 'customerDto', customerDto)
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all cages with animals names
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetAllCagesWithAnimalsDetails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/cages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get customer for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetCurrentCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerSetSignature: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/signature`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UploadCustomerPdfTemplateDto} uploadCustomerPdfTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdatePdfTemplate: async (templateId: string, uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('customersControllerUpdatePdfTemplate', 'templateId', templateId)
            // verify required parameter 'uploadCustomerPdfTemplateDto' is not null or undefined
            assertParamExists('customersControllerUpdatePdfTemplate', 'uploadCustomerPdfTemplateDto', uploadCustomerPdfTemplateDto)
            const localVarPath = `/customers/pdf-templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadCustomerPdfTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UploadCustomerTemplateDto} uploadCustomerTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdateTemplate: async (templateId: string, uploadCustomerTemplateDto: UploadCustomerTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('customersControllerUpdateTemplate', 'templateId', templateId)
            // verify required parameter 'uploadCustomerTemplateDto' is not null or undefined
            assertParamExists('customersControllerUpdateTemplate', 'uploadCustomerTemplateDto', uploadCustomerTemplateDto)
            const localVarPath = `/customers/templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadCustomerTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadCustomerDocumentDto} uploadCustomerDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadDocument: async (uploadCustomerDocumentDto: UploadCustomerDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadCustomerDocumentDto' is not null or undefined
            assertParamExists('customersControllerUploadDocument', 'uploadCustomerDocumentDto', uploadCustomerDocumentDto)
            const localVarPath = `/customers/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadCustomerDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadCustomerPdfTemplateDto} uploadCustomerPdfTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadPdfTemplate: async (uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadCustomerPdfTemplateDto' is not null or undefined
            assertParamExists('customersControllerUploadPdfTemplate', 'uploadCustomerPdfTemplateDto', uploadCustomerPdfTemplateDto)
            const localVarPath = `/customers/pdf-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadCustomerPdfTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadProfilePhoto: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customers/upload-profile-photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadCustomerTemplateDto} uploadCustomerTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadTemplate: async (uploadCustomerTemplateDto: UploadCustomerTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadCustomerTemplateDto' is not null or undefined
            assertParamExists('customersControllerUploadTemplate', 'uploadCustomerTemplateDto', uploadCustomerTemplateDto)
            const localVarPath = `/customers/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadCustomerTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new customer and the initial admin account (from direct API call only).
         * @summary 
         * @param {InitiateCustomerDto} initiateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerCreateCustomerSpace(initiateCustomerDto: InitiateCustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerCreateCustomerSpace(initiateCustomerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeleteDocument(documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeleteDocument(documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeletePdfTemplate(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeletePdfTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDeleteTemplate(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDeleteTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit customer.
         * @summary 
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerEditAccount(customerDto: CustomerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerEditAccount(customerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all cages with animals names
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerGetAllCagesWithAnimalsDetails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCageWithAnimalsDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerGetAllCagesWithAnimalsDetails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get customer for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerGetCurrentCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerGetCurrentCustomer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerSetSignature(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerSetSignature(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UploadCustomerPdfTemplateDto} uploadCustomerPdfTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUpdatePdfTemplate(templateId: string, uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUpdatePdfTemplate(templateId, uploadCustomerPdfTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UploadCustomerTemplateDto} uploadCustomerTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUpdateTemplate(templateId: string, uploadCustomerTemplateDto: UploadCustomerTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUpdateTemplate(templateId, uploadCustomerTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadCustomerDocumentDto} uploadCustomerDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUploadDocument(uploadCustomerDocumentDto: UploadCustomerDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUploadDocument(uploadCustomerDocumentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadCustomerPdfTemplateDto} uploadCustomerPdfTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUploadPdfTemplate(uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUploadPdfTemplate(uploadCustomerPdfTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUploadProfilePhoto(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUploadProfilePhoto(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadCustomerTemplateDto} uploadCustomerTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUploadTemplate(uploadCustomerTemplateDto: UploadCustomerTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUploadTemplate(uploadCustomerTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * Create a new customer and the initial admin account (from direct API call only).
         * @summary 
         * @param {InitiateCustomerDto} initiateCustomerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreateCustomerSpace(initiateCustomerDto: InitiateCustomerDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerCreateCustomerSpace(initiateCustomerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteDocument(documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerDeleteDocument(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeletePdfTemplate(templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerDeletePdfTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDeleteTemplate(templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerDeleteTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit customer.
         * @summary 
         * @param {CustomerDto} customerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerEditAccount(customerDto: CustomerDto, options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerEditAccount(customerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all cages with animals names
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetAllCagesWithAnimalsDetails(options?: any): AxiosPromise<CustomerCageWithAnimalsDetailsDto> {
            return localVarFp.customersControllerGetAllCagesWithAnimalsDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * Get customer for the currently logged in user.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerGetCurrentCustomer(options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.customersControllerGetCurrentCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerSetSignature(options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerSetSignature(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UploadCustomerPdfTemplateDto} uploadCustomerPdfTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdatePdfTemplate(templateId: string, uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto, options?: any): AxiosPromise<string> {
            return localVarFp.customersControllerUpdatePdfTemplate(templateId, uploadCustomerPdfTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UploadCustomerTemplateDto} uploadCustomerTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdateTemplate(templateId: string, uploadCustomerTemplateDto: UploadCustomerTemplateDto, options?: any): AxiosPromise<string> {
            return localVarFp.customersControllerUpdateTemplate(templateId, uploadCustomerTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadCustomerDocumentDto} uploadCustomerDocumentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadDocument(uploadCustomerDocumentDto: UploadCustomerDocumentDto, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerUploadDocument(uploadCustomerDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadCustomerPdfTemplateDto} uploadCustomerPdfTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadPdfTemplate(uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerUploadPdfTemplate(uploadCustomerPdfTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadProfilePhoto(options?: any): AxiosPromise<string> {
            return localVarFp.customersControllerUploadProfilePhoto(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadCustomerTemplateDto} uploadCustomerTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUploadTemplate(uploadCustomerTemplateDto: UploadCustomerTemplateDto, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerUploadTemplate(uploadCustomerTemplateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customersControllerCreateCustomerSpace operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerCreateCustomerSpaceRequest
 */
export interface CustomersApiCustomersControllerCreateCustomerSpaceRequest {
    /**
     * 
     * @type {InitiateCustomerDto}
     * @memberof CustomersApiCustomersControllerCreateCustomerSpace
     */
    readonly initiateCustomerDto: InitiateCustomerDto
}

/**
 * Request parameters for customersControllerDeleteDocument operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerDeleteDocumentRequest
 */
export interface CustomersApiCustomersControllerDeleteDocumentRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiCustomersControllerDeleteDocument
     */
    readonly documentId: string
}

/**
 * Request parameters for customersControllerDeletePdfTemplate operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerDeletePdfTemplateRequest
 */
export interface CustomersApiCustomersControllerDeletePdfTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiCustomersControllerDeletePdfTemplate
     */
    readonly templateId: string
}

/**
 * Request parameters for customersControllerDeleteTemplate operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerDeleteTemplateRequest
 */
export interface CustomersApiCustomersControllerDeleteTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiCustomersControllerDeleteTemplate
     */
    readonly templateId: string
}

/**
 * Request parameters for customersControllerEditAccount operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerEditAccountRequest
 */
export interface CustomersApiCustomersControllerEditAccountRequest {
    /**
     * 
     * @type {CustomerDto}
     * @memberof CustomersApiCustomersControllerEditAccount
     */
    readonly customerDto: CustomerDto
}

/**
 * Request parameters for customersControllerUpdatePdfTemplate operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerUpdatePdfTemplateRequest
 */
export interface CustomersApiCustomersControllerUpdatePdfTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiCustomersControllerUpdatePdfTemplate
     */
    readonly templateId: string

    /**
     * 
     * @type {UploadCustomerPdfTemplateDto}
     * @memberof CustomersApiCustomersControllerUpdatePdfTemplate
     */
    readonly uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto
}

/**
 * Request parameters for customersControllerUpdateTemplate operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerUpdateTemplateRequest
 */
export interface CustomersApiCustomersControllerUpdateTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomersApiCustomersControllerUpdateTemplate
     */
    readonly templateId: string

    /**
     * 
     * @type {UploadCustomerTemplateDto}
     * @memberof CustomersApiCustomersControllerUpdateTemplate
     */
    readonly uploadCustomerTemplateDto: UploadCustomerTemplateDto
}

/**
 * Request parameters for customersControllerUploadDocument operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerUploadDocumentRequest
 */
export interface CustomersApiCustomersControllerUploadDocumentRequest {
    /**
     * 
     * @type {UploadCustomerDocumentDto}
     * @memberof CustomersApiCustomersControllerUploadDocument
     */
    readonly uploadCustomerDocumentDto: UploadCustomerDocumentDto
}

/**
 * Request parameters for customersControllerUploadPdfTemplate operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerUploadPdfTemplateRequest
 */
export interface CustomersApiCustomersControllerUploadPdfTemplateRequest {
    /**
     * 
     * @type {UploadCustomerPdfTemplateDto}
     * @memberof CustomersApiCustomersControllerUploadPdfTemplate
     */
    readonly uploadCustomerPdfTemplateDto: UploadCustomerPdfTemplateDto
}

/**
 * Request parameters for customersControllerUploadTemplate operation in CustomersApi.
 * @export
 * @interface CustomersApiCustomersControllerUploadTemplateRequest
 */
export interface CustomersApiCustomersControllerUploadTemplateRequest {
    /**
     * 
     * @type {UploadCustomerTemplateDto}
     * @memberof CustomersApiCustomersControllerUploadTemplate
     */
    readonly uploadCustomerTemplateDto: UploadCustomerTemplateDto
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * Create a new customer and the initial admin account (from direct API call only).
     * @summary 
     * @param {CustomersApiCustomersControllerCreateCustomerSpaceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerCreateCustomerSpace(requestParameters: CustomersApiCustomersControllerCreateCustomerSpaceRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerCreateCustomerSpace(requestParameters.initiateCustomerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerDeleteDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerDeleteDocument(requestParameters: CustomersApiCustomersControllerDeleteDocumentRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerDeleteDocument(requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerDeletePdfTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerDeletePdfTemplate(requestParameters: CustomersApiCustomersControllerDeletePdfTemplateRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerDeletePdfTemplate(requestParameters.templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerDeleteTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerDeleteTemplate(requestParameters: CustomersApiCustomersControllerDeleteTemplateRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerDeleteTemplate(requestParameters.templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit customer.
     * @summary 
     * @param {CustomersApiCustomersControllerEditAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerEditAccount(requestParameters: CustomersApiCustomersControllerEditAccountRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerEditAccount(requestParameters.customerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all cages with animals names
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerGetAllCagesWithAnimalsDetails(options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerGetAllCagesWithAnimalsDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get customer for the currently logged in user.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerGetCurrentCustomer(options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerGetCurrentCustomer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerSetSignature(options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerSetSignature(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerUpdatePdfTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerUpdatePdfTemplate(requestParameters: CustomersApiCustomersControllerUpdatePdfTemplateRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerUpdatePdfTemplate(requestParameters.templateId, requestParameters.uploadCustomerPdfTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerUpdateTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerUpdateTemplate(requestParameters: CustomersApiCustomersControllerUpdateTemplateRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerUpdateTemplate(requestParameters.templateId, requestParameters.uploadCustomerTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerUploadDocumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerUploadDocument(requestParameters: CustomersApiCustomersControllerUploadDocumentRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerUploadDocument(requestParameters.uploadCustomerDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerUploadPdfTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerUploadPdfTemplate(requestParameters: CustomersApiCustomersControllerUploadPdfTemplateRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerUploadPdfTemplate(requestParameters.uploadCustomerPdfTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerUploadProfilePhoto(options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerUploadProfilePhoto(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersApiCustomersControllerUploadTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public customersControllerUploadTemplate(requestParameters: CustomersApiCustomersControllerUploadTemplateRequest, options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).customersControllerUploadTemplate(requestParameters.uploadCustomerTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunAdoptionAttemptsMigration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/operation-nanoid-migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunAnimalProfilesMigration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/animal-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunGeocodeServicesMigration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/geocode-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunUpdateAdoptionAttemptsMigration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations/update-attempts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrationControllerRunAdoptionAttemptsMigration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrationControllerRunAdoptionAttemptsMigration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrationControllerRunAnimalProfilesMigration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrationControllerRunAnimalProfilesMigration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrationControllerRunGeocodeServicesMigration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrationControllerRunGeocodeServicesMigration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrationControllerRunUpdateAdoptionAttemptsMigration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrationControllerRunUpdateAdoptionAttemptsMigration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunAdoptionAttemptsMigration(options?: any): AxiosPromise<void> {
            return localVarFp.migrationControllerRunAdoptionAttemptsMigration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunAnimalProfilesMigration(options?: any): AxiosPromise<void> {
            return localVarFp.migrationControllerRunAnimalProfilesMigration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunGeocodeServicesMigration(options?: any): AxiosPromise<void> {
            return localVarFp.migrationControllerRunGeocodeServicesMigration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrationControllerRunUpdateAdoptionAttemptsMigration(options?: any): AxiosPromise<void> {
            return localVarFp.migrationControllerRunUpdateAdoptionAttemptsMigration(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public migrationControllerRunAdoptionAttemptsMigration(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).migrationControllerRunAdoptionAttemptsMigration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public migrationControllerRunAnimalProfilesMigration(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).migrationControllerRunAnimalProfilesMigration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public migrationControllerRunGeocodeServicesMigration(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).migrationControllerRunGeocodeServicesMigration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public migrationControllerRunUpdateAdoptionAttemptsMigration(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).migrationControllerRunUpdateAdoptionAttemptsMigration(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DonationTrackingsApi - axios parameter creator
 * @export
 */
export const DonationTrackingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create donation tracking.
         * @summary 
         * @param {CreateDonationTrackingBodyDto} createDonationTrackingBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerCreateDonationTracking: async (createDonationTrackingBodyDto: CreateDonationTrackingBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDonationTrackingBodyDto' is not null or undefined
            assertParamExists('donationTrackingsControllerCreateDonationTracking', 'createDonationTrackingBodyDto', createDonationTrackingBodyDto)
            const localVarPath = `/donationtrackings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDonationTrackingBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit donationtracking.
         * @summary 
         * @param {DonationTrackingDto} donationTrackingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerEditAnimal: async (donationTrackingDto: DonationTrackingDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'donationTrackingDto' is not null or undefined
            assertParamExists('donationTrackingsControllerEditAnimal', 'donationTrackingDto', donationTrackingDto)
            const localVarPath = `/donationtrackings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(donationTrackingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} donorId 
         * @param {string} donationTrackingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerExportFiscalReportAsPDF: async (donorId: string, donationTrackingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'donorId' is not null or undefined
            assertParamExists('donationTrackingsControllerExportFiscalReportAsPDF', 'donorId', donorId)
            // verify required parameter 'donationTrackingId' is not null or undefined
            assertParamExists('donationTrackingsControllerExportFiscalReportAsPDF', 'donationTrackingId', donationTrackingId)
            const localVarPath = `/donationtrackings/export/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (donorId !== undefined) {
                localVarQueryParameter['donorId'] = donorId;
            }

            if (donationTrackingId !== undefined) {
                localVarQueryParameter['donationTrackingId'] = donationTrackingId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} donorId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerExportMultipleFiscalReportAsPDF: async (donorId: string, startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'donorId' is not null or undefined
            assertParamExists('donationTrackingsControllerExportMultipleFiscalReportAsPDF', 'donorId', donorId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('donationTrackingsControllerExportMultipleFiscalReportAsPDF', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('donationTrackingsControllerExportMultipleFiscalReportAsPDF', 'endDate', endDate)
            const localVarPath = `/donationtrackings/export/multiple-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (donorId !== undefined) {
                localVarQueryParameter['donorId'] = donorId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer donation trackings.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerGetAllDonationTrackings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/donationtrackings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DonationTrackingsApi - functional programming interface
 * @export
 */
export const DonationTrackingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DonationTrackingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create donation tracking.
         * @summary 
         * @param {CreateDonationTrackingBodyDto} createDonationTrackingBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donationTrackingsControllerCreateDonationTracking(createDonationTrackingBodyDto: CreateDonationTrackingBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DonationTrackingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donationTrackingsControllerCreateDonationTracking(createDonationTrackingBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit donationtracking.
         * @summary 
         * @param {DonationTrackingDto} donationTrackingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donationTrackingsControllerEditAnimal(donationTrackingDto: DonationTrackingDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DonationTrackingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donationTrackingsControllerEditAnimal(donationTrackingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} donorId 
         * @param {string} donationTrackingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donationTrackingsControllerExportFiscalReportAsPDF(donorId: string, donationTrackingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donationTrackingsControllerExportFiscalReportAsPDF(donorId, donationTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} donorId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donationTrackingsControllerExportMultipleFiscalReportAsPDF(donorId: string, startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donationTrackingsControllerExportMultipleFiscalReportAsPDF(donorId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all customer donation trackings.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donationTrackingsControllerGetAllDonationTrackings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DonationTrackingDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donationTrackingsControllerGetAllDonationTrackings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DonationTrackingsApi - factory interface
 * @export
 */
export const DonationTrackingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DonationTrackingsApiFp(configuration)
    return {
        /**
         * Create donation tracking.
         * @summary 
         * @param {CreateDonationTrackingBodyDto} createDonationTrackingBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerCreateDonationTracking(createDonationTrackingBodyDto: CreateDonationTrackingBodyDto, options?: any): AxiosPromise<DonationTrackingDto> {
            return localVarFp.donationTrackingsControllerCreateDonationTracking(createDonationTrackingBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit donationtracking.
         * @summary 
         * @param {DonationTrackingDto} donationTrackingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerEditAnimal(donationTrackingDto: DonationTrackingDto, options?: any): AxiosPromise<DonationTrackingDto> {
            return localVarFp.donationTrackingsControllerEditAnimal(donationTrackingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} donorId 
         * @param {string} donationTrackingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerExportFiscalReportAsPDF(donorId: string, donationTrackingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.donationTrackingsControllerExportFiscalReportAsPDF(donorId, donationTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} donorId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerExportMultipleFiscalReportAsPDF(donorId: string, startDate: string, endDate: string, options?: any): AxiosPromise<void> {
            return localVarFp.donationTrackingsControllerExportMultipleFiscalReportAsPDF(donorId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer donation trackings.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donationTrackingsControllerGetAllDonationTrackings(options?: any): AxiosPromise<DonationTrackingDto> {
            return localVarFp.donationTrackingsControllerGetAllDonationTrackings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for donationTrackingsControllerCreateDonationTracking operation in DonationTrackingsApi.
 * @export
 * @interface DonationTrackingsApiDonationTrackingsControllerCreateDonationTrackingRequest
 */
export interface DonationTrackingsApiDonationTrackingsControllerCreateDonationTrackingRequest {
    /**
     * 
     * @type {CreateDonationTrackingBodyDto}
     * @memberof DonationTrackingsApiDonationTrackingsControllerCreateDonationTracking
     */
    readonly createDonationTrackingBodyDto: CreateDonationTrackingBodyDto
}

/**
 * Request parameters for donationTrackingsControllerEditAnimal operation in DonationTrackingsApi.
 * @export
 * @interface DonationTrackingsApiDonationTrackingsControllerEditAnimalRequest
 */
export interface DonationTrackingsApiDonationTrackingsControllerEditAnimalRequest {
    /**
     * 
     * @type {DonationTrackingDto}
     * @memberof DonationTrackingsApiDonationTrackingsControllerEditAnimal
     */
    readonly donationTrackingDto: DonationTrackingDto
}

/**
 * Request parameters for donationTrackingsControllerExportFiscalReportAsPDF operation in DonationTrackingsApi.
 * @export
 * @interface DonationTrackingsApiDonationTrackingsControllerExportFiscalReportAsPDFRequest
 */
export interface DonationTrackingsApiDonationTrackingsControllerExportFiscalReportAsPDFRequest {
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingsApiDonationTrackingsControllerExportFiscalReportAsPDF
     */
    readonly donorId: string

    /**
     * 
     * @type {string}
     * @memberof DonationTrackingsApiDonationTrackingsControllerExportFiscalReportAsPDF
     */
    readonly donationTrackingId: string
}

/**
 * Request parameters for donationTrackingsControllerExportMultipleFiscalReportAsPDF operation in DonationTrackingsApi.
 * @export
 * @interface DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDFRequest
 */
export interface DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDFRequest {
    /**
     * 
     * @type {string}
     * @memberof DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDF
     */
    readonly donorId: string

    /**
     * 
     * @type {string}
     * @memberof DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDF
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDF
     */
    readonly endDate: string
}

/**
 * DonationTrackingsApi - object-oriented interface
 * @export
 * @class DonationTrackingsApi
 * @extends {BaseAPI}
 */
export class DonationTrackingsApi extends BaseAPI {
    /**
     * Create donation tracking.
     * @summary 
     * @param {DonationTrackingsApiDonationTrackingsControllerCreateDonationTrackingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTrackingsApi
     */
    public donationTrackingsControllerCreateDonationTracking(requestParameters: DonationTrackingsApiDonationTrackingsControllerCreateDonationTrackingRequest, options?: AxiosRequestConfig) {
        return DonationTrackingsApiFp(this.configuration).donationTrackingsControllerCreateDonationTracking(requestParameters.createDonationTrackingBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit donationtracking.
     * @summary 
     * @param {DonationTrackingsApiDonationTrackingsControllerEditAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTrackingsApi
     */
    public donationTrackingsControllerEditAnimal(requestParameters: DonationTrackingsApiDonationTrackingsControllerEditAnimalRequest, options?: AxiosRequestConfig) {
        return DonationTrackingsApiFp(this.configuration).donationTrackingsControllerEditAnimal(requestParameters.donationTrackingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DonationTrackingsApiDonationTrackingsControllerExportFiscalReportAsPDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTrackingsApi
     */
    public donationTrackingsControllerExportFiscalReportAsPDF(requestParameters: DonationTrackingsApiDonationTrackingsControllerExportFiscalReportAsPDFRequest, options?: AxiosRequestConfig) {
        return DonationTrackingsApiFp(this.configuration).donationTrackingsControllerExportFiscalReportAsPDF(requestParameters.donorId, requestParameters.donationTrackingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTrackingsApi
     */
    public donationTrackingsControllerExportMultipleFiscalReportAsPDF(requestParameters: DonationTrackingsApiDonationTrackingsControllerExportMultipleFiscalReportAsPDFRequest, options?: AxiosRequestConfig) {
        return DonationTrackingsApiFp(this.configuration).donationTrackingsControllerExportMultipleFiscalReportAsPDF(requestParameters.donorId, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer donation trackings.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTrackingsApi
     */
    public donationTrackingsControllerGetAllDonationTrackings(options?: AxiosRequestConfig) {
        return DonationTrackingsApiFp(this.configuration).donationTrackingsControllerGetAllDonationTrackings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DonorsApi - axios parameter creator
 * @export
 */
export const DonorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create donor.
         * @summary 
         * @param {CreateDonorBodyDto} createDonorBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donorsControllerCreateDonor: async (createDonorBodyDto: CreateDonorBodyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDonorBodyDto' is not null or undefined
            assertParamExists('donorsControllerCreateDonor', 'createDonorBodyDto', createDonorBodyDto)
            const localVarPath = `/donors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDonorBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Edit donor.
         * @summary 
         * @param {Donor} donor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donorsControllerEditAnimal: async (donor: Donor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'donor' is not null or undefined
            assertParamExists('donorsControllerEditAnimal', 'donor', donor)
            const localVarPath = `/donors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(donor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all customer donors.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donorsControllerGetAllDonors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/donors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DonorsApi - functional programming interface
 * @export
 */
export const DonorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DonorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create donor.
         * @summary 
         * @param {CreateDonorBodyDto} createDonorBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donorsControllerCreateDonor(createDonorBodyDto: CreateDonorBodyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Donor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donorsControllerCreateDonor(createDonorBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Edit donor.
         * @summary 
         * @param {Donor} donor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donorsControllerEditAnimal(donor: Donor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Donor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donorsControllerEditAnimal(donor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all customer donors.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async donorsControllerGetAllDonors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Donor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.donorsControllerGetAllDonors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DonorsApi - factory interface
 * @export
 */
export const DonorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DonorsApiFp(configuration)
    return {
        /**
         * Create donor.
         * @summary 
         * @param {CreateDonorBodyDto} createDonorBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donorsControllerCreateDonor(createDonorBodyDto: CreateDonorBodyDto, options?: any): AxiosPromise<Donor> {
            return localVarFp.donorsControllerCreateDonor(createDonorBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit donor.
         * @summary 
         * @param {Donor} donor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donorsControllerEditAnimal(donor: Donor, options?: any): AxiosPromise<Donor> {
            return localVarFp.donorsControllerEditAnimal(donor, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all customer donors.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        donorsControllerGetAllDonors(options?: any): AxiosPromise<Donor> {
            return localVarFp.donorsControllerGetAllDonors(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for donorsControllerCreateDonor operation in DonorsApi.
 * @export
 * @interface DonorsApiDonorsControllerCreateDonorRequest
 */
export interface DonorsApiDonorsControllerCreateDonorRequest {
    /**
     * 
     * @type {CreateDonorBodyDto}
     * @memberof DonorsApiDonorsControllerCreateDonor
     */
    readonly createDonorBodyDto: CreateDonorBodyDto
}

/**
 * Request parameters for donorsControllerEditAnimal operation in DonorsApi.
 * @export
 * @interface DonorsApiDonorsControllerEditAnimalRequest
 */
export interface DonorsApiDonorsControllerEditAnimalRequest {
    /**
     * 
     * @type {Donor}
     * @memberof DonorsApiDonorsControllerEditAnimal
     */
    readonly donor: Donor
}

/**
 * DonorsApi - object-oriented interface
 * @export
 * @class DonorsApi
 * @extends {BaseAPI}
 */
export class DonorsApi extends BaseAPI {
    /**
     * Create donor.
     * @summary 
     * @param {DonorsApiDonorsControllerCreateDonorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonorsApi
     */
    public donorsControllerCreateDonor(requestParameters: DonorsApiDonorsControllerCreateDonorRequest, options?: AxiosRequestConfig) {
        return DonorsApiFp(this.configuration).donorsControllerCreateDonor(requestParameters.createDonorBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Edit donor.
     * @summary 
     * @param {DonorsApiDonorsControllerEditAnimalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonorsApi
     */
    public donorsControllerEditAnimal(requestParameters: DonorsApiDonorsControllerEditAnimalRequest, options?: AxiosRequestConfig) {
        return DonorsApiFp(this.configuration).donorsControllerEditAnimal(requestParameters.donor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all customer donors.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonorsApi
     */
    public donorsControllerGetAllDonors(options?: AxiosRequestConfig) {
        return DonorsApiFp(this.configuration).donorsControllerGetAllDonors(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorsApi - axios parameter creator
 * @export
 */
export const ErrorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Receive frontend error.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorsControllerOnFrontError: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorsApi - functional programming interface
 * @export
 */
export const ErrorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Receive frontend error.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorsControllerOnFrontError(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorsControllerOnFrontError(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorsApi - factory interface
 * @export
 */
export const ErrorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorsApiFp(configuration)
    return {
        /**
         * Receive frontend error.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorsControllerOnFrontError(options?: any): AxiosPromise<void> {
            return localVarFp.errorsControllerOnFrontError(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorsApi - object-oriented interface
 * @export
 * @class ErrorsApi
 * @extends {BaseAPI}
 */
export class ErrorsApi extends BaseAPI {
    /**
     * Receive frontend error.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorsApi
     */
    public errorsControllerOnFrontError(options?: AxiosRequestConfig) {
        return ErrorsApiFp(this.configuration).errorsControllerOnFrontError(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthzApi - axios parameter creator
 * @export
 */
export const HealthzApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Debugging endpoint to check if the API server is online/working.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzControllerCheck: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/healthz`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthzApi - functional programming interface
 * @export
 */
export const HealthzApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthzApiAxiosParamCreator(configuration)
    return {
        /**
         * Debugging endpoint to check if the API server is online/working.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthzControllerCheck(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthzControllerCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthzApi - factory interface
 * @export
 */
export const HealthzApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthzApiFp(configuration)
    return {
        /**
         * Debugging endpoint to check if the API server is online/working.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthzControllerCheck(options?: any): AxiosPromise<string> {
            return localVarFp.healthzControllerCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthzApi - object-oriented interface
 * @export
 * @class HealthzApi
 * @extends {BaseAPI}
 */
export class HealthzApi extends BaseAPI {
    /**
     * Debugging endpoint to check if the API server is online/working.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthzApi
     */
    public healthzControllerCheck(options?: AxiosRequestConfig) {
        return HealthzApiFp(this.configuration).healthzControllerCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProxyApi - axios parameter creator
 * @export
 */
export const ProxyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Proxy for HTML2Canvas request.
         * @summary 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyControllerProxyHtml2Canvas: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('proxyControllerProxyHtml2Canvas', 'url', url)
            const localVarPath = `/proxy/html2canvas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProxyApi - functional programming interface
 * @export
 */
export const ProxyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProxyApiAxiosParamCreator(configuration)
    return {
        /**
         * Proxy for HTML2Canvas request.
         * @summary 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyControllerProxyHtml2Canvas(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proxyControllerProxyHtml2Canvas(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProxyApi - factory interface
 * @export
 */
export const ProxyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProxyApiFp(configuration)
    return {
        /**
         * Proxy for HTML2Canvas request.
         * @summary 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyControllerProxyHtml2Canvas(url: string, options?: any): AxiosPromise<object> {
            return localVarFp.proxyControllerProxyHtml2Canvas(url, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proxyControllerProxyHtml2Canvas operation in ProxyApi.
 * @export
 * @interface ProxyApiProxyControllerProxyHtml2CanvasRequest
 */
export interface ProxyApiProxyControllerProxyHtml2CanvasRequest {
    /**
     * 
     * @type {string}
     * @memberof ProxyApiProxyControllerProxyHtml2Canvas
     */
    readonly url: string
}

/**
 * ProxyApi - object-oriented interface
 * @export
 * @class ProxyApi
 * @extends {BaseAPI}
 */
export class ProxyApi extends BaseAPI {
    /**
     * Proxy for HTML2Canvas request.
     * @summary 
     * @param {ProxyApiProxyControllerProxyHtml2CanvasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProxyApi
     */
    public proxyControllerProxyHtml2Canvas(requestParameters: ProxyApiProxyControllerProxyHtml2CanvasRequest, options?: AxiosRequestConfig) {
        return ProxyApiFp(this.configuration).proxyControllerProxyHtml2Canvas(requestParameters.url, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SandboxApi - axios parameter creator
 * @export
 */
export const SandboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a sandbox environment.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sandboxControllerCreateCustomerSpace: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sandbox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication defaultBearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SandboxApi - functional programming interface
 * @export
 */
export const SandboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SandboxApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a sandbox environment.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sandboxControllerCreateCustomerSpace(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sandboxControllerCreateCustomerSpace(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SandboxApi - factory interface
 * @export
 */
export const SandboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SandboxApiFp(configuration)
    return {
        /**
         * Create a sandbox environment.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sandboxControllerCreateCustomerSpace(options?: any): AxiosPromise<CustomerDto> {
            return localVarFp.sandboxControllerCreateCustomerSpace(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SandboxApi - object-oriented interface
 * @export
 * @class SandboxApi
 * @extends {BaseAPI}
 */
export class SandboxApi extends BaseAPI {
    /**
     * Create a sandbox environment.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SandboxApi
     */
    public sandboxControllerCreateCustomerSpace(options?: AxiosRequestConfig) {
        return SandboxApiFp(this.configuration).sandboxControllerCreateCustomerSpace(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScriptsApi - axios parameter creator
 * @export
 */
export const ScriptsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * recalculate-all-adoption-insights
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scriptsControllerRecalculateAllAdoptionInsights: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scripts/recalculate-all-adoption-insights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * recalculate-all-insights
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scriptsControllerRecalculateAllInsights: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/scripts/recalculate-all-insights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScriptsApi - functional programming interface
 * @export
 */
export const ScriptsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScriptsApiAxiosParamCreator(configuration)
    return {
        /**
         * recalculate-all-adoption-insights
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scriptsControllerRecalculateAllAdoptionInsights(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scriptsControllerRecalculateAllAdoptionInsights(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * recalculate-all-insights
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scriptsControllerRecalculateAllInsights(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scriptsControllerRecalculateAllInsights(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScriptsApi - factory interface
 * @export
 */
export const ScriptsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScriptsApiFp(configuration)
    return {
        /**
         * recalculate-all-adoption-insights
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scriptsControllerRecalculateAllAdoptionInsights(options?: any): AxiosPromise<string> {
            return localVarFp.scriptsControllerRecalculateAllAdoptionInsights(options).then((request) => request(axios, basePath));
        },
        /**
         * recalculate-all-insights
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scriptsControllerRecalculateAllInsights(options?: any): AxiosPromise<string> {
            return localVarFp.scriptsControllerRecalculateAllInsights(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScriptsApi - object-oriented interface
 * @export
 * @class ScriptsApi
 * @extends {BaseAPI}
 */
export class ScriptsApi extends BaseAPI {
    /**
     * recalculate-all-adoption-insights
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public scriptsControllerRecalculateAllAdoptionInsights(options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).scriptsControllerRecalculateAllAdoptionInsights(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * recalculate-all-insights
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScriptsApi
     */
    public scriptsControllerRecalculateAllInsights(options?: AxiosRequestConfig) {
        return ScriptsApiFp(this.configuration).scriptsControllerRecalculateAllInsights(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a Stripe Checkout Session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateCheckoutSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/create-checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Stripe Billing Portal Session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreatePortalSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/create-portal-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a Stripe Billing Portal Session from a customerId.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreatePortalSessionFromCustomerId: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/create-portal-session-customer-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handle Stripe webhook events.
         * @summary 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerHandleWebhook: async (stripeSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('stripeControllerHandleWebhook', 'stripeSignature', stripeSignature)
            const localVarPath = `/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerReactivateSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stripe/reactivate-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a Stripe Checkout Session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreateCheckoutSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreateCheckoutSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Stripe Billing Portal Session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreatePortalSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreatePortalSession(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a Stripe Billing Portal Session from a customerId.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerCreatePortalSessionFromCustomerId(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerCreatePortalSessionFromCustomerId(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handle Stripe webhook events.
         * @summary 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerHandleWebhook(stripeSignature: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerHandleWebhook(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stripeControllerReactivateSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stripeControllerReactivateSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * Create a Stripe Checkout Session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreateCheckoutSession(options?: any): AxiosPromise<string> {
            return localVarFp.stripeControllerCreateCheckoutSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Stripe Billing Portal Session.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreatePortalSession(options?: any): AxiosPromise<string> {
            return localVarFp.stripeControllerCreatePortalSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Create a Stripe Billing Portal Session from a customerId.
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerCreatePortalSessionFromCustomerId(options?: any): AxiosPromise<string> {
            return localVarFp.stripeControllerCreatePortalSessionFromCustomerId(options).then((request) => request(axios, basePath));
        },
        /**
         * Handle Stripe webhook events.
         * @summary 
         * @param {string} stripeSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerHandleWebhook(stripeSignature: string, options?: any): AxiosPromise<string> {
            return localVarFp.stripeControllerHandleWebhook(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeControllerReactivateSubscription(options?: any): AxiosPromise<object> {
            return localVarFp.stripeControllerReactivateSubscription(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stripeControllerHandleWebhook operation in StripeApi.
 * @export
 * @interface StripeApiStripeControllerHandleWebhookRequest
 */
export interface StripeApiStripeControllerHandleWebhookRequest {
    /**
     * 
     * @type {string}
     * @memberof StripeApiStripeControllerHandleWebhook
     */
    readonly stripeSignature: string
}

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * Create a Stripe Checkout Session.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreateCheckoutSession(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreateCheckoutSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Stripe Billing Portal Session.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreatePortalSession(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreatePortalSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a Stripe Billing Portal Session from a customerId.
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerCreatePortalSessionFromCustomerId(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerCreatePortalSessionFromCustomerId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handle Stripe webhook events.
     * @summary 
     * @param {StripeApiStripeControllerHandleWebhookRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerHandleWebhook(requestParameters: StripeApiStripeControllerHandleWebhookRequest, options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerHandleWebhook(requestParameters.stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeControllerReactivateSubscription(options?: AxiosRequestConfig) {
        return StripeApiFp(this.configuration).stripeControllerReactivateSubscription(options).then((request) => request(this.axios, this.basePath));
    }
}


