import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, useMediaQuery } from '@mui/material'
import React from 'react'
import { theme } from '../../theme'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
  isLoading: boolean
}

export const UnlockContractModal: React.FC<Props> = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      fullWidth
      scroll="paper"
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'hidden' }}
    >
      <Box
        sx={{
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
          boxSizing: 'content-box',
        }}
      >
        <DialogContent
          sx={{ overflowY: 'scroll', maxHeight: 'calc(100vh - 230px)', fontSize: isMobile ? '14px' : '16px' }}
        >
          <b>Le contrat est prêt à l’envoi. Souhaitez-vous le transmettre pour signature ?</b>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
          <Button
            variant="outlined"
            type="button"
            sx={{
              textTransform: 'none',
              px: isMobile ? 'auto' : '50px',
              fontSize: isMobile ? '14px' : '16px',
            }}
            onClick={onClose}
            disabled={isLoading}
          >
            Plus tard
          </Button>
          <LoadingButton
            variant="contained"
            type="button"
            sx={{
              textTransform: 'none',
              fontSize: isMobile ? '14px' : '16px',
            }}
            loading={isLoading}
            onClick={() => onSubmit()}
          >
            Envoyer
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
