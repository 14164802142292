import React, { useEffect, useRef, useState } from 'react'
import { getReadableAnimalAdoptionStatus } from '../../domain/Animal/AnimalDisplay'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, Box, Typography, DialogActions, Button, useMediaQuery } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import {
  AdopterDto,
  AdopterDtoProfileHasBeenAccepted,
  AdoptionAttemptDtoStatus,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
} from '../../interactors/gen/backendClient'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { useMutation } from 'react-query'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { fullName } from '../../domain/Adopters/AccountDisplay'
import { nanoid } from 'nanoid'
import { useAccountsStore } from '../../store/AccountsStore'
import { theme } from '../theme'

const formatPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return undefined

  phoneNumber = phoneNumber.trim()

  if (phoneNumber.startsWith('+33')) return phoneNumber

  if (phoneNumber.startsWith('0')) return `+33${phoneNumber.substring(1)}`

  return phoneNumber
}

interface Props {
  animalId: string | null
  type: string | null
}

export const DesiredAnimalConfirmModal: React.FC<Props> = ({ animalId, type }) => {
  const queryParams = new URLSearchParams(location.search)
  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const adoptersStore = useAdoptersStore()
  const accountsStore = useAccountsStore()
  const [animal, setAnimal] = useState<AnimalDto | null>(null)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const prevAnimalId = useRef<string | null>(animalId)

  useEffect(() => {
    if (animalId === prevAnimalId.current) {
      return
    }

    if (animalId) {
      const loadAnimal = async () => {
        await AnimalsClient.getAnimal(animalId)
          .then((animal) => {
            setAnimal(animal)
          })
          .catch((_error) => {
            globalSnackBarStore.triggerErrorMessage(`L'animal n'a pas été trouvé.`)
          })
      }
      loadAnimal()
    }

    prevAnimalId.current = animalId
  }, [animalId])

  const editAdopterMutation = useMutation(
    async (data: AdopterDto) => {
      const response = await AdoptersClient.editAccount(data)
      return response
    },
    {
      onSuccess: (account) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${account.firstName} a été modifié.`)
        navigate(`/suivi-adoptant/${account.id}?tab=profil&assign=success`)
      },
    }
  )

  const editAnimalMutation = useMutation(
    async (data: AnimalDto) => {
      const response = await AnimalsClient.editAnimal(data)
      return response
    },
    {
      onSuccess: (animal) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${animal.name} a été modifié.`)
        navigate(`/suivi-adoptant/${animal.adoption.adopterId}?tab=past-adoption`)
      },
    }
  )

  const onSubmitPastAdoption = () => {
    if (queryParams.get('adopterId') && animal) {
      suspendAdoptionAttempt()
      const updatedAnimal: AnimalDto = {
        ...animal,
        adoption: {
          adopterId: queryParams.get('adopterId')!,
          status: AnimalAdoptionDtoStatus.HasBeenAdopted,
          step: AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit,
          adoptionDate: undefined,
          exitDate: undefined,
          vpaDate: undefined,
        },
      }
      if (adopter) {
        adopter.profileHasBeenAccepted = AdopterDtoProfileHasBeenAccepted.Accepted
        if (!adopter.adoptionAttempts?.some((attempt) => attempt.animalId === animal.id)) {
          adopter.adoptionAttempts = adopter.adoptionAttempts || []
          adopter.adoptionAttempts.push({
            id: nanoid(),
            animalId: animal.id,
            status: AdoptionAttemptDtoStatus.Done,
            step: 6,
            done: true,
            paymentDone: false,
            verifiedPaymentInfo: false,
            verifiedPriceInfo: false,
            customerName: accountsStore.connectedCustomer?.name || '',
            events: [],
            insights: [],
          })
        } else {
          adopter.adoptionAttempts = adopter.adoptionAttempts.map((attempt) => {
            if (attempt.animalId === animal.id) {
              attempt.status = AdoptionAttemptDtoStatus.Done
              attempt.step = 6
              attempt.done = true
            }
            return attempt
          })
        }
        AdoptersClient.editAccount(adopter)
      }
      editAnimalMutation.mutate(updatedAnimal)
    } else {
      globalSnackBarStore.triggerErrorMessage(`L'adoptant ou l'animal n'ont pas été trouvé(s).`)
    }
  }

  const onSubmit = () => {
    if (queryParams.get('adopterId')) {
      const adopter = adoptersStore.adopters.find((anAdopter) => anAdopter.id === queryParams.get('adopterId'))
      if (adopter) {
        const newAdopter: AdopterDto = {
          ...adopter!,
          desiredAnimal: {
            hasDesiredAnimal: true,
            desiredAnimalId: animalId!,
          },
          phoneNumber: formatPhoneNumber(adopter.phoneNumber),
        }
        suspendAdoptionAttempt()
        setAnimalAdoptionStatusToAdoptable()
        editAdopterMutation.mutate(newAdopter)
      }
    } else {
      globalSnackBarStore.triggerErrorMessage(`L'adoptant n'a pas été trouvé.`)
    }
  }

  const suspendAdoptionAttempt = () => {
    if (animal && animal.adoption.adopterId) {
      const adopter = adoptersStore.adopters.find((anAdopter) => anAdopter.id === animal.adoption.adopterId)
      if (adopter && adopter.adoptionAttempts?.some((attempt) => attempt.animalId === animal.id)) {
        adopter.adoptionAttempts = adopter.adoptionAttempts.map((attempt) => {
          if (attempt.animalId === animal.id) {
            attempt.status = AdoptionAttemptDtoStatus.SuspendedByCustomer
          }
          return attempt
        })
        AdoptersClient.editAccount(adopter)
      }
    }
  }

  const setAnimalAdoptionStatusToAdoptable = () => {
    if (animal && animal.adoption.status !== AnimalAdoptionDtoStatus.Adoptable) {
      animal.adoption.status = AnimalAdoptionDtoStatus.Adoptable
      animal.adoption.step = undefined
      animal.adoption.adopterId = undefined
      animal.adoption.adoptionDate = undefined
      animal.adoption.exitDate = undefined
      animal.adoption.vpaDate = undefined
      AnimalsClient.editAnimal(animal)
    }
  }

  useEffect(() => {
    if (animalId) {
      openDialog()
    }
  }, [animalId])

  const adopter = adoptersStore.adopters.find((anAdopter) => anAdopter.id === queryParams.get('adopterId'))
  const animalAdopter = animal?.adoption.adopterId
    ? adoptersStore.adopters.find((anAdopter) => anAdopter.id === animal?.adoption.adopterId)
    : null

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      PaperProps={{
        style: { borderRadius: 15, padding: 15 },
      }}
    >
      <DialogContent sx={{ pb: isMobile ? 0 : 'auto' }}>
        {animal && (
          <Box>
            <Typography variant="h4" component="h2" marginBottom={4} fontSize={20}>
              {`Vous avez sélectionné ${animal.name} comme ${
                type === 'pastAdoption' ? 'ayant été adopté par' : "étant le souhait d'adoption de"
              } ${adopter && fullName(adopter)}`}
            </Typography>
            {type !== 'pastAdoption' && animal.adoption.status !== AnimalAdoptionDtoStatus.Adoptable && (
              <Typography variant="h4" component="h2" marginBottom={4} fontSize={15} color="black">
                <span style={{ fontWeight: 700 }}>
                  <Link style={{ color: 'black' }} to={`/animaux/${animal.id}`}>
                    {animal.name}
                  </Link>{' '}
                  {` est actuellement en statut d'adoption: ${
                    animal.adoption.status ? `"${getReadableAnimalAdoptionStatus(animal.adoption.status)}"` : 'Erreur'
                  }.`}
                </span>
                {`S'il s'agit bien du souhait principal de ${
                  adopter && fullName(adopter)
                } et que vous souhaitez le valider, son statut d'adoption passera automatiquement en "Adoptable".`}
                <br />⚠ Attention si une procédure d&#39;adoption est en cours, elle sera suspendue.
              </Typography>
            )}
            {type === 'pastAdoption' && animal.adoption.status !== AnimalAdoptionDtoStatus.HasBeenAdopted && (
              <Typography variant="h4" component="h2" marginBottom={4} fontSize={15} color="black">
                <span style={{ fontWeight: 700 }}>
                  <Link style={{ color: 'black' }} to={`/animaux/${animal.id}`}>
                    {animal.name}
                  </Link>{' '}
                  {` est actuellement en statut d'adoption: ${
                    animal.adoption.status ? `"${getReadableAnimalAdoptionStatus(animal.adoption.status)}"` : 'Erreur'
                  }.`}
                </span>
                {`S'il s'agit bien de l'animal adopté par ${
                  adopter && fullName(adopter)
                } et que vous souhaitez le valider, son statut d'adoption passera automatiquement en "Adopté".`}
                <br />⚠ Attention si une procédure d&#39;adoption est en cours, elle sera suspendue.
              </Typography>
            )}
            {type === 'pastAdoption' &&
              animal.adoption.adopterId != null &&
              animal.adoption.status === AnimalAdoptionDtoStatus.HasBeenAdopted && (
                <Typography variant="h4" component="h2" marginBottom={4} fontSize={15} color="black">
                  <span style={{ fontWeight: 700 }}>
                    <Link style={{ color: 'black' }} to={`/animaux/${animal.id}`}>
                      {animal.name}
                    </Link>{' '}
                    {` a déjà été adopté par: ${animalAdopter ? `"${fullName(animalAdopter)}"` : 'Erreur'}.`}
                  </span>
                  {`Si vous pensez que c'est une erreur, vous pouvez confirmer que ${
                    adopter ? fullName(adopter) : 'Erreur'
                  } est bien l'adoptant, ce qui aura pour effet de remplacer l'adoptant actuel.`}
                  <br />⚠ Attention si une procédure d&#39;adoption est en cours, elle sera suspendue.
                </Typography>
              )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent={isMobile ? 'auto' : 'flex-end'}
          gap={1}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: isMobile ? '14px' : '16px',
              width: isMobile ? '100%' : 'auto',
              px: isMobile ? 'auto' : '50px',
            }}
            onClick={() => closeDialog()}
          >
            Annuler
          </Button>
          {type !== 'pastAdoption' ? (
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              sx={{ textTransform: 'none', fontSize: isMobile ? '14px' : '16px', width: isMobile ? '100%' : 'auto' }}
              onClick={() => onSubmit()}
            >
              {animal?.adoption.status !== AnimalAdoptionDtoStatus.Adoptable ? 'Valider quand même' : 'Valider'}
            </LoadingButton>
          ) : (
            <LoadingButton
              data-testid="apply-changes"
              variant="contained"
              sx={{ textTransform: 'none', fontSize: isMobile ? '14px' : '16px', width: isMobile ? '100%' : 'auto' }}
              onClick={() => onSubmitPastAdoption()}
            >
              {animal?.adoption.status !== AnimalAdoptionDtoStatus.HasBeenAdopted || animal.adoption.adopterId != null
                ? 'Valider quand même'
                : 'Valider'}
            </LoadingButton>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}
