import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Card, Grid, Typography, useMediaQuery } from '@mui/material'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import {
  AccountDtoPermission,
  AdopterDto,
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
  CustomerDto,
} from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useAdoptersStore } from '../../../store/AdoptersStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { useFetchOrRefreshAdoptersOnMount } from '../../../store/useFetchOrRefreshAdoptersOnMount'
import { useFetchOrRefreshMembersOnMount } from '../../../store/useFetchOrRefreshMembersOnMount'
import { readableAxiosError } from '../../../utils/axios'
import { getAdoptionAttemptStatus } from '../../../utils/getAdoptionAttemptStatus'
import { CollapsableCard } from '../../common/CollapsableCard'
import { spacingItem, theme } from '../../theme'
import { AllAdoptionInsightsInfoBox } from './AllAdoptionInsightsInfoBox'
import { ContributionCard } from './ContributionCard'
import { AdoptionAttemptEvents } from './Events'
import { ExitDatesCard } from './ExitDatesCard'
import { ExitDocumentCard } from './ExitDocumentCard'
import { ExitDocumentsCard } from './ExitDocumentsCard'
import { FileCard } from './FileCard'
import { OnGoingAdoptionAnimalCard } from './OnGoingAdoptionAnimalCard'
import { RestartProcedureModal } from './RestartProcedureModal'
import { SuspendProcedureModal } from './SuspendProcedureModal'
import { UnlockContractModal } from './UnlockContractModal'
import { SterilizationCard } from './SterilizationCard'

interface Props {
  adopter: AdopterDto
}

export const OnGoingAdoptionScreen: React.FC<Props> = ({ adopter }) => {
  const queryParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [adoptionAttempt, setAdoptionAttempt] = useState<AdoptionAttemptDto | undefined>(undefined)
  const [animal, setAnimal] = useState<AnimalDto | undefined>(undefined)
  const [association, setAssociation] = useState<CustomerDto | null>(null)
  const animalId = adoptionAttempt?.animalId
  const adoptersStore = useAdoptersStore()
  const [restartModalOpen, setRestartModalOpen] = useState(false)
  const [suspendModalOpen, setSuspendModalOpen] = useState(false)
  const [unlockModalOpen, setUnlockModalOpen] = useState(false)

  const onCloseUnlockModal = () => setUnlockModalOpen(false)
  const onCloseRestartModal = () => setRestartModalOpen(false)
  const onCloseSuspendModal = () => setSuspendModalOpen(false)

  useFetchOrRefreshMembersOnMount()
  useFetchOrRefreshAdoptersOnMount()

  useEffect(() => {
    if (queryParams.get('attemptId') != null) {
      const attempt = adopter.adoptionAttempts?.find(
        (adoptionAttempt) => adoptionAttempt.id === queryParams.get('attemptId')
      )
      setAdoptionAttempt(attempt)
    }
  }, [adopter.adoptionAttempts])

  useEffect(() => {
    const fetchAnimal = async () => {
      if (animalId == null) return
      const animal = await AnimalsClient.getAnimal(animalId)
      if (animal) {
        setAnimal(animal)
      }
    }
    const loadAssociation = async () => {
      const selectedAssociation = await CustomersClient.getCurrentCustomer()
      setAssociation(selectedAssociation || null)
    }

    loadAssociation()
    fetchAnimal()
  }, [animalId])

  const updateAttemptMutation = useMutation(
    async (data: AdoptionAttemptDto) => {
      const response: AdoptionAttemptDto = await AdoptersClient.updateAdoptionAttempt(adopter.id, data)
      return response
    },
    {
      onSuccess: (attempt) => {
        if (
          adoptionAttempt?.contract?.isLocked &&
          !adoptionAttempt?.certificate?.validated &&
          attempt.certificate?.validated &&
          attempt.contract?.original &&
          attempt.contract?.original !== ''
        ) {
          setUnlockModalOpen(true)
        }
        setAdoptionAttempt(attempt)
        adopter.adoptionAttempts = adopter.adoptionAttempts?.map((a) => (a.id === attempt.id ? attempt : a))
        globalSnackBarStore.triggerSuccessMessage(`Vos modifications ont bien été enregistrées.`)
      },
      onError: (error: Error | AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const deleteAttempt = () => {
    if (adoptionAttempt == null) return
    adopter.adoptionAttempts = adopter.adoptionAttempts?.filter((attempt) => attempt.id !== adoptionAttempt.id)
    AdoptersClient.editAccount(adopter)
    globalSnackBarStore.triggerSuccessMessage(`La procédure d'adoption a bien été supprimée.`)
    navigate(`/suivi-adoptant/${adopter.id}?tab=profil`)
  }

  const onClickUnlockContract = async () => {
    if (adoptionAttempt && adoptionAttempt.contract) {
      const newAttempt = {
        ...adoptionAttempt,
        contract: {
          ...adoptionAttempt.contract,
          isLocked: false,
        },
      }
      setAdoptionAttempt(newAttempt)
      await updateAttemptMutation.mutateAsync(newAttempt)
      setUnlockModalOpen(false)
    }
  }

  const onClickEnd = async (withAnimal = false) => {
    if (adoptionAttempt && animal) {
      await updateAttemptMutation.mutateAsync({
        ...adoptionAttempt,
        done: true,
        archived: true,
      })
      if (withAnimal) {
        animal.adoption.status = AnimalAdoptionDtoStatus.HasBeenAdopted
        animal.adoption.adopterId = adopter.id
        animal.adoption.step = AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit
        await AnimalsClient.editAnimal(animal)
      }
      return navigate(`/suivi-adoptant/${adopter.id}?tab=past-adoption&attemptId=${adoptionAttempt.id}`)
    }
  }

  const setAnimalAdoptionStatusToAdoptable = () => {
    if (animal && animal.adoption.status !== AnimalAdoptionDtoStatus.Adoptable) {
      animal.adoption.status = AnimalAdoptionDtoStatus.Adoptable
      animal.adoption.adopterId = ''
      AnimalsClient.editAnimal(animal)
    }
  }

  const onClickSuspendAttempt = async () => {
    if (!adoptionAttempt) return
    const newAttempt = {
      ...adoptionAttempt,
      status: AdoptionAttemptDtoStatus.SuspendedByCustomer,
    }
    setAdoptionAttempt(newAttempt)
    setAnimalAdoptionStatusToAdoptable()
    await updateAttemptMutation.mutateAsync(newAttempt)
    setSuspendModalOpen(false)
    navigate(`/suivi-adoptant/${adopter.id}?tab=suspended-adoption&attemptId=${adoptionAttempt.id}`)
  }

  const onClickRestartAttempt = async (confirmation?: boolean) => {
    if (!adoptionAttempt || !animal) return

    if (animal.adoption.adopterId && animal.adoption.adopterId !== adopter.id && !confirmation) {
      return setRestartModalOpen(true)
    }
    if (confirmation) {
      const oldAdopter = adoptersStore.adopters.find((a) => a.id === animal.adoption.adopterId)
      if (oldAdopter) {
        const attempt = oldAdopter.adoptionAttempts?.find(
          (a) => a.animalId === adoptionAttempt.animalId && a.status !== AdoptionAttemptDtoStatus.SuspendedByCustomer
        )
        if (attempt) {
          attempt.status = AdoptionAttemptDtoStatus.SuspendedByCustomer
          await AdoptersClient.updateAdoptionAttempt(oldAdopter.id, attempt)
        }
      }
    }
    await updateAttemptMutation.mutateAsync({
      ...adoptionAttempt,
      id: adoptionAttempt.id,
      animalId: adoptionAttempt.animalId,
      status: AdoptionAttemptDtoStatus.PendingUser,
      step: adoptionAttempt.step,
      done: false,
      customerName: adoptionAttempt.customerName,
    })
    animal.adoption.status = AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable
    animal.adoption.adopterId = adopter.id
    animal.adoption.step = undefined
    animal.adoption.vpaDate = undefined
    animal.adoption.exitDate = undefined
    animal.adoption.adoptionDate = undefined
    await AnimalsClient.editAnimal(animal)
    navigate(`/suivi-adoptant/${adopter.id}?tab=ongoing-adoption&attemptId=${adoptionAttempt.id}`)
  }

  const accountsStore = useAccountsStore()
  const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read

  if (adoptionAttempt == null || animal == null) return null

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: isMobile ? 'auto' : 'white',
        p: isMobile ? '0px' : '30px',
      }}
    >
      {/* 1st Box: "<Retour", "Marquer comme terminée" */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
          px: 2,
          pt: isMobile ? 2 : 'auto',
        }}
      >
        <Box display="flex">
          <ChevronLeftIcon />
          <Typography
            sx={{
              width: 'fit-content',
              color: 'black',
              fontSize: 16,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => navigate(`/suivi-adoptant/${adopter.id}?tab=${queryParams.get('tab')}`)}
          >
            Retour
          </Typography>
        </Box>
        {/* Button: "Marquer comme terminée" */}
        {adoptionAttempt.status !== 'suspended-by-customer' &&
          adoptionAttempt.status !== 'suspended-by-user' &&
          !adoptionAttempt.archived && (
            <LoadingButton
              color="secondary"
              sx={{ textTransform: 'none', fontSize: isMobile ? '14px' : '16px' }}
              variant="contained"
              loading={updateAttemptMutation.isLoading}
              onClick={() => onClickEnd(true)}
              disabled={isReader}
            >
              Marquer comme terminée
            </LoadingButton>
          )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          px: isMobile ? 0 : '15px',
        }}
      >
        {/* Animal card */}
        <Grid container spacing={2} paddingX={0}>
          <Grid item xs={12} md={12} paddingX={0}>
            <OnGoingAdoptionAnimalCard animal={animal} status={getAdoptionAttemptStatus(adoptionAttempt)} />
            <AllAdoptionInsightsInfoBox attempt={adoptionAttempt} />
          </Grid>
        </Grid>
        {/* If adoption suspension: Buttons: delete, start again */}
        {(adoptionAttempt.status === AdoptionAttemptDtoStatus.SuspendedByCustomer ||
          adoptionAttempt.status === AdoptionAttemptDtoStatus.SuspendedByUser) && (
          <Box
            sx={{
              ...spacingItem,
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              width: '100%',
              px: '16px',
              gap: 1,
            }}
          >
            <LoadingButton
              variant="contained"
              color="error"
              sx={{ textTransform: 'none', width: isMobile ? '100%' : 'auto' }}
              onClick={() => deleteAttempt()}
              disabled={isReader}
            >
              Supprimer l&#39;adoption
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={updateAttemptMutation.isLoading}
              disabled={isReader}
              onClick={() => onClickRestartAttempt()}
              sx={{ textTransform: 'none', width: isMobile ? '100%' : 'auto' }}
            >
              Reprendre l&#39;adoption
            </LoadingButton>
          </Box>
        )}
        {/* Adoption documents */}
        {adoptionAttempt.status !== AdoptionAttemptDtoStatus.SuspendedByCustomer &&
          adoptionAttempt.status !== AdoptionAttemptDtoStatus.SuspendedByUser && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  mt: 2,
                }}
              >
                {/* Suivi d'adoption */}
                <AdoptionAttemptEvents
                  animal={animal}
                  attempt={adoptionAttempt}
                  editAttemptMutation={updateAttemptMutation}
                />
                {/* Certificat & contract */}
                <CollapsableCard defaultExpanded title="Certificat et Contrat">
                  <Grid container spacing={isMobile ? 0 : 2}>
                    {/* Knowledge certificate */}
                    <Grid item xs={12} md={6}>
                      {!adoptionAttempt?.certificate?.original && !adoptionAttempt?.certificate?.signed ? (
                        <FileCard
                          title="Certificat de connaissance à envoyer"
                          description={`${adopter.firstName} attend le certificat de connaissance, veuillez le lui envoyer au plus vite.`}
                          adopter={adopter}
                          animal={animal}
                          adoptionAttemptId={adoptionAttempt.id}
                          adoptionAttempt={adoptionAttempt}
                          type="certificate"
                          setAdoptionAttempt={setAdoptionAttempt}
                          isReader={isReader}
                          specie={animal.species}
                          association={association}
                        />
                      ) : !adoptionAttempt?.certificate?.signed ? (
                        <FileCard
                          title={`Certificat de connaissance envoyé le ${
                            adoptionAttempt?.certificate?.originalDate
                              ? dayjs(adoptionAttempt?.certificate?.originalDate).format('DD/MM/YYYY')
                              : ''
                          }`}
                          type="certificate"
                          originalFileKey={adoptionAttempt.certificate?.original}
                          skipProcedure
                          description={
                            !adoptionAttempt?.certificate?.deliveredByHand
                              ? `${adopter.firstName} vous transmettra le document signé via son propre espace Petso. Vous pourrez valider la réception du certificat une fois signé par l'adoptant.`
                              : `Vous avez transmis par vos propres moyens le certificat. Pensez à l’enregistrer une fois reçu pour conserver un suivi complet de l’adoption`
                          }
                          adoptionAttemptId={adoptionAttempt.id}
                          adopter={adopter}
                          setAdoptionAttempt={setAdoptionAttempt}
                        />
                      ) : !adoptionAttempt?.certificate?.validated ? (
                        <FileCard
                          title="Certificat de connaissance signé"
                          description={`${adopter.firstName} a signé le certificat de connaissance, vous pouvez le télécharger ci-dessous et le valider`}
                          fileKey={adoptionAttempt.certificate?.signed}
                          type="certificate"
                          validation
                          adopter={adopter}
                          adoptionAttemptId={adoptionAttempt.id}
                          adoptionAttempt={adoptionAttempt}
                          setAdoptionAttempt={setAdoptionAttempt}
                          isReader={isReader}
                          updateAttemptMutation={updateAttemptMutation}
                        />
                      ) : (
                        <ExitDocumentCard
                          title={'Certificat signé et validé'}
                          type={'knowledge-certificate'}
                          adopterId={adopter.id}
                          adoptionAttemptId={adoptionAttempt.id}
                          originalFileKey={adoptionAttempt.certificate?.signed || ''}
                          disabled={isReader}
                        />
                      )}
                    </Grid>
                    {/* Adoption contract */}
                    <Grid item xs={12} md={6}>
                      {!adoptionAttempt?.contract?.original ? (
                        <FileCard
                          title="Contrat d'adoption à envoyer"
                          description={`${adopter.firstName} attend le contrat d'adoption, veuillez le lui envoyer au plus vite.`}
                          adopter={adopter}
                          animal={animal}
                          adoptionAttemptId={adoptionAttempt.id}
                          adoptionAttempt={adoptionAttempt}
                          type="contract"
                          setAdoptionAttempt={setAdoptionAttempt}
                          isReader={isReader}
                          association={association}
                          isLocked={adoptionAttempt?.contract?.isLocked}
                        />
                      ) : !adoptionAttempt?.contract?.signed ? (
                        <FileCard
                          title={`Contrat d'adoption envoyé le ${
                            adoptionAttempt?.contract?.originalDate
                              ? dayjs(adoptionAttempt?.contract?.originalDate).format('DD/MM/YYYY')
                              : ''
                          }`}
                          type="contract"
                          originalFileKey={adoptionAttempt.contract?.original}
                          description={
                            adoptionAttempt?.contract?.isLocked
                              ? "Le contrat est verrouillé, débloquez le pour que l'adoptant puisse y avoir accès et le signer."
                              : !adoptionAttempt?.contract?.deliveredByHand
                              ? `${adopter.firstName} vous transmettra le document signé via son propre espace Petso. Vous pourrez valider la réception du contrat une fois signé par l'adoptant.`
                              : `Vous avez transmis par vos propres moyens le contrat. Pensez à l’enregistrer une fois reçu pour conserver un suivi complet de l’adoption`
                          }
                          skipProcedure
                          adoptionAttemptId={adoptionAttempt.id}
                          adopter={adopter}
                          setAdoptionAttempt={setAdoptionAttempt}
                          updateAttemptMutation={updateAttemptMutation}
                          adoptionAttempt={adoptionAttempt}
                          isLocked={adoptionAttempt?.contract?.isLocked}
                        />
                      ) : !adoptionAttempt?.contract?.validated ? (
                        <FileCard
                          title="Contrat d'adoption signé"
                          description={`${adopter.firstName} a signé le contrat d'adoption, vous pouvez le télécharger ci-dessous et le valider`}
                          fileKey={adoptionAttempt.contract?.signed}
                          type="contract"
                          validation
                          adopter={adopter}
                          adoptionAttemptId={adoptionAttempt.id}
                          adoptionAttempt={adoptionAttempt}
                          setAdoptionAttempt={setAdoptionAttempt}
                          isReader={isReader}
                          updateAttemptMutation={updateAttemptMutation}
                        />
                      ) : (
                        <ExitDocumentCard
                          title={'Contrat signé et validé'}
                          type={'adoption-contract'}
                          adopterId={adopter.id}
                          adoptionAttemptId={adoptionAttempt.id}
                          originalFileKey={adoptionAttempt.contract?.signed || ''}
                          disabled={isReader}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CollapsableCard>
                <SterilizationCard
                  adoptionAttempt={adoptionAttempt}
                  adopter={adopter}
                  updateAttemptMutation={updateAttemptMutation}
                  setAdoptionAttempt={setAdoptionAttempt}
                  isReader={isReader}
                />
                <ContributionCard
                  adoptionAttempt={adoptionAttempt}
                  updateAttemptMutation={updateAttemptMutation}
                  animal={animal}
                  isReader={isReader}
                />
                <ExitDatesCard animal={animal} adopter={adopter} defaultExpanded endProcedure={onClickEnd} />
                <ExitDocumentsCard
                  adoptionAttempt={adoptionAttempt}
                  adopterId={adopter.id}
                  defaultExpanded
                  isReader={isReader}
                />
                {isMobile ? (
                  // MOBILE
                  <Card
                    sx={{
                      width: '100%',
                      mb: '100px',
                      p: '16px',
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap={1}>
                      {!adoptionAttempt.archived && (
                        <LoadingButton
                          variant="contained"
                          color="secondary"
                          sx={{ textTransform: 'none', width: '100%' }}
                          loading={updateAttemptMutation.isLoading}
                          onClick={() => onClickEnd(true)}
                          disabled={isReader}
                        >
                          Marquer comme terminée
                        </LoadingButton>
                      )}

                      <Button
                        variant="outlined"
                        sx={{ textTransform: 'none', width: '100%' }}
                        color="error"
                        onClick={() => setSuspendModalOpen(true)}
                      >
                        Suspendre l&#39;adoption
                      </Button>
                    </Box>
                  </Card>
                ) : (
                  // DESKTOP
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      gap: 2,
                      width: '100%',
                      mt: 2,
                    }}
                  >
                    {!adoptionAttempt.archived && (
                      <LoadingButton
                        variant="contained"
                        color="secondary"
                        sx={{ textTransform: 'none', fontSize: '16px' }}
                        loading={updateAttemptMutation.isLoading}
                        onClick={() => onClickEnd(true)}
                        disabled={isReader}
                      >
                        Marquer comme terminée
                      </LoadingButton>
                    )}
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ textTransform: 'none', fontSize: '16px' }}
                      onClick={() => setSuspendModalOpen(true)}
                    >
                      Suspendre l&#39;adoption
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          )}
      </Box>
      <RestartProcedureModal onClose={onCloseRestartModal} isOpen={restartModalOpen} onSubmit={onClickRestartAttempt} />
      <SuspendProcedureModal
        onClose={onCloseSuspendModal}
        isOpen={suspendModalOpen}
        onSubmit={onClickSuspendAttempt}
        animalName={animal.name}
        isLoading={updateAttemptMutation.isLoading}
      />
      <UnlockContractModal
        onClose={onCloseUnlockModal}
        isOpen={unlockModalOpen}
        onSubmit={onClickUnlockContract}
        isLoading={updateAttemptMutation.isLoading}
      />
    </Box>
  )
}
