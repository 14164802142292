import { Paper, Typography, List, Box, IconButton, ListItem } from '@mui/material'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CustomerPdfTemplateVariableZoneDto } from '../../interactors/gen/backendClient'
import { DragIndicator, Delete } from '@mui/icons-material'
import React, { useRef } from 'react'
import { translateVariablePlaceholder } from '../../domain/Customer/TemplateVariables'

interface Props {
  variableZones: CustomerPdfTemplateVariableZoneDto[]
  selectedVariableZoneIndex: number | null
  handleVariableZoneClick: (index: number, fromMenu?: boolean) => void
  handleDeleteVariableZone: (index: number) => void
  setVariableZones: React.Dispatch<React.SetStateAction<CustomerPdfTemplateVariableZoneDto[]>>
}

export const VariableZoneList: React.FC<Props> = ({
  variableZones,
  selectedVariableZoneIndex,
  handleVariableZoneClick,
  handleDeleteVariableZone,
  setVariableZones,
}) => {
  const moveVariableZone = (fromIndex: number, toIndex: number) => {
    const updatedVariableZones = [...variableZones]
    const [movedVariableZone] = updatedVariableZones.splice(fromIndex, 1)
    updatedVariableZones.splice(toIndex, 0, movedVariableZone)
    setVariableZones(updatedVariableZones)
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Paper elevation={2} sx={{ p: 2, mt: 2, width: '250px', borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
          Variables du Document
        </Typography>
        <List dense sx={{ maxHeight: 400, overflowY: 'auto' }}>
          {variableZones.map((zone, index) => (
            <DraggableVariableZone
              key={index}
              zone={zone}
              index={index}
              selectedVariableZoneIndex={selectedVariableZoneIndex}
              handleVariableZoneClick={() => handleVariableZoneClick(index, true)}
              handleDeleteVariableZone={handleDeleteVariableZone}
              moveVariableZone={moveVariableZone}
            />
          ))}
        </List>
      </Paper>
    </DndProvider>
  )
}

const ItemType = 'zone'

interface DragItem {
  index: number
  type: string
}

const DraggableVariableZone: React.FC<{
  zone: CustomerPdfTemplateVariableZoneDto
  index: number
  selectedVariableZoneIndex: number | null
  handleVariableZoneClick: (index: number) => void
  handleDeleteVariableZone: (index: number) => void
  moveVariableZone: (dragIndex: number, hoverIndex: number) => void
}> = ({
  zone,
  index,
  selectedVariableZoneIndex,
  handleVariableZoneClick,
  handleDeleteVariableZone,
  moveVariableZone,
}) => {
  const ref = useRef<HTMLLIElement>(null)

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item: DragItem) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex !== hoverIndex) {
        moveVariableZone(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))

  return (
    <ListItem
      ref={ref}
      onClick={() => handleVariableZoneClick(index)}
      selected={selectedVariableZoneIndex === index}
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: selectedVariableZoneIndex === index ? 'rgba(255, 228, 181, 0.3)' : 'transparent',
        opacity: isDragging ? 0.5 : 1,
        transition: 'background-color 0.2s ease-in-out',
        cursor: 'pointer',
        pl: 0,
        mb: 1,
        pb: 1,
        borderBottom: '1px solid #ddd',
        '&:hover': {
          backgroundColor: 'rgba(255, 228, 181, 0.2)',
        },
      }}
    >
      {/* Drag Handle */}
      <DragIndicator fontSize="small" sx={{ color: 'grey.500', mr: 1, cursor: 'grab' }} />

      <Typography variant="body2" sx={{ color: 'text.primary', mr: 1 }}>
        {index + 1}
      </Typography>

      {/* VariableZone details */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pl: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
          {translateVariablePlaceholder(zone.variable)}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Page {zone.page}
        </Typography>
      </Box>

      {/* Delete Button */}
      <IconButton
        edge="end"
        onClick={(e) => {
          e.stopPropagation()
          handleDeleteVariableZone(index)
        }}
        size="small"
        color="error"
      >
        <Delete fontSize="small" />
      </IconButton>
    </ListItem>
  )
}
