import { AdopterDto, AdoptionAttemptDto, CreateAdopterBodyDto } from '../gen/backendClient'
import { backendClient } from './client'
import { AxiosRequestConfig } from 'axios'

export class AdoptersClient {
  public static async createAdopter(params: CreateAdopterBodyDto): Promise<AdopterDto> {
    const config: AxiosRequestConfig<CreateAdopterBodyDto> = {
      method: 'POST',
      url: '/adopters',
      data: params,
    }

    const response = await backendClient<AdopterDto>(config)

    return response.data
  }

  public static async getAllAdopters(): Promise<Array<AdopterDto>> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/adopters',
    }

    const response = await backendClient<Array<AdopterDto>>(config)

    return response.data
  }

  public static async editAccount(params: AdopterDto): Promise<AdopterDto> {
    const config: AxiosRequestConfig<AdopterDto> = {
      method: 'PUT',
      url: '/adopters',
      data: {
        ...params,
      },
    }

    const response = await backendClient<AdopterDto>(config)

    return response.data
  }

  public static async sendForm(adopterId: string): Promise<AdopterDto> {
    const config: AxiosRequestConfig<AdopterDto> = {
      method: 'POST',
      url: `/adopters/${adopterId}/send-form`,
    }

    const response = await backendClient<AdopterDto>(config)

    return response.data
  }

  public static async deleteAdopter(adopterId: string): Promise<void> {
    const config: AxiosRequestConfig<{ adopterId: string }> = {
      method: 'DELETE',
      url: '/adopters',
      data: {
        adopterId,
      },
    }

    await backendClient<void>(config)
  }

  public static async newAdoptionAttempt(adopterId: string, animalId: string): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/adopters/${adopterId}/adoption-attempts/${animalId}`,
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async addOriginalDocumentToAdoptionAttempt(
    adopterId: string,
    adoptionAttemptId: string,
    formData: FormData,
    type: 'certificate' | 'contract'
  ): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters/${adopterId}/adoption-attempts/${adoptionAttemptId}/original-document?type=${type}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async addSignedCertificateOrContractToAdoptionAttempt(
    adopterId: string,
    adoptionAttemptId: string,
    type: 'certificate' | 'contract' | 'sterilization',
    formData: FormData
  ): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters/${adopterId}/adoption-attempts/${adoptionAttemptId}/signed-certificate?type=${type}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async updateAdoptionAttempt(adopterId: string, data: AdoptionAttemptDto): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters/${adopterId}/adoption-attempts/${data.id}`,
      data: data,
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async goNextStepAdoptionAttempt(adopterId: string, attemptId: string): Promise<AdoptionAttemptDto> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters/${adopterId}/adoption-attempts/${attemptId}/next-step`,
    }

    const response = await backendClient<AdoptionAttemptDto>(config)

    return response.data
  }

  public static async addDocumentToAdoptionAttempt(
    adopterId: string,
    attemptId: string,
    formData: FormData
  ): Promise<string> {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/adopters/${adopterId}/adoption-attempts/${attemptId}/documents`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    const response = await backendClient<string>(config)

    return response.data
  }

  public static async generateCertificate(animalId: string): Promise<Blob> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/adopters/generate/certificate/${animalId}`,
      responseType: 'blob',
    }

    const response = await backendClient<Blob>(config)

    return response.data
  }
}
