import { UploadFile } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, Typography, useMediaQuery } from '@mui/material'
import { blue } from '@mui/material/colors'
import { AxiosError } from 'axios'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useMutation } from 'react-query'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { AdopterDto, AdoptionAttemptDto } from '../../../interactors/gen/backendClient'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { setOpacity } from '../../../utils/setOpacity'
import { colors, spacingItem, theme } from '../../theme'

interface Props {
  adopter: AdopterDto
  adoptionAttemptId: string
  setAdoptionAttempt: React.Dispatch<React.SetStateAction<AdoptionAttemptDto | undefined>>
  type: 'certificate' | 'contract' | 'sterilization'
}

export const SkipDocumentProcedureModal: React.FC<Props> = ({
  adopter,
  adoptionAttemptId,
  setAdoptionAttempt,
  type,
}) => {
  const globalSnackBarStore = useGlobalSnackbarStore()
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone()
  const [open, setOpen] = React.useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => setOpen(false)

  const sendDocumentMutation = useMutation(
    async (file: File | undefined) => {
      const formData = new FormData()
      if (file) formData.append('file', file)
      const response = await AdoptersClient.addSignedCertificateOrContractToAdoptionAttempt(
        adopter.id,
        adoptionAttemptId,
        type,
        formData
      )
      return response
    },
    {
      onSuccess: (data) => {
        setAdoptionAttempt(data)
        adopter.adoptionAttempts = adopter.adoptionAttempts?.map((a) => (a.id === adoptionAttemptId ? data : a))
        globalSnackBarStore.triggerSuccessMessage('Vos modifications ont bien été enregistrées')
      },
      onError: (error: AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(error.message)
      },
    }
  )

  return (
    <Box display="flex" sx={{ justifyContent: { sm: 'flex-end' } }}>
      <Button
        variant="contained"
        sx={{
          textTransform: 'none',
          fontSize: { xs: '14px', sm: '16px' },
          width: { xs: '100%', sm: 'auto' },
        }}
        onClick={() => setOpen(true)}
      >
        {type === 'sterilization' ? 'Stérilisation déjà validée' : "J'ai récupéré le document signé"}
      </Button>
      <Dialog onClose={() => console.log('trying to close')} open={open}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: { xs: 'auto', sm: 'center' },
            justifyContent: { xs: 'auto', sm: 'center' },
            p: 2,
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: blue, fontSize: { xs: 18, sm: 20 }, fontWeight: 700, textAlign: { xs: 'center', sm: 'auto' } }}
          >
            {type === 'sterilization'
              ? "Ajout du certificat fourni par l'adoptant ici (optionnel)"
              : "Ajout du document signé par l'adoptant ici (optionnel)"}
          </Typography>
          {isMobile ? (
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              <Box>
                <Typography
                  variant="h4"
                  component="p"
                  fontSize={16}
                  textAlign="center"
                  color={colors.black}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    flexGrow: 1,
                  }}
                >
                  {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                </Typography>
              </Box>
              <Button variant="contained" sx={{ width: '100%', mt: 2, textTransform: 'none' }}>
                Parcourir
              </Button>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              padding={3}
              {...getRootProps({
                sx: {
                  ...spacingItem,
                  border: 3,
                  borderRadius: 5,
                  borderStyle: 'dashed',
                  borderColor: isDragActive ? 'green' : blue,
                  backgroundColor: isDragActive
                    ? 'lightgreen'
                    : acceptedFiles[0]
                    ? setOpacity(colors.geyser, 0.4)
                    : 'transparent',
                  transition: 'all 0.3s',
                },
              })}
            >
              <input {...getInputProps()} />
              <UploadFile sx={{ color: blue, fontSize: 64, mb: 1 }} />
              <Typography variant="h4" component="p" fontSize={20} textAlign="center">
                Glissez-déposer ou
                <br />
                Insérer un document
              </Typography>
              <Box
                marginTop={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  maxWidth: '100%',
                  mb: 2,
                }}
              >
                <Typography variant="h4" component="p" fontSize={18} textAlign="center" sx={{ whiteSpace: 'nowrap' }}>
                  Votre document:
                </Typography>
                <Typography
                  variant="h4"
                  component="p"
                  fontSize={18}
                  textAlign="center"
                  color={colors.black}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    flexGrow: 1,
                  }}
                >
                  {acceptedFiles[0]?.name || 'Aucun fichier sélectionné'}
                </Typography>
              </Box>
              <Button variant="contained" sx={{ width: '40%', mt: 1, textTransform: 'none', fontSize: '16px' }}>
                Parcourir
              </Button>
            </Box>
          )}
        </Box>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pb: { xs: 2, sm: 2 },
            pt: { xs: 0, sm: 2 },
            px: { sm: 2 },
          }}
        >
          <LoadingButton
            variant="outlined"
            sx={{ textTransform: 'none', fontSize: { xs: '14px', sm: '16px' }, width: { xs: '50%', sm: 'auto' } }}
            onClick={handleClose}
          >
            Annuler
          </LoadingButton>
          <LoadingButton
            variant="contained"
            sx={{ textTransform: 'none', fontSize: { xs: '14px', sm: '16px' }, width: { xs: '50%', sm: 'auto' } }}
            onClick={() => sendDocumentMutation.mutate(acceptedFiles[0])}
            loading={sendDocumentMutation.isLoading}
          >
            Valider
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
