import { DescriptionOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { getAdoptionAttemptDocumentsSrc } from '../../../../utils/S3-links'
import { DisplayChipLikeTextField } from '../../../common/DisplayChipLikeTextField'
import { blueSky, theme } from '../../../theme'
import { AdoptionAttemptDocumentDtoType } from '../../../../interactors/gen/backendClient'
import { getReadableAdoptionDocumentType } from '../../../../domain/Adopters/AccountDisplay'

interface DocumentItemProps {
  type: 'certificate' | 'contract' | 'sterilization' | AdoptionAttemptDocumentDtoType
  documentKey: string
  uploadedAt: string
  fullName: string
}

export const AdoptionDocumentItem: React.FC<DocumentItemProps> = ({ type, documentKey, uploadedAt, fullName }) => {
  /* const sxField = { cursor: 'disabled', input: { cursor: 'not-allowed' } } */

  const translatedType =
    type === 'certificate'
      ? 'Certificat de connaissance signé'
      : type === 'sterilization'
      ? 'Certificat de stérilisation'
      : type === 'contract'
      ? "Contrat d'adoption signé"
      : getReadableAdoptionDocumentType(type)

  return (
    <Box mt={2} sx={{ border: { xs: 1, sm: 1, md: 0 }, borderRadius: { xs: 2, md: 0 }, padding: { xs: 2, md: 0 } }}>
      <Grid container spacing={2}>
        <Grid item xs={1.8} sm={0.8}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <DescriptionOutlined sx={{ width: 42, height: 42, color: blueSky }} />
          </Box>
        </Grid>
        <Grid item xs={10.2} sm={5.6} md={3}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <DisplayChipLikeTextField
              label="Nom du document"
              value={translatedType}
              fullWidth
              documentPage
              color={blueSky}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.6} md={3}>
          {/* <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <TextField
              label="Libellé"
              type="text"
              fullWidth
              value={document.reference}
              sx={sxField}
              size="small"
              inputProps={{ readOnly: true }}
            />
          </Box> */}
        </Grid>
        <Grid item xs={12} sm={5} md={2.2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="center"
            height="100%"
            sx={{ color: theme.palette.grey[500] }}
          >
            <Box
              display="flex"
              flexDirection={{ xs: 'row', sm: 'column' }}
              justifyContent={{ xs: 'space-between', sm: 'center' }}
            >
              <Typography variant="body2" fontSize={14}>
                Signé le {new Date(uploadedAt).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" fontSize={14}>
                Par {fullName}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={5.5} md={2.2}>
          <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center" height="100%">
            <Button variant="contained" href={getAdoptionAttemptDocumentsSrc(documentKey)} target="_blank">
              Consulter
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
