import { Box, IconButton, Menu, MenuItem, Tab, Tabs, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { fullName } from '../../domain/Adopters/AccountDisplay'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AdopterDto } from '../../interactors/gen/backendClient'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
/* import { AdopterPreAdoptionForm } from './AdopterPreAdoptionForm' */
import { MoreHoriz } from '@mui/icons-material'
import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { useAccountsStore } from '../../store/AccountsStore'
import { readableAxiosError } from '../../utils/axios'
import { PATHS } from '../PATHS'
import { theme } from '../theme'
import InactivityPopup from '../utils/InactivityPopup'
import { AdopterGeneralInfo } from './AdopterGeneralInfo'
import { AdopterOnGoingAdoptionTab } from './AdopterOnGoingAdoptions/AdopterOnGoingAdoptionTab'
import { AdopterPastAdoptionsTab } from './AdopterPastAdoptions/AdopterPastAdoptionsTab'
import { AdopterProfil } from './AdopterProfil'
import { AdopterSuspendedAdoptionTab } from './AdopterSuspendedAdoptions/AdopterSuspendedAdoptionTab'
import { DeleteAdopterPrompt } from './DeleteAdopterPrompt'
import { FastLaunchProcedureDialog } from './FastLaunchProcedureDialog'

export type TabKey = 'profil' | 'ongoing-adoption' | 'past-adoption' | 'suspended-adoption'

//Hard coded for now. I don't know if Adopters will have their own account.
const isOwnAccount = false

export const AdopterDetailsScreen = () => {
  const queryParams = new URLSearchParams(location.search)
  const [adopter, setAdopter] = useState<AdopterDto | null>(null)
  const { adopterId } = useParams() as { adopterId: string }
  const adoptersStore = useAdoptersStore()
  const accountsStore = useAccountsStore()
  /* const isReader = accountsStore.connectedAccount?.permission === AccountDtoPermission.Read */
  const globalSnackBarStore = useGlobalSnackbarStore()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [adoptionPopUpOpen, setAdoptionPopUpOpen] = useState(false)

  const navigate = useNavigate()
  const [currentTabIndex, setCurrentTabIndex] = useState<TabKey>('profil')

  const memberProfil = accountsStore.members.find((member) => member.email === adopter?.email)

  const handleTabChange = (_: React.SyntheticEvent | null, newTabKey: TabKey) => {
    setCurrentTabIndex(newTabKey)
    navigate(`/suivi-adoptant/${adopterId}?tab=${newTabKey}`)
  }

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  useFetchOrRefreshAdoptersOnMount()

  useEffect(() => {
    const loadAdopter = async () => {
      const selectedAdopter = adoptersStore.adopters.find((anAdopter) => adopterId === anAdopter.id)
      // Fix to remove the 0 in front of the phone number and assign +33 by default. This is not a problem because new users will not have a 0 in front of their phone number.
      if (selectedAdopter?.phoneNumber && selectedAdopter?.phoneNumber.startsWith('0')) {
        const newPhoneNumber = selectedAdopter?.phoneNumber.replace('0', '+33 ')
        setAdopter({ ...selectedAdopter, phoneNumber: newPhoneNumber })
      } else {
        console.log(selectedAdopter)
        setAdopter(selectedAdopter || null)
      }
    }

    loadAdopter()
  }, [adopterId, adoptersStore.adopters])

  //Mutation to edit the adopter
  const editAdopterMutation = useMutation(
    async (data: AdopterDto) => {
      const response = await AdoptersClient.editAccount(data)
      return response
    },
    {
      onSuccess: (account) => {
        globalSnackBarStore.triggerSuccessMessage(`Le profil de ${account.firstName} a été modifié.`)
        setAdopter(account)
      },
      onError: (error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === StatusCodes.CONFLICT) {
            globalSnackBarStore.triggerErrorMessage('Un utilisateur existe déjà avec cette addresse mail.')
            return
          }
        }

        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  //Global submit function used by all the form components
  const onSubmit = async (data: Partial<AdopterDto>) => {
    console.log('On submit', data)

    const newAdopter: AdopterDto = {
      ...adopter!,
      ...data,
      phoneNumber: ['+33', ''].includes(data.phoneNumber?.trim() || '') ? undefined : data.phoneNumber,
    }

    setAdopter(newAdopter)

    editAdopterMutation.mutate(newAdopter)
  }

  function a11yProps(tabKey: TabKey) {
    return {
      id: `simple-tab-${tabKey}`,
      value: tabKey,
      'aria-controls': `simple-tabpanel-${tabKey}`,
      key: tabKey,
    }
  }

  useEffect(() => {
    const tab = queryParams.get('tab') as TabKey | null
    if (tab !== null) {
      setCurrentTabIndex(tab)
    }
  }, [queryParams.get('tab')])

  useEffect(() => {
    const newAdoption = queryParams.get('newAdoption')
    if (newAdoption === 'true') {
      setAdoptionPopUpOpen(true)
    }
  }, [queryParams.get('newAdoption')])

  useEffect(() => {
    const tab = new URLSearchParams(window.location.search).get('tab') as TabKey | null
    if ((tab === null || tab === 'profil') && adopter?.profileHasBeenAccepted === 'accepted') {
      if (adopter.adoptionAttempts && adopter.adoptionAttempts.length > 0) {
        return handleTabChange(null, 'ongoing-adoption')
      }
      /* if (!isReader) {
        if (adopter.desiredAnimal?.hasDesiredAnimal) {
          return openDialog()
        }
        if (!adopter.adoptionAttempts) {
          return openDialog()
        }
      } */

      return handleTabChange(null, 'past-adoption')
    }
  }, [adopter?.profileHasBeenAccepted])

  if (!adopter) {
    return null
  }

  return (
    <Box
      sx={{
        padding: isMobile ? 1 : 2,
        mb: isMobile ? 2 : 0,
        backgroundColor: 'grey.100',
        minHeight: '100%',
      }}
    >
      <InactivityPopup />
      <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: isMobile ? 0 : '5rem' }}>
        <Box display="flex" alignItems="center" mb={isMobile ? 1 : 'auto'}>
          <Typography
            variant="h4"
            sx={{
              marginLeft: isMobile ? 2 : 2,
              marginTop: isMobile ? 3 : 0,
            }}
          >
            {adopter ? fullName(adopter) : undefined}
          </Typography>
          {
            /* Menu pour gérer le changement de mot de passe et la suppression du compte. */
            (accountsStore.canEdit() || isOwnAccount) && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                sx={{
                  marginTop: 1,
                  marginLeft: 1,
                }}
              >
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    mt: isMobile ? 3 : 'auto',
                  }}
                >
                  <MoreHoriz />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {memberProfil && (
                    <MenuItem onClick={() => navigate(`/${PATHS.suiviBenevole.relative}/${memberProfil.id}`)}>
                      Voir le profil membre
                    </MenuItem>
                  )}
                  <DeleteAdopterPrompt adopter={adopter!} />
                </Menu>
              </Box>
            )
          }
        </Box>
        <AdopterGeneralInfo
          adopter={adopter}
          isOwnAccount={isOwnAccount}
          onSubmit={onSubmit}
          isLoading={editAdopterMutation.isLoading}
          changeTab={handleTabChange}
        />

        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
          >
            <Tab label="Profil" {...a11yProps('profil')} />
            <Tab label="Adoptions en cours" {...a11yProps('ongoing-adoption')} />
            <Tab label="Adoptions terminées" {...a11yProps('past-adoption')} />
            <Tab label="Adoptions suspendues" {...a11yProps('suspended-adoption')} />
          </Tabs>
        </Box>

        <TabPanel value={currentTabIndex} index={'profil' as TabKey}>
          <AdopterProfil adopter={adopter} onSubmit={onSubmit} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'ongoing-adoption' as TabKey}>
          <AdopterOnGoingAdoptionTab adopter={adopter} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'past-adoption' as TabKey}>
          <AdopterPastAdoptionsTab adopter={adopter} changeTab={handleTabChange} />
        </TabPanel>

        <TabPanel value={currentTabIndex} index={'suspended-adoption' as TabKey}>
          <AdopterSuspendedAdoptionTab adopter={adopter} />
        </TabPanel>

        {/* <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <AdopterForm adopter={adopter} isOwnAccount={isOwnAccount} onSubmit={onSubmit} sx={spacingItem} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <AdopterPreferences adopter={adopter} isOwnAccount={isOwnAccount} onSubmit={onSubmit} sx={spacingItem} />
          </Grid>
        </Grid>
        <AdopterOwnAnimalsCard adopterId={adopterId} sx={spacingItem} /> */}

        {/* Hard passing preAdoptionDone to false for now. Needs to be updated when backend is ready. */}
        {/* Commented for now, will be used when backend is ready. */}
        {/* <AdopterPreAdoptionForm sx={spacingItem} preAdoptionDone={false} /> */}

        {/* <AddNewAdoptionPopUp adopter={adopter} isDialogOpen={isDialogOpen} closeDialog={closeDialog} /> */}
        <FastLaunchProcedureDialog
          adopter={adopter}
          open={adoptionPopUpOpen}
          setOpen={setAdoptionPopUpOpen}
          noAdopterDelete
          needNavigate
        />
      </Box>
    </Box>
  )
}

interface TabPanelProps<T> {
  children?: React.ReactNode
  index: T
  value: T
}

function TabPanel<T>(props: TabPanelProps<T>) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {/* <Typography>{children}</Typography> */}
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  )
}
